import React from "react";
import { useState, useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  Paper,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  styled,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "app/components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { H2, H1, H3, h6 } from "app/components/Typography";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { countries } from "countries-list";
// import Select from 'react-select'

const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginTop: "-8px",
  marginLeft: "44px",
  gap: "5px",
}));

const EditSuervisor = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [userUuid, setUserUid] = useState("");
  const navigate = useNavigate();

  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [status, setStatus] = useState("");
  const [tenantlimit, setTenantLimit] = useState("");
  const [totalSims, setTotalSims] = useState("");
  const [enabled, setEnabled] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [phoneVerified, setPhoneVerified] = useState("");
  const [otpVerified, setOtpVerified] = useState("");
  const [otp, setOtp] = useState("");
  const [username, setUsername] = useState("");

  const [phone, setPhone] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // This is for the Shipping Address
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingPostalCode, setShippingPostalCode] = useState("");

  const storedUserUuid = localStorage.getItem("edit_user_uuid");
  const storedEmail = localStorage.getItem("edit_emailId");
  const [countryList, setCountryList] = useState([]);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState("1");
  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setUserUid(storedUserUuid);
    const fetchUserData = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        UserUUID: storedUserUuid,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${REACT_APP_BASE_URL}/user/api/v1/get_client`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setFirstname(result.Account_details.get_user[0].FirstName);
          setLastname(result.Account_details.get_user[0].LastName);
          setPhone(result.Account_details.get_user[0].PhoneNumber);
          setDob(result.Account_details.get_user[0].DateOfBirth);
          setAddress(result.Account_details.get_user[0].Address);
          setPassword(result.Account_details.get_user[0].Password.slice(-1, 0));
          setStatus(result.Account_details.get_user[0].Status);
          setEnabled(result.Account_details.get_user[0].Enabled);
          setTenantLimit(result.Account_details.get_user[0].MaxTenantLimit);
          setTotalSims(result.Account_details.get_user[0].TotalSims);
          setEmailVerified(result.Account_details.get_user[0].EmailVerified);
          setPhoneVerified(result.Account_details.get_user[0].PhoneVerified);
          setOtp(result.Account_details.get_user[0].Otp);
          setOtpVerified(result.Account_details.get_user[0].OtpVerified);
          setUsername(result.Account_details.get_user[0].Username);
          setState(result.Account_details.get_user[0].State);
          setCity(result.Account_details.get_user[0].City);
          setAddressLine1(result.Account_details.get_user[0].AddressLine1);
          setAddressLine2(result.Account_details.get_user[0].AddressLine2);
          setAddressLine3(result.Account_details.get_user[0].AddressLine3);
          setSelectedCountry(result.Account_details.get_user[0].Country);
          setState(result.Account_details.get_user[0].State);
          setCity(result.Account_details.get_user[0].City);
          setPostalCode(result.Account_details.get_user[0].PostalCode);
          setShippingAddress1(
            result.Account_details.get_user[0].ShippingAddress1
          );
          setShippingAddress2(
            result.Account_details.get_user[0].ShippingAddress2
          );
          setShippingAddress3(
            result.Account_details.get_user[0].ShippingAddress3
          );
          setShippingCountry(
            result.Account_details.get_user[0].ShippingCountry
          );
          setShippingState(result.Account_details.get_user[0].ShippingState);
          setShippingCity(result.Account_details.get_user[0].ShippingCity);
          setShippingPostalCode(
            result.Account_details.get_user[0].ShippingPostalCode
          );
        });
    };
    fetchUserData();
  }, []);

  const handleUpdateUser = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !addressLine1 ||
      !addressLine2 ||
      !state ||
      !city ||
      !postalCode ||
      !shippingAddress1 ||
      !shippingAddress2 ||
      !shippingState ||
      !shippingCity ||
      !shippingPostalCode
    ) {
      // Handle validation error, show a message, or prevent proceeding to the next step
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var raw = JSON.stringify({
      UserUUID: storedUserUuid,
      FirstName: firstName,
      LastName: lastName,
      Address: address,
      Password: password,
      OldPassword: "dsfads",
      PhoneNumber: phone,
      Status: status,
      Enabled: enabled,
      EmailId: storedEmail,
      EmailVerified: emailVerified,
      PhoneVerified: phoneVerified,
      Otp: otp,
      OtpVerified: otpVerified,
      MaxTenantLimit: tenantlimit,
      TotalSims: totalSims,
      ActiveSims: "23",
      SuspendedSims: "3",
      MTDUsage: "",
      PrepaidBalance: "",
      OutstandingBalace: "",
      PastDueBalance: "",
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      Country: selectedCountry,
      City: city,
      State: state,
      PostalCode: postalCode,
      ShippingAddress1: shippingAddress1,
      ShippingAddress2: shippingAddress2,
      ShippingAddress3: shippingAddress3,
      ShippingCountry: shippingCountry,
      ShippingCity: shippingCity,
      ShippingState: shippingState,
      ShippingPostalCode: shippingPostalCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/user/api/v1/update_Client`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        navigate("/client/client");
        if (result.Status === 1) {
          toast("Details Updated Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      });
  };

  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));

  return (
    <>
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center" }}
        >
          <H3>Edit Client | </H3>

          <a
            href="/client/client"
            style={{
              margin: "10px",
              color: "#8224e3",
              textDecoration: "none",
            }}
          >
            Clients &gt;
          </a>
          <p
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              margin: "0",
            }}
          >
            Edit Client
          </p>
        </Box>
        <Paper
          style={{ marginTop: "2rem" }}
          elevation={3}
          sx={{ padding: "20px" }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Client Details
          </Typography>
          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
             
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Update General Details" value="1" />
                  <Tab label="Update Address" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div>
                  <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                    <Grid container spacing={3} style={{ marginTop: "5px" }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          label="First Name"
                          variant="outlined"
                          inputProps={{ tabIndex: "1" }}
                          value={firstName}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          label="Last Name"
                          variant="outlined"
                          inputProps={{ tabIndex: "2" }}
                          value={lastName}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          variant="outlined"
                          inputProps={{ tabIndex: "3" }}
                          // type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibilityToggle}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          padding: "0px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                          style={{
                            width: "77%",
                            marginLeft: "20px",
                            marginTop: "15px",
                          }}
                          containerStyle={{}}
                          inputStyle={{
                            background: "transparent",
                            width: "100%",
                            padding: "14px 14px 14px 60px",
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Button
                      variant="outlined"
                      color="inherit"
                      style={{
                        width: "30%",
                        fontFamily: "poppins",
                        fontSize: "15px",
                        color: "#9d4edd",
                        border: "1px solid #9d4edd",
                        borderRadius: "6px",
                        marginTop: "30px",
                      }}
                      onClick={handleUpdateUser}
                      sx={{ marginTop: "20px", ml: "300px" }}
                    >
                      Update
                    </Button>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div
                  className="addressBox"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="currentAddress"
                    style={{
                      width: "48%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "17px",
                      }}
                    >
                      Current Address
                      <hr />
                    </p>
                    <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={addressLine3}
                        onChange={(e) => setAddressLine3(e.target.value)}
                      />

                      <Select
                        labelId="country-label"
                        value={selectedCountry}
                        onChange={handleCurrentCountry}
                        style={{
                          width: "81%",
                          height: "51px",
                          marginLeft: "41px",
                          marginTop: "12px",
                        }}
                      >
                        <MenuItem disabled>--Select Country </MenuItem>
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </ValidatorForm>
                  </div>
                  <div
                    className="shippingAddress"
                    style={{
                      width: "48%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "17px",
                      }}
                    >
                      Shipping Address
                      <hr />
                    </p>
                    <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={shippingAddress1}
                        onChange={(e) => setShippingAddress1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress2}
                        onChange={(e) => setShippingAddress2(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress3}
                        onChange={(e) => setShippingAddress3(e.target.value)}
                      />

                      <Select
                        labelId="country-label"
                        value={selectedCountry}
                        onChange={handleCurrentCountry}
                        style={{
                          width: "81%",
                          height: "51px",
                          marginLeft: "41px",
                          marginTop: "12px",
                        }}
                      >
                        <MenuItem disabled>--Select Country </MenuItem>
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingState}
                        onChange={(e) => setShippingState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingCity}
                        onChange={(e) => setShippingCity(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingPostalCode}
                        onChange={(e) => setShippingPostalCode(e.target.value)}
                      />
                    </ValidatorForm>
                  </div>
                </div>
                <form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    style={{
                      width: "30%",
                      fontFamily: "poppins",
                      fontSize: "15px",
                      color: "#9d4edd",
                      border: "1px solid #9d4edd",
                      borderRadius: "6px",
                      marginTop: "30px",
                    }}
                    onClick={handleUpdateUser}
                    sx={{ marginTop: "20px", ml: 5 }}
                  >
                    Update
                  </Button>
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default EditSuervisor;
