import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import EditTenant from '../Tenant/EditTenant';
import CreateSupervisor from '../supervisors/CreateSupervisor';
import EditSupervisor from '../supervisors/EditSupervisor';
import ActiveTenants from '../Tenant/ActiveTenants';
import AssignSim from '../Tenant/AssignSim';
import InactiveTenants from '../Tenant/InactiveTenants';
import DisabledTenants from '../Tenant/DisabledTenants';
import ActiveSupervisor from '../supervisors/ActiveSupervisor';
import InactiveSupervisor from '../supervisors/InactiveSupervisor';
import DisabledSupervisor from '../supervisors/DisabledSupervisor';
import EmailSMTP from 'app/components/SMS/EmailSMTP';
import SMSGateways from 'app/components/SMS/SMSGateways';
import MailTemplates from 'app/components/SMS/MailTemplates';
import MailNotification from 'app/components/SMS/MailNotification';
import CDR from 'app/components/Reports/CDR';
import ActiveAccounts from 'app/components/Reports/ActiveAccounts';
import ESimReports from 'app/components/Reports/eSimReports';
import UsageReports from 'app/components/Reports/UsageReports';
import BillingReports from 'app/components/Reports/BillingReports';
import InactiveAccounts from 'app/components/Reports/InactiveAccounts';
import SuspendedAccounts from 'app/components/Reports/SuspendedAccounts';
import TerminatedAccounts from 'app/components/Reports/TerminatedAccounts';
import Support from 'app/Support/Support';
import Active_accounts from 'app/components/TenantReports/Active_accounts';
import Billing_Reports from 'app/components/TenantReports/Billing_Reports';
import CDR_ from 'app/components/TenantReports/CDR_';
import E_SimReports from 'app/components/TenantReports/eSim_Reports';
import Inactive_accounts from 'app/components/TenantReports/InActive_accounts';
import Suspended_accounts from 'app/components/TenantReports/Suspended_accounts';
import Terminated_accounts from 'app/components/TenantReports/Terminated_accounts';
import Inventorygroup from '../Tenant/Inventorygroup';
import BillingDetails from '../Tenant/BillingDetails';
import BillingCreateGroup from '../Tenant/BillingCreateGroup';
import SimInfo from '../Tenant/SimInfo';
import UserSettings from '../sessions/UserSettings';
import SignUp from '../sessions/SignUp';
import InventoryInfo from '../Tenant/InventoryInfo';
import User from '../users/User';
import EditUSer from '../users/EditUSer';
import SimData from '../users/SimData';
import EmailSMTP_T from 'app/components/TenantSMS/EmailSMTP_T';
import MailTemplates_T from 'app/components/TenantSMS/MailTemplates_T';
import SMSGateways_T from 'app/components/TenantSMS/SMSGateways_T';
import AssignInventory from '../Admin/AssignInventory';
import TenantDetails from '../Tenant/TenantDetails';
// import ClientInfo from '../users/ClientInfo';
import ClientDetails from '../users/ClientDetails';
import ClientSim from '../users/ClientSim';
import AdminInventories from '../Admin/AdminInventories';
import AdminSIMs from '../Admin/AdminSIMs';
import AdminBillingGroup from '../Admin/AdminBillingGroup';
import AdminSIMInfo from '../Admin/AdminSIMInfo';
import SimOverview from '../Tenant/SimOverview';
import TenantSimInfo from '../Tenant/TenantSimInfo';
import CreatePackage from '../Admin/CreatePackage';
import Package from '../Admin/Package';
import AssignPackage from '../Admin/AssignPackage';
import AdminInventoryInfo from '../Admin/AdminInventoryInfo';
import PackageInfo from '../Admin/PackageInfo'
import AdminInventoriesDetails from '../Admin/AdminInventoriesDetails';
import InventorySims from '../Admin/InventorySims'
import Success from '../Stripe/Success';
import Cancel from '../Stripe/Cancel';
import CDR_Details from 'app/components/TenantReports/CDR_Details';
import InventorySimDetails from '../Tenant/InventorySimDetails';
import { InventoryeSim } from '../Tenant/InventoryeSims';
import ParentGroupIDDetails from '../Tenant/ParentGroupIDDetails';
import AllocatedSims from '../Admin/AllocatedSims';
import Billing from '../Tenant/Billing';
import AdminBilling from '../Admin/AdminBilling'
import TenantPackages from '../Tenant/TenantPackages'
import AdminTotalSims from '../Admin/AdminTotalSims'
import InService_Admin from '../Admin/InService_Admin'
import Suspended_Admin from '../Admin/Suspended_Admin'
import Preservice_Admin from '../Admin/Preservice_Admin'
import InService_Tenant from '../Tenant/InService_Tenant'
import Preservice_Tenant from '../Tenant/Preservice_Tenant'
import PaymentScreen from '../Tenant/PaymentScreen'
import Pending_Tenant_SIMs from '../Tenant/Pending_Tenant_SIMs'
import CDR_Details_A from 'app/components/Reports/CDR_Details_A'
import Assigned_Tenant_SIMs from '../Tenant/Assigned_Tenant_SIMs'
import ClientTotalSIM from '../dashboard/All_Dashboards/ClientTotalSIM'




const AppTable = Loadable(lazy(() => import('./tables/AppTable')));
const AppForm = Loadable(lazy(() => import('./forms/AppForm')));
const AppButton = Loadable(lazy(() => import('./buttons/AppButton')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const AppProgress = Loadable(lazy(() => import('./AppProgress')));
const AppMenu = Loadable(lazy(() => import('./menu/AppMenu')));
const AppCheckbox = Loadable(lazy(() => import('./checkbox/AppCheckbox')));
const AppSwitch = Loadable(lazy(() => import('./switch/AppSwitch')));
const AppRadio = Loadable(lazy(() => import('./radio/AppRadio')));
const AppSlider = Loadable(lazy(() => import('./slider/AppSlider')));
const AppDialog = Loadable(lazy(() => import('./dialog/AppDialog')));
const AppSnackbar = Loadable(lazy(() => import('./snackbar/AppSnackbar')));
const AppAutoComplete = Loadable(lazy(() => import('./auto-complete/AppAutoComplete')));
const AppExpansionPanel = Loadable(lazy(() => import('./expansion-panel/AppExpansionPanel')));
const Supervisor = Loadable(lazy(() => import('../supervisors/Supervisor')));
const Inventories = Loadable(lazy(() => import('../Tenant/Inventories')));
const BillingGroups = Loadable(lazy(() => import('../Tenant/BillingGroups')));
const SIMs = Loadable(lazy(() => import('../Tenant/eSIMs')));
// const UsersTable = Loadable(lazy(() => import('../users/CreateUser')));
const Tenant = Loadable(lazy(() => import('../Tenant/Tenant')));
const CreateTenant = Loadable(lazy(() => import('../Tenant/CreateTenant')));
const InventoryTemplate = Loadable(lazy(() => import('../Tenant/InventoryTemplate')));
// const ActiveTenant = Loadable(lazy(() => import('../Tenant/ActiveTenant')));

const materialRoutes = [
  { path: '/material/table', element: <AppTable /> },
  { path: '/material/form', element: <AppForm /> },
  { path: '/material/buttons', element: <AppButton /> },
  { path: '/material/icons', element: <AppIcon /> },
  { path: '/material/progress', element: <AppProgress /> },
  { path: '/material/menu', element: <AppMenu /> },
  { path: '/material/checkbox', element: <AppCheckbox /> },
  { path: '/material/switch', element: <AppSwitch /> },
  { path: '/material/radio', element: <AppRadio /> },
  { path: '/material/slider', element: <AppSlider /> },
  { path: '/material/autocomplete', element: <AppAutoComplete /> },
  { path: '/material/expansion-panel', element: <AppExpansionPanel /> },
  { path: '/material/dialog', element: <AppDialog /> },
  { path: '/material/snackbar', element: <AppSnackbar /> },
  { path: '/client/client', element: <Supervisor /> },
  { path: '/client/assign-sim', element: <AssignSim /> },
  { path: '/client/add-Client', element: <CreateSupervisor /> },
  { path: '/tenants/Tenants', element: <Tenant /> },
  { path: '/users/Users', element: <User /> },
  { path: '/tenants/add-Tenant', element: <CreateTenant /> },
  { path: '/tenant/edit-tenant', element: <EditTenant /> },
  { path: '/tenant/Inactive-tenants', element: <InactiveTenants /> },
  { path: '/tenant/Disabled-tenants', element: <DisabledTenants /> },
  { path: '/tenant/active-tenants', element: <ActiveTenants /> },
  { path: '/client/edit-client', element: <EditSupervisor /> },
  { path: '/user/edit-user', element: <EditUSer /> },
  { path: '/client/active-client', element: <ActiveSupervisor /> },
  { path: '/client/inactive-client', element: <InactiveSupervisor /> },
  { path: '/client/disabled-client', element: <DisabledSupervisor /> },
  { path: '/sms/emailsmtp', element: <EmailSMTP /> },
  { path: '/sms/SMSGateways', element: <SMSGateways /> },
  { path: '/sms/mailtemplates', element: <MailTemplates /> },
  { path: '/SMS/MailNotification', element: <MailNotification /> },
  { path: '/reports/CDR', element: <CDR /> },
  { path: '/reports/activeacount', element: <ActiveAccounts /> },
  { path: '/reports/eSimReports', element: <ESimReports /> },
  { path: '/reports/UsageReports', element: <UsageReports /> },
  { path: '/reports/BillingReports', element: <BillingReports /> },
  { path: '/reports/inactiveaccounts', element: <InactiveAccounts /> },
  { path: '/reports/suspendedaccounts', element: <SuspendedAccounts /> },
  { path: '/reports/terminatedaccounts', element: <TerminatedAccounts /> },
  { path: '/supports', element: <Support /> },
  { path: '/inventory/inventory', element: <Inventories /> },
  { path: 'inventory/admin-inventory', element: <AdminInventories /> },
  { path: '/inventory/groups', element: <BillingGroups /> },
  { path: 'inventory/sims', element: <SIMs /> },
  { path: '/inventory/admin-sims', element: <AdminSIMs /> },
  { path: '/allocated-sims', element: <AllocatedSims /> },
  { path: 'inventory/admin-siminfo', element: <AdminSIMInfo /> },
  { path: '/inventory/admin-inventoryinfo', element: <AdminInventoryInfo /> },
  { path: '/inventory/inventory-details', element: <AdminInventoriesDetails /> },
  { path: '/inventory/sims-inventory', element: <InventorySims /> },
  { path: 'billingGroup/admin-group', element: <AdminBillingGroup /> },
  { path: '/reports/activeacount_tenant', element: <Active_accounts /> },
  { path: '/reports/BillingReports_tenant', element: <Billing_Reports /> },
  { path: '/reports/CDR_tenant', element: <CDR_ /> },
  { path: '/reports/eSimReports_tenant', element: <E_SimReports /> },
  { path: '/reports/suspendedaccounts_tenant', element: <Suspended_accounts /> },
  { path: '/reports/terminatedaccounts_tenant', element: <Terminated_accounts /> },
  { path: '/reports/inactiveaccounts_tenant', element: <Inactive_accounts /> },
  { path: '/inventory/inventory_details', element: <Inventorygroup /> },
  { path: '/billing_groups/group_details', element: <BillingDetails /> },
  { path: '/billing_create/create_group', element: <BillingCreateGroup /> },
  // { path: '/sims/simInfo', element: <SimInfo /> },
  { path: '/sims/simInfo', element: <SimOverview /> },
  { path: '/inventory/inventory_details', element: <Inventorygroup /> },
  { path: '/billing_groups/group_details', element: <BillingDetails /> },
  { path: '/sessions/UserSettings', element: <UserSettings /> },
  { path: '/inventory/info', element: <InventoryInfo /> },
  { path: '/inventory/template', element: <InventoryTemplate /> },
  { path: '/inventory/template', element: <InventoryTemplate /> },
  { path: '/sims/simData', element: <SimData /> },
  { path: '/sms/emailsmtp_tenant', element: <EmailSMTP_T /> },
  { path: '/sms/mailtemplates_tenant', element: <MailTemplates_T /> },
  { path: '/sms/SMSGateways_tenant', element: <SMSGateways_T /> },
  { path: '/assign-inventory', element: <AssignInventory /> },
  { path: '/tenant/tenantdetails', element: <TenantDetails /> },
  // { path: '/client/clientinfo, element: <ClientInfo/>' },
  { path: '/client/clientdetails', element: <ClientDetails /> },
  { path: '/client/clientsim', element: <ClientSim /> },
  { path: '/inventory/tenant-siminfo', element: <TenantSimInfo /> },
  { path: '/package/create-package', element: <CreatePackage /> },
  { path: '/package', element: <Package /> },
  { path: '/assign-package', element: <AssignPackage /> },
  { path: '/package/package-info', element: <PackageInfo /> },
  { path: '/success', element: <Success /> },
  { path: '/cancel', element: <Cancel /> },
  { path: '/CDR_Details', element: <CDR_Details /> },
  {path: '/inventory/esim-details', element: <InventorySimDetails/>},
  {path: '/inventory/esim', element: <InventoryeSim/>},
  {path: '/parentGroupID-Details' , element: <ParentGroupIDDetails/>},
  {path: '/billing' , element: <Billing/>},
  {path: "/admin-billing" ,element: <AdminBilling/>},
  {path: '/tenant/package' , element: <TenantPackages/>},
  {path: '/admin/totalsims', element: <AdminTotalSims/>},
  {path: '/admin/pre_service_SIMs', element: <Preservice_Admin/>},
  {path: '/admin/in_service_SIMs', element: <InService_Admin/>},
  {path: '/tenant/pre_service_SIMs', element: <Preservice_Tenant/>},
  {path: '/tenant/in_service_SIMs', element: <InService_Tenant/>},
  {path: '/tenant/pending_SIMs', element: <Pending_Tenant_SIMs/>},
  {path: '/tenant/assigned_SIMs', element: <Assigned_Tenant_SIMs/>},
  {path: '/admin/cdr_details', element: <CDR_Details_A/>},
  {path: '/client/client-sims', element: <ClientTotalSIM/>},
  {path: '/payment', element: <PaymentScreen/>},
  {path: '/admin/suspended_SIMs', element: <Suspended_Admin/>}




];

export default materialRoutes;
