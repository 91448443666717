import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Modal from "@mui/material/Modal";
import iso3166 from "iso-3166-1";
import "./AssignInventoryStyle.css";

import {
  Box,
  Container,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Input,
  Button,
  Chip,
  Checkbox,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1, H3, H4 } from "app/components/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import countries from "../Admin/countries";
import AddIcon from "@mui/icons-material/Add";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "0px",
  p: 4,
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const AssignInventory = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const accessToken = localStorage.getItem("accessToken");

  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [apiData, setApiData] = useState({});
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [tenantList, setTenantList] = useState([]);
  const [tenantName, setTenantName] = useState("");
  const [selectedSupervisor, setSelectedSupervisor] = useState("");
  const DomainUUID = localStorage.getItem("DomainUUID");

  const [iccids, setIccids] = useState([]);
  const [selectedStartIccid, setSelectedIStartccid] = useState("");
  const [selectedEndIccid, setSelectedEndIccid] = useState("");
  const [selectedICCIDs, setSelectedICCIDs] = useState([]);

  const [inventoryList, setInventoryList] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState("");
  const [selectedIccids, setSelectedIccids] = useState([]);

  const [inventoryName, setInventoryName] = useState([]);
  const [billingGroup, setBillingGroup] = useState("");
  const [parentGroupId, setParentGroupId] = useState("");

  const [selectedPackage, setSelectedPackage] = useState("");
  const [packages, setPackages] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [checkBox1, setCheckBox1] = useState(false);
  const [basePrice, setBasePrice] = useState("");
  const [ratePrice, setRatePrice] = useState("");

  const [trialPeriod, setTrialPeriod] = useState("");
  const [startBillDate, setStartBillDate] = useState("");
  const [endBillDate, setEndBillDate] = useState("");
  const [checkBoxTrial, setCheckBoxTrial] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [trial1, setTrial1] = useState(false);
  const [ThrottleSpeedList, setThrottleSpeedList] = useState("");
  const [SelectedThrottleSpeed, setSelectedThrottleSpeed] = useState("");
  const [provider, setProvider] = useState("");
  const [Package, setPackage] = useState("");
  const [packgaeexpireDate, setPacakgeExpireDate] = useState("");

  const handleProviderChange = (e) => {
    setProvider(e.target.value);
  };

  const handlePackageType = (e) => {
    setPackage(e.target.value);
  };

  const getUserNameById = (TenantUUID) => {
    const user = tenantList
      ? tenantList.find((contact) => contact.TenantUUID === TenantUUID)
      : "";
    return user ? user.FirstName + " " + user.LastName : "";
  };

  const getInventoryNameById = (inventoryId) => {
    const inventoryName = inventoryList
      ? inventoryList.find((inventory) => inventory.id === inventoryId)
      : "";
    return inventoryName ? inventoryName.name : "";
  };

  const handleChangePackage = (event) => {
    setSelectedPackage(event.target.value);
  };

  const getPackageNameById = (PackageUUID) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.PackageUUID === PackageUUID
    );
    return selectedPackage ? selectedPackage.name : "";
  };

  const [groupStatus, setGroupStatus] = useState(true);

  const getGroupStatusById = (selectedSupervisor) => {
    const GroupStatus = tenantList.find(
      (status) => status.TenantUUID === selectedSupervisor
    );
    const status = GroupStatus ? GroupStatus.GroupStatus : "";
    setGroupStatus(status);
  };

  const convertUtcToLocal = (utcTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
    };

    const localTime = new Date(utcTimeString).toLocaleString("en-US", options);

    // Remove "at" from the time string
    const timeWithoutAt = localTime.replace(" at", "");

    return timeWithoutAt;
  };

  useEffect(() => {
    fetchpackages();
  }, []);

  const fetchpackages = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const packagevalue = result.Packages.map((packages) => packages.name);
        setPackages(result.Packages);
      })
      .catch(error);
  };

  //////////////for setting parent group ID from inventory detail API/////////////////////
  useEffect(() => {
    // Check if selectedInventory has data before calling getInventoryDetail
    if (selectedInventory) {
      getInventoryDetail();
    }
  }, [selectedInventory]);

  const getInventoryDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      inventoryId: selectedInventory,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/inventory_details_by_id`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setParentGroupId(result.InventoryData.parentGroupId);

        //-------------------------------------- set group ID----------------------------
      })

      .catch(error);
  };

  const cancelpage = () => {
    setOpen(false);
  };

  const createInventory = () => {
    if (groupStatus === false) {
      if (!selectedSupervisor || !inventoryName || !billingGroup) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
    } else {
      if (!selectedSupervisor || !inventoryName) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
      TenantUUID: selectedSupervisor,
      name: inventoryName,
      distributorId: 70083,
      providerId: 89457,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_inventory`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else if (result.Status == 1) {
          if (groupStatus === false) {
            createBillingGroup(result.InventoryData.id); //api to create group
          }

          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch(error);
    setOpen(false);
    setSelectedSupervisor("");
    setInventoryName("");
  };

  const createBillingGroup = (Inventoryid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      TenantUUID: selectedSupervisor,
      name: billingGroup,
      inventoryId: Inventoryid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/parent_group`, requestOptions)
      .then((response) => response.json())
      .then((result) => {})
      .catch(error);
  };

  const handleRemoveICCID = (iccid) => {
    // Update selectedICCIDs
    const updatedSelectedICCIDs = selectedICCIDs.filter((id) => id !== iccid);
    setSelectedICCIDs(updatedSelectedICCIDs);

    // Update selectedIccids
    const updatedSelectedIccids = selectedIccids.filter((id) => id !== iccid);
    setSelectedIccids(updatedSelectedIccids);
  };

  const handleStartIccidChange = (newValue) => {
    setSelectedIStartccid(newValue);
  };

  const handleEndIccidChange = (newValue) => {
    setSelectedEndIccid(newValue);

    setSelectedICCIDs([]);

    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === newValue);

    if (startIndex !== -1 && endIndex !== -1) {
      const idsInRange = iccids.slice(startIndex, endIndex + 1);
      setSelectedICCIDs(idsInRange);
    }
  };

  useEffect(() => {
    const fetchIccids = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_admin_sims`,
          requestOptions
        );
        const result = await response.json();
        if (result.Status === 1) {
          const iccidValues = result.AdminSims.map((sim) => sim.iccid);
          setIccids(iccidValues);
        } else {
        }
      } catch (error) {}
    };
    fetchIccids();
  }, []);

  useEffect(() => {
    fetchTenant();
  }, []);

  useEffect(() => {
    if (selectedSupervisor) {
      fetchInventory(selectedSupervisor);
    }
  }, [selectedSupervisor]);

  const fetchTenant = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const raw = "";
      const requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
        headers: myHeaders,
      };

      const response = await fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/get_tenants`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch tenant data");
      }

      const result = await response.json();
      if (result.Status === 1) {
        setTenantList(result.Account_details);
      }
    } catch (error) {}
  };

  const fetchInventory = async (tenantUUID) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        TenantUUID: tenantUUID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${REACT_APP_BASE_URL}/inventory_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch inventory data");
      }

      const result = await response.json();
      setInventoryList(result.InventoryList || []);
    } catch (error) {}
  };

  const handleToggleIccids = (iccid) => {
    const currentIndex = selectedIccids.indexOf(iccid);
    const newSelectedIccids = [...selectedIccids];

    if (currentIndex === -1) {
      newSelectedIccids.push(iccid);
    } else {
      newSelectedIccids.splice(currentIndex, 1);
    }

    setSelectedIccids(newSelectedIccids);
  };

  const sample = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(objectsArray);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/assign_sim_to_tenant`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      })
      .catch(error);
  };

  const handleCheckBoxTrial = () => {
    setCheckBoxTrial((prevCheckBox) => !prevCheckBox);
    setTrial1(!trial1);
  };

  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));
  const [objectsArray, setObjectsArray] = useState([]);

  const handleButtonClick = () => {
    const commaSeparatedIds = selectedICCIDs.join(",");
    const commaSeparatedIdsss = selectedIccids.join(",");

    const mergedCommaSeparatedIds = commaSeparatedIds.concat(
      ",",
      commaSeparatedIdsss
    );

    var formattedMergedIds = "";

    if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
      formattedMergedIds = `${mergedCommaSeparatedIds}`;
    }

    if (commaSeparatedIds == "") {
      formattedMergedIds = `${commaSeparatedIdsss}`;
    }
    if (commaSeparatedIdsss == "") {
      formattedMergedIds = `${commaSeparatedIds}`;
    }

    const idsArray = formattedMergedIds.split(",");

    // Map each ID without double quotes
    const formattedIdsArray = idsArray.map((id) => id.trim());

    // Join the array back into a string
    const resultOfSIMs = JSON.stringify(formattedIdsArray);

    if (startBillDate) {
      var startbilldate = new Date(startBillDate);
      var formattedStartBillDate = startbilldate
        .toISOString()
        .replace("Z", "+00:00");
    } else {
      formattedStartBillDate = "";
    }

    if (endBillDate) {
      var endbilldate = new Date(endBillDate);
      var formattedEndBillDate = endbilldate
        .toISOString()
        .replace("Z", "+00:00");
    } else {
      formattedEndBillDate = "";
    }

    const currentDate = new Date();
    const epochTimeMilliseconds = currentDate.getTime() - 10000;
    const dateEarliestActivation = epochTimeMilliseconds + 15000;
    const LatestAvailable = dateEarliestActivation + 1000000000;

    const BasePrice = parseFloat(basePrice);

    const epochMilliseconds = new Date(packgaeexpireDate).getTime();
    if (Package === "CAPPED") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      } else {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      }
    }

    if (Package === "AUTORENEW") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      } else {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      }
    }

    if (Package === "POSTPAID") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: ratePrice,
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: SelectedThrottleSpeed,
        };
      } else {
        var newObject = {
          TenantUUID: selectedSupervisor,
          iccidRange: formattedIdsArray,
          inventoryId: selectedInventory,
          parentGroupId: parentGroupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: ratePrice,
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountry,
          timeAllowanceInSeconds: 2592000,
          activationTimeAllowanceInSeconds: 2592000,
          dateEarliestAvailable: epochTimeMilliseconds, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: SelectedThrottleSpeed,
        };
      }
    }

    setObjectsArray((prevArray) => [...prevArray, newObject]);

    setPackage("");
    setSelectedPackage([]);
    setEndBillDate("");
    setStartBillDate("");
    setPacakgeExpireDate("");
    setBasePrice("");
    setSelectedCountry([]);
    setTrialPeriod("");
    setCheckBoxTrial(false);
    setSelectedICCIDs([]);
    setSelectedIccids([]);
    setSelectedThrottleSpeed("");
    setRatePrice("");
  };

  const [error, setError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const checkDate = (endDate, startDate) => {
    if (startBillDate && startDate) {
      const startTimestamp = new Date(startBillDate).getTime();
      const currentTimestamp = new Date(startDate).getTime();
      if (startTimestamp < currentTimestamp) {
        setDateError(true);
        return;
      } else {
        setDateError(false);
      }
    }

    if (startBillDate && endDate) {
      const startTimestamp = new Date(startBillDate).getTime();
      const endTimestamp = new Date(endDate).getTime();

      if (endTimestamp <= startTimestamp) {
        setError(true);
        return;
      } else {
        setError(false);
      }
    }
  };

  const handleDelete = (index) => {
    const newArray = [...objectsArray];
    newArray.splice(index, 1);
    setObjectsArray(newArray);
  };

  const showThrottleSpeedList = () => {
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/throttle_list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setThrottleSpeedList(result.throttleList);
      })
      .catch(error);
  };

  useEffect(() => {
    showThrottleSpeedList();
  }, []);

  const handleThrottleSpeed = (event) => {
    setSelectedThrottleSpeed(event.target.value);
  };

  const iso3166 = require("iso-3166-1");

  const fetchCountry = (supportedCountries) => {
    const countryCodes = supportedCountries.split(", ");
    const fullCountryNames = countryCodes.map((code) => {
      const countryInfo = iso3166.whereAlpha3(code);
      return !countryInfo
        ? "No Code"
        : supportedCountries == "USA"
        ? "United States"
        : countryInfo.country;
    });
    return fullCountryNames.join(", ");
  };

  return (
    <>
      <SimpleCard style={{ padding: "10px" }}>
        <>
          <ValidatorForm onSubmit={(e) => e.preventDefault()}>
            <Box
              style={{
                alignItems: "center",
                backgroundColor: "white",
                padding: "30px",
              }}
            >
              <div
                className="box1"
                style={{
                  width: "100%",
                  height: "auto",
                  padding: "15px 10px",
                  borderRadius: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                }}
              >
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={ModalStyle}>
                    <div
                      className="box2_1"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <div className="ModalInputSelect">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "50%",
                          }}
                        >
                          Tenant :
                        </H3>
                        <div
                          className="inputInfoBox "
                          style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                        >
                          <div style={{ display: "flex" }}>
                            <p id="inputInfoTopText">Tenant</p>
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Choose the Tenant to create an inventory "
                              placement="right"
                            >
                              <Icon style={{ transform: "scale(.7)" }}>
                                info_outline
                              </Icon>
                            </Tooltip>
                          </div>
                          <Select
                            value={selectedSupervisor}
                            onChange={(e) => {
                              setSelectedSupervisor(e.target.value);
                              getGroupStatusById(e.target.value);
                            }}
                            displayEmpty
                            style={{ height: "50px", width: "100%" }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              -- Select the Tenant --
                            </MenuItem>
                            {tenantList.map((supervisor, index) => (
                              <MenuItem
                                key={index}
                                value={supervisor.TenantUUID}
                              >
                                {supervisor.FirstName}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="ModalInputSelect">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "50%",
                          }}
                        >
                          Enter Name of Inventory :
                        </H3>
                        <div
                          className="inputInfoBox "
                          style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                        >
                          <div style={{ display: "flex" }}>
                            <p id="inputInfoTopText">Inventory Name</p>
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Enter Name of the Inventory"
                              placement="right"
                            >
                              <Icon style={{ transform: "scale(.7)" }}>
                                info_outline
                              </Icon>
                            </Tooltip>
                          </div>
                          <OutlinedInput
                            type="text"
                            name="inventoryname"
                            inputProps={{ tabIndex: "10" }}
                            value={inventoryName}
                            onChange={(e) => setInventoryName(e.target.value)}
                            style={{ width: "100%", height: "50px" }}
                            placeholder="Enter Inventory Name Here "
                          />
                        </div>
                      </div>

                      {groupStatus === false && (
                        <div className="ModalInputSelect">
                          <H3
                            style={{
                              fontSize: "16px",
                              width: "50%",
                            }}
                          >
                            Enter Name of The Billing Group :
                          </H3>
                          <div
                            className="inputInfoBox "
                            style={{
                              width: "50%",
                              margin: "0px 0px 12px 0px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <p id="inputInfoTopText">Billing Group Name</p>
                              <span style={{ color: "red" }}>*</span>
                              <Tooltip
                                title="Enter Name of the Billing Group"
                                placement="right"
                              >
                                <Icon style={{ transform: "scale(.7)" }}>
                                  info_outline
                                </Icon>
                              </Tooltip>
                            </div>
                            <OutlinedInput
                              type="text"
                              name="shippingState"
                              value={billingGroup}
                              onChange={(e) => setBillingGroup(e.target.value)}
                              style={{ width: "100%", height: "50px" }}
                              placeholder="Billing Group Name "
                            />
                          </div>
                        </div>
                      )}

                      <Button
                        onClick={createInventory}
                        style={{
                          color: "#8224e3",
                          margin: "20px 0px 10px 38%",
                        }}
                        variant="outlined"
                        color="inherit"
                      >
                        Create Inventory
                      </Button>
                      <Button
                        onClick={cancelpage}
                        style={{ color: "red", margin: "20px 0px 10px 5%" }}
                        variant="outlined"
                        color="inherit"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Modal>

                <div
                  style={{
                    padding: "5px 20px 7px 10px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <H3 style={{ padding: "0px" }}>Assign SIM to Tenant</H3>
                  <Button
                    onClick={handleOpen}
                    style={{ color: "#8224e3" }}
                    variant="outlined"
                    color="inherit"
                  >
                    Create Inventory
                  </Button>
                </div>
                <hr
                  style={{
                    border: "1px lightGrey solid",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                />
                <div className="createTenantAssignSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Tenant</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Tenant You Wish to Assign the SIM Card"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedSupervisor}
                      onChange={(e) => setSelectedSupervisor(e.target.value)}
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Tenant --
                      </MenuItem>
                      {tenantList.map((supervisor, index) => (
                        <MenuItem key={index} value={supervisor.TenantUUID}>
                          {getUserNameById(supervisor.TenantUUID)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="createTenantAssignSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 0px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Select Provider</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="Select the Provider " placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      inputProps={{ tabIndex: "1" }}
                      value={provider}
                      onChange={(e) => handleProviderChange(e)}
                      input={<OutlinedInput labelWidth={0} />}
                      style={{ height: "35px", width: "100%" }}
                      displayEmpty
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="">-- Select --</MenuItem>
                      <MenuItem value="pond-mobile">Pond Mobile</MenuItem>
                    </Select>
                  </div>
                </div>

                <div className="createTenantAssignSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 0px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Inventory</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Inventory for Assigning the SIM Card"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedInventory}
                      onChange={(e) => setSelectedInventory(e.target.value)}
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Inventory --
                      </MenuItem>
                      {inventoryList.map((inventory) => (
                        <MenuItem key={inventory.id} value={inventory.id}>
                          {getInventoryNameById(inventory.id)}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                {/*------------------------------------- checkbox 1 -------------------------*/}
                <div
                  style={{
                    width: "100%",
                    paddingRight: "20px",
                    borderRadius: "14px",
                    backgroundColor: " rgb(233 226 226 / 35%)",
                    paddingTop: "20px",
                    margin: "0px 10px",
                  }}
                >
                  {/* ------------------Trial Period ---------------------*/}
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "-10px 10px 4px 0px" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkBoxTrial}
                            onChange={handleCheckBoxTrial}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Keep this as Trial Package"
                        sx={{}}
                      />
                    </div>
                  </div>
                  {checkBoxTrial && (
                    <div className="createTenantAssignSelect">
                      <div
                        className="inputInfoBox "
                        style={{ width: "50%", margin: "0px 0px 0px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Trial Days</p>
                          <span style={{ color: "red" }}>*</span>
                          <Tooltip
                            title="Select the Days for Trial Period"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <Select
                          value={trialPeriod}
                          onChange={(e) => setTrialPeriod(e.target.value)}
                          displayEmpty
                          style={{ height: "35px", width: "100%" }}
                          MenuProps={{
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            -- Select the Days of Trial Period --
                          </MenuItem>
                          <MenuItem value="7"> 7 Days </MenuItem>
                          <MenuItem value="14"> 14 Days </MenuItem>
                          <MenuItem value="21"> 21 Days </MenuItem>
                          <MenuItem value="28"> 28 Days </MenuItem>
                        </Select>
                      </div>
                    </div>
                  )}{" "}
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Package Type</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the Package Type"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        inputProps={{ tabIndex: "1" }}
                        value={Package}
                        onChange={(e) => handlePackageType(e)}
                        input={<OutlinedInput labelWidth={0} />}
                        style={{ height: "35px", width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="">-- Select --</MenuItem>
                        <MenuItem value="CAPPED">Capped</MenuItem>
                        <MenuItem value="AUTORENEW">Autorenew</MenuItem>
                        <MenuItem value="POSTPAID">Postpaid</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div
                      className="inputInfoBox "
                      style={{
                        width: "48%",
                        margin: "0px 0px",
                        padding: "0px 0px 0px 45px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Package Name</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip title="Select the Package" placement="right">
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        value={selectedPackage}
                        onChange={handleChangePackage}
                        style={{ height: "35px", width: "100%" }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          -- Select the Package --
                        </MenuItem>
                        {packages.map((pkg) => (
                          <MenuItem
                            key={pkg.PackageUUID}
                            value={pkg.PackageUUID}
                          >
                            {pkg.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {/* Here this is for the slection -------------------------------------------------------- */}
                  <div className="SelectionBox">
                    <div className="Selection ">
                      <div className="range">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "100%",
                          }}
                        >
                          Range Selection of SIM Cards{" "}
                          <span style={{ color: "red" }}>*</span>
                        </H3>

                        <Autocomplete
                          value={selectedStartIccid}
                          onChange={(event, newValue) =>
                            handleStartIccidChange(newValue)
                          }
                          options={iccids.filter(
                            (option) =>
                              !selectedIccids.includes(option) &&
                              option !== selectedEndIccid
                          )}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="-- Starting ICCID --"
                              variant="outlined"
                              style={{
                                height: "47px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            />
                          )}
                        />

                        <Autocomplete
                          value={selectedEndIccid}
                          onChange={(event, newValue) =>
                            handleEndIccidChange(newValue)
                          }
                          options={iccids.filter(
                            (option) =>
                              !selectedIccids.includes(option) &&
                              option !== selectedStartIccid
                          )}
                          getOptionLabel={(option) => option}
                          style={{ width: "100%", marginTop: "10px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="-- Ending ICCID --"
                              variant="outlined"
                              style={{
                                height: "47px",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            />
                          )}
                        />
                      </div>

                      <H2
                        style={{
                          margin: "12px 46% 12px 0px",
                        }}
                      >
                        OR
                      </H2>

                      <div className="individual">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "100%",
                          }}
                        >
                          Individual Selection of SIM Cards{" "}
                          <span style={{ color: "red" }}>*</span>
                        </H3>
                        <div style={{ width: "100%", marginTop: "10px" }}>
                          <FormControl style={{ width: "100%" }}>
                            <Autocomplete
                              multiple
                              id="demo-single-checkbox"
                              options={iccids.filter(
                                (option) =>
                                  option !== selectedStartIccid &&
                                  option !== selectedEndIccid &&
                                  option !== selectedICCIDs &&
                                  !selectedICCIDs.includes(option)
                              )}
                              value={selectedIccids}
                              renderTags={() => null}
                              onChange={(event, newValue) =>
                                setSelectedIccids(newValue)
                              }
                              disableCloseOnSelect
                              getOptionLabel={(option) => option}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="-- Select ICCID --"
                                  variant="outlined"
                                  onChange={(e) =>
                                    setSearchValue(e.target.value)
                                  }
                                />
                              )}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    checked={selected}
                                    onClick={() => handleToggleIccids(option)}
                                  />
                                  {option}
                                </li>
                              )}
                              isOptionEqualToValues={(option, value) =>
                                option === value
                              }
                              filterOptions={(options, { inputValue }) => {
                                if (!inputValue) {
                                  return options;
                                }

                                const regex = new RegExp(inputValue, "i");
                                return options.filter((option) =>
                                  regex.test(option)
                                );
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div
                      className="SelectionIccid"
                      style={{
                        width: "50%",
                        height: "200px",
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        border: "1px #2d3436 solid",
                        boxShadow: "rgba(0, 0, 0, 0.1) - 4px 9px 25px - 6px",
                        marginLeft: "20px",
                      }}
                    >
                      <H4 style={{ padding: "0px", color: "#636e72" }}>
                        <i>These are the Selected SIM Card :</i>
                      </H4>
                      {(selectedEndIccid || selectedIccids.length > 0) && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            margin: "10px 0px 0px 5px",
                            overflow: "auto",
                            height: "150px",
                          }}
                        >
                          {[...selectedICCIDs, ...selectedIccids].map(
                            (iccid, index) => (
                              <Chip
                                key={index}
                                label={iccid}
                                onDelete={() => handleRemoveICCID(iccid)}
                                color="primary"
                                variant="outlined"
                                style={{
                                  marginRight: "8px",
                                  marginBottom: "8px",
                                }}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Starting Billing Date</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the Billing Date for the selected Package"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <OutlinedInput
                        id="startbillDate"
                        type="datetime-local"
                        inputProps={{ tabIndex: "" }}
                        value={startBillDate}
                        onChange={(e) => {
                          setStartBillDate(e.target.value);
                          checkDate(e.target.value);
                        }}
                        style={{ height: "35px", width: "100%" }}
                      />
                      {dateError && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "11px",
                            margin: "0px 0 0 10px",
                          }}
                        >
                          Ending billing date must be greater than the starting
                          billing date
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Ending Billing Date</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the Billing Date for the selected Package"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <OutlinedInput
                        id="billDate"
                        type="datetime-local"
                        inputProps={{ tabIndex: "" }}
                        value={endBillDate}
                        onChange={(e) => {
                          setEndBillDate(e.target.value);
                          checkDate(e.target.value);
                        }}
                        style={{ height: "35px", width: "100%" }}
                      />
                      {error && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "11px",
                            margin: "0px 0 0 10px",
                          }}
                        >
                          Ending billing date must be greater than the starting
                          billing date
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Package Expiration Date</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the Package Expiraton  Date"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <OutlinedInput
                        id="billDate"
                        type="datetime-local"
                        inputProps={{ tabIndex: "" }}
                        value={packgaeexpireDate}
                        onChange={(e) => {
                          setPacakgeExpireDate(e.target.value);
                        }}
                        style={{ height: "35px", width: "100%" }}
                      />
                    </div>
                  </div>
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Base Price</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Enter the Base Price of the Package"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <OutlinedInput
                        type="number"
                        inputProps={{ tabIndex: "2" }}
                        onChange={(e) => setBasePrice(e.target.value)}
                        value={basePrice}
                        style={{ width: "100%", height: "35px" }}
                        placeholder="Base Price"
                      />
                    </div>
                  </div>
                  {Package == "POSTPAID" && (
                    <div className="createTenantAssignInput">
                      <div
                        className="inputInfoBox "
                        style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Rate Price</p>
                          <span style={{ color: "red" }}>*</span>
                          <Tooltip
                            title="Enter the Rate Price of the Package(per MB)"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <OutlinedInput
                          type="text"
                          inputProps={{ tabIndex: "2" }}
                          onChange={(e) => setRatePrice(e.target.value)}
                          value={ratePrice}
                          style={{ width: "100%", height: "35px" }}
                          placeholder="Rate Price"
                        />
                      </div>
                    </div>
                  )}
                  {Package == "POSTPAID" && (
                    <div className="createTenantAssignSelect">
                      <div
                        className="inputInfoBox "
                        style={{ width: "50%", margin: "0px 0px 0px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Throttle Speed</p>
                          <span style={{ color: "red" }}>*</span>
                          <Tooltip
                            title="Enter the Throttle Speed of the Package"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <Select
                          inputProps={{ tabIndex: "1" }}
                          value={SelectedThrottleSpeed}
                          onChange={(e) => handleThrottleSpeed(e)}
                          input={<OutlinedInput labelWidth={0} />}
                          style={{ height: "42px", width: "100%" }}
                          displayEmpty
                          MenuProps={{
                            getContentAnchorEl: null,
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            -- Select --
                          </MenuItem>
                          {ThrottleSpeedList.map((speed, index) => {
                            return (
                              <MenuItem key={index} value={speed}>
                                {speed}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  )}
                  {/* ------------------supported Country 1---------------------*/}
                  <div className="createTenantAssignInput">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Supported Country</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the supported countries"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        multiple
                        labelId="country-label"
                        inputProps={{ tabIndex: "4" }}
                        value={selectedCountry}
                        onChange={handleCurrentCountry}
                        style={{ width: "100%", height: "35px" }}
                      >
                        <MenuItem disabled>--Select Country </MenuItem>
                        {countries.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>

                <div
                  className="createTenantAssignSelect"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "15px",
                    marginRight: "92px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="inputInfoBox "
                    style={{
                      width: "fitContent",
                      margin: "0px 0px 0px 0px",
                    }}
                  >
                    <Button
                      style={{ color: "green" }}
                      variant="outlined"
                      color="inherit"
                      disabled={
                        error === true ||
                        !provider ||
                        !selectedInventory ||
                        !selectedSupervisor ||
                        !selectedPackage ||
                        !Package ||
                        !startBillDate ||
                        !endBillDate ||
                        !packgaeexpireDate ||
                        !basePrice ||
                        selectedCountry.length === 0 ||
                        (selectedICCIDs.length === 0 &&
                          selectedIccids.length === 0)
                      }
                      onClick={handleButtonClick}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                </div>
                <Box
                  width="100%"
                  style={{
                    overflowX: "auto",
                    marginTop: "10px",
                  }}
                  minHeight="200px"
                >
                  <StyledTable
                    style={{
                      overflow: "scroll",
                      width: "150%",
                    }}
                    hover
                  >
                    <TableHead>
                      <TableRow
                        style={{
                          backgroundColor: " rgb(233 226 226 / 35%)",
                        }}
                      >
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Trial Period
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Type
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Package Name
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          SIM
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Bill Start Date
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Bill End Date
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Base Price
                        </TableCell>
                        {(Package === "POSTPAID" || Package === "") && (
                          <TableCell
                            style={{
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                            align="center"
                          >
                            Rate Price
                          </TableCell>
                        )}

                        <TableCell
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Supported Countries
                        </TableCell>
                        <TableCell
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {objectsArray.length > 0 ? (
                        objectsArray.map((obj, index) => (
                          <TableRow
                            hover
                            key={index}
                          >
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {obj.TrialPeriod === ""
                                ? "0 Days"
                                : `${obj.TrialPeriod} Days`}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {obj.type}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {getPackageNameById(obj.PackageUUID)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                                whiteSpace: "pre-line", // Allows line breaks
                              }}
                            >
                              {obj.iccidRange.join(",\n")}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {convertUtcToLocal(obj.billStartDate)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {convertUtcToLocal(obj.billDate)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {obj.basePrice}
                            </TableCell>
                            {(Package === "POSTPAID" || Package === "") && (
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "100",
                                }}
                              >
                                {obj.TrialPeriod === ""
                                  ? "N/A"
                                  : `${obj.ratePrice}`}
                              </TableCell>
                            )}

                            <TableCell
                              align="center"
                              style={{
                                fontSize: "13px",
                                fontWeight: "100",
                              }}
                            >
                              {fetchCountry(obj.supportedCountries.join(", "))}
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                onClick={() => handleDelete(index)}
                                sx={{
                                  ".text-effect": {
                                    position: "absolute",
                                    top: "50%",
                                    right: "100%",
                                    transform: "translateX(50%,-50%)",
                                    display: "none",
                                    textAlign: "center",
                                    width: "100%",
                                    border: "1px solid black",
                                    backgroundColor: "white",
                                  },
                                  "&:hover .text-effect": {
                                    display: "block",
                                  },
                                  position: "relative",
                                }}
                              >
                                <Icon color="error">delete</Icon>
                                <Typography
                                  variant="caption"
                                  className="text-effect"
                                >
                                  Delete
                                </Typography>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={10}
                            align="center"
                            style={{ fontSize: "13px", fontWeight: "10" }}
                          >
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </StyledTable>
                </Box>

                <div
                  style={{
                    padding: "10px 4px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={sample}
                    disabled={objectsArray.length === 0}
                    style={{ color: "#8224e3" }}
                    variant="outlined"
                    color="inherit"
                  >
                    Assign SIM Cards
                  </Button>
                </div>
              </div>
            </Box>
          </ValidatorForm>
        </>
      </SimpleCard>
    </>
  );
};

export default AssignInventory;
