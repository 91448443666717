import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { H1 } from "app/components/Typography";
import { Container } from "@mui/material";

const InventorySimDetails = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const iccId = localStorage.getItem("iccId");
  const [inventoryId, setInventoryId] = useState('');
  const [simStatus, setsimStatus] = useState('');
  const [groupName, setGroupName] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState('')
  const [status, setStatus] = useState('')
  const [inventoryName, setInventoryName] = useState('');

  const simDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      iccid: iccId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_iccid_details`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        setInventoryId(result.SimData.inventoryId);
        setInventoryName(result.SimData.inventoryName)
        setsimStatus(result.SimData.statusOfSim);
        setType(result.SimData.type);
        setGroupName(result.SimData.groupName)
        setDate(result.SimData.createdOn);
        setStatus(result.SimData.iccids[0].status)
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    simDetails();
  }, []);

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }



  return (
    <>
      <Container>
        <Box
          sx={{ height: "auto", typography: "body1" }}
          style={{
            backgroundColor: "white",
            paddingBottom: '20px'
          }}
        >
          <Box
            className="breadcrumb"
            style={{ display: "flex", margin: "10px 0px 0px 20px", }}
          >
            <h3 style={{ fontWeight: "500", fontSize: "21.14px" }}>
              SIM Details |
            </h3>

            <span
              style={{
                marginTop: "26px",
                marginLeft: "10px",
                color: "#8224e3",
                fontSize: "14px",
              }}
            >
              {" "}
              {iccId}
            </span>
          </Box>
          <hr
            style={{
              border: "1px lightGrey solid",
              width: "97%",
              marginBottom: "20px",
              marginTop: '-13px'
            }}
          />
          <div
            style={{
              margin: "40px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="box1"
              style={{
                width: "50%",
                height: "auto",
                padding: "10px 10px 0px 15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "25px",
                paddingBottom: '10px',
                
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  margin: '10px 5px'
                }}
              >
                General Details <hr />
              </p>
              <table>
                <tr>
                  <td>ICCID :</td>
                  <td style={{ padding: "2px 25px" }}>{iccId}</td>
                </tr>

                <tr>
                  <td>Inventory ID:</td>
                  <td style={{ padding: "2px 25px" }}>{inventoryId}</td>
                </tr>
                <tr>
                  <td>Inventory Name:</td>
                  <td style={{ padding: "2px 25px" }}>{inventoryName}</td>
                </tr>
                <tr>
                  <td>SIM Status :</td>
                  <td style={{ padding: "2px 25px", textTransform:'capitalize' }}>{simStatus}</td>
                </tr>


                <tr>
                  <td>Group Name :</td>
                  <td style={{ padding: "2px 25px" }}>{groupName}</td>
                </tr>
                <tr>
                  <td>Status :</td>
                  <td style={{ padding: "2px 25px",  textTransform:'capitalize' }}>{status}</td>
                </tr>
                <tr>
                  <td>Date Created :</td>
                  <td style={{ padding: "2px 25px" }}>{epochToDate(date)}</td>
                </tr>
              </table>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default InventorySimDetails;
