import React from 'react';

const Cancel = () => {
  return (
    <div style={styles.container}>
      <div style={styles.cancelIcon}>&#10060;</div>
      <h1 style={styles.heading}>Payment Cancelled</h1>
      <p style={styles.message}>Your payment was cancelled. If you have any questions, please contact customer support.</p>
      <a href="/" style={styles.linkBtn}>Back to Home</a>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  cancelIcon: {
    color: '#dc3545',
    fontSize: '64px',
    marginBottom: '20px',
  },
  heading: {
    color: '#dc3545',
  },
  message: {
    color: '#333',
    fontSize: '18px',
  },
  linkBtn: {
    display: 'inline-block',
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  },
  linkBtnHover: {
    backgroundColor: '#0056b3',
  },
};

export default Cancel;
