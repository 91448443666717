import { Container } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import { Collapse, MenuItem, FormControl, InputLabel, Select, TextField } from '@mui/material';

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination'; 
import { H2 } from '../Typography';
import { SimpleCard } from '..';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  }
}));

const MailTemplates = () => {
  const [showNewTenant, setShowNewTenant] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(7); 


  const NextMailNotificaton = () => {
    navigate('/SMS/MailNotification')
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <>
      <Container
        style={{
          padding: '20px'
        }}
      >
        <>
          <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
            <H2>Mail Templates</H2>
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginLeft: 'auto', color: '#8224e3' }}
              onClick={NextMailNotificaton}
            >
              ADD NEW TEMPLATE
            </Button>
          </Box>

          <SimpleCard title="E-Mail SMTP">
            <Box width="100%" overflow="auto">
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="left"
                    >
                      Mail
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell align="left">tester@admin.com</TableCell>
                    <TableCell align="center">test</TableCell>
                    <TableCell align="center">test</TableCell>
                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '50%',
                            right: '100%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', // Initially hide the text
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' // Show the text when hovering over the icon
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon color="error">delete</Icon>
                        <Typography variant="caption" className="text-effect">
                          Delete
                        </Typography>
                      </IconButton>

                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '-21%',
                            left: '20%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon>edit</Icon>
                        <Typography variant="caption" className="text-effect">
                          Edit
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">pop@gmail.com</TableCell>
                    <TableCell align="center">usher</TableCell>
                    <TableCell align="center"> Appearance</TableCell>
                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '50%',
                            right: '100%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon color="error">delete</Icon>
                        <Typography variant="caption" className="text-effect">
                          Delete
                        </Typography>
                      </IconButton>

                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '-21%',
                            left: '20%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', // Initially hide the text
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block'
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon>edit</Icon>
                        <Typography variant="caption" className="text-effect">
                          Edit
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">demo.admin@startelelogic.com</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell align="center">Hello go lang</TableCell>
                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '50%',
                            right: '100%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon color="error">delete</Icon>
                        <Typography variant="caption" className="text-effect">
                          Delete
                        </Typography>
                      </IconButton>

                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '-21%',
                            left: '20%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon>edit</Icon>
                        <Typography variant="caption" className="text-effect">
                          Edit
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>

              <TablePagination
                rowsPerPageOptions={[7, 14, 25]}
                component="div"
                count={tenantData.length} 
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </SimpleCard>
        </>
      </Container>
    </>
  );
};

export default MailTemplates;
