import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react'
import { H1 } from 'app/components/Typography'
import { Container } from '@mui/material';
import { use } from 'echarts';


const TenantSimInfo = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    let icccid = localStorage.getItem('iccId');
    const TenantUUID = localStorage.getItem('TenantUUID');

    const [groupid, setGroupId] = useState('');
    const [inventoryId, setInventoryId] = useState('');
    const [InventoryName, setInventoryName] = useState('');
    const [sku, setSku] = useState('');

    const fetchSimDetails = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "iccid": icccid
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/api/v1/get_iccid_details`, requestOptions)
            .then(response => response.json())
            .then(result => {

                setGroupId(result.SimData.groupId);
                setInventoryId(result.SimData.inventoryId);
                setSku(result.SimData.SkuPrefix);
                setInventoryName(result.SimData.inventoryName)
            })
            .catch(error => {
            });


    }

    useEffect(() => {
        fetchSimDetails();
    }, [])



    return (
        <>
            <Container>

                <Box sx={{ height: '450px', typography: 'body1' }} style={{
                    backgroundColor: 'white'
                }}>
                    <p
                        style={{
                            fontSize: '20px',
                            paddingLeft: '11px',
                            // fontWeight: '600',

                        }}
                    >
                        Sim Details ({icccid})
                    </p>


                    <div style={{ margin: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="box1" style={{
                            // border: '2px red solid',
                            width: '60%',
                            height: '320px',
                            padding: '0px 25px',
                            backgroundColor: 'rgb(233 226 226 / 35%)',
                            borderRadius: '25px',

                        }} >
                            <p style={{
                                // border: '2px purple solid',
                                fontSize: '24px'
                            }} >General Details <hr /></p>
                            <table>
                                <tr>
                                    <td>TenantUUID :</td>
                                    <td style={{ padding: '2px 25px' }}>{TenantUUID}</td>
                                </tr>
                                <tr>
                                    <td>ICCID :</td>
                                    <td style={{ padding: '2px 25px' }}>{icccid}</td>
                                </tr>


                                <tr>
                                    <td>InventoryID:</td>
                                    <td style={{ padding: '2px 25px' }}>{inventoryId}</td>
                                </tr>
                                <tr>
                                    <td>InventoryName :</td>
                                    <td style={{ padding: '2px 25px' }}>{InventoryName}</td>
                                </tr>
                                <tr>
                                    <td>SKU Prefix :</td>
                                    <td style={{ padding: '2px 25px' }}>{sku}</td>
                                </tr>
                            </table>
                        </div>



                    </div>
                </Box>
            </Container>
        </>
    )
}

export default TenantSimInfo; 