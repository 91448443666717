import { Container } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import { Collapse, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { TextField, Grid } from '@mui/material';


import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination'; 
import { H2 } from '../Typography';
import { SimpleCard } from '..';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  }
}));

const EmailSMTP_T = () => {
  const [showNewTenant, setShowNewTenant] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(7); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  const handleCancel = () => {
    setOpen(false);
  };
  const toggleCollapse = () => {
    setOpen(!open);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const maxLength = 10;

  return (
    <>
      <Container
        style={{
          padding: '20px'
        }}
      >
        <>
          <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
            <H2>E-Mail SMTP</H2>
            <Button
              variant="outlined"
              color="inherit"
              style={{ marginLeft: 'auto', color: '#8224e3' }}
              onClick={toggleCollapse}
            >
              ADD SMTP
            </Button>
          </Box>
          <Collapse in={open}>
            <div
              style={{
                // backgroundColor: 'green',
                boxShadow: '0px 0px 3px gray',
                alignItems: 'center',
                // borderRadius: '10px',
                backgroundColor: 'white',

              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '10px',
                  margin: '10px',
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto auto'
                }}
              >
                <div style={{ margin: '10px' }}>
                  <TextField size='small' label="Mail Id*" fullWidth={false} style={{ margin: '10px', width: '400px' }} />
                  <TextField size='small' label="Mail Server*" fullWidth={false} style={{ margin: '10px', width: '400px' }} />
                </div>
                <div style={{ margin: '10px' }}>
                  <TextField size='small' label="Password*" fullWidth={false} style={{ margin: '10px', width: '400px' }} />
                  <TextField size='small' label="Port*" fullWidth={false} style={{ margin: '10px', width: '400px' }} />
                </div>
              </div>
              <div
                style={{
                  padding: '10px'
                  //   margin: '10px',
                }}
              >
                {' '}
                <Button
                  style={{ marginLeft: '35px', color: 'red' }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ margin: '10px', color: 'green' }}
                  variant="outlined"
                  color="inherit"
                >Add</Button>
              </div>
            </div>
          </Collapse>

          <SimpleCard title="E-Mail SMTP">
            <Box width="100%" overflow="auto">
              <StyledTable>
                <TableHead>
                  <TableRow style={{ backgroundColor: ' rgb(233 226 226 / 35%)' }}>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        paddingLeft: '5px'
                      }}
                      align="left"
                    >
                      Mail ID
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Mail Server
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Password
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Port
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell align="left">startelelogic22@gmail.com</TableCell>
                    <TableCell align="center">smtp.gmail.com</TableCell>
                    <TableCell align="center">tgetzyyxmguffbqn</TableCell>
                    <TableCell align="center">587</TableCell>
                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '50%',
                            right: '100%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', // Initially hide the text
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' // Show the text when hovering over the icon
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon color="error">delete</Icon>
                        <Typography variant="caption" className="text-effect">
                          Delete
                        </Typography>
                      </IconButton>

                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '-21%',
                            left: '20%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', // Initially hide the text
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' // Show the text when hovering over the icon
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon>edit</Icon>
                        <Typography variant="caption" className="text-effect">
                          Edit
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">deplofy@startelelogic.com</TableCell>
                    <TableCell align="center">abcd.gmail.com</TableCell>
                    <TableCell align="center">qwert rty</TableCell>
                    <TableCell align="center">187</TableCell>
                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '50%',
                            right: '100%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon color="error">delete</Icon>
                        <Typography variant="caption" className="text-effect">
                          Delete
                        </Typography>
                      </IconButton>

                      <IconButton
                        sx={{
                          '.text-effect': {
                            position: 'absolute',
                            // bottom: '100%',
                            top: '-21%',
                            left: '20%',
                            transform: 'translateX(50%,-50%)',
                            display: 'none', 
                            textAlign: 'center',
                            width: '100%',
                            border: '1px solid black',
                            // color: 'white'
                            backgroundColor: 'white'
                          },
                          '&:hover .text-effect': {
                            display: 'block' 
                          },
                          position: 'relative'
                        }}
                      >
                        <Icon>edit</Icon>
                        <Typography variant="caption" className="text-effect">
                          Edit
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>

              <TablePagination
                rowsPerPageOptions={[7, 14, 25]}
                component="div"
                count={tenantData.length} 
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </SimpleCard>
        </>
      </Container>
    </>
  );
};

export default EmailSMTP_T;
