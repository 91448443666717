import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import { useEffect } from 'react';
import { useState } from 'react';

const SimData = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    let iccid = localStorage.getItem('iccid')
    let UserUUID = localStorage.getItem('UserUUID')

    const [balance, setBalance] = useState('')
    const [simInfoData, setSimInfoData] = useState('')
    const [imsis, setImsis] = useState([])
    const [statusInfoData, setStatusInfoData] = useState('')

    const fetchSImData = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserUUID": UserUUID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/api/v1/get_sim_data?iccid=${iccid}`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setBalance(result.SimData[0].balance)
                setSimInfoData(result.SimData[0].simInfoData)
                setStatusInfoData(result.SimData[0].statusInfoData)
                setImsis(result.SimData[0].simInfoData.imsis)
            })
    }

    useEffect(() => {
        fetchSImData()
    }, [])

    function epochToDate(epochTimestamp) {
        // Create a new Date object from the epochTimestamp
        const date = new Date(epochTimestamp);

        // Extract date components
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted date string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }

    return (
        <>
            <div
                className="boxes"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '20px',
                }}
            >
                <div
                    className="box1"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        className="box1_1"
                        style={{
                            width: '40%',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div className="box1_1_1"
                            style={{
                                width: '100%',
                                height: 'auto',
                                backgroundColor: 'rgb(233 226 226 /35%)',
                                borderRadius: '25px',
                                padding: '0px 20px',
                                paddingBottom: '10px'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600'
                                }}
                            >
                                SIM Balance
                            </p>
                            <hr
                                style={{
                                    border: '1px grey solid',
                                    width: '100%'
                                }}
                            />
                            <table
                                style={{
                                    padding: '2px 0px'
                                }}
                            >
                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        Balance In Cents :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {balance.balanceInCents}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        {' '}
                                        Currency
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {balance.currency}
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="box1_1_2"
                            style={{
                                width: '100%',
                                height: 'auto',
                                backgroundColor: 'rgb(233 226 226 /35%)',
                                borderRadius: '25px',
                                padding: '0px 20px',
                                paddingBottom: '10px',
                                marginTop: '20px'

                            }}
                        >
                            <p
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600'
                                }}
                            >
                                Status Information Data
                            </p>
                            <hr
                                style={{
                                    border: '1px grey solid',
                                    width: '100%'
                                }}
                            />
                            <table
                                style={{
                                    padding: '2px 0px'
                                }}
                            >
                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        Status :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {statusInfoData.status}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        SIM Data Service Status :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {statusInfoData.simDataServiceStatus}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        SIM Voice Service Status :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {statusInfoData.simVoiceServiceStatus}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        SIM SMS Service Status :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {statusInfoData.simSmsServiceStatus}
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        style={{
                                            fontWeight: '500',
                                            color: 'black'
                                        }}
                                    >
                                        Status Of SIM :
                                    </td>
                                    <td
                                        style={{
                                            padding: '2px 25px '
                                        }}
                                    >
                                        {statusInfoData.statusOfSim}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div
                        className="box1_2"
                        style={{
                            width: '60%',
                            backgroundColor: 'rgb(233 226 226 /35%)',
                            borderRadius: '25px',
                            padding: '0px 20px',
                            marginLeft: '17px',
                            paddingBottom: '10px',
                        }}
                    >
                        <p
                            style={{

                                fontSize: '20px',
                                fontWeight: '600'
                            }}
                        >
                            SIM Information Data
                        </p>
                        <hr
                            style={{
                                border: '1px grey solid',
                                width: '100%'
                            }}
                        />
                        <table
                            style={{

                                width: '100%'
                            }}
                        >
                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    ICCID :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.iccid}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Parent Group Id
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.parentGroupId}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Distributor ID :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.distributorId}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Distributor Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.distributorName}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Inventory ID :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.inventoryId}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Inventory Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.inventoryName}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Inventory Type :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.inventoryType}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Date Created :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {epochToDate(simInfoData.epochCreated)}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Date Modified :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {epochToDate(simInfoData.epochModified)}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    Mapped IMSI  :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.mappedImsi}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    White List ID :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.whitelistId}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    White List Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.whitelistName}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{

                                        fontWeight: '500',
                                        color: 'black'
                                    }}
                                >
                                    White List Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {simInfoData.simType}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SimData
