import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import { TextField, Grid } from '@mui/material';

//These import are for the reports download   -- Starts here
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import './Reports.css';

import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
//These import are for the reports download   -- Ends here

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { Collapse, MenuItem, FormControl, InputLabel, Select, Tooltip } from '@mui/material';
import { H2, H1 } from 'app/components/Typography';
import ExportReports from '../TenantReports/ExportReports';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import './Reports.css';
const Container = styled('div')(({ theme }) => ({
  margin: '17px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } }
  }
}));

const TerminatedAccounts = () => {
  const [showNewUser, setShowNewUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const tenantUuid = localStorage.getItem('tenant_uuid');
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem('TenantUUID');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [nameVisible, setNameVisible] = useState(true);
  const [idVisible, setIdVisible] = useState(true);
  const [usernameVisible, setUsernameVisible] = useState(true);
  const [teamVisible, setTeamVisible] = useState(true);
  const [startVisible, setStartVisible] = useState(true);
  const [endVisible, setEndVisible] = useState(true);
  const [columnVisible, setColumnVisible] = useState(true);

  const [TenantsFilter, setTenantsFilter] = useState('');
  const [DurationFilter, setDurationFilter] = useState('');
  const [FormatFilter, setFormatFilter] = useState('');

  const [containerStyle, setContainerStyle] = useState({
    padding: '10px',
    top: '-238px',
    left: '500px',
    transitionProperty: 'all',
    transitionDuration: '0.5s', // Adjust the duration as needed
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == false) {
      setNameVisible(true);
      setIdVisible(true);
      setUsernameVisible(true);
      setTeamVisible(true);
      setEndVisible(true);
      setStartVisible(true);
    } else if (columnVisible == true) {
      setNameVisible(false);
      setIdVisible(false);
      setUsernameVisible(false);
      setTeamVisible(false);
      setEndVisible(false);
      setStartVisible(false);
    }
  }

  const handleToggleNameSwitch = () => {
    setNameVisible(!nameVisible);

  }

  const handleToggleIdSwitch = () => {
    setIdVisible(!idVisible);
  }

  const handleToggleUsernameSwitch = () => {
    setUsernameVisible(!usernameVisible);
  }

  const handleToggleTeamSwitch = () => {
    setTeamVisible(!teamVisible);
  }

  const handleToggleStartSwitch = () => {
    setStartVisible(!startVisible);
  }
  const handleToggleEndSwitch = () => {
    setEndVisible(!endVisible);
  }

  //These Functions are for the reports download   -- Starts here
  // This is for the PDF format
  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    // Check if there is any data before generating PDF
    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      // Use jsPDF autoTable plugin to add table to PDF
      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("Terminated_Report.pdf");
    } else {
      toast.error("No data available for export.");
    }
  };

  // This is for the Excel format
  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Check if there is any data before exporting to Excel
    if (table && table.rows.length > 1) {
      const ws = XLSX.utils.table_to_sheet(table);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Terminated_Report" + fileExtension);
    } else {
      toast.error("No data available for export.");
    }
  };
  //These Functions are for the reports download   -- Ends here

  return (
    <Container>
      <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
        <H2>Reports | </H2>
        <p
          style={{
            margin: '5px',
            color: '#8224e3'
          }}
        >
          Terminated Reports
        </p>
        <Button
          style={{ marginLeft: 'auto', height: '40px', width: '10px', color: '#8224e3' }}
          onClick={toggleCollapse}
          variant="outlined"
          color="inherit"

        // Push the button to the extreme right
        >
          <Tooltip title="Filter" arrow>

            <IconButton
              sx={{
                '.text-effect': {
                  position: 'absolute',
                  top: '50%',
                  right: '100%',
                  transform: 'translateX(50%,-50%)',
                  display: 'none',
                  textAlign: 'center',
                  width: '100%',
                  border: '1px solid black',
                  backgroundColor: 'white'
                },
                '&:hover .text-effect': {
                  display: 'block'
                },
                position: 'relative'
              }}
            >
              <Icon color="#8224e3">filter_alt_outlined</Icon>
            </IconButton>
          </Tooltip>
        </Button>
        <Button
          style={{ color: '#8224e3', marginLeft: '10px', }}
          variant="outlined"
          color="inherit"
          onClick={handleButtonClick}

        >
          <Icon color="#8224e3">settings</Icon>
        </Button>
      </Box>
      <Collapse in={open}>
        <div
          style={{
            backgroundColor: 'white',
            alignItems: 'center',
            borderRadius: '10px',
            marginTop: '20px',
            marginBottom: '20px',
            boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px'
          }}
        >
          <div
            style={{
              padding: '10px',
              margin: '10px',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <div className='inputInfoBox'>
              <p id="inputInfoTopText">Tenant</p>
              <Select
                value={TenantsFilter}
                onChange={(e) => setTenantsFilter(e.target.value)}
                displayEmpty
                style={{ height: '39px', margin: '0px', width: '100%' }}
              >
                <MenuItem value="">--Select Tenants--</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </div>

            <div className='inputInfoBox'>
              <p id="inputInfoTopText">Start Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: '39px', margin: '0px', width: '100%' }}
              />
            </div>

            <div className='inputInfoBox'>
              <p id="inputInfoTopText">End Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: '39px', margin: '0px', width: '100%' }}
              />
            </div>

            <div className='inputInfoBox'>
              <p id="inputInfoTopText">Duration</p>
              <Select
                value={DurationFilter}
                onChange={(e) => setDurationFilter(e.target.value)}
                displayEmpty
                style={{ height: '39px', margin: '0px', width: '100%' }}
              >
                <MenuItem value="">--Select Duration--</MenuItem>
                <MenuItem value={10}>Daily</MenuItem>
                <MenuItem value={20}>Weekly</MenuItem>
                <MenuItem value={30}>Annual</MenuItem>
              </Select>
            </div>

            <div className='inputInfoBox'>
              <p id="inputInfoTopText">Select Format</p>
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: '39px', margin: '0px', width: '100%' }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value={10}>excel</MenuItem>
                <MenuItem value={20}>csv</MenuItem>
              </Select>
            </div>
          </div>
          <div>
            <Button
              style={{
                marginLeft: '27px',
                marginBottom: '15px', color: 'green'
              }}
              variant="outlined"
              color="inherit"
            >
              Search
            </Button>
          </div>
        </div>
      </Collapse>

      <Container style={{
        ...containerStyle, display: animateContainer ? 'block' : 'none',
        position: 'absolute',
        top: '25%',
        left: '25%',
        transform: 'translate(-25%,-25%)',
        zIndex: '100'
      }}>
        <>

          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              backgroundColor: ' rgb(242 242 242 / 100%)',
              borderRadius: '25px',
              marginLeft: '10%',
              animation: 'fadeIn 1.5s ease-in-out',
            }}

          >
            <div style={{ padding: '0px', marginLeft: '10px', marginTop: '0px' }}>
              <H2 style={{ margin: '10px', padding: '0px', marginRight: '10px' }}>
                Columns
                <Switch
                  color="primary" checked={columnVisible} onChange={handleToggleColumnSwitch}
                />
              </H2>
              <hr />
            </div>
            <br />

            <div style={{ padding: '5px', display: 'flex', marginLeft: '20px' }}>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  marginRight: '20px',
                  fontSize: '16px',
                  marginLeft: '10px'
                }}
              >
                Tenant Name
                <Switch
                  color="primary" checked={nameVisible} onChange={handleToggleNameSwitch}
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Tenant ID
                <Switch
                  color="primary" checked={idVisible} onChange={handleToggleIdSwitch}
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Tenant Username
                <Switch
                  color="primary" checked={usernameVisible} onChange={handleToggleUsernameSwitch}
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Team
                <Switch
                  color="primary" checked={teamVisible} onChange={handleToggleTeamSwitch}
                />
              </p>



            </div>

            <div style={{ padding: '5px', display: 'flex', marginLeft: '20px' }}>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Session Start
                <Switch
                  color="primary" checked={startVisible} onChange={handleToggleStartSwitch}
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Session End
                <Switch
                  color="primary" checked={endVisible} onChange={handleToggleEndSwitch}
                />
              </p>
            </div>


            <hr />
            <div style={{ padding: '4px', marginLeft: '600px' }}>
              <Button
                style={{ color: '#8224e3' }}
                variant="outlined"
                color="inherit"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>

          </div>
        </>
      </Container>
      <SimpleCard title="Terminated Reports">

        
      <div>
          <div>
            <input
              style={{
                height: "36px",
                width: "25%",
                border: "1px solid  #dededf",
                paddingLeft: "10px",
              }}
              name="search"
              placeholder="Search..."
              type="search"
            />
          </div>
        </div>
        
        {/* This is for the format selection  */}
        <div style={{ marginLeft: '69%', marginTop: '-54px', width: '30%' }}>
          <div className='inputInfoBox' style={{ width: '100%', margin: '0px' }} >
            <p id="inputInfoTopText">Select Format
              <Tooltip title="Select a format to download the report" placement='top-start'>
                <Icon style={{ transform: 'scale(.7)' }} >info_outline</Icon>
              </Tooltip>
            </p>
            <Select
              value={FormatFilter}
              onChange={(e) => setFormatFilter(e.target.value)}
              displayEmpty
              style={{ height: '39px', margin: '0px', width: '100%' }}
            >
              <MenuItem value="" >--Select Downloadable Format--</MenuItem>
              <MenuItem value='excel' onClick={() => exportToExcel()} >excel</MenuItem>
              <MenuItem onClick={generatePDF} value='pdf'>pdf</MenuItem>
            </Select>
          </div>
        </div>
        <Box width="100%" overflow="auto" style={{ marginTop: '20px' }}>
          <StyledTable id="Table-Data">
            <TableHead>
              <TableRow style={{ backgroundColor: ' rgb(233 226 226 / 35%)' }}>
                {nameVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Tenant Name
                  </TableCell>
                )}
                {idVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Tenant ID
                  </TableCell>
                )}
                {usernameVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Tenant Username
                  </TableCell>
                )}
                {teamVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Team
                  </TableCell>
                )}
                {startVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Session Start
                  </TableCell>
                )}
                {endVisible && (
                  <TableCell
                    style={{
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Session End
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {nameVisible && <TableCell align="center">Tenant 1</TableCell>}
                {idVisible && <TableCell align="center">665</TableCell>}
                {usernameVisible && <TableCell align="center">tenant_123</TableCell>}

                {teamVisible && <TableCell align="center">stl</TableCell>}
                {startVisible && <TableCell align="center">10/02/2023</TableCell>}
                {endVisible && <TableCell align="center">20/05/2023</TableCell>}
              </TableRow>

              <TableRow>
                {nameVisible && <TableCell align="center">Tenant 2</TableCell>}
                {idVisible && <TableCell align="center">834</TableCell>}
                {usernameVisible && <TableCell align="center">tenant_xyz</TableCell>}

                {teamVisible && <TableCell align="center">stl</TableCell>}
                {startVisible && <TableCell align="center">05/07/2023</TableCell>}
                {endVisible && <TableCell align="center">30/10/2023</TableCell>}
              </TableRow>
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userData.length} // Total number of rows
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </SimpleCard>
    </Container>
  );
};

export default TerminatedAccounts;
