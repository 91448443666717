import { Box, Fab, IconButton, Container } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { Tooltip } from "@mui/material";

import { countries } from "countries-list";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  FormControl,
  InputLabel,
  MenuItem,
  styled,
  Chip,
} from "@mui/material";
import { H2, H1, H3, H4 } from "app/components/Typography";
import iso3166 from "iso-3166-1";

import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import {
  Select,
  // Tooltip,
} from "@mui/material";

const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginBottom: "16px",
  marginLeft: "44px",
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AppButtonRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
  "& .button": { margin: theme.spacing(1) },
  "& .input": { display: "none" },
}));

const CreatePackage = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const DomainUUID = localStorage.getItem("DomainUUID");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState('');
  const [country, setCountry] = useState("");
  const [voiceUsage, setVoiceUsage] = useState("");
  const [dataUsage, setDataUsage] = useState("");
  const [SMSUsage, setSMSUsage] = useState("");
  const [dataError, setDataError] = useState("");
  const [smsusageError, setSmsUsageError] = useState("");
  const [voiceusageError, setVoiceUsageError] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [SelectedDataSpeed, setSelectedDataSpeed] = useState("");
  const [ThrottleSpeedList, setThrottleSpeedList] = useState("");
  const [SelectedThrottleSpeed, setSelectedThrottleSpeed] = useState("");
  const [showSMSUsageInput, setShowSMSUsageInput] = useState(false);
  const [showDataUsageInput, setShowDataUsageInput] = useState(false);
  const [isVoiceUsageClicked, setIsVoiceUsageClicked] = useState(false);
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const accessToken = localStorage.getItem("accessToken");

  const navigate = useNavigate();

// Validation Start here

  const handleNameChange = (e) => {
    const enteredValue = e.target.value
    if(enteredValue.trim() === ""){
      setName("");
      setNameError('Please enter package name');
    }else {
      const alphabeticValue = enteredValue.replace(/[^a-zA-Z0-9\s]/g, "")
      setName(alphabeticValue);
      setNameError("");
    }
  }

  const handleDataUsageChange = (e) => {
    const enteredValue = e.target.value;
    if (enteredValue.trim() === "") {
      setDataUsage("");
      setDataError("Please input data usage using numerical values"
      );
    } else if (/^[0-9\s]*$/.test(enteredValue)) {
      setDataUsage(enteredValue);
      setDataError(""); 
     
    } else {
    }
  };

  const handleSMSUsageChange = (e) => {
    const enteredValue = e.target.value;
    if(enteredValue.trim() === ""){
      setSMSUsage("");
      setSmsUsageError("Please input SMS usage using numerical values")
    }
    else if (/^[0-9\s]*$/.test(enteredValue)) {
      setSMSUsage(enteredValue);
      setSmsUsageError("")
    }
  };

  const handleVoiceUsageChange = (e) => {
    const enteredValue = e.target.value;
    if(enteredValue.trim() === ""){
      setVoiceUsage("");
      setVoiceUsageError('Please input voice usage using numerical values')
    }else if(/^[0-9\s]*$/.test(enteredValue)) {
      setVoiceUsageError("");
      setVoiceUsage(enteredValue);
    }
  };

  const handleCheckBox1 = () => {
    setCheckBox1((prevCheckBox) => !prevCheckBox);

    if (checkBox1) {
      setDataUsage(dataUsage);
    } else {
      setDataUsage("");
    }
  };
  const handleCheckBox2 = () => {
    setCheckBox2((prevCheckBox) => !prevCheckBox);

    if (checkBox2) {

      setSMSUsage(SMSUsage);
    } else {
      setSMSUsage("");
    }
  };
  const handleCheckBox3 = () => {
    setCheckBox3((prevCheckBox) => !prevCheckBox);

    if (checkBox3) {
      setVoiceUsage(voiceUsage);
    } else {
      setVoiceUsage("");
    }
  };
  const [provider, setProvider] = useState("");

  const handleProviderChange = (e) => {
    setProvider(e.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const handleDataSpeed = (event) => {
    setSelectedDataSpeed(event.target.value);
  };

  const showThrottleSpeedList = () => {
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/throttle_list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setThrottleSpeedList(result.throttleList);
      })
      .catch((error) => {
    });
  };

  useEffect(() => {
    showThrottleSpeedList();
  }, []);

  const handleThrottleSpeed = (event) => {
    setSelectedThrottleSpeed(event.target.value);
  };

  const handleCreatePackage = () => {
    if (!provider || !name || !dataUsage) {
      toast.error("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    } else {
    }
    const dataUsageInBytes = dataUsage * 1024 * 1024;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
      PackageName: name,
      dataUsageAllowanceInBytes: dataUsageInBytes,
      packageDescription: packageDescription,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_package`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Package Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        navigate("/package");
      })
      .catch((error) => {
      });
  };

  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <AppButtonRoot>
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center" }}
        >
          <H3>Create Package | </H3>

          <p
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              margin: "0",
              marginTop: "4px",
            }}
          >
            Create Package
          </p>
        </Box>
        <Box>
          <SimpleCard title="Create Package">
            <hr />
            <div
              className="inputInfoBox"
              style={{ width: "47%", marginLeft: "1.5%" }}
            >
              <div style={{ display: "flex" }}>
                <p id="inputInfoTopText">Select Provider</p>
                <span style={{ color: "red" }}>*</span>
                <Tooltip title="Select the Provider" placement="right">
                  <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
                </Tooltip>
              </div>
              <Select
                inputProps={{ tabIndex: "1" }}
                value={provider}
                onChange={(e) => handleProviderChange(e)}
                input={<OutlinedInput labelWidth={0} />}
                style={{ height: "42px", width: "100%" }}
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value="">-- Select --</MenuItem>
                <MenuItem value="pond-mobile">Pond Mobile</MenuItem>
              </Select>
            </div>

            <ValidatorForm>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ mt: 2 }}
                  px={2}
                  style={{}}
                >
                  <div
                    className="inputInfoBox "
                    style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Name</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="Name" placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "2" }}
                      onChange={handleNameChange}
                      value={name}
                      style={{ width: "100%" }}
                      placeholder="Name"
                    />
                    {nameError && (
                      <div
                      style={{
                        color: "red",
                        fontSize: "11px",
                        margin: "0px 0 0 10px",
                      }}>
                        {nameError}
                      </div>
                    )}
                  </div>

                  <div style={{ display: "grid" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBox1}
                          onChange={handleCheckBox1}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Data Usage"
                      sx={{}}
                    />
                    {checkBox1 && (
                      <div
                        className="inputInfoBox"
                        style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Data Usage Limit</p>
                          <span style={{ color: "red" }}>*</span>
                          <Tooltip
                            title="Data Usage Limit in MB"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <OutlinedInput
                          type="text"
                          inputProps={{ tabIndex: "3" }}
                          onChange={handleDataUsageChange}
                          value={dataUsage}
                          style={{ width: "100%" }}
                          placeholder="Data Usage Limit in MB"
                        />
                        {dataError && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "11px",
                              margin: "0px 0 0 10px",
                            }}
                          >
                            {dataError}
                          </div>
                        )}
                      </div>
                    )}

                    {provider == "" && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkBox2}
                              onChange={handleCheckBox2}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="SMS Usage"
                          sx={{}}
                        />

                        {checkBox2 && (
                          <div
                            className="inputInfoBox"
                            style={{
                              width: "100%",
                              margin: "0px 0px 12px 0px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <p id="inputInfoTopText">SMS Usage Limit</p>
                              <Tooltip
                                title="SMS Usage in Number"
                                placement="right"
                              >
                                <Icon style={{ transform: "scale(.7)" }}>
                                  info_outline
                                </Icon>
                              </Tooltip>
                            </div>
                            <OutlinedInput
                              type="text"
                              inputProps={{ tabIndex: "4" }}
                              onChange={handleSMSUsageChange}
                              value={SMSUsage}
                              style={{ width: "100%" }}
                              placeholder="SMS Usage Limit in Number"
                            />
                            {smsusageError && (
                          <div
                            style={{
                              color: "red",
                              fontSize: "11px",
                              margin: "0px 0 0 10px",
                            }}
                          >
                            {smsusageError}
                          </div>
                        )}
                          </div>
                        )}
                      </>
                    )}
                    {provider == "" && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkBox3}
                              onChange={handleCheckBox3}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Voice Usage"
                          sx={{}}
                        />
                        {checkBox3 && (
                          <div
                            className="inputInfoBox"
                            style={{
                              width: "100%",
                              margin: "0px 0px 12px 0px",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <p id="inputInfoTopText">Voice Usage Limit</p>
                              <Tooltip
                                title="Voice Usage Limit in Number"
                                placement="right"
                              >
                                <Icon style={{ transform: "scale(.7)" }}>
                                  info_outline
                                </Icon>
                              </Tooltip>
                            </div>
                            <OutlinedInput
                              type="text"
                              inputProps={{ tabIndex: "5" }}
                              onChange={handleVoiceUsageChange}
                              value={voiceUsage}
                              style={{ width: "100%" }}
                              placeholder="Voice Usage Limit"
                            />
                            {voiceusageError && (
                              <div 
                              style={{
                                color: "red",
                                fontSize: "11px",
                                margin: "0px 0 0 10px",
                              }}
                            >
                              {voiceusageError}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {selectedPlan === "Overages" && (
                    <div
                      className="inputInfoBox"
                      style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Select Throttle Speeds</p>
                        <Tooltip
                          title="Select Throttle Speeds"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        inputProps={{ tabIndex: "7" }}
                        value={selectedPlan}
                        onChange={(e) => handlePlanChange(e)}
                        input={<OutlinedInput labelWidth={0} />}
                        style={{ height: "42px", width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Throttle Speeds
                        </MenuItem>
                      </Select>
                    </div>
                  )}

                  <div style={{ padding: "5px", marginLeft: "-6px" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: "4px",
                      }}
                    >
                      Remark (Optional)
                    </p>
                    <TextField
                      multiline
                      inputProps={{ tabIndex: "9" }}
                      onChange={(e) => setPackageDescription(e.target.value)}
                      value={packageDescription}
                      rows={6}
                      label="Description"
                      variant="outlined"
                      style={{
                        margin: "2px",
                        width: "100%",
                        marginLeft: "1px",
                        marginTop: "2px",
                      }}
                    />
                  </div>
                </Grid>

                <div style={{ width: "50%" }}>
                  <Grid
                    item
                    style={{ width: "95%" }}
                  >
                    
                  </Grid>
                </div>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleCreatePackage}
                  inputProps={{ tabIndex: "10" }}
                  style={{
                    width: "18%",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    color: "#9d4edd",
                    border: "1px solid #9d4edd",
                    borderRadius: "6px",
                    marginTop: "30px",
                    marginLeft: "40%",
                  }}
                >
                  Create Package
                </Button>
              </Grid>
            </ValidatorForm>
          </SimpleCard>
        </Box>
      </AppButtonRoot>
    </>
  );
};

export default CreatePackage;
