import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  styled,
  Container,
  Box,
  TableBody,
  Button,
} from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const PaymentScreen = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const TenantUUID = localStorage.getItem("TenantUUID");
  const Stripe_Url = process.env.Stripe_Url;

  const navigate = useNavigate();

  const TrialSIMs = localStorage.getItem("TrialSIMs");
  const simData = JSON.parse(TrialSIMs) || [];

  const [packageDetails, setPackageDetails] = useState({});
  const [selectedPackages, setSelectedPackages] = useState({});
  const [selectedRows, setSelectedRows] = useState({});

  const handleCheckboxChange = (iccid, event) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [iccid]: event.target.checked,
    }));
  };

  const handleSelectAll = (event) => {
    const newSelectedRows = {};

    simData.forEach((sim) => {
      newSelectedRows[sim.iccid] = event.target.checked;
    });

    setSelectedRows(newSelectedRows);
  };

  useEffect(() => {
    const fetchPackageDetails = async (iccid) => {
      const requestOptions = {
        method: "POST",
        // redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_packageTypebyICCID?iccid=${iccid}`,
          requestOptions
        );
        const result = await response.json();

        if (result.Status === 1) {
          setPackageDetails((prevDetails) => ({
            ...prevDetails,
            [iccid]: result.packages || [],
          }));
        } else {
        }
      } catch (error) {}
    };

    const fetchSequentially = async () => {
      for (const sim of simData) {
        await fetchPackageDetails(sim.iccid);
      }
    };

    fetchSequentially();
  }, [simData]);

  const handlePackageSelect = (iccid, event) => {
    setSelectedPackages((prevSelected) => ({
      ...prevSelected,
      [iccid]: event.target.value,
    }));
  };

  const makePayment = async () => {
    CalculateBasePrice();
  };

  const CalculateBasePrice = async () => {
    const commaSeparatedIds = Object.keys(selectedPackages).join(",");

    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/api/v1/get_payment_on_enable_disable_sim?iccid=${commaSeparatedIds}`,
        requestOptions
      );
      const result = await response.json();

      let totalBasePrice = 0;

      if (result.Status === 1 && result.Data) {
        result.Data.forEach((tenant) => {
          if (tenant.iccids && Array.isArray(tenant.iccids)) {
            tenant.iccids.forEach((icc) => {
              totalBasePrice += icc.basePrice || 0;
            });
          }
        });
      }

      StripePayment(totalBasePrice);
    } catch (error) {}
  };

  const StripePayment = async (totalBasePrice) => {
    const stripe = await loadStripe(`Stripe_Url`);
    const Product = {
      name: "SIM Activation",
      price: totalBasePrice,

      quantity: 1,
      description: "",
    };

    const body = {
      products: [Product],
      AccountType: "Tenant",
      packageName: "SIM Activation",
      packageId: "",
      packageDescription: "",
      UUID: TenantUUID,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      `${REACT_APP_BASE_URL}/stripe/api/v1/create-checkout-session`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );
    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    localStorage.setItem("SessionId", session.id);
    localStorage.setItem("ICCIDS", Object.keys(selectedPackages));
    localStorage.setItem("Packages", Object.values(selectedPackages));
    localStorage.setItem("PaymentFlag", "TrialPayment");

    if (result.error) {
    }
  };

  return (
    <>
      <Container>
        <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <SimpleCard title="Total SIM Cards" style={{}}>
            <div
              className="box1_1"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "15px",
                padding: "0px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <StyledTable
                id="Table-Data"
                style={{
                  padding: "5px 0px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={Object.values(selectedRows).every(Boolean)}
                        onChange={(event) => handleSelectAll(event)}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      ICCID
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      Package
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {simData.map((sim, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: "14px", color: "black" }}>
                        <input
                          type="checkbox"
                          checked={selectedRows[sim.iccid] || false}
                          onChange={(event) =>
                            handleCheckboxChange(sim.iccid, event)
                          }
                        />
                      </TableCell>
                      <TableCell style={{ fontSize: "14px", color: "black" }}>
                        {sim.iccid}
                      </TableCell>
                      <TableCell style={{ padding: "2px 25px" }}>
                        <Select
                          value={selectedPackages[sim.iccid] || ""}
                          displayEmpty
                          onChange={(event) =>
                            handlePackageSelect(sim.iccid, event)
                          }
                          style={{ height: "30px", width: "200px" }}
                          disabled={!selectedRows[sim.iccid]}
                        >
                          <MenuItem value="" disabled>
                            Select Package
                          </MenuItem>
                          {packageDetails[sim.iccid] &&
                            packageDetails[sim.iccid].map((packageInfo) => (
                              <MenuItem
                                key={packageInfo.packageTypeId}
                                value={packageInfo.packageTypeId}
                              >
                                {packageInfo.packageName}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </div>
          </SimpleCard>
        </Box>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            padding: "15px 0px ",
          }}
        >
          <Button
            style={{ color: "Blue", transform: "scale(.9)" }}
            variant="outlined"
            color="inherit"
            onClick={makePayment}
          >
            Make Payment
          </Button>
        </div>
      </Container>
    </>
  );
};

export default PaymentScreen;
