import { Container } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import { Collapse, MenuItem, FormControl, InputLabel, Select, TextField } from '@mui/material';

import {
    Box,
    Icon,
    IconButton,
    Text,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, FormLabel, Input, FormHelperText,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { H2 } from '../Typography';
import { SimpleCard } from '..';

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
    },
    '& tbody': {
        '& tr': { '& td': { paddingLeft: 0 } }
    }
}));

const MailNotification = () => {
    const [showNewTenant, setShowNewTenant] = useState(false);
    const [tenantData, setTenantData] = useState([]);
    const [tenants, setTenants] = useState([]);
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);




    const PrevMailTemp = () => {
        navigate('/sms/mailtemplates')
    }


    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    return (
        <>
            <Container
                style={{
                    padding: '20px'
                }}
            >
                <>
                    <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
                        <H2>Mail Notification |</H2>
                        <p
                            style={{
                                margin: '5px',
                                color: '#8224e3'
                            }}
                            onClick={PrevMailTemp}
                        >
                            Email Notification
                        </p>

                    </Box>

                    <SimpleCard>
                        <Box width="100%" overflow="auto">
                            <div
                                style={{
                                    alignItems: 'center',
                                    backgroundColor: 'white'
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        //   padding: '0px',
                                        //   margin: '10px',
                                        display: 'flex',


                                        //   gridTemplateColumns: 'auto auto auto auto'
                                    }}
                                >
                                    <div style={{ margin: '10px' }}>
                                        <TextField size='small' label="Enter Template Name*" style={{ margin: '10px', width: '30%' }} ></TextField>
                                        <TextField size='small' label="Status*" style={{ margin: '10px', width: '30%' }} />
                                        <TextField size='small' label="Email Id*" style={{ margin: '10px', width: '30%' }} />


                                    </div>
                                </div>
                                <div style={{ margin: '10px' }}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={15}
                                        label="Body"
                                        variant="outlined"
                                        style={{ margin: '10px' }}
                                    />


                                </div>

                            </div>
                            <StyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="left"
                                        >
                                            Tag
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="center"
                                        >
                                            Description
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="center"
                                        >
                                            Tag
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="center"
                                        >
                                            Description
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="center"
                                        >
                                            Tag
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                color: 'Gray'
                                            }}
                                            align="center"
                                        >
                                            Description
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow >
                                        <TableCell style={{ color: 'gray' }} align="left">tenant_name</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Tenant Name</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">phone_number</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Phone Number</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">agent_limit</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Agent Limit</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ color: 'gray' }} align="left">city</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">City</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center"> state</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">State</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">domain_name</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Domain Name</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ color: 'gray' }} align="left">country</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Country</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center"> address</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Address</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">time_zone</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Time Zone</TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ color: 'gray' }} align="left">password</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Password</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center"> Country</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Country Name</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">domain_description</TableCell>
                                        <TableCell style={{ color: 'gray' }} align="center">Domain Description</TableCell>
                                    </TableRow>
                                </TableBody>
                            </StyledTable>
                            <div
                                style={{
                                    padding: '10px'
                                    //   margin: '10px',
                                }}
                            >
                                {' '}
                                <Button
                                    style={{ margin: '10px', color: 'red' }}
                                    variant="outlined"
                                    color="inherit"
                                //   onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ margin: '10px', color: 'green' }}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Save
                                </Button>
                            </div>


                        </Box>
                    </SimpleCard>
                </>
            </Container>
        </>
    );
};

export default MailNotification;
