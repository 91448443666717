import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { H1 } from "app/components/Typography";
import { Container } from "@mui/material";

const AdminSIMInfo = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  let icccid = localStorage.getItem("iccid");

  const [id, setId] = useState("");
  const [parentId, setParentId] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [date, setDate] = useState("");
  const [simStatus, setSimStatus] = useState("");
  const [provider, setProvider] = useState("");
  const [statusofSIM, setStatusofSIM] = useState("");
  const [skuid, setSkuId] = useState("");
  const [packageType, setPackageType] = useState("");
  const [packageStatus, setPackageStatus] = useState("");
  const [remainingData, setRemainingData] = useState("");




  const fetchSimDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      iccid: icccid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_iccid_details_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setId(result.ICCID._id);
        setParentId(result.ICCID.parentGroupId);
        setInventoryId(result.ICCID.inventoryId);
        setDate(result.ICCID.epochCreated);
        setSimStatus(result.ICCID.status1);
        setProvider(result.ICCID.Provider)
        setStatusofSIM(result.ICCID.statusOfSim)
        setSkuId(result.ICCID.SkuId);
      })
    .catch((error) => {
    });
  };

  useEffect(() => {
    fetchSimDetails();
  }, []);

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  return (
    <>
      <Container>
        <Box
          sx={{ height: "100%", typography: "body1" }}
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          <Box
            className="breadcrumb"
            style={{ display: "flex", margin: "0px 0px 0px 20px" }}
          >
            <h3 style={{ fontWeight: "500", fontSize: "21.14px" }}>
              SIM Card Details |
            </h3>

            <span
              style={{
                marginTop: "26px",
                marginLeft: "10px",
                color: "#8224e3",
                fontSize: "14px",
              }}
            >
              {" "}
              {icccid}
            </span>
          </Box>
          <hr
            style={{
              border: "1px lightGrey solid",
              width: "97%",
              marginBottom: "20px",
              marginTop: "-13px",
            }}
          />

          <div
            style={{
              margin: "40px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="box1"
              style={{
                width: "50%",
                height: "auto",
                padding: "10px 10px 10px  15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "15px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                General Details <hr />
              </p>
              <table>
                
                <tr>
                  <td>ICCID :</td>
                  <td style={{ padding: "2px 25px" }}>{icccid}</td>
                </tr>
                <tr>
                  <td>Parent Group ID :</td>
                  <td style={{ padding: "2px 25px" }}>{parentId}</td>
                </tr>

                <tr>
                  <td>Inventory ID:</td>
                  <td style={{ padding: "2px 25px" }}>{inventoryId}</td>
                </tr>

                <tr>
                  <td>SIM Status :</td>
                  <td style={{ padding: "2px 25px", textTransform: 'capitalize' }}>{simStatus}</td>
                </tr>
                <tr>
                  <td>Provider :</td>
                  <td style={{ padding: "2px 25px" }}>{provider}</td>
                </tr>
                <tr>
                  <td>SKU ID :</td>
                  <td style={{ padding: "2px 25px" }}>{skuid}</td>
                </tr>
                <tr>
                  <td>Status :</td>
                  <td style={{ padding: "2px 25px" }}>{statusofSIM}</td>
                </tr>
              </table>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default AdminSIMInfo;
