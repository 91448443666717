import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import {
    Box,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { H2, H1 } from 'app/components/Typography';

const Container = styled('div')(({ theme }) => ({
    margin: '17px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
}));

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
    },
    '& tbody': {
        '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } }
    }
}));
const User = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/user/api/v1/get_users`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setUserData(result.Account_details)
               
            })
    };

    const handleDeleteUser = (UserId) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserId": UserId
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/user/api/v1/delete_user`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                window.location.reload()
            })
    }

    const handleEditUser = (UserId, EmailId) => {
        localStorage.setItem('edit_user_id', UserId);
        localStorage.setItem('edit_emailId', EmailId);
        navigate('/user/edit-user');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Container>
            <Box className="breadcrumb" style={{ display: 'flex' }}>
                <H2>Uers | </H2>
                <p
                    style={{
                        margin: '5px',
                        color: '#8224e3'
                    }}
                >
                    Users
                </p>

            </Box>

            <SimpleCard title="Users">
                <Box width="100%" overflow="auto">
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="left"
                                >
                                    First Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Last Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Email ID
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Address
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Contact NO
                                </TableCell>

                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Time Zone
                                </TableCell>

                                <TableCell
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: '600'
                                    }}
                                    align="center"
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {userData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((user, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{user.FirstName}</TableCell>
                                    <TableCell align="center">{user.LastName}</TableCell>
                                    <TableCell align="center">{user.EmailId}</TableCell>
                                    <TableCell align="center">{user.Address}</TableCell>
                                    <TableCell align="center">{user.PhoneNumber}</TableCell>
                                    <TableCell align="center">{user.Timezone}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => handleDeleteUser(user.UserId)}
                                            sx={{
                                                '.text-effect': {
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '100%',
                                                    transform: 'translateX(50%,-50%)',
                                                    display: 'none',
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    border: '1px solid black',
                                                    backgroundColor: 'white'
                                                },
                                                '&:hover .text-effect': {
                                                    display: 'block'
                                                },
                                                position: 'relative'
                                            }}
                                        >
                                            <Icon color="error">delete</Icon>
                                            <Typography variant="caption" className="text-effect">
                                                Delete
                                            </Typography>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleEditUser(user.UserId, user.EmailId)}
                                            sx={{
                                                '.text-effect': {
                                                    position: 'absolute',
                                                    top: '-21%',
                                                    left: '20%',
                                                    transform: 'translateX(50%,-50%)',
                                                    display: 'none', // Initially hide the text
                                                    textAlign: 'center',
                                                    width: '100%',
                                                    border: '1px solid black',
                                                    backgroundColor: 'white'
                                                },
                                                '&:hover .text-effect': {
                                                    display: 'block' // Show the text when hovering over the icon
                                                },
                                                position: 'relative'
                                            }}
                                        >
                                            <Icon>edit</Icon>
                                            <Typography variant="caption" className="text-effect">
                                                Edit
                                            </Typography>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </StyledTable>

                    <TablePagination
                        rowsPerPageOptions={[7, 14, 25]}
                        component="div"
                        count={userData.length} // Total number of rows
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </SimpleCard>
        </Container>
    )
}

export default User
