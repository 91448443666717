import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Checkbox,
  Menu
} from "@mui/material";
import "./eSIM.css";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import { SearchOff } from "@mui/icons-material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const Pending_Tenant_SIMs = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [simData, setSimData] = useState([]);
  const [status, setStatus] = useState("");

  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("iccId");
  const [order, setOrder] = useState("asc");
  const [inventoryId, setInventoryId] = useState("");

  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [iccId, setIccid] = useState(true);
  const [inventoryids, setInventoryIds] = useState(false);
  const [columnVisible, setColumnVisible] = useState(true);
  const [inventoryName, setInventoryName] = useState(true);
  const [skuId, setSkuId] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [simStatus, setSimStatus] = useState(true);
  const [type, setType] = useState(true);
  const [FormatFilter, setFormatFilter] = useState("");

  const [length, setLength] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleSelectAll = (event) => {
    if(event.target.checked){
      setSelectedRows(filteredSims.map((sim) => sim.iccId))
    }else{
      setSelectedRows([]);
    }
  }



  const handleRowSelect = (iccId) => {
    // Toggle selection for a specific row
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(iccId)) {
        // Row is already selected, so deselect it
        return prevSelected.filter((id) => id !== iccId);
      } else {
        // Row is not selected, so select it
        return [...prevSelected, iccId];
      }
    });
  };

  const fetchSims = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${REACT_APP_BASE_URL}/api/v1/get_all_sims_of_tenant`,
      requestOptions
    );
    const result = await response.json();
    const mappedData = result.Sims.reduce((acc, sim) => {
      return acc.concat(sim.iccids.map((iccid) => iccid.iccId));
    }, []);
    setLength(mappedData.length);

    setSimData(result.Sims);
  };

  useEffect(() => {
    fetchSims();
  }, []);

  const filteredSims = simData
    .flatMap((sim) => sim.iccids)
    .filter((iccid) => iccid.status.toLowerCase() === "unassigned")
    .filter((sim) => {
      const fullName =
        `${sim.iccId}   ${sim.statusOfSim} ${sim.status}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset page when the search term changes
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedSims = filteredSims.sort((a, b) => {
    const isAsc = order === "asc";
    return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Inventory_Details" + fileExtension);
  };

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s",
  });

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == false) {
      setInventoryIds(true);
      setInventoryName(true);
      setIccid(true);
      setType(true);
      setEnabled(true);
      setSkuId(true);
      setSimStatus(true);
    } else if (columnVisible == true) {
      setInventoryIds(false);
      setInventoryName(false);
      setIccid(false);
      setType(false);
      setEnabled(true);
      setSkuId(false);
      setSimStatus(false);
    }
  };

  const handleToggleSwitch = () => {
    setIccid(!iccId);
  };
  const handleToggleInventoryName = () => {
    setInventoryName(!inventoryName);
  };

  const handleToggleInventoryid = () => {
    setInventoryId(!inventoryids);
  };
  const handleToggleSKU = () => {
    setSkuId(!skuId);
  };

  const handleToggleenabled = () => {
    setEnabled(!enabled);
  };

  const handleTogglesimStatus = () => {
    setSimStatus(!simStatus);
  };
  const handleToggletype = () => {
    setType(!type);
  };

  const simInfo = (iccId) => {
    localStorage.setItem("iccId", iccId);
    navigate("/inventory/esim-details");
  };
  const selectt = () => {
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>SIM Cards | </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Pending SIM Cards
          </p>
        </Box>
        <Container
          style={{
            ...containerStyle,
            display: animateContainer ? "block" : "none",
            position: "absolute",
            top: "25%",
            left: "25%",
            transform: "translate(-25%,-25%)",
            zIndex: "100",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "10px",
                backgroundColor: " rgb(242 242 242 / 100%)",
                borderRadius: "25px",
                marginLeft: "15%",
                animation: "fadeIn 1.5s ease-in-out",
              }}
            >
              <div
                style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
              >
                <H2
                  style={{
                    margin: "10px",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                >
                  Columns
                  <Switch
                    color="primary"
                    checked={columnVisible}
                    onChange={handleToggleColumnSwitch}
                  />
                </H2>
                <hr />
              </div>
              <br />

              <div
                style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
              >
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    marginRight: "20px",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  ICCID
                  <Switch
                    color="primary"
                    checked={iccId}
                    onChange={handleToggleSwitch}
                  />
                </p>
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Inventory ID
                  <Switch
                    color="primary"
                    checked={inventoryids}
                    onChange={handleToggleInventoryid}
                  />
                </p>
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Inventory Name
                  <Switch
                    color="primary"
                    checked={inventoryName}
                    onChange={handleToggleInventoryName}
                  />
                </p>

                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  SKU ID
                  <Switch
                    color="primary"
                    checked={skuId}
                    onChange={handleToggleSKU}
                  />
                </p>
              </div>

              <div
                style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
              >
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Enabled
                  <Switch
                    color="primary"
                    checked={enabled}
                    onChange={handleToggleenabled}
                  />
                </p>

                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Status
                  <Switch
                    color="primary"
                    checked={simStatus}
                    onChange={handleTogglesimStatus}
                  />
                </p>
              </div>

              <hr />
              <div style={{ padding: "4px", marginLeft: "302px" }}>
                <Button
                  style={{ color: "#8224e3" }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleButtonClick}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        </Container>

        <SimpleCard title="Pending SIM Cards" style={{}}>
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              height: "70px",
            }}
          >
            <input
              style={{
                height: "40px",
                width: "28%",
                border: "1px solid  lightgrey",
                paddingLeft: "10px",
                borderRadius: "5px",
                outline: "1px black lightgrey",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              value={searchTerm}
              onChange={handleSearch}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {selectedRows.length > 0 && (
                <div style={{ width: "100%", margin: "0px 55px 0px 0px" }}>
                  <p id="inputInfoTopText">
                    Action
                    <Tooltip title="Select an action" placement="top-start">
                      <IconButton onClick={handleClick}>
                        <Icon style={{ transform: "scale(.9)" }}>
                          format_list_bulleted
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </p>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>Activate SIM</MenuItem>

                    <MenuItem>Suspend SIM</MenuItem>
                  </Menu>
                </div>
              )}

            </div>
          </div>

          
          <Box
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <Button onClick={selectt}></Button>
            <StyledTable
              id="Table-Data"
              stickyHeader
              style={{
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingRight: "8px" }} align="center">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 && selectedRows.length < filteredSims.length
                      }
                      checked={
                        filteredSims.length > 0 ? selectedRows.length === filteredSims.length : false
                      }
                    
                      onChange={handleSelectAll}
                    />
                  </TableCell>

                  {iccId && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        width: "180px",
                        paddingLeft: "10px",
                        cursor: "pointer",
                      }}
                      align="center"
                      onClick={() => handleSort("iccId")}
                    >
                      ICCID
                      <TableSortLabel
                        active={orderBy === "iccId"}
                        direction={orderBy === "iccId" ? order : "asc"}
                      ></TableSortLabel>
                    </TableCell>
                  )}

                  {simStatus && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Status
                    </TableCell>
                  )}
                  {enabled && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Assigned
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSims.length > 0 ? (
                  sortedSims
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (


                      <TableRow key={sim._id} id="iccidRow">
                        <TableCell align="center">
                          <Checkbox
                            checked={selectedRows.includes(sim.iccId)}
                            onChange={() => handleRowSelect(sim.iccId)}
                          />
                        </TableCell>

                        {iccId && (
                          <TableCell
                            align="center"
                            onClick={() => simInfo(sim.iccId)}
                            id="iccId"
                          >
                            {sim.iccId}
                          </TableCell>
                        )}

                        {simStatus && (
                          <TableCell align="center">
                            {sim.statusOfSim}
                          </TableCell>
                        )}
                        <TableCell style={{ fontSize: "13px" }} align="center">
                          <Tooltip
                            title={
                              sim.status === "assigned"
                                ? "Assigned"
                                : "Unassigned"
                            }
                          >
                            {sim.status === "assigned" ? (
                              <CheckCircleOutlineIcon color="primary" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[7, 14, 30]}
            component="div"
            count={sortedSims.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>
    </>
  );
};

export default Pending_Tenant_SIMs;
