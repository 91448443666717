import React from 'react';
import { useState, useEffect } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
    Box,
    Button,
    Typography,
    Grid,
    Container,
    Paper,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'app/components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { H2, H1, H3, h6 } from 'app/components/Typography';

const TextField = styled(TextValidator)(() => ({
    width: '80%',
    marginTop: '-8px',
    marginLeft: '44px',
    gap: '5px'
}));
const EditUSer = () => {
    const navigate = useNavigate();
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [status, setStatus] = useState('');
    const [tenantlimit, setTenantLimit] = useState('');
    const [totalSims, setTotalSims] = useState('');
    const [enabled, setEnabled] = useState('');
    const [emailVerified, setEmailVerified] = useState('');
    const [phoneVerified, setPhoneVerified] = useState('');
    const [otpVerified, setOtpVerified] = useState('');
    const [otp, setOtp] = useState('');
    const [username, setUsername] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [id, setId] = useState('')

    const storedUserID = localStorage.getItem('edit_user_id');
    const storedEmail = localStorage.getItem('edit_emailId');

    const [showPassword, setShowPassword] = React.useState(false);

    const handlePasswordVisibilityToggle = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        const fetchUserData = () => {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "UserId": storedUserID
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${REACT_APP_BASE_URL}/user/api/v1/get_user`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    setFirstname(result.UserData.FirstName);
                    setLastname(result.UserData.LastName);
                    setMobileNumber(result.UserData.PhoneNumber);
                    setDob(result.UserData.DateOfBirth);
                    setAddress(result.UserData.Address);
                    setPassword(result.UserData.Password.slice(-1, 0));
                    setStatus(result.UserData.Status);
                    setEnabled(result.UserData.Enabled);
                    setTenantLimit(result.UserData.MaxTenantLimit);
                    setTotalSims(result.UserData.TotalSims);
                    setEmailVerified(result.UserData.EmailVerified);
                    setPhoneVerified(result.UserData.PhoneVerified);
                    setOtp(result.UserData.Otp);
                    setOtpVerified(result.UserData.OtpVerified);
                    setUsername(result.UserData.Username);
                    setState(result.UserData.State)
                    setCity(result.UserData.City)
                })
        }
        fetchUserData();
    }, [])

    const handleUpdateUser = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "UserId": storedUserID,
            "FirstName": firstName,
            "LastName": lastName,
            "DateOfBirth": dob,
            "Address": address,
            "Password": password,
            "OldPassword": "",
            "Status": status,
            "Enabled": enabled,
            "EmailId": storedEmail,
            "EmailVerified": emailVerified,
            "PhoneVerified": phoneVerified,
            "Otp": otp,
            "OtpVerified": otpVerified,
            "MaxTenantLimit": tenantlimit,
            "TotalSims": totalSims,
            "ActiveSims": "23",
            "SuspendedSims": "3",
            "MTDUsage": "",
            "PrepaidBalance": "",
            "OutstandingBalace": "",
            "PastDueBalance": "",
            State: state,
            City: city,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/user/api/v1/update_user`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                navigate('/users/Users');
            })
    }

    return (
        <>
            <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
                <Box className="breadcrumb" style={{ display: 'flex', alignItems: 'center' }}>
                    <H3>Edit User | </H3>

                    <a
                        href="/supervisor/supervisor"
                        style={{
                            margin: '10px',
                            color: '#8224e3',
                            textDecoration: 'none'
                        }}
                    >
                        Users &gt;
                    </a>
                    <p
                        style={{
                            marginLeft: '10px',
                            color: '#8224e3',
                            margin: '0'
                        }}
                    >
                        Edit Users
                    </p>
                </Box>
                <Paper style={{ marginTop: '2rem' }} elevation={3} sx={{ padding: '20px' }}>
                    <Typography variant="h6" gutterBottom>
                        Edit User Details
                    </Typography>
                    <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                        <Grid container spacing={3} style={{ marginTop: '5px' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="First Name"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "1" }}
                                    value={firstName}
                                    onChange={(e) => setFirstname(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "2" }}
                                    value={lastName}
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "3" }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handlePasswordVisibilityToggle} edge="end">
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Date of Birth"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "4" }}
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "5" }}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "6" }}
                                    type="tel"
                                    value={mobileNumber}
                                    onChange={(e) => setMobileNumber(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "7" }}
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="State"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "8" }}
                                    type="text"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="City"
                                    variant="outlined"
                                    inputProps={{ tabIndex: "9" }}
                                    type="text"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Grid>
                        </Grid>

                        <Button
                            variant="outlined"
                            color="inherit"
                            style={{ color: '#8224e3' }}
                            onClick={handleUpdateUser}
                            sx={{ marginTop: '20px', ml: 5 }}
                        >
                            Save
                        </Button>
                    </ValidatorForm>
                </Paper>
            </Container>
        </>
    )
}

export default EditUSer
