import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Modal from "@mui/material/Modal";

import "./AssignInventoryStyle.css";

import {
  Box,
  Container,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Input,
  Button,
  Chip,
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1, H3, H4 } from "app/components/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "0px",
  p: 4,
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const AssignPackage = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [tenantList, setTenantList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("");
  const DomainUUID = localStorage.getItem("DomainUUID");

  const [packages, setPackages] = useState([]);

  const [inventoryList, setInventoryList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [selectedIccids, setSelectedIccids] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setInventoryList(result.Packages || []);
        })
        .catch((error) => {
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchIccids = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: myHeaders,
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_admin_sims`,
          requestOptions
        );
        const result = await response.json();
        if (result.Status === 1) {
          const iccidValues = result.AdminSims.map((sim) => sim.iccid);
        } else {
        }
      } catch (error) {
      }
    };
    fetchIccids();
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedPackage(value);
  };

  const handleSave = () => {
    const PackageIds = selectedPackage.join(",");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
      TenantUUID: selectedTenant,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/assign_package_to_tenant?packageRange=${PackageIds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => {
    });


  };

  useEffect(() => {
    fetchTenant();
  }, []);

  useEffect(() => {
    if (selectedTenant) {
      fetchpackages(selectedTenant);
    }
  }, [selectedTenant]);

  const accessToken = localStorage.getItem("accessToken");
  const fetchTenant = async () => {
    try {
      var raw = "";
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/get_tenants`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch tenant data");
      }

      const result = await response.json();
      if (result.Status === 1) {
        setTenantList(result.Account_details);
      }
    } catch (error) {
    }
  };

  const goToPackage = () => {
    navigate("/package/create-package");
  };

  const fetchpackages = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        setInventoryList(result.Packages || []);
        const packagevalue = result.Packages.map((packages) => packages.name);
        setPackages(result.Packages);
      })
      .catch((error) => {
      });
  };

  const handleTogglePackages = (packageId) => {
    const isSelected = selectedPackage.indexOf(packageId) > -1;

    // Create a new array with the selectedPackage IDs as strings
    const newSelectedPackages = isSelected
      ? selectedPackage.filter((id) => id !== packageId)
      : [...selectedPackage, packageId];

    setSelectedPackage(newSelectedPackages);
  };

  return (
    <>
      <Container style={{ padding: "10px" }}>
        <>
          <ValidatorForm onSubmit={(e) => e.preventDefault()}>
            <Box
              style={{
                alignItems: "center",
                backgroundColor: "white",
                padding: "30px",
              }}
            >
              <div
                className="box1"
                style={{
                  width: "100%",
                  height: "auto",
                  padding: "15px 10px",
                  backgroundColor: " rgb(233 226 226 / 35%)",
                  borderRadius: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                }}
              >
                <div
                  style={{
                    padding: "5px 20px 7px 10px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <H3 style={{ padding: "0px" }}>Assign Package to Tenant</H3>
                  <Button
                    onClick={goToPackage}
                    style={{ color: "#8224e3" }}
                    variant="outlined"
                    color="inherit"
                  >
                    Create Package
                  </Button>
                </div>

                <hr
                  style={{
                    border: "1px lightGrey solid",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                />
                <div className="createTenantAssignSelect">
                  <H3
                    style={{
                      fontSize: "16px",
                      width: "50%",
                    }}
                  >
                    Tenant
                  </H3>
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Tenant</p>
                      <Tooltip
                        title="Select the tenant you wish to Assign the SIMs"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedTenant}
                      onChange={(e) => setSelectedTenant(e.target.value)}
                      displayEmpty
                      style={{ height: "50px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Tenant --
                      </MenuItem>
                      {tenantList.map((supervisor, index) => (
                        <MenuItem key={index} value={supervisor.TenantUUID}>
                          {supervisor.FirstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>

                <div className="createTenantAssignSelect">
                  <H3
                    style={{
                      fontSize: "16px",
                      width: "50%",
                    }}
                  >
                    Select Package:
                  </H3>
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Package</p>
                      <Tooltip title="Select the Package " placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>

                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Packages
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        value={selectedPackage}
                        onChange={handleChange}
                        multiple
                        displayEmpty
                        style={{ height: "47px", width: "100%" }}
                        input={<OutlinedInput label="hello Packages" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        <MenuItem value="" disabled>
                          -- Select --
                        </MenuItem>
                        {packages.map((pkg) => (
                          <MenuItem key={pkg.packageId} value={pkg.packageId}>
                            <Checkbox
                              checked={
                                selectedPackage.indexOf(pkg.packageId) > -1
                              }
                              onClick={() =>
                                handleTogglePackages(pkg.packageId)
                              }
                            />
                            <ListItemText primary={pkg.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    padding: "5px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    onClick={handleSave}
                    style={{ color: "#8224e3" }}
                    variant="outlined"
                    color="inherit"
                  >
                    Assign Package
                  </Button>
                </div>
              </div>
            </Box>
          </ValidatorForm>
        </>
      </Container>
    </>
  );
};

export default AssignPackage;
