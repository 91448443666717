import { Container } from '@mui/material';
import React from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import { Collapse, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { TextField, Grid } from '@mui/material';
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination'; 
import { H2 } from '../Typography';
import { SimpleCard } from '..';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  }
}));
const SMSGateways = () => {
 
  const navigate = useNavigate();
  
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(7); // Rows per page
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <>
      <Container
        style={{
          padding: '20px'
        }}
      >
        <>
          <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
            <H2>SMS Gateway</H2>
            <Button
              variant="outlined"
              color="inherit"
              onClick={toggleCollapse}
              style={{ marginLeft: 'auto', color: '#8224e3' }}
            >
              Add New API
            </Button>
          </Box>

          <Collapse in={open}>
            <div
              style={{
                // backgroundColor: 'green',
                boxShadow: '0px 0px 3px black',
                alignItems: 'center',
                borderRadius: '10px'
              }}
            >
              <div
                style={{
                  padding: '10px',
                  margin: '10px',
                  display: 'grid',
                  gridTemplateColumns: 'auto auto auto auto'
                  //   alignItems: 'center',
                  //   borderRadius: '10px'
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField label="Gateway Name" fullWidth size='small' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label="Gateway Message Parameter" fullWidth size='small' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label="Gateway URL" fullWidth size='small'/>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField label="Gateway from Number Parameter" fullWidth size='small' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label="Gateway from Number" fullWidth size='small' />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField label="SMS Gateway Type" fullWidth size='small' />
                  </Grid>
                </Grid>
              </div>
              <div
                style={{
                  padding: '10px'
                  //   margin: '10px',
                }}
              >
                {' '}
                <Button
                  style={{ margin: '10px', color: 'red' }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{ margin: '10px', color: 'green' }}
                  variant="outlined"
                  color="inherit"
                >Add</Button>
              </div>
            </div>
          </Collapse>

          <SimpleCard title="SMS Gateway">
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }} >
              <img src="/assets/images/no-data-found.png" alt=""
                style={{
                }} />
              <p>No Data to Show</p>
            </div>

            <div>
            
            </div>

          </SimpleCard>
        </>
      </Container>
    </>
  );
};
export default SMSGateways;
