import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";

import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const Package = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [simData, setSimData] = useState([]);
  const [status, setStatus] = useState("");
  const [packageData, setPackageData] = useState([])

  const [iccid, setIccid] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [createdOn, setCreatedOn] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  // Update the existing state variables
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState("PackageUUID");
  const [order, setOrder] = useState("asc");


  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name'); // Default sorting by 'name'
  const [sortOrder, setSortOrder] = useState('asc');

  // Option selection states

  const [option, setOption] = useState("1");
  const navigate = useNavigate();

  const fetchSims = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const packages = result.Packages;
        setFilteredData(packages);
        setSortedData(sortData(packages, orderBy, order));
        setSimData(result.Packages);
      });
  };

  useEffect(() => {
    fetchSims();
    packageDetails();
  }, []);

  // package details api here

  const packageDetails = () => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(`${REACT_APP_BASE_URL}/get_assigned_packages`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setPackageData(result.Packages)
      })
      .catch(error => {
      });
  }

  const sortData = (data, property, order) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA < valueB) {
        return order === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  };

  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);

    setSortedData(sortData(filteredData, property, newOrder));
  };

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);

    const filteredData = simData.filter(
      (sim) =>
        (sim.PackageUUID &&
          sim.PackageUUID.toString().toLowerCase().includes(searchText)) ||
        (sim.name && sim.name.toLowerCase().includes(searchText)) ||
        (sim.type && sim.type.toLowerCase().includes(searchText))
    );

    setFilteredData(filteredData);
    setSortedData(sortData(filteredData, orderBy, order));
  };


  const sortedPackageData = [...packageData].sort((a, b) => {
    // Implement sorting logic based on the selected column (sortBy)
    if (sortOrder === 'asc') {
      return a[sortBy].localeCompare(b[sortBy]);
    } else {
      return b[sortBy].localeCompare(a[sortBy]);
    }
  });

  const filteredPackageData = sortedPackageData.filter(data =>
    data.name.toLowerCase().includes(searchText.toLowerCase()) ||
    data.packageTypeId.toLowerCase().includes(searchText.toLowerCase()) ||
    data.packageType.toLowerCase().includes(searchText.toLowerCase) ||
    data.packageDescription.toLowerCase().includes(searchText.toLowerCase)
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function isoDate(inputDateString) {
    const date = new Date(inputDateString);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedDate;
  }

  const packageInfo = (packageId) => {
    localStorage.setItem("packageId", packageId);
    navigate("/package/package-info");
  };

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  const createPacakge = () => {
    navigate("/package/create-package");
  };

  return (
    <>
      <Container>
        <Box
          className="breadcrumb"
          style={{ display: "flex", padding: "10px 10px 0px 10px" }}
        >
          <H2>Package | </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Package
          </p>
          <Button
            onClick={createPacakge}
            style={{
              marginLeft: "auto",
              width: "auto",
              height: "40px",
              color: "#8224e3",
            }} // Push the button to the extreme right
            variant="outlined"
            color="inherit"
          >
            Create Package Template
          </Button>
        </Box>

        <SimpleCard title="Package">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 3px 20px 3px",
            }}
          >
            <input
              style={{
                height: "36px",
                width: "25%",
                border: "1px solid  #dededf",
                paddingLeft: "10px",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
            />
            <Select
              inputProps={{ tabIndex: "" }}
              value={option}
              onChange={(e) => setOption(e.target.value)}
              input={<OutlinedInput labelWidth={0} />}
              style={{ height: "35px", width: "25%" }}
              displayEmpty
              MenuProps={{
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
            >
              <MenuItem value="1">Template</MenuItem>
              <MenuItem value="2">Package </MenuItem>
            </Select>
          </div>

          <Box
            style={{
              width: "100%",
              height: "auto",

            }}
          >
            {option == "1" && (
              <StyledTable
                stickyHeader
                style={{
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      align="center"
                      onClick={() => handleSort("PackageUUID")}
                    >
                      Package ID
                      <TableSortLabel
                        active={orderBy === "PackageUUID"}
                        direction={orderBy === "PackageUUID" ? order : "asc"}
                      ></TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        paddingLeft: "10px",
                      }}
                      align="center"
                    >
                      Package Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Package Description
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {simData.length > 0 ? (
                    sortedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((sim) => (
                        <TableRow key={sim._id}>
                          <TableCell
                            align="center"
                          >
                            {sim.PackageUUID}
                          </TableCell>

                          <TableCell align="center">{sim.name}</TableCell>

                          <TableCell align="center">{sim.packageDescription}</TableCell>

                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </StyledTable>
            )}
            {option == "2" && (
              <StyledTable
                stickyHeader
                style={{
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      align="center"
                      onClick={() => handleSort("PackageUUID")}
                    >
                      Name
                      <TableSortLabel
                        active={orderBy === "PackageUUID"}
                        direction={orderBy === "PackageUUID" ? order : "asc"}
                      ></TableSortLabel>
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        paddingLeft: "10px",
                      }}
                      align="center"
                    >
                      Package Type ID
                    </TableCell>

                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Package Type
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredPackageData.length > 0 ? (
                    filteredPackageData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(data => (
                        <TableRow key={data._id}>
                          <TableCell align="center">{data.name}</TableCell>
                          <TableCell align="center">{data.packageTypeId}</TableCell>
                          <TableCell align="center">{data.packageType}</TableCell>
                          <TableCell align="center">{data.packageDescription}</TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No data available.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </StyledTable>
            )}
          </Box>
          {option == "1" && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 30]}
              component="div"
              count={packageData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {option == "2" && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 30]}
              component="div"
              count={simData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

        </SimpleCard>
      </Container>
    </>
  );
};

export default Package;
