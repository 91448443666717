import { Box, Container, Fab, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import axios from "axios";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OtpTimer from "otp-timer";
import OutlinedInput from "@mui/material/OutlinedInput";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import "./CreateTenantStyle.css";

import {
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  Input,
  Button,
  Chip,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
// import StepperForm from '../forms/StepperForm';
// import SimpleTable from '../tables/SimpleTable';
import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { H2, H1, H3, H4 } from "app/components/Typography";
import ListItemText from "@mui/material/ListItemText";
import iso3166 from "iso-3166-1";
import AddIcon from "@mui/icons-material/Add";
import countries from "../Admin/countries";

import Autocomplete from "@mui/material/Autocomplete";
function getSteps() {
  return [
    "Fill the details",
    "Enter the address",
    "Assign Inventory",
    "Success",
  ];
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginBottom: "16px",
  marginLeft: "44px",
}));

const AppButtonRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
  "& .button": { margin: theme.spacing(1) },
  "& .input": { display: "none" },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const CreateTenants = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const iso3166 = require("iso-3166-1");

  const accessToken = localStorage.getItem("accessToken");
  const DomainUUID = localStorage.getItem("DomainUUID");

  const [firstName, setFirstName] = useState("");
  const [fNameFlag, setFNameFlag] = useState(true);
  const [error, setError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lNameEror, setLNameError] = useState("");
  const [lNameFlag, setLNameFlag] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailFlag, setEmailFlag] = useState(true);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isClientValid, setIsClientValid] = useState(true);
  const [tenantlimit, setTenantLimit] = useState("");
  const [phone, setPhone] = useState("");

  const [checkBox1, setCheckBox1] = useState(true); // for setting the billing and current address the same

  // This is for the Billing address
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [cityFlag, setCityFlag] = useState(true);
  const [currentState, setCurrentState] = useState("");
  const [stateError, setStateError] = useState("");
  const [stateFlag, setStateFlag] = useState(true);
  const [postalCode, setPostalCode] = useState("");
  const [postalError, setPostalError] = useState("");
  const [postalFlag, setPostalFlag] = useState(true);

  // This is for the Shipping Address
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingStateError, setShippingStateError] = useState("");
  const [shippingStateFlag, setShippingStateFlag] = useState(true);
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCityError, setShippingCityError] = useState("");
  const [shippingCityFlag, setShippingCityFlag] = useState(true);
  const [shippingPostalCode, setShippingPostalCode] = useState("");
  const [shippingPostalError, setShippingPostalError] = useState("");
  const [shippingPostalFlag, setShippingPostalFlag] = useState(true);

  // For assigning the Inventories
  const [inventoryName, setInventoryName] = useState("");
  const [billingGroup, setBillingGroup] = useState("");

  const [iccids, setIccids] = useState([]);
  const [selectedStartIccid, setSelectedIStartccid] = useState("");
  const [selectedEndIccid, setSelectedEndIccid] = useState("");
  const [selectedICCIDs, setSelectedICCIDs] = useState([]);
  const [selectedIccids, setSelectedIccids] = useState([]);

  const [shippingCountry, setShippingCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [tenantUI_, setTenantUI_] = useState("");
  const [storedFormattedMergedIds, setStoredFormattedMergedIds] = useState("");

  const [packages, setPackages] = useState([]);
  const [packgaeexpireDate, setPacakgeExpireDate] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");

  const [basePrice, setBasePrice] = useState("");
  const [ratePrice, setRatePrice] = useState("");

  const [trialPeriod, setTrialPeriod] = useState("");
  const [startBillDate, setStartBillDate] = useState("");
  const [endBillDate, setEndBillDate] = useState("");
  const [checkBoxTrial, setCheckBoxTrial] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [trial1, setTrial1] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [ThrottleSpeedList, setThrottleSpeedList] = useState("");
  const [SelectedThrottleSpeed, setSelectedThrottleSpeed] = useState("");
  const [provider, setProvider] = useState("");
  const [Package, setPackage] = useState("");
  const [objectsArray, setObjectsArray] = useState([]);
  const [errordate, setErrordate] = useState(false);
  const [totalSims, setTotalSims] = useState("");
  const [InventoryId, setInventoryId] = useState("");

  const maxPasswordLength = 18;
  const maxClientLenght = 3;

  const handleReset = () => setActiveStep(0);
  const handleSubmit = () => { };
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const stepIndex = activeStep;

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleShippingCountry = (event) => {
    setShippingCountry(event.target.value);
  };

  const fetchpackages = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const packagevalue = result.Packages.map((packages) => packages.name);
        setPackages(result.Packages);
      })
      .catch(error);
  };

  const fetchIccids = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: myHeaders,
    };
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/api/v1/get_admin_sims`,
        requestOptions
      );
      const result = await response.json();

      if (result.Status === 1) {
        const iccidValues = result.AdminSims.map((sim) => sim.iccid);
        setIccids(iccidValues);
      } else {
      }
    } catch (error) {
    }
  };

  const handleCheckBox1 = () => {
    setCheckBox1((prevCheckBox) => !prevCheckBox);

    if (!checkBox1) {
      setShippingAddress1(addressLine1);
      setShippingAddress2(addressLine2);
      setShippingAddress3(addressLine3);
      setShippingCountry(selectedCountry);
      setShippingState(currentState);
      setShippingCity(city);
      setShippingPostalCode(postalCode);
    } else {
      setShippingAddress1("");
      setShippingAddress2("");
      setShippingAddress3("");
      setShippingCountry("");
      setShippingState("");
      setShippingCity("");
      setShippingPostalCode("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFinishClick = () => {
    navigate("/tenants/Tenants");
  };

  const handlePrev = () => {
    setActiveStep(0);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepperZero = () => {
    if (emailFlag === false) {
      toast("Please fill a proper bussiness Email ID", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (isPasswordValid === false) {
      toast("Password must be between 6 and 18 characters in length.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (isClientValid === false) {
      toast("Please enter client limit in digit(10 to 999)", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (fNameFlag === false) {
      toast("Please enter first name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (lNameFlag === false) {
      toast("Please enter last name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !password ||
      !tenantlimit
    ) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStepperOne = () => {
    if (checkBox1 === true) {
      if (
        !addressLine1 ||
        !addressLine2 ||
        !selectedCountry ||
        !city ||
        !currentState ||
        !postalCode
      ) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (cityFlag === false) {
        toast("Please enter city", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (stateFlag === false) {
        toast("Please enter state", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (postalFlag === false) {
        toast("Please enter postal code", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        DomainUUID: DomainUUID,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phone,
        Password: password,
        EmailId: email,
        MaxTenantLimit: tenantlimit,
        ActiveSims: "23",
        SuspendedSims: "3",
        MTDUsage: "",
        PrepaidBalance: "",
        OutstandingBalace: "",
        PastDueBalance: "",
        Country: selectedCountry,
        City: city,
        State: currentState,
        AddressLine1: addressLine1,
        AddressLine2: addressLine2,
        AddressLine3: addressLine3,
        PostalCode: postalCode,
        ShippingAddress1: addressLine1,
        ShippingAddress2: addressLine2,
        ShippingAddress3: addressLine3,
        ShippingCountry: selectedCountry,
        ShippingCity: city,
        ShippingState: currentState,
        ShippingPostalCode: postalCode,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/create_tenant`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status == 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else if (result.Status == 1) {
            setTenantUI_(result.TenantData.TenantUUID);

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        })
        .catch(error);
    } else if (checkBox1 == false) {
      if (
        !addressLine1 ||
        !addressLine2 ||
        // !addressLine3 ||
        !selectedCountry ||
        !city ||
        !currentState ||
        !postalCode ||
        !shippingAddress1 ||
        !shippingAddress2 ||
        // !shippingAddress3 ||
        !shippingCountry ||
        !shippingCity ||
        !shippingState ||
        !shippingPostalCode
      ) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (shippingCityFlag === false || shippingStateFlag === false) {
        toast("Please enter only alphabetic characters", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (shippingPostalFlag === false) {
        toast("Please enter only number", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        DomainUUID: DomainUUID,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phone,
        Password: password,
        EmailId: email,
        MaxTenantLimit: tenantlimit,
        TotalSims: totalSims,
        ActiveSims: "23",
        SuspendedSims: "3",
        MTDUsage: "",
        PrepaidBalance: "",
        OutstandingBalace: "",
        PastDueBalance: "",
        Country: selectedCountry,
        City: city,
        State: currentState,
        AddressLine1: addressLine1,
        AddressLine2: addressLine2,
        AddressLine3: addressLine3,
        PostalCode: postalCode,
        ShippingAddress1: shippingAddress1,
        ShippingAddress2: shippingAddress2,
        ShippingAddress3: shippingAddress3,
        ShippingCountry: shippingCountry,
        ShippingCity: shippingCity,
        ShippingState: shippingState,
        ShippingPostalCode: shippingPostalCode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/create_tenant`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status == 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          } else if (result.Status == 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setTenantUI_(result.TenantData.TenantUUID);
          }
        })
        .catch(error);
    }
  };

  const handleNextt = () => {
    if (activeStep === 0) {
      handleStepperZero();
    } else if (activeStep === 1) {
      handleStepperOne();
    }
  };

  const AssignInventoryButton = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
      TenantUUID: tenantUI_,
      name: inventoryName,
      // "totalSim": totalSims,
      distributorId: 70083,
      providerId: 89457,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_inventory`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else if (result.Status == 1) {
          setInventoryId(result.InventoryData.id);
          handleBillingGroup(result.InventoryData.id); //api to create group
        }
      })
      .catch(error);
  };

  const handleBillingGroup = (Inventoryid) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      TenantUUID: tenantUI_,
      name: billingGroup,
      inventoryId: Inventoryid,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/parent_group`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result.Status === 1) {
          handleButtonClick(
            result.GroupData.parentGroupId,
            result.GroupData.inventoryId
          );
          // logIccidsInRange(result.GroupDetail.id, Inventoryid); //api to assign sim
        }
      })
      .catch(error);
  };

  const handleButtonClick = (groupId, inventoryid) => {
    const commaSeparatedIds = selectedICCIDs.join(",");
    const commaSeparatedIdsss = selectedIccids.join(",");

    const mergedCommaSeparatedIds = commaSeparatedIds.concat(
      ",",
      commaSeparatedIdsss
    );

    var formattedMergedIds = "";

    if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
      formattedMergedIds = `${mergedCommaSeparatedIds}`;
    }

    if (commaSeparatedIds == "") {
      formattedMergedIds = `${commaSeparatedIdsss}`;

    }
    if (commaSeparatedIdsss == "") {
      formattedMergedIds = `${commaSeparatedIds}`;
    }


    const idsArray = formattedMergedIds.split(",");

    // Map each ID without double quotes
    const formattedIdsArray = idsArray.map((id) => id.trim());


    // Join the array back into a string
    const resultOfSIMs = JSON.stringify(formattedIdsArray);




    if (startBillDate) {
      var startbilldate = new Date(startBillDate);
      var formattedStartBillDate = startbilldate
        .toISOString()
        .replace("Z", "+00:00");
    } else {
      formattedStartBillDate = "";
    }

    if (endBillDate) {
      var endbilldate = new Date(endBillDate);
      var formattedEndBillDate = endbilldate
        .toISOString()
        .replace("Z", "+00:00");
    } else {
      formattedEndBillDate = "";
    }

    const currentDate = new Date();
    const epochTimeMilliseconds = currentDate.getTime() - 10000;
    const dateEarliestActivation = epochTimeMilliseconds + 15000;
    const LatestAvailable = dateEarliestActivation + 1000000000;

    const BasePrice = parseFloat(basePrice);
    const epochMilliseconds = new Date(packgaeexpireDate).getTime();
    if (Package === "CAPPED") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      } else {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      }
    }

    if (Package === "AUTORENEW") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      } else {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: "",
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: "",
        };
      }
    }

    if (Package === "POSTPAID") {
      if (checkBoxTrial) {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: true,
          TrialPeriod: trialPeriod,
          basePrice: BasePrice,
          ratePrice: ratePrice,
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: SelectedThrottleSpeed,
        };
      } else {
        var newObject = {
          TenantUUID: tenantUI_,
          iccidRange: formattedIdsArray,
          inventoryId: inventoryid,
          parentGroupId: groupId,
          billcycle: 14,
          billStartDate: formattedStartBillDate,
          billDate: formattedEndBillDate,
          trial: false,
          TrialPeriod: "",
          basePrice: BasePrice,
          ratePrice: ratePrice,
          type: Package,
          PackageUUID: selectedPackage,
          supportedCountries: selectedCountries,
          timeAllowanceInSeconds: 3660,
          activationTimeAllowanceInSeconds: 3700,
          dateEarliestAvailable: dateEarliestActivation, //2
          dateEarliestActivation: epochTimeMilliseconds, //1
          dateLatestAvailable: epochMilliseconds, //3  2033
          ThrottleSpeed: SelectedThrottleSpeed,
        };
      }
    }

    setObjectsArray((prevArray) => [...prevArray, newObject]);

    setPackage("");
    setSelectedPackage([]);
    setEndBillDate("");
    setStartBillDate("");
    setBasePrice("");
    setSelectedCountries([]);
    setTrialPeriod("");
    setCheckBoxTrial(false);
    setSelectedICCIDs([]);
    setSelectedIccids([]);
    setSelectedThrottleSpeed("");
    setRatePrice("");
    setPacakgeExpireDate("");
  };

  const AssignInventory = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(objectsArray);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };


    fetch(`${REACT_APP_BASE_URL}/api/v1/assign_sim_to_tenant`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        } else if (result.Status == 1) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .catch((error) => {
      });
  };

  // For Validations

  const handleFirstNameChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is blank
    if (inputValue.trim() === "") {
      setFirstName("");
      setError("Please enter first name");
      setFNameFlag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");

      setFirstName(alphabeticValue);
      setError("");
      setFNameFlag(true);
    }
  };

  const handleLastNameChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is blank
    if (inputValue.trim() === "") {
      setLastName("");
      setLNameError("Please enter last name");
      setLNameFlag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z.\s]/g, "");

      setLastName(alphabeticValue);
      setLNameError("");
      setLNameFlag(true);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!email) {
      setEmailError("Please enter your bussiness email id");
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
    } else {
      const [, domain] = email.split("@").map((part) => part.toLowerCase());

      // Check if the email domain is allowed
      const allowedDomains = ["startelelogic.com"]; // Add your company domain(s) here

      // Disallow Gmail, Yahoo, and Hotmail
      const disallowedDomains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "rediffmail.com",
      ];

      if (disallowedDomains.includes(domain)) {
        // allowedDomains.includes(domain) && !
        setEmailError("Only company email addresses are allowed");
        setEmailFlag(false);
      } else {
        setEmailError("");
        setEmailFlag(true);
      }
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const handleChange = (e) => {
    const inputValues = e.target.value;

    // Check if the password has reached the maximum length
    if (inputValues.length <= maxPasswordLength) {
      setPassword(inputValues);
    }
    setIsPasswordValid(
      password.length >= 6 && password.length <= maxPasswordLength
    );
  };

  const handleClientChange = (e) => {
    const clinetValues = e.target.value;

    // Check if the password has reached the maximum length
    if (
      /^[0-9\s]*$/.test(clinetValues) &&
      clinetValues.length <= maxClientLenght
    ) {
      setTenantLimit(clinetValues);
    }
    setIsClientValid(
      tenantlimit.length >= 1 && tenantlimit.length <= maxClientLenght
    );
  };

  const handleStateChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is empty
    if (inputValue.trim() === "") {
      setCurrentState("");
      setStateError("Please enter  state");
      setStateFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(inputValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setCurrentState(inputValue);
      setStateError(""); // Clear the error when the input is valid
      setStateFlag(true);
    } else {
    }
  };

  const handleCityChange = (e) => {
    const cityValue = e.target.value;

    if (cityValue.trim() === "") {
      setCity("");
      setCityError("Please enter  city");
      setCityFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(cityValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setCity(cityValue);
      setCityError(""); // Clear the error when the input is valid
      setCityFlag(true);
    } else {
    }
  };

  const handlePostalChange = (e) => {
    const postalValue = e.target.value;

    if (postalValue.trim() === "") {
      setPostalCode("");
      setPostalError("Please enter  postal code");
      setPostalFlag(false);
    } else if (/^[0-9\s]+$/.test(postalValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setPostalCode(postalValue);
      setPostalError(""); // Clear the error when the input is valid
      setPostalFlag(true);
    } else {
    }
  };

  const ShippingState = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only characters using a regular expression
    if (inputValue.trim() === "") {
      setShippingState("");
      setShippingStateError("Please enter state");
      setShippingStateFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(inputValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingState(inputValue);
      setShippingStateError(""); // Clear the error when the input is valid
      setShippingStateFlag(true);
    } else {
    }
  };

  const ShippingCity = (e) => {
    const cityValue = e.target.value;

    if (cityValue.trim() === "") {
      setShippingCity("");
      setShippingCityError("Please enter  city");
      setShippingCityFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(cityValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingCity(cityValue);
      setShippingCityError(""); // Clear the error when the input is valid
      setShippingCityFlag(true);
    } else {
    }
  };

  const ShippingPostalCode = (e) => {
    const postalValue = e.target.value;

    if (postalValue.trim() === "") {
      setShippingPostalCode("");
      setShippingPostalError("Please enter  postal code");
      setShippingPostalFlag(false);
    } else if (/^[0-9\s]+$/.test(postalValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingPostalCode(postalValue);
      setShippingPostalError(""); // Clear the error when the input is valid
      setShippingPostalFlag(true);
    } else {
    }
  };

  const handlePackageType = (e) => {
    setPackage(e.target.value);
  };

  const handleChangePackage = (event) => {
    setSelectedPackage(event.target.value);
  };

  const getPackageNameById = (PackageUUID) => {
    const selectedPackage = packages.find(
      (pkg) => pkg.PackageUUID === PackageUUID
    );
    return selectedPackage ? selectedPackage.name : "";
  };

  const convertUtcToLocal = (utcTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      hour12: false,
      minute: "numeric",
      // second: 'numeric',
    };

    const localTime = new Date(utcTimeString).toLocaleString("en-US", options);

    const timeWithoutAt = localTime.replace(" at", "");

    return timeWithoutAt;
  };

  const handleRemoveICCID = (iccid) => {

    // Update selectedICCIDs
    const updatedSelectedICCIDs = selectedICCIDs.filter((id) => id !== iccid);
    setSelectedICCIDs(updatedSelectedICCIDs);

    // Update selectedIccids
    const updatedSelectedIccids = selectedIccids.filter((id) => id !== iccid);
    setSelectedIccids(updatedSelectedIccids);
  };

  const handleStartIccidChange = (newValue) => {
    setSelectedIStartccid(newValue);
  };

  const handleEndIccidChange = (newValue) => {
    setSelectedEndIccid(newValue);

    setSelectedICCIDs([]);

    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === newValue);

    if (startIndex !== -1 && endIndex !== -1) {
      const idsInRange = iccids.slice(startIndex, endIndex + 1);
      setSelectedICCIDs(idsInRange);
    }
  };

  const handleToggleIccids = (iccid) => {
    const currentIndex = selectedIccids.indexOf(iccid);
    const newSelectedIccids = [...selectedIccids];

    if (currentIndex === -1) {
      newSelectedIccids.push(iccid);
    } else {
      newSelectedIccids.splice(currentIndex, 1);
    }

    setSelectedIccids(newSelectedIccids);
  };

  const handleCheckBoxTrial = () => {
    setCheckBoxTrial((prevCheckBox) => !prevCheckBox);
    setTrial1(!trial1);
  };

  const handleCurrentCountries = (event) => {
    setSelectedCountries(event.target.value);
  };

  const checkDate = (endDate) => {
    if (startBillDate && endDate) {
      const startTimestamp = new Date(startBillDate).getTime();
      const endTimestamp = new Date(endDate).getTime();

      if (endTimestamp <= startTimestamp) {
        setErrordate(true);
        return;
      } else {
        setErrordate(false);
      }
    }
  };

  const handleDelete = (index) => {
    const newArray = [...objectsArray];
    newArray.splice(index, 1);
    setObjectsArray(newArray);
  };

  const showThrottleSpeedList = () => {
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/throttle_list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setThrottleSpeedList(result.throttleList);
      })
      .catch(error);
  };

  const handleThrottleSpeed = (event) => {
    setSelectedThrottleSpeed(event.target.value);
  };

  const fetchCountry = (supportedCountries) => {
    const countryCodes = supportedCountries.split(", ");
    const fullCountryNames = countryCodes.map((code) => {
      const countryInfo = iso3166.whereAlpha3(code);
      return !countryInfo
        ? "No Code"
        : supportedCountries == "USA"
          ? "United States"
          : countryInfo.country;
    });
    return fullCountryNames.join(", ");
  };

  useEffect(() => {
    showThrottleSpeedList();
    fetchpackages();
    fetchIccids();
  }, []);

  return (
    <AppButtonRoot>
      <Box className="breadcrumb">
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center" }}
        >
          <H3>Add New Tenant | </H3>

          <a
            href="/tenants/Tenants"
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              textDecoration: "none", // Remove underline
            }}
          >
            Tenants &gt;
          </a>
          <p
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              margin: "0", // Remove default margin
            }}
          >
            Add New Tenants
          </p>
        </Box>
      </Box>

      <div>
        <SimpleCard title="Add New Tenant">
          <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box mt={4}>
              {activeStep === steps.length ? (
                <Box>
                  <Typography>All steps completed</Typography>

                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography>
                    {stepIndex === 0 && (
                      <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={() => null}
                      >
                        <Grid container>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={2}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">First Name</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter your first name"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  inputProps={{ tabIndex: "1" }}
                                  onChange={handleFirstNameChange}
                                  value={firstName || ""}
                                  style={{ width: "100%" }}
                                  placeholder="Enter First Name"
                                />
                                {error && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {error}
                                  </p>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Email ID</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter your bussiness Email ID"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="email"
                                  inputProps={{ tabIndex: "3" }}
                                  value={email}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Your Email Id"
                                  onChange={handleEmailChange}
                                />
                                {emailError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {emailError}
                                  </div>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Client Limit</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the maximum number of clients a tenant can create"
                                    placement="right"
                                    whiteSpace="nowrap"
                                  // style={{whiteSpace: 'nowrap'}}
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  inputProps={{ tabIndex: "5" }}
                                  // onChange={(e) =>
                                  //   setTenantlimit(e.target.value)
                                  // }
                                  onChange={handleClientChange}
                                  value={tenantlimit}
                                  style={{ width: "100%" }}
                                  placeholder="Client Limit"
                                />
                              </div>
                              {!isClientValid && (
                                <p
                                  style={{
                                    color: "red",
                                    margin: "-8px 0px 0px 10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  Please enter client limit in digit(10 to 999)
                                </p>
                              )}
                            </div>

                            {/* <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Rate Price</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Per MB Cost Price"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                inputProps={{ tabIndex: "7" }}
                                value={ratePrice}
                                style={{ width: "100%" }}
                                placeholder="Enter Your Rate Price"
                                onChange={handleRatePrice}
                              />
                              {ratePriceError && (
                                <p
                                  // variant="caption"
                                  style={{
                                    color: "red",
                                    margin: "0px 0px 0px 10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {ratePriceError}
                                </p>
                              )}
                            </div> */}
                          </Grid>

                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={2}
                          >
                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Last Name</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter your last name"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                inputProps={{ tabIndex: "2" }}
                                onChange={handleLastNameChange}
                                autoComplete="new-value"
                                value={lastName || ""}
                                style={{ width: "100%" }}
                                placeholder="Enter Last Name"
                              />
                              {lNameEror && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {lNameEror}
                                </p>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Password</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter your password here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                name="password"
                                type={showPassword ? "text" : "password"}
                                inputProps={{ tabIndex: "4" }}
                                placeholder="Enter Password"
                                autoComplete="new-text"
                                style={{ width: "100%", height: "42px" }}
                                value={password}
                                onChange={handleChange}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={togglePasswordVisibility}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {!isPasswordValid && (
                                <p
                                  style={{
                                    color: "red",
                                    margin: "2px 0px 0px 10px",
                                    fontSize: "11px",
                                  }}
                                >
                                  Password must be between 6 and 18 characters
                                  in length.
                                </p>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Phone Number</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter your contact number here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                value={phone}
                                inputProps={{ tabIndex: "6" }}
                                onChange={(phone) => setPhone(phone)}
                                style={{
                                  width: "100%",
                                }}
                                inputStyle={{
                                  background: "transparent",
                                  width: "100%",
                                  height: "42px",
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    )}

                    {/* {/ <------------------For Address----------------------> /} */}
                    {stepIndex === 1 && (
                      <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={() => null}
                      >
                        <Grid container>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={3}
                          >
                            <p
                              style={{
                                fontSize: "17px",
                              }}
                            >
                              Billing Address
                              <hr />
                            </p>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 1</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter first address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine1"
                                inputProps={{ tabIndex: "1" }}
                                onChange={(e) =>
                                  setAddressLine1(e.target.value)
                                }
                                value={addressLine1}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 1"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 2</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter second address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine2"
                                inputProps={{ tabIndex: "2" }}
                                onChange={(e) =>
                                  setAddressLine2(e.target.value)
                                }
                                value={addressLine2}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 2"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 3</p>
                                <Tooltip
                                  title="Enter third address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine3"
                                inputProps={{ tabIndex: "3" }}
                                onChange={(e) =>
                                  setAddressLine3(e.target.value)
                                }
                                value={addressLine3}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 3"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Country</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Select your country from the list"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  labelId="country-label"
                                  inputProps={{ tabIndex: "4" }}
                                  value={selectedCountry}
                                  onChange={handleCurrentCountry}
                                  style={{ width: "100%", height: "42px" }}
                                >
                                  <MenuItem disabled>
                                    --Select Country{" "}
                                  </MenuItem>
                                  {countries.map((option) => (
                                    <MenuItem
                                      key={option.code}
                                      value={option.name}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">State</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the name of your state"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="state"
                                inputProps={{ tabIndex: "5" }}
                                onChange={handleStateChange}
                                value={currentState}
                                style={{ width: "100%" }}
                                placeholder="Enter State "
                              />
                              {stateError && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {stateError}
                                </div>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">City</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the name of your city"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="city"
                                inputProps={{ tabIndex: "6" }}
                                onChange={handleCityChange}
                                value={city}
                                style={{ width: "100%" }}
                                placeholder="Enter City "
                              />
                              {cityError && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {cityError}
                                </div>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Postal Code</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the postal code of your city"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="postalcode"
                                inputProps={{ tabIndex: "7" }}
                                onChange={handlePostalChange}
                                value={postalCode}
                                style={{ width: "100%" }}
                                placeholder="Enter Postal Code"
                              />
                              {postalError && (
                                <div
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {postalError}
                                </div>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkBox1}
                              onChange={handleCheckBox1}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Same as Billing Address"
                          sx={{ marginLeft: "30px" }}
                        />
                        {!checkBox1 && (
                          <Grid container>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{ mt: 2 }}
                              px={3}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                }}
                              >
                                Shipping Address
                                <hr />
                              </p>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 1
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter first line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine1"
                                  inputProps={{ tabIndex: "7" }}
                                  onChange={(e) =>
                                    setShippingAddress1(e.target.value)
                                  }
                                  value={shippingAddress1}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping Address Line 1"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 2
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter second line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine2"
                                  inputProps={{ tabIndex: "8" }}
                                  onChange={(e) =>
                                    setShippingAddress2(e.target.value)
                                  }
                                  value={shippingAddress2}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Address Line 2"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 3
                                  </p>
                                  <Tooltip
                                    title="Enter third line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine3"
                                  inputProps={{ tabIndex: "9" }}
                                  onChange={(e) =>
                                    setShippingAddress3(e.target.value)
                                  }
                                  value={shippingAddress3}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Address Line 3"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping Country</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Select your desired shipping country from the list"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <FormControl style={{ width: "100%" }}>
                                  {/* <InputLabel id="country-label">Select the Country</InputLabel> */}
                                  <Select
                                    labelId="country-label"
                                    inputProps={{ tabIndex: "10" }}
                                    value={shippingCountry}
                                    onChange={handleShippingCountry}
                                    style={{ width: "100%", height: "42px" }}
                                  >
                                    {countryOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.label}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping State</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the name of your desired shipping state"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingState"
                                  inputProps={{ tabIndex: "11" }}
                                  onChange={ShippingState}
                                  value={shippingState}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping State "
                                />
                                {shippingStateError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {shippingStateError}
                                  </div>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping City</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the name of your desired shipping city"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingCity"
                                  inputProps={{ tabIndex: "12" }}
                                  onChange={ShippingCity}
                                  value={shippingCity}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping City "
                                />
                                {shippingCityError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {shippingCityError}
                                  </div>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Postal Code
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the postal code of the desired  shipping city"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingPostalcode"
                                  inputProps={{ tabIndex: "13" }}
                                  onChange={ShippingPostalCode}
                                  value={shippingPostalCode}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping Postal Code"
                                />
                                {shippingPostalError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {shippingPostalError}
                                  </div>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </ValidatorForm>
                    )}

                    {stepIndex === 2 && (
                      <Container style={{ padding: "10px" }}>
                        <>
                          <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                            <Box>
                              <div
                                className="box1"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  padding: "15px 10px",
                                  backgroundColor: " rgb(233 226 226 / 35%)",
                                  borderRadius: "25px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "0px",
                                    marginLeft: "10px",
                                    marginTop: "0px",
                                    width: "100%",
                                  }}
                                >
                                  <H3
                                    style={{
                                      margin: "10px",
                                      padding: "0px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Assign Inventory to Tenant
                                  </H3>
                                  <hr />
                                </div>
                                <br />

                                <div className="createTenantAssignInput">
                                  {/* <H3
                                    style={{
                                      fontSize: '16px',
                                      width: '50%'
                                    }}
                                  >
                                    Enter Name of Inventory :
                                  </H3> */}
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">
                                        Inventory Name
                                      </p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Enter name of the inventory"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      type="text"
                                      name="shippingState"
                                      inputProps={{ tabIndex: "10" }}
                                      onChange={(e) =>
                                        setInventoryName(e.target.value)
                                      }
                                      value={inventoryName}
                                      style={{ width: "100%" }}
                                      placeholder="Inventory Name "
                                    />
                                  </div>
                                </div>

                                {/* <div className='createTenantAssignInput'>
                                  <H3
                                    style={{
                                      fontSize: '16px',
                                      width: '50%'
                                    }}
                                  >
                                    Enter Total Sims for Inventory :
                                  </H3>
                                  <div className='inputInfoBox ' style={{ width: '50%', margin: '0px 0px 12px 0px' }} >
                                    <div style={{ display: 'flex' }} >
                                      <p id="inputInfoTopText">Number of Sims</p>
                                      <Tooltip title="Enter the numbers of SIMS you wish to Assign" placement='right'>
                                        <Icon style={{ transform: 'scale(.7)' }} >info_outline</Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      type="text"
                                      name="shippingState"
                                      inputProps={{ tabIndex: "10" }}
                                      value={totalSims}
                                      onChange={(e) => setTotalSims(e.target.value)}
                                      style={{ width: '100%' }}
                                      placeholder="Total SIMS "
                                    />
                                  </div>
                                </div> */}

                                <div className="createTenantAssignInput">
                                  {/* <H3
                                    style={{  
                                      fontSize: '16px',
                                      width: '50%'
                                    }}
                                  >
                                    Enter name of the Billing Group :
                                  </H3> */}
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">
                                        Billing Group Name
                                      </p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Enter name of the billing group"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      type="text"
                                      name="shippingState"
                                      inputProps={{ tabIndex: "10" }}
                                      value={billingGroup}
                                      onChange={(e) =>
                                        setBillingGroup(e.target.value)
                                      }
                                      style={{ width: "100%" }}
                                      placeholder="Billing Group Name "
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    width: "100%",
                                    paddingRight: "20px",
                                    borderRadius: "6px",
                                    // backgroundColor: " rgb(233 226 226 / 35%)",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {/* ------------------Trial Period ---------------------*/}
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "-10px 10px 4px 0px",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checkBoxTrial}
                                            onChange={handleCheckBoxTrial}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label="Keep this as Trial Package"
                                        sx={{}}
                                      />
                                    </div>
                                  </div>
                                  {checkBoxTrial && (
                                    <div className="createTenantAssignSelect">
                                      <div
                                        className="inputInfoBox "
                                        style={{
                                          width: "50%",
                                          margin: "0px 0px 0px 0px",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <p id="inputInfoTopText">
                                            Trial Days
                                          </p>
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                          <Tooltip
                                            title="Select the Days for Trial Period"
                                            placement="right"
                                          >
                                            <Icon
                                              style={{ transform: "scale(.7)" }}
                                            >
                                              info_outline
                                            </Icon>
                                          </Tooltip>
                                        </div>
                                        <Select
                                          value={trialPeriod}
                                          onChange={(e) =>
                                            setTrialPeriod(e.target.value)
                                          }
                                          displayEmpty
                                          style={{
                                            height: "35px",
                                            width: "100%",
                                          }}
                                          MenuProps={{
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                              style: {
                                                maxHeight: 200,
                                              },
                                            },
                                          }}
                                        >
                                          <MenuItem value="" disabled>
                                            -- Select the Days of Trial Period
                                            --
                                          </MenuItem>
                                          <MenuItem value="7">
                                            {" "}
                                            7 Days{" "}
                                          </MenuItem>
                                          <MenuItem value="14">
                                            {" "}
                                            14 Days{" "}
                                          </MenuItem>
                                          <MenuItem value="21">
                                            {" "}
                                            21 Days{" "}
                                          </MenuItem>
                                          <MenuItem value="28">
                                            {" "}
                                            28 Days{" "}
                                          </MenuItem>
                                        </Select>
                                      </div>
                                    </div>
                                  )}{" "}
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Package Type
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Package Type"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <Select
                                        inputProps={{ tabIndex: "1" }}
                                        value={Package}
                                        onChange={(e) => handlePackageType(e)}
                                        input={<OutlinedInput labelWidth={0} />}
                                        style={{
                                          height: "35px",
                                          width: "101%",
                                        }}
                                        displayEmpty
                                        MenuProps={{
                                          getContentAnchorEl: null,
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem value="">
                                          -- Select --
                                        </MenuItem>
                                        <MenuItem value="CAPPED">
                                          Capped
                                        </MenuItem>
                                        <MenuItem value="AUTORENEW">
                                          Autorenew
                                        </MenuItem>
                                        <MenuItem value="POSTPAID">
                                          Postpaid
                                        </MenuItem>
                                      </Select>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "48%",
                                        margin: "0px 0px",
                                        padding: "0px 0px 0px 45px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Package Name
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Package"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <Select
                                        value={selectedPackage}
                                        onChange={handleChangePackage}
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                        }}
                                        MenuProps={{
                                          getContentAnchorEl: null,
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          -- Select the Package --
                                        </MenuItem>
                                        {packages.map((pkg) => (
                                          <MenuItem
                                            key={pkg.PackageUUID}
                                            value={pkg.PackageUUID}
                                          >
                                            {pkg.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </div>
                                  </div>
                                  {/* Here this is for the slection -------------------------------------------------------- */}
                                  <div className="SelectionBox">
                                    <div className="Selection ">
                                      <div className="range">
                                        <H3
                                          style={{
                                            fontSize: "16px",
                                            width: "100%",
                                          }}
                                        >
                                          Range Selection of SIM Cards{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </H3>

                                        <Autocomplete
                                          value={selectedStartIccid}
                                          onChange={(event, newValue) =>
                                            handleStartIccidChange(newValue)
                                          }
                                          options={iccids.filter(
                                            (option) =>
                                              !selectedIccids.includes(
                                                option
                                              ) && option !== selectedEndIccid
                                          )}
                                          getOptionLabel={(option) => option}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="-- Starting ICCID --"
                                              variant="outlined"
                                              style={{
                                                height: "47px",
                                                width: "100%",
                                                marginTop: "15px",
                                              }}
                                            />
                                          )}
                                        />

                                        <Autocomplete
                                          value={selectedEndIccid}
                                          onChange={(event, newValue) =>
                                            handleEndIccidChange(newValue)
                                          }
                                          options={iccids.filter(
                                            (option) =>
                                              !selectedIccids.includes(
                                                option
                                              ) && option !== selectedStartIccid
                                          )}
                                          getOptionLabel={(option) => option}
                                          style={{
                                            width: "100%",
                                            marginTop: "10px",
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label="-- Ending ICCID --"
                                              variant="outlined"
                                              style={{
                                                height: "47px",
                                                width: "100%",
                                              }}
                                            />
                                          )}
                                        />
                                      </div>

                                      <H2
                                        style={{
                                          margin: "10px 46% 12px 0px",
                                        }}
                                      >
                                        OR
                                      </H2>

                                      <div className="individual">
                                        <H3
                                          style={{
                                            fontSize: "16px",
                                            width: "100%",
                                          }}
                                        >
                                          Individual Selection of SIM Cards{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </H3>
                                        <div
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          <FormControl
                                            style={{ width: "100%" }}
                                          >
                                            <Autocomplete
                                              multiple
                                              // single
                                              id="demo-single-checkbox"
                                              options={iccids.filter(
                                                (option) =>
                                                  option !==
                                                  selectedStartIccid &&
                                                  option !== selectedEndIccid &&
                                                  option !== selectedICCIDs &&
                                                  !selectedICCIDs.includes(
                                                    option
                                                  )
                                              )}
                                              value={selectedIccids}
                                              renderTags={() => null}
                                              onChange={(event, newValue) =>
                                                setSelectedIccids(newValue)
                                              }
                                              disableCloseOnSelect
                                              getOptionLabel={(option) =>
                                                option
                                              }
                                              isOptionEqualToValue={(
                                                option,
                                                value
                                              ) => option === value}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="-- Select ICCID --"
                                                  variant="outlined"
                                                  onChange={(e) =>
                                                    setSearchValue(
                                                      e.target.value
                                                    )
                                                  }
                                                  style={{
                                                    height: "47px",
                                                    width: "100%",
                                                    marginTop: "10px",
                                                  }}
                                                />
                                              )}
                                              renderOption={(
                                                props,
                                                option,
                                                { selected }
                                              ) => (
                                                <li {...props}>
                                                  <Checkbox
                                                    checked={selected}
                                                    onClick={() =>
                                                      handleToggleIccids(option)
                                                    }
                                                  />
                                                  {option}
                                                </li>
                                              )}
                                              isOptionEqualToValues={(
                                                option,
                                                value
                                              ) => option === value}
                                              filterOptions={(
                                                options,
                                                { inputValue }
                                              ) => {
                                                if (!inputValue) {
                                                  return options;
                                                }

                                                const regex = new RegExp(
                                                  inputValue,
                                                  "i"
                                                );
                                                return options.filter(
                                                  (option) => regex.test(option)
                                                );
                                              }}
                                            />
                                          </FormControl>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="SelectionIccid"
                                      style={{
                                        width: "50%",
                                        height: "200px",
                                        padding: "10px",
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                        border: "1px #2d3436 solid",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.1) - 4px 9px 25px - 6px",
                                        marginLeft: "20px",
                                        // overflow: 'scroll'
                                      }}
                                    >
                                      <H4
                                        style={{
                                          padding: "0px",
                                          color: "#636e72",
                                        }}
                                      >
                                        <i>These are the Selected SIM Card :</i>
                                      </H4>
                                      {(selectedEndIccid ||
                                        selectedIccids.length > 0) && (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              margin: "10px 0px 0px 5px",
                                              overflow: "auto",
                                              height: "150px",
                                            }}
                                          >
                                            {[
                                              ...selectedICCIDs,
                                              ...selectedIccids,
                                            ].map((iccid, index) => (
                                              <Chip
                                                key={index}
                                                label={iccid}
                                                onDelete={() =>
                                                  handleRemoveICCID(iccid)
                                                }
                                                color="primary"
                                                variant="outlined"
                                                style={{
                                                  marginRight: "8px",
                                                  marginBottom: "8px",
                                                }}
                                              />
                                            ))}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Starting Billing Date
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Billing Date for the selected Package"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <OutlinedInput
                                        id="startbillDate"
                                        type="datetime-local"
                                        inputProps={{ tabIndex: "" }}
                                        value={startBillDate}
                                        onChange={(e) =>
                                          setStartBillDate(e.target.value)
                                        }
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Ending Billing Date
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Billing Date for the selected Package"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <OutlinedInput
                                        id="billDate"
                                        type="datetime-local"
                                        inputProps={{ tabIndex: "" }}
                                        value={endBillDate}
                                        onChange={(e) => {
                                          setEndBillDate(e.target.value);
                                          checkDate(e.target.value);
                                        }}
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                        }}
                                      />
                                      {errordate && (
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "11px",
                                            margin: "0px 0 0 10px",
                                          }}
                                        >
                                          Ending billing date must be greater
                                          than the starting billing date
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Package Expiration Date
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Package Expiraton  Date"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <OutlinedInput
                                        id="billDate"
                                        type="datetime-local"
                                        inputProps={{ tabIndex: "" }}
                                        value={packgaeexpireDate}
                                        onChange={(e) => {
                                          setPacakgeExpireDate(e.target.value);
                                        }}
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">Base Price</p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Enter the Base Price of the Package"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <OutlinedInput
                                        type="number"
                                        inputProps={{ tabIndex: "2" }}
                                        onChange={(e) =>
                                          setBasePrice(e.target.value)
                                        }
                                        value={basePrice}
                                        style={{
                                          width: "100%",
                                          height: "35px",
                                        }}
                                        placeholder="Base Price"
                                      />
                                    </div>
                                  </div>
                                  {Package == "POSTPAID" && (
                                    <div className="createTenantAssignInput">
                                      <div
                                        className="inputInfoBox "
                                        style={{
                                          width: "50%",
                                          margin: "0px 0px 12px 0px",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <p id="inputInfoTopText">
                                            Rate Price
                                          </p>
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                          <Tooltip
                                            title="Enter the Rate Price of the Package(per MB)"
                                            placement="right"
                                          >
                                            <Icon
                                              style={{ transform: "scale(.7)" }}
                                            >
                                              info_outline
                                            </Icon>
                                          </Tooltip>
                                        </div>
                                        <OutlinedInput
                                          type="text"
                                          inputProps={{ tabIndex: "2" }}
                                          onChange={(e) =>
                                            setRatePrice(e.target.value)
                                          }
                                          value={ratePrice}
                                          style={{
                                            width: "100%",
                                            height: "35px",
                                          }}
                                          placeholder="Rate Price"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {Package == "POSTPAID" && (
                                    <div className="createTenantAssignSelect">
                                      <div
                                        className="inputInfoBox "
                                        style={{
                                          width: "50%",
                                          margin: "0px 0px 0px 0px",
                                        }}
                                      >
                                        <div style={{ display: "flex" }}>
                                          <p id="inputInfoTopText">
                                            Throttle Speed
                                          </p>
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                          <Tooltip
                                            title="Enter the Throttle Speed of the Package"
                                            placement="right"
                                          >
                                            <Icon
                                              style={{ transform: "scale(.7)" }}
                                            >
                                              info_outline
                                            </Icon>
                                          </Tooltip>
                                        </div>
                                        <Select
                                          inputProps={{ tabIndex: "1" }}
                                          value={SelectedThrottleSpeed}
                                          onChange={(e) =>
                                            handleThrottleSpeed(e)
                                          }
                                          input={
                                            <OutlinedInput labelWidth={0} />
                                          }
                                          style={{
                                            height: "42px",
                                            width: "100%",
                                          }}
                                          displayEmpty
                                          MenuProps={{
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                              style: {
                                                maxHeight: 300,
                                              },
                                            },
                                          }}
                                        >
                                          <MenuItem value="" disabled>
                                            -- Select --
                                          </MenuItem>
                                          {ThrottleSpeedList.map(
                                            (speed, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={speed}
                                                >
                                                  {speed}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </div>
                                    </div>
                                  )}
                                  {/* ------------------supported Country 1---------------------*/}
                                  <div className="createTenantAssignInput">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 12px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">
                                          Supported Country
                                        </p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the supported countries"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <Select
                                        multiple
                                        labelId="country-label"
                                        inputProps={{ tabIndex: "4" }}
                                        value={selectedCountries}
                                        onChange={handleCurrentCountries}
                                        style={{
                                          width: "100%",
                                          height: "35px",
                                        }}
                                      >
                                        <MenuItem disabled>
                                          --Select Country{" "}
                                        </MenuItem>
                                        {countries.map((option) => (
                                          <MenuItem
                                            key={option.code}
                                            value={option.code}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Box>
                          </ValidatorForm>
                        </>

                        <div
                          // className="createTenantAssignSelect"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            marginTop: "15px",
                            // border: '2px red solid',
                            padding: "0px 20px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="inputInfoBox "
                            style={{
                              width: "100%",
                              margin: "0px 0px 0px 0px",
                            }}
                          >
                            <Button
                              style={{ color: "green" }}
                              variant="outlined"
                              color="inherit"
                              disabled={
                                error === true ||
                                !billingGroup ||
                                !inventoryName ||
                                // !selectedSupervisor ||
                                !selectedPackage ||
                                !packgaeexpireDate ||
                                !Package ||
                                !startBillDate ||
                                !endBillDate ||
                                !basePrice ||
                                selectedCountries.length === 0 ||
                                (selectedICCIDs.length === 0 &&
                                  selectedIccids.length === 0)
                              }
                              onClick={AssignInventoryButton}
                            // onClick={handleButtonClick}
                            >
                              <AddIcon />
                            </Button>
                          </div>


                        </div>
                        <Box
                          width="100%"
                          style={{
                            overflowX: "auto",
                            marginTop: "10px",
                            // border: '2px green solid'
                          }}
                          minHeight="200px"
                        >
                          <StyledTable
                            style={{
                              overflow: "scroll",
                              width: "135%",
                              // border: '2px blue solid'
                            }}
                            hover
                          >
                            <TableHead>
                              <TableRow
                                style={{
                                  backgroundColor: " rgb(233 226 226 / 35%)",
                                }}
                              >
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Trial Period
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Package Name
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  SIM
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Bill Start Date
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Bill End Date
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Base Price
                                </TableCell>
                                {(Package === "POSTPAID" ||
                                  Package === "") && (
                                    <TableCell
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "500",
                                      }}
                                      align="center"
                                    >
                                      Rate Price
                                    </TableCell>
                                  )}

                                <TableCell
                                  style={{
                                    fontSize: "13px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Supported Countries
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                  align="center"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {objectsArray.length > 0 ? (
                                objectsArray.map((obj, index) => (
                                  <TableRow hover key={index}>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {obj.TrialPeriod === ""
                                        ? "0 Days"
                                        : `${obj.TrialPeriod} Days`}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {obj.type}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {getPackageNameById(obj.PackageUUID)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                        whiteSpace: "pre-line", // Allows line breaks
                                      }}
                                    >
                                      {obj.iccidRange.join(",\n")}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {convertUtcToLocal(obj.billStartDate)}
                                      {/* {obj.billStartDate} */}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {convertUtcToLocal(obj.billDate)}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {obj.basePrice}
                                    </TableCell>
                                    {(Package === "POSTPAID" ||
                                      Package === "") && (
                                        <TableCell
                                          align="center"
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "100",
                                          }}
                                        >
                                          {obj.TrialPeriod === ""
                                            ? "N/A"
                                            : `${obj.ratePrice}`}
                                        </TableCell>
                                      )}

                                    <TableCell
                                      align="center"
                                      style={{
                                        fontSize: "13px",
                                        fontWeight: "100",
                                      }}
                                    >
                                      {fetchCountry(
                                        obj.supportedCountries.join(", ")
                                      )}
                                    </TableCell>

                                    <TableCell align="center">
                                      <IconButton
                                        onClick={() => handleDelete(index)}
                                        sx={{
                                          ".text-effect": {
                                            position: "absolute",
                                            top: "50%",
                                            right: "100%",
                                            transform: "translateX(50%,-50%)",
                                            display: "none",
                                            textAlign: "center",
                                            width: "100%",
                                            border: "1px solid black",
                                            backgroundColor: "white",
                                          },
                                          "&:hover .text-effect": {
                                            display: "block",
                                          },
                                          position: "relative",
                                        }}
                                      >
                                        <Icon color="error">delete</Icon>
                                        <Typography
                                          variant="caption"
                                          className="text-effect"
                                        >
                                          Delete
                                        </Typography>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={10}
                                    align="center"
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "10",
                                    }}
                                  >
                                    No Data Available
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </StyledTable>
                        </Box>

                        <div
                          style={{
                            padding: "10px 4px",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            onClick={handleSkip}
                            style={{ color: "#8224e3" }}
                            variant="outlined"
                            color="inherit"
                          >
                            Skip
                          </Button>

                          <Button
                            onClick={AssignInventory}
                            disabled={objectsArray.length === 0}
                            style={{
                              color: "#8224e3",
                              marginLeft: "20px",
                            }}
                            variant="outlined"
                            color="inherit"
                          >
                            Assign Inventory
                          </Button>
                        </div>
                      </Container>
                    )}
                    {stepIndex === 3 && (
                      <p style={{ textAlign: "center" }}>
                        Tenant Created Successfully! Welcome to Deplofy !
                      </p>
                    )}
                  </Typography>

                  <Box pt={2}>
                    {activeStep === steps.length ? (
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        color="inherit"
                        style={{ color: "#8224e3" }}
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    ) : activeStep === steps.length - 1 ? (
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        color="inherit"
                        style={{ color: "#8224e3" }}
                        onClick={() => handleFinishClick()}
                      >
                        Finish
                      </Button>
                    ) : activeStep === 1 ? (
                      <>
                        <Button
                          sx={{ ml: 3, mt: 1 }}
                          variant="outlined"
                          color="inherit"
                          style={{ color: "#8224e3" }}
                          onClick={handlePrev}
                        >
                          Previous
                        </Button>

                        <Button
                          sx={{ ml: 5, mt: 1 }}
                          variant="outlined"
                          color="inherit"
                          style={{
                            color: "#8224e3",
                            marginLeft: "10px",
                          }}
                          onClick={handleNextt}
                        >
                          Next
                        </Button>
                      </>
                    ) : activeStep === 0 ? (
                      <Button
                        sx={{ ml: 2, mt: 1 }}
                        variant="outlined"
                        color="inherit"
                        inputProps={{ tabIndex: "7" }}
                        style={{ color: "#8224e3" }}
                        onClick={handleNextt}
                      >
                        Next
                      </Button>
                    ) : null}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </SimpleCard>
      </div>
    </AppButtonRoot>
  );
};

export default CreateTenants;
