import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import {
    Box,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TableSortLabel // Import TableSortLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { H2, H1 } from 'app/components/Typography';

const Container = styled('div')(({ theme }) => ({
    margin: '17px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
}));

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
    },
    '& tbody': {
        '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } }
    }
}));

const AdminBillingGroup = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [groupData, setGroupData] = useState([]);

    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');

    const [searchText, setSearchText] = useState('');

    const handleSort = (property) => {
        const newOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc';
        setOrderBy(property);
        setOrder(newOrder);
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filteredData = groupData.filter((sim) =>
        sim.id.toLowerCase().includes(searchText.toLowerCase())
    );

    const sortedData = filteredData.sort((a, b) => {
        const isAsc = order === 'asc';
        if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
        if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
        return 0;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // ///
    const fetchGroups = () => {
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        fetch(`${REACT_APP_BASE_URL}/api/v1/get_groups_admin`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                setGroupData(result.Groups)
            })
            .catch(error => {
            });
    }

    useEffect(() => {
        fetchGroups()
    }, [])

    return (
        <>
            <Container>
                <Box className="breadcrumb" style={{ display: 'flex' }}>
                    <H1>Groups | </H1>
                    <p
                        style={{
                            marginLeft: '5px',
                            color: '#8224e3'
                        }}
                    >
                        Billing Groups
                    </p>
                </Box>

                <SimpleCard title="Billing Group" style={{}}>
                    <div>
                        <div>
                            <input
                                style={{
                                    height: '36px',
                                    width: '40%',
                                    border: '1px solid  #dededf',
                                    paddingLeft: '10px'
                                }}
                                name="search"
                                placeholder="Search by Group ID.."
                                type="search"
                                autoComplete="off"
                                value={searchText}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>

                    <br />
                    <br />
                    <Box
                        style={{
                            width: '100%',
                            height: '80vh',
                            overflowY: 'scroll',
                            overflowX: 'scroll'
                        }}
                    >
                        <StyledTable
                            stickyHeader
                            style={{
                                width: '100%'
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '600',
                                            paddingLeft: '10px'
                                        }}
                                        align="center"
                                    >
                                        <TableSortLabel
                                            active={orderBy === 'id'}
                                            direction={orderBy === 'id' ? order : 'asc'}
                                            onClick={() => handleSort('id')}
                                        >
                                            Group Id
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '600'
                                        }}
                                        align="center"
                                    >
                                        Inventory ID
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '600'
                                        }}
                                        align="center"
                                    >
                                        Group Name
                                    </TableCell>

                                    <TableCell
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '600'
                                        }}
                                        align="center"
                                    >
                                        Date Created(UTC)
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {sortedData.length > 0
                                    ? sortedData

                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((sim) => (
                                            <TableRow key={sim.id}>
                                                <TableCell
                                                    style={{ color: '#8224E3' }}
                                                    align="center"
                                                >
                                                    {sim.id}
                                                </TableCell>
                                                <TableCell align="center">{sim.inventoryId}</TableCell>
                                                <TableCell align="center">{sim.name}</TableCell>
                                                <TableCell align="center"> {new Date(sim.createdOn * 1000).toLocaleString()} </TableCell>
                                            </TableRow>
                                        ))
                                    : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No data available.
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        </StyledTable>
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30]}
                        component="div"
                        count={sortedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </SimpleCard>
            </Container>
        </>
    );
}

export default AdminBillingGroup
