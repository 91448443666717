import { Box, Fab, IconButton, Container } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Autocomplete from "@mui/material/Autocomplete";
import "./CreateSupervisorStyle.css";
import { Tooltip } from "@mui/material";

import { countries } from "countries-list";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  Chip,
} from "@mui/material";
import { H2, H1, H3, H4 } from "app/components/Typography";

import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

function getSteps() {
  return ["Fill the details", "Enter Address", "Assign SIM", "Success"];
}

const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginBottom: "16px",
  marginLeft: "44px",
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AppButtonRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
  "& .button": { margin: theme.spacing(1) },
  "& .input": { display: "none" },
}));

const CreateSupervisor = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const TenantUUID = localStorage.getItem("TenantUUID");
  const accessToken = localStorage.getItem("accessToken");

  const [showPassword, setShowPassword] = React.useState(false);
  const [userUUID, setUserUUID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [error1, setError1] = useState("");
  const [fNameFlag, setFNameFlag] = useState(true);
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [fLastFlag, setFLastFlag] = useState(true);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const maxPasswordLength = 18;
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailFlag, setEmailFlag] = useState(true);
  const [checkBoxTrial, setCheckBoxTrial] = useState(false);
  const [trial, setTrial] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState("");
  const [billCycle, setBillCycle] = useState("");
  const [startBillDate, setStartBillDate] = useState("");
  const [endBillDate, setEndBillDate] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [ratePrice, setRatePrice] = useState("");
  const [billDate, setBillDate] = useState("");
 



  const [iccid, setIccid] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [state, setState] = useState("");
  const [stateFlag, setStateFlag] = useState(true);
  const [city, setCity] = useState("");
  const [stateError, setStateError] = useState("");
  const [cityFlag, setCityFlag] = useState(true);
  const [cityError, setCityError] = useState("");

  const [postalCode, setPostalCode] = useState("");
  const [postalFlag, setPostalFlag] = useState(true);
  const [postalCodeError, setPostalCodeError] = useState("");

  const [shippingAddressLine1, setShippingAddressLine1] = useState("");
  const [shippingAddressLine2, setShippingAddressLine2] = useState("");
  const [shippingAddressLine3, setShippingAddressLine3] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingStateError, setShippingStateError] = useState();
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCityError, setShippingCityError] = useState();
  const [shippingpostalCode, setShippingPostalCode] = useState("");
  const [shippingpostalCodeError, setShippingpostalCodeError] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const stepIndex = activeStep;
  const [inventoryList, setInventoryList] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState("");
  const [iccids, setIccids] = useState([]);
  const [selectedStartIccid, setSelectedStartIccid] = useState("");
  const [selectedEndIccid, setSelectedEndIccid] = useState("");
  const [selectedICCIDs, setSelectedICCIDs] = useState([]);
  const [selectedIccids, setSelectedIccids] = useState([]);
  const [userUI_, setUserUI_] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [groupName, setGroupName] = useState("");

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [packages, setPackages] = useState([]);

  const [parentGroupId, setParentGroupId] = useState("");

  const handleChangePackage = (event) => {
    const { value } = event.target;
    setSelectedPackage(value);
  };

  useEffect(() => {
    fetchpackages();
  }, []);

  const fetchpackages = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const packagevalue = result.Packages.map((packages) => packages.name);
        setPackages(result.Packages);
      })
  };

  const handleFirstNameChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is blank
    if (inputValue.trim() === "") {
      setFirstName("");
      setError1("Please enter first name");
      setFNameFlag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");

      setFirstName(alphabeticValue);
      setError1("");
      setFNameFlag(true);
    }
  };

  const handleLastNameChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is blank
    if (inputValue.trim() === "") {
      setLastName("");
      setError("Please enter last name");
      setFLastFlag(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputValue.replace(/[^a-zA-Z\s]/g, "");

      setLastName(alphabeticValue);
      setError("");
      setFLastFlag(true);
    }
  };

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!email) {
      setEmailError("Please enter your bussiness email id");
      setEmailFlag(false);
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
    } else {
      const [, domain] = email.split("@").map((part) => part.toLowerCase());

      // Check if the email domain is allowed
      const allowedDomains = ["startelelogic.com"]; // Add your company domain(s) here

      // Disallow Gmail, Yahoo, and Hotmail
      const disallowedDomains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "rediffmail.com",
      ];

      if (disallowedDomains.includes(domain)) {
        setEmailError("Only bussiness email are allowed");
        setEmailFlag(false);
      } else {
        setEmailError("");
        setEmailFlag(true);
      }
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const handleChanges = (e) => {
    const inputValue = e.target.value;

    // Check if the password has reached the maximum length
    if (inputValue.length <= maxPasswordLength) {
      setPassword(inputValue);
    }
    setIsPasswordValid(
      password.length >= 5 && password.length <= maxPasswordLength
    );
  };


  const handleStateChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input is empty
    if (inputValue.trim() === "") {
      setState("");
      setStateError("Please enter  state");
      setStateFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(inputValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setState(inputValue);
      setStateError(""); // Clear the error when the input is valid
      setStateFlag(true);
    } else {
    }
  };

  const handleCityChange = (e) => {
    const cityValue = e.target.value;

    if (cityValue.trim() === "") {
      setCity("");
      setCityError("Please enter  city");
      setCityFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(cityValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setCity(cityValue);
      setCityError(""); // Clear the error when the input is valid
      setCityFlag(true);
    } else {
    }
  };


  const handlePostalCodeChange = (e) => {
    const postalValue = e.target.value;

    if (postalValue.trim() === "") {
      setPostalCode("");
      setPostalCodeError("Please enter  postal code");
      setPostalFlag(false);
    } else if (/^[0-9\s]+$/.test(postalValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setPostalCode(postalValue);
      setPostalCodeError(""); // Clear the error when the input is valid
      setPostalFlag(true);
    } else {
    }
  };

  const handleShippingStateChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only characters using a regular expression
    if (inputValue.trim() === "") {
      setShippingState("");
      setShippingStateError("Please enter state");
    } else if (/^[a-zA-Z0-9\s]+$/.test(inputValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingState(inputValue);
      setShippingStateError(""); // Clear the error when the input is valid
    } else {
    }
  };

  const handleShippingCityChange = (e) => {
    const cityValue = e.target.value;

    // Check if the input contains only alphabetical characters
    if (cityValue.trim() === "") {
      setShippingCity("");
      setShippingCityError("Please enter  city");
    } else if (/^[a-zA-Z0-9\s]+$/.test(cityValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingCity(cityValue);
      setShippingCityError(""); // Clear the error when the input is valid
    } else {
    }
  };

  const handlePostalCodeChanges = (e) => {
    const postalValue = e.target.value;

    if (postalValue.trim() === "") {
      setShippingPostalCode("");
      setShippingpostalCodeError("Please enter  postal code");
    } else if (/^[0-9\s]+$/.test(postalValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setShippingPostalCode(postalValue);
      setShippingpostalCodeError(""); // Clear the error when the input is valid
    } else {
    }
  };

  const handleCheckBoxTrial = () => {
    setCheckBoxTrial((prevCheckBox) => !prevCheckBox);
    setTrial(!trial);
  };

  const handleRemoveICCID = (iccid) => {

    const updatedSelectedICCIDs = selectedICCIDs.filter((id) => id !== iccid);
    setSelectedICCIDs(updatedSelectedICCIDs);

    const updatedSelectedIccids = selectedIccids.filter((id) => id !== iccid);
    setSelectedIccids(updatedSelectedIccids);
  };

  const handleStartIccidChange = (newValue) => {
    setSelectedStartIccid(newValue);
  };

  const handleEndIccidChange = (newValue) => {
    setSelectedEndIccid(newValue);

    setSelectedICCIDs([]);

    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === newValue);

    if (startIndex !== -1 && endIndex !== -1) {
      const idsInRange = iccids.slice(startIndex, endIndex + 1);
      setSelectedICCIDs(idsInRange);
    }
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/get_parent_group?domain=${TenantUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setParentGroupId(result.ParentGroup[0].parentGroupId);
      })
  });

  const handleSave = () => {
    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === selectedEndIccid);
    const commaSeparatedIds = selectedICCIDs.join(",");
    const commaSeparatedIdsss = selectedIccids.join(",");


    const mergedCommaSeparatedIds = commaSeparatedIds.concat(
      ",",
      commaSeparatedIdsss
    );

    var formattedMergedIds = "";

    if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
      formattedMergedIds = `${mergedCommaSeparatedIds}`;
    }

    if (commaSeparatedIds == "") {
      formattedMergedIds = `${commaSeparatedIdsss}`;

    }
    if (commaSeparatedIdsss == "") {
      formattedMergedIds = `${commaSeparatedIds}`;

    }


    if (!selectedInventory || !formattedMergedIds) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    const startbilldate = new Date(startBillDate);
    const formattedStartBillDate = startbilldate
      .toISOString()
      .replace("Z", "+00:00");

    const endbilldate = new Date(endBillDate);
    const formattedEndBillDate = endbilldate
      .toISOString()
      .replace("Z", "+00:00");


    if (trial) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      if (!dateFlag) {
        toast.error(
          "Ending billing date must be greater than the starting billing date",
          {
            toast: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        return;
      }

      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        inventoryId: selectedInventory,
        packageId: "1234",
        billDate: billDate,
        billStartDate: formattedStartBillDate,
        billDate: formattedEndBillDate,
        trial: true,
        TrialPeriod: trialPeriod,
        basePrice: basePrice,
        ratePrice: ratePrice,
      });


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/api/v1/assign_sim_to_user?iccidRange=${formattedMergedIds}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (result.Status === 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      if (!dateFlag) {
        toast.error(
          "Ending billing date must be greater than the starting billing date",
          {
            toast: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          }
        );
        return;
      }

      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        inventoryId: selectedInventory,
        packageId: "1234",
        billDate: billDate,
        billStartDate: formattedStartBillDate,
        billDate: formattedEndBillDate,
        trial: false,
        TrialPeriod: "",
        basePrice: basePrice,
        ratePrice: ratePrice,
      });


      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/api/v1/assign_sim_to_user?iccidRange=${formattedMergedIds}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else if (result.Status === 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
    }
  };

  const [dateerror, setDateError] = useState(false);
  const [dateFlag, setDateFlag] = useState(true);

  const checkDate = (endDate) => {
    if (startBillDate && endDate) {
      const startTimestamp = new Date(startBillDate).getTime();
      const endTimestamp = new Date(endDate).getTime();

      if (endTimestamp <= startTimestamp) {
   
        setDateError(true);
        setDateFlag(false);
        return;
      } else {
        setDateError(false);
        setDateFlag(true);
      }
    }
  };

  const createGroup = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      name: groupName,
      inventoryId: selectedInventory,
      parentGroupId: parentGroupId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_group`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        assignSim(result.GroupDetail.id);
      })
      .catch((error) => {
    });
  };

  const assignSim = (groupId) => {
    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === selectedEndIccid);

    const commaSeparatedIds = selectedICCIDs.join(",");
    const commaSeparatedIdsss = selectedIccids.join(",");



    const mergedCommaSeparatedIds = commaSeparatedIds.concat(
      ",",
      commaSeparatedIdsss
    );

    var formattedMergedIds = "";

    if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
      formattedMergedIds = `${mergedCommaSeparatedIds}`;
    }

    if (commaSeparatedIds == "") {
      formattedMergedIds = `${commaSeparatedIdsss}`;
    }
    if (commaSeparatedIdsss == "") {
      formattedMergedIds = `${commaSeparatedIds}`;
  
    }


    if (
  
      !selectedInventory ||
      !formattedMergedIds
    ) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });

      return;
    }

    const formattedPackageId = selectedPackage.toString();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      UserUUID: userUI_,
      inventoryId: selectedInventory,
      GroupId: groupId,
      packageId: formattedPackageId,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/assign_sim_to_user?iccidRange=${formattedMergedIds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
     
          setActiveStep((prevActiveStep) => prevActiveStep + 1);

        } else if (result.Status === 0) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  useEffect(() => {
    const fetchIccids = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          TenantUUID: TenantUUID,
          inventoryId: selectedInventory,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_sims_of_tenant`,
          requestOptions
        );
        const result = await response.json();

        if (result.Status === 1) {
          const iccidValues = result.Sims[0].iccids.map((sim) => sim.iccId);
          setIccids(iccidValues);
        } else {
        }
      } catch (error) {
      }
    };

    fetchIccids();
  }, [selectedInventory]);

  useEffect(() => {
    const fetchInventoryList = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        TenantUUID: TenantUUID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/inventory_list`,
          requestOptions
        );

        if (response.ok) {
          const result = await response.json();
          if (result.Status === 1) {
            setInventoryList(result.InventoryList);
          } else {
          }
        } else {
        }
      } catch (error) {
      }
    };
    fetchInventoryList();
  }, []);

  const handleInventoryChange = (event) => {
    const selectedId = event.target.value;
    setSelectedInventory(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [checkBox1, setCheckBox1] = useState(true); // for setting the billing and current address the same

  const handleCheckBox1 = () => {
    setCheckBox1((prevCheckBox) => !prevCheckBox);
    if (!checkBox1) {
      setShippingAddressLine1(addressLine1);
      setShippingAddressLine2(addressLine2);
      setShippingAddressLine3(addressLine3);
      setShippingCountry(selectedCountry);
      setShippingState(state);
      setShippingCity(city);
      setShippingPostalCode(postalCode);
    } else {
      setShippingAddressLine1("");
      setShippingAddressLine2("");
      setShippingAddressLine3("");
      setShippingCountry("");
      setShippingState("");
      setShippingCity("");
      setShippingPostalCode("");
    }
  };

  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== state.password) return false;

      return true;
    });
    return () => ValidatorForm.removeValidationRule("isPasswordMatch");
  }, [state.password]);

  const handleSkip = () => setActiveStep(3);
  const handleReset = () => setActiveStep(0);
  const handleSubmit = () => {};

  const handleFinishClick = () => {
    navigate("/client/client");
  };

  const handleNext = () => {
    if (activeStep === 0) {
      // Check individual fields and display error messages
      if (!fNameFlag) {
        toast("Please enter  first name", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      if (!fLastFlag) {
        toast("Please enter  last name", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      if (!emailFlag) {
        toast("Please enter a valid bussiness email address", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      if (!isPasswordValid) {
        toast("Password must be between 6 and 12 characters in length", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }


      if (!firstName || !lastName || !email || !password || !mobile) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (checkBox1 === true) {
        if (
          !addressLine1 ||
          !addressLine2 ||
          !city ||
          !state ||
          !selectedCountry ||
          !postalCode
        ) {
          toast("Please fill in all required fields", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        if (cityFlag === false) {
          toast("please enter city", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        if (stateFlag === false) {
          toast("please enter state", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        if (postalFlag === false) {
          toast("please enter postal code", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          TenantUUID: TenantUUID,
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: mobile,
          Password: password,
          EmailId: email,
          ActiveSims: "23",
          SuspendedSims: "3",
          MTDUsage: "",
          PrepaidBalance: "",
          OutstandingBalace: "",
          PastDueBalance: "",
          MaxTenantLimit: "100",
          SimLimit: "20",
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          ShippingAddress1: addressLine1,
          ShippingAddress2: addressLine2,
          ShippingAddress3: addressLine3,
          Country: selectedCountry,
          ShippingCountry: selectedCountry,
          City: city,
          ShippingCity: city,
          State: state,
          ShippingState: state,
          PostalCode: postalCode,
          ShippingPostalCode: postalCode,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_URL}/user/api/v1/create_client`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.Status === 1) {
              toast(result.Message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              setUserUI_(result.UserData.UserUUID);
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else if (result.Status === 0) {
              toast(result.Message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
          });
      } else if (checkBox1 == false) {
        if (
          !addressLine1 ||
          !addressLine2 ||
          !city ||
          !state ||
          !selectedCountry ||
          !postalCode ||
          !shippingAddressLine1 ||
          !shippingAddressLine2 ||
          !shippingCity ||
          !shippingState ||
          !shippingCountry ||
          !shippingpostalCode
        ) {
          toast("Please fill in all required fields", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          TenantUUID: TenantUUID,
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: mobile,
          Password: password,
          EmailId: email,
          TotalSims: "23",
          ActiveSims: "23",
          SuspendedSims: "3",
          MTDUsage: "",
          PrepaidBalance: "",
          OutstandingBalace: "",
          PastDueBalance: "",
          MaxTenantLimit: "22",
          SimLimit: "2",
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          ShippingAddress1: shippingAddressLine1,
          ShippingAddress2: shippingAddressLine2,
          ShippingAddress3: shippingAddressLine3,
          Country: selectedCountry,
          ShippingCountry: shippingCountry,
          City: city,
          ShippingCity: shippingCity,
          State: state,
          ShippingState: shippingState,
          PostalCode: postalCode,
          ShippingPostalCode: shippingpostalCode,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_URL}/user/api/v1/create_client`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.Status === 1) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else if (result.Status === 0) {
              toast(result.Message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
          });
      }
    }
  };
  const handleToggleIccid = (iccid) => {
    const currentIndex = selectedIccids.indexOf(iccid);
    const newSelectedIccids = [...selectedIccids];

    if (currentIndex === -1) {
      newSelectedIccids.push(iccid);
    } else {
      newSelectedIccids.splice(currentIndex, 1);
    }

    setSelectedIccids(newSelectedIccids);
  };

  const handlePrev = () => {
    setActiveStep(0);
  };

  return (
    <AppButtonRoot>
      <Box
        className="breadcrumb"
        style={{ display: "flex", alignItems: "center" }}
      >
        <H3>Add New Client | </H3>

        <a
          href="/client/client"
          style={{
            margin: "10px",
            color: "#8224e3",
            textDecoration: "none",
          }}
        >
          Clients &gt;
        </a>
        <p
          style={{
            marginLeft: "10px",
            color: "#8224e3",
            margin: "0",
          }}
        >
          Add New Client
        </p>
      </Box>

      <div>
        <SimpleCard title="Add New Client">
          <Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box mt={4}>
              {activeStep === steps.length ? (
                <Box>
                  <Typography>All steps completed</Typography>

                  <Button
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography>
                    {/* <----------------For General Details ----------------------> */}
                    {stepIndex === 0 && (
                      <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={() => null}
                      >
                        <Grid container>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={2}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                      

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">First Name</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter your first name"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  inputProps={{ tabIndex: "1" }}
                                  onChange={handleFirstNameChange}
                                  value={firstName}
                                  style={{ width: "100%", height: "42px" }}
                                  placeholder="Enter First Name"
                                />
                                {error1 && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {error1}
                                  </p>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Email ID</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter your bussiness Email ID"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  inputProps={{ tabIndex: "3" }}
                                  value={email}
                                  onChange={handleEmailChange}
                                  style={{ width: "100%", height: "42px" }}
                                  placeholder="Enter Your Email Id"
                                />
                                {emailError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "11px",
                                      margin: "0px 0 0 10px",
                                    }}
                                  >
                                    {emailError}
                                  </p>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Phone Number</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter your contact number here"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <PhoneInput
                                  country={"us"}
                                  enableSearch={true}
                                  inputProps={{ tabIndex: "6" }}
                                  value={mobile}
                                  onChange={(mobile) => setMobile(mobile)}
                                  style={{
                                    width: "100%",
                                  }}
                                  inputStyle={{
                                    background: "transparent",
                                    width: "100%",
                                    height: "42px",
                                  }}
                                />
                              </div>
                            </div>
                          </Grid>

                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={2}
                          >

                            <div
                              className="inputInfoBox"
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Last Name</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter your last name"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                autoComplete="new-email"
                                inputProps={{ tabIndex: "2" }}
                                onChange={handleLastNameChange}
                                value={lastName || ""}
                                style={{ width: "100%", height: "42px" }}
                                placeholder="Enter Last Name"
                              />
                              {error && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {error}
                                </p>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Password</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter your password here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                name="password"
                                type={showPassword ? "text" : "password"}
                                inputProps={{ tabIndex: "4" }}
                                placeholder="Enter Password"
                                autoComplete="new-text"
                                style={{ width: "100%", height: "42px" }}
                                value={password}
                                onChange={handleChanges}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={togglePasswordVisibility}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {!isPasswordValid && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  Password must be between 6 and 18 characters
                                  in length..
                                </p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </ValidatorForm>
                    )}

                    {/* <------------------For Address----------------------> */}
                    {stepIndex === 1 && (
                      <ValidatorForm
                        onSubmit={handleSubmit}
                        onError={() => null}
                      >
                        <Grid container>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{ mt: 2 }}
                            px={3}
                          >
                            <p
                              style={{
                                fontSize: "17px",
                              }}
                            >
                              Billing Address
                              <hr />
                            </p>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 1</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter first address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine1"
                                inputProps={{ tabIndex: "1" }}
                                onChange={(e) =>
                                  setAddressLine1(e.target.value)
                                }
                                value={addressLine1 || ""}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 1"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 2</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter second address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine2"
                                inputProps={{ tabIndex: "2" }}
                                onChange={(e) =>
                                  setAddressLine2(e.target.value)
                                }
                                value={addressLine2 || ""}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 2"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Address Line 3</p>
                                <Tooltip
                                  title="Enter third address line here"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="addressLine3"
                                inputProps={{ tabIndex: "3" }}
                                onChange={(e) =>
                                  setAddressLine3(e.target.value)
                                }
                                value={addressLine3 || ""}
                                style={{ width: "100%" }}
                                placeholder="Enter Address Line 3"
                              />
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Country</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Select your country from the list"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <FormControl style={{ width: "100%" }}>
                                <Select
                                  labelId="country-label"
                                  inputProps={{ tabIndex: "4" }}
                                  value={selectedCountry}
                                  onChange={handleCurrentCountry}
                                  style={{ width: "100%", height: "42px" }}
                                >
                                  {countryOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">State</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the name of your state"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="state"
                                inputProps={{ tabIndex: "5" }}
                                value={state}
                                onChange={handleStateChange}
                                style={{ width: "100%" }}
                                placeholder="Enter State"
                              />
                              {stateError && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {stateError}
                                </p>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">City</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the name of your city"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="city"
                                inputProps={{ tabIndex: "6" }}
                                value={city}
                                onChange={handleCityChange}
                                style={{ width: "100%" }}
                                placeholder="Enter City"
                              />
                              {cityError && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {cityError}
                                </p>
                              )}
                            </div>

                            <div
                              className="inputInfoBox "
                              style={{
                                width: "100%",
                                margin: "0px 0px 12px 0px",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <p id="inputInfoTopText">Postal Code</p>
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip
                                  title="Enter the postal code of your city"
                                  placement="right"
                                >
                                  <Icon style={{ transform: "scale(.7)" }}>
                                    info_outline
                                  </Icon>
                                </Tooltip>
                              </div>
                              <OutlinedInput
                                type="text"
                                name="postalcode"
                                inputProps={{ tabIndex: "7" }}
                                value={postalCode}
                                onChange={handlePostalCodeChange}
                                style={{ width: "100%" }}
                                placeholder="Enter Postal Code"
                              />
                              {postalCodeError && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "11px",
                                    margin: "0px 0 0 10px",
                                  }}
                                >
                                  {postalCodeError}
                                </p>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkBox1}
                              onChange={handleCheckBox1}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Same as Billing Address"
                          sx={{ marginLeft: "30px" }}
                        />

                        {!checkBox1 && (
                          <Grid container>
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{ mt: 2 }}
                              px={3}
                            >
                              <p
                                style={{
                                  fontSize: "17px",
                                }}
                              >
                                Shipping Address
                                <hr />
                              </p>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 1
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter first line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine1"
                                  inputProps={{ tabIndex: "8" }}
                                  onChange={(e) =>
                                    setShippingAddressLine1(e.target.value)
                                  }
                                  value={shippingAddressLine1 || ""}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping Address Line 1"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 2
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter second line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine2"
                                  inputProps={{ tabIndex: "9" }}
                                  onChange={(e) =>
                                    setShippingAddressLine2(e.target.value)
                                  }
                                  value={shippingAddressLine2 || ""}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Address Line 2"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Address Line 3
                                  </p>
                                  <Tooltip
                                    title="Enter third line of your desired shipping address"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingAddressLine3"
                                  inputProps={{ tabIndex: "10" }}
                                  onChange={(e) =>
                                    setShippingAddressLine3(e.target.value)
                                  }
                                  value={shippingAddressLine3 || ""}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Address Line 3"
                                />
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping Country</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Select your desired shipping country from the list"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <FormControl style={{ width: "100%" }}>
                                  <Select
                                    labelId="country-label"
                                    inputProps={{ tabIndex: "11" }}
                                    value={shippingCountry || ""}
                                    onChange={(e) =>
                                      setShippingCountry(e.target.value)
                                    }
                                    style={{ width: "100%", height: "42px" }}
                                  >
                                    {countryOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.label}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping State</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the name of your desired shipping state"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>

                                <OutlinedInput
                                  type="text"
                                  name="shippingState"
                                  inputProps={{ tabIndex: "12" }}
                                  value={shippingState}
                                  onChange={handleShippingStateChange}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping State "
                                />
                                {shippingStateError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      margin: "2px 0 0 0",
                                    }}
                                  >
                                    {shippingStateError}
                                  </div>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">Shipping City</p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the name of your desired shipping city"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingCity"
                                  inputProps={{ tabIndex: "13" }}
                                  value={shippingCity}
                                  onChange={handleShippingCityChange}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping City "
                                />
                                {shippingCityError && (
                                  <div
                                    style={{
                                      color: "red",
                                      fontSize: "12px",
                                      margin: "2px 0 0 0",
                                    }}
                                  >
                                    {shippingCityError}
                                  </div>
                                )}
                              </div>

                              <div
                                className="inputInfoBox "
                                style={{
                                  width: "100%",
                                  margin: "0px 0px 12px 0px",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <p id="inputInfoTopText">
                                    Shipping Postal Code
                                  </p>
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip
                                    title="Enter the postal code of the desired  shipping city"
                                    placement="right"
                                  >
                                    <Icon style={{ transform: "scale(.7)" }}>
                                      info_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                                <OutlinedInput
                                  type="text"
                                  name="shippingPostalcode"
                                  inputProps={{ tabIndex: "14" }}
                                  value={shippingpostalCode}
                                  onChange={handlePostalCodeChanges}
                                  style={{ width: "100%" }}
                                  placeholder="Enter Shipping Postal Code"
                                />
                                {shippingpostalCodeError && (
                                  <p
                                    style={{
                                      color: "red",
                                      margin: "2px 0 0 0",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {shippingpostalCodeError}
                                  </p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </ValidatorForm>
                    )}

                    {stepIndex === 2 && (
                      <Container style={{ padding: "10px" }}>
                        <>
                          <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                            <Box>
                              <div
                                className="box1"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  padding: "15px 10px",
                                  backgroundColor: " rgb(233 226 226 / 35%)",
                                  borderRadius: "25px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "0px",
                                    marginLeft: "10px",
                                    marginTop: "0px",
                                    width: "100%",
                                  }}
                                >
                                  <H3
                                    style={{
                                      margin: "10px",
                                      padding: "0px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Assign SIM to Client
                                  </H3>
                                  <hr />
                                </div>
                                <br />

                                <div className="createSupervisorAssignSelect">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 10px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">
                                        Inventory Name
                                      </p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Select the Inventory from the List"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <Select
                                      value={selectedInventory}
                                      onChange={handleInventoryChange}
                                      displayEmpty
                                      style={{
                                        height: "35px",
                                        width: "100%",
                                      }}
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                          style: {
                                            maxHeight: 200,
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value="" disabled>
                                        -- Select Inventory--
                                      </MenuItem>
                                      {inventoryList.map((inventory, index) => (
                                        <MenuItem
                                          key={index}
                                          value={inventory.id}
                                        >
                                          {inventory.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </div>

                        
                                <div className="createTenantAssignInput">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "-10px 10px 4px 0px",
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checkBoxTrial}
                                          onChange={handleCheckBoxTrial}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      }
                                      label="Keep This as Trial Package"
                                      sx={{}}
                                    />
                                  </div>
                                </div>
                                {checkBoxTrial && (
                                  <div className="createTenantAssignSelect">
                                    <div
                                      className="inputInfoBox "
                                      style={{
                                        width: "50%",
                                        margin: "0px 0px 0px 0px",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <p id="inputInfoTopText">Trial Days</p>
                                        <span style={{ color: "red" }}>*</span>
                                        <Tooltip
                                          title="Select the Days for Trial Period"
                                          placement="right"
                                        >
                                          <Icon
                                            style={{ transform: "scale(.7)" }}
                                          >
                                            info_outline
                                          </Icon>
                                        </Tooltip>
                                      </div>
                                      <Select
                                        value={trialPeriod}
                                        onChange={(e) =>
                                          setTrialPeriod(e.target.value)
                                        }
                                        displayEmpty
                                        style={{
                                          height: "35px",
                                          width: "100%",
                                        }}
                                        MenuProps={{
                                          getContentAnchorEl: null,
                                          PaperProps: {
                                            style: {
                                              maxHeight: 200,
                                            },
                                          },
                                        }}
                                      >
                                        <MenuItem value="" disabled>
                                          -- Select the Days of Trial Period --
                                        </MenuItem>
                                        <MenuItem value="7"> 7 Days </MenuItem>
                                        <MenuItem value="14">
                                          {" "}
                                          14 Days{" "}
                                        </MenuItem>
                                        <MenuItem value="21">
                                          {" "}
                                          21 Days{" "}
                                        </MenuItem>
                                        <MenuItem value="28">
                                          {" "}
                                          28 Days{" "}
                                        </MenuItem>
                                      </Select>
                                    </div>
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "48%",
                                      margin: "0px 0px 12px 0px",
                                      padding: "0px 0px 0px 45px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">Package Name</p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Select the Package"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <Select
                                      value={selectedPackage}
                                      onChange={handleChangePackage}
                                      // multiple
                                      displayEmpty
                                      style={{
                                        height: "35px",
                                        width: "100%",
                                      }}
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                          style: {
                                            maxHeight: 200,
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value="" disabled>
                                        -- Select the Package --
                                      </MenuItem>
                                    
                                      {packages.map((pkg) => (
                                        <MenuItem
                                          key={pkg.packageId}
                                          value={pkg.packageId}
                                        >
                                          {pkg.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                                <div className="AssignSIMSelect">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">Billing Cycle</p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Select the Billing Cycle for the Selected Package"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <Select
                                      value={billCycle}
                                      onChange={(e) =>
                                        setBillCycle(e.target.value)
                                      }
                                      displayEmpty
                                      style={{
                                        height: "35px",
                                        width: "100%",
                                      }}
                                      MenuProps={{
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                          style: {
                                            maxHeight: 200,
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value="" disabled>
                                        -- Select the Billing Cycle --
                                      </MenuItem>
                                      <MenuItem value="7"> 7 Days </MenuItem>
                                      <MenuItem value="14"> 14 Days </MenuItem>
                                      <MenuItem value="21"> 21 Days </MenuItem>
                                      <MenuItem value="28"> 28 Days </MenuItem>
                                    </Select>
                                  </div>
                                </div>
                                <div className="createTenantAssignInput">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">
                                        Starting Billing Date
                                      </p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Select the Billing Date for the Selected Package"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      id="startbillDate"
                                      type="datetime-local"
                                      inputProps={{ tabIndex: "" }}
                                      value={startBillDate}
                                      onChange={(e) =>
                                        setStartBillDate(e.target.value)
                                      }
                                      style={{ height: "35px", width: "100%" }}
                                    />
                                  </div>
                                </div>
                                <div className="createTenantAssignInput">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">
                                        Ending Billing Date
                                      </p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Select the Billing Date for the Selected Package"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      id="billDate"
                                      type="datetime-local"
                                      inputProps={{ tabIndex: "" }}
                                      value={endBillDate}
                                      onChange={(e) => {
                                        setEndBillDate(e.target.value);
                                        checkDate(e.target.value);
                                      }}
                                      style={{ height: "35px", width: "100%" }}
                                    />
                                    {dateerror && (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "11px",
                                          margin: "0px 0 0 10px",
                                        }}
                                      >
                                        Ending billing date must be greater than
                                        the starting billing date
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="createTenantAssignInput">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">Base Price</p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Enter the Base Price of the Package"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      type="text"
                                      inputProps={{ tabIndex: "" }}
                                      onChange={(e) =>
                                        setBasePrice(e.target.value)
                                      }
                                      value={basePrice}
                                      style={{ width: "100%", height: "35px" }}
                                      placeholder="Base Price"
                                    />
                                  </div>
                                </div>
                                <div className="createTenantAssignInput">
                                  <div
                                    className="inputInfoBox "
                                    style={{
                                      width: "50%",
                                      margin: "0px 0px 12px 0px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <p id="inputInfoTopText">Rate Price</p>
                                      <span style={{ color: "red" }}>*</span>
                                      <Tooltip
                                        title="Enter the Rate Price of the Package(per MB Cost)"
                                        placement="right"
                                      >
                                        <Icon
                                          style={{ transform: "scale(.7)" }}
                                        >
                                          info_outline
                                        </Icon>
                                      </Tooltip>
                                    </div>
                                    <OutlinedInput
                                      type="text"
                                      inputProps={{ tabIndex: "" }}
                                      onChange={(e) =>
                                        setRatePrice(e.target.value)
                                      }
                                      value={ratePrice}
                                      style={{ width: "100%", height: "35px" }}
                                      placeholder="Rate Price"
                                    />
                                  </div>
                                </div>

                                {/* This is for the selection of the range and indiviual  --------------- */}
                                <div className="SelectionBox">
                                  <div className="Selection ">
                                    <div className="range">
                                      <H3
                                        style={{
                                          fontSize: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        Range Selection of SIM Cards{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </H3>
                                      <Autocomplete
                                        value={selectedStartIccid}
                                        onChange={(event, newValue) =>
                                          handleStartIccidChange(newValue)
                                        }
                                        options={iccids.filter(
                                          (option) =>
                                            !selectedIccids.includes(option) &&
                                            option !== selectedEndIccid
                                        )}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="-- Starting ICCID --"
                                            variant="outlined"
                                            style={{
                                              height: "47px",
                                              width: "100%",
                                              marginTop: "10px",
                                              marginLeft: "-4px",
                                            }}
                                          />
                                        )}
                                      />
                                      <Autocomplete
                                        value={selectedEndIccid}
                                        onChange={(event, newValue) =>
                                          handleEndIccidChange(newValue)
                                        }
                                        options={iccids.filter(
                                          (option) =>
                                            !selectedIccids.includes(option) &&
                                            option !== selectedStartIccid
                                        )}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="-- Ending ICCID --"
                                            variant="outlined"
                                            style={{
                                              height: "47px",
                                              width: "100%",
                                              marginTop: "10px",
                                              marginLeft: "-4px",
                                            }}
                                          />
                                        )}
                                      />
                                    </div>

                                    <H2
                                      style={{
                                        margin: "12px 46% 12px 0px",
                                      }}
                                    >
                                      OR
                                    </H2>

                                    <div className="individual">
                                      <H3
                                        style={{
                                          fontSize: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        Individual Selection of SIM Cards{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </H3>
                                      <div
                                        style={{
                                          width: "100%",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <FormControl
                                          style={{
                                            width: "500px",
                                         
                                          }}
                                        >
                                       
                                          <Autocomplete
                                            multiple
                                            displayEmpty={true}
                                            id="demo-multiple-checkbox"
                                            options={iccids.filter(
                                              (option) =>
                                                option !== selectedStartIccid &&
                                                option !== selectedEndIccid &&
                                                option !== selectedICCIDs &&
                                                !selectedICCIDs.includes(option)
                                            )}
                                            value={selectedIccids}
                                            renderTags={() => null}
                                            onChange={(event, newValue) =>
                                              setSelectedIccids(newValue)
                                            }
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option}
                                            isOptionEqualToValue={(
                                              option,
                                              value
                                            ) => option === value}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="-- Select ICCIDs --"
                                                variant="outlined"
                                                onChange={(e) =>
                                                  setSearchValue(e.target.value)
                                                }
                                              />
                                            )}
                                            renderOption={(
                                              props,
                                              option,
                                              { selected }
                                            ) => (
                                              <li {...props}>
                                                <Checkbox
                                                  checked={selected}
                                                  onClick={() =>
                                                    handleToggleIccid(option)
                                                  }
                                                />
                                                {option}
                                              </li>
                                            )}
                                            isOptionEqualToValues={(
                                              option,
                                              value
                                            ) => option === value}
                                            filterOptions={(
                                              options,
                                              { inputValue }
                                            ) => {
                                              if (!inputValue) {
                                                return options;
                                              }

                                              const regex = new RegExp(
                                                inputValue,
                                                "i"
                                              );
                                              return options.filter((option) =>
                                                regex.test(option)
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="SelectionIccid"
                                    style={{
                                      width: "50%",
                                      height: "200px",
                                      padding: "10px",
                                      backgroundColor: "white",
                                      borderRadius: "10px",
                                      border: "1px #2d3436 solid",
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.1) - 4px 9px 25px - 6px",
                                      marginLeft: "20px",
                                      // overflow: "auto",
                                    }}
                                  >
                                    <H4
                                      style={{
                                        padding: "0px",
                                        color: "#636e72",
                                      }}
                                    >
                                      <i>These are the Selected SIM :</i>
                                    </H4>
                                    {(selectedEndIccid ||
                                      selectedIccids.length > 0) && (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          margin: "10px 0px 0px 5px",
                                          overflow: "auto",
                                          height: "150px",
                                        }}
                                      >
                                        {[
                                          ...selectedICCIDs,
                                          ...selectedIccids,
                                        ].map((iccid, index) => (
                                          <Chip
                                            key={index}
                                            label={iccid}
                                            onDelete={() =>
                                              handleRemoveICCID(iccid)
                                            }
                                            color="primary"
                                            variant="outlined"
                                            style={{
                                              marginRight: "8px",
                                              marginBottom: "8px",
                                            }}
                                          />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  style={{
                                    padding: "10px 4px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Button
                                    onClick={handleSkip}
                                    style={{ color: "#8224e3" }}
                                    variant="outlined"
                                    color="inherit"
                                  >
                                    Skip
                                  </Button>

                                  <Button
                                    onClick={handleSave}
                                    style={{
                                      color: "#8224e3",
                                      marginLeft: "20px",
                                    }}
                                    variant="outlined"
                                    color="inherit"
                                  >
                                    Assign SIM
                                  </Button>
                                </div>
                              </div>
                            </Box>
                          </ValidatorForm>
                        </>
                      </Container>
                    )}
                    {stepIndex === 3 && (
                      <p style={{ textAlign: "center" }}>
                        Client Created Successfully! Welcome to Deplofy !
                      </p>
                    )}
                  </Typography>

                  <Box pt={2}>
                    {stepIndex === 0 && (
                      <Button
                        sx={{ ml: 2, mt: 1 }}
                        variant="outlined"
                        color="inherit"
                        style={{ color: "#8224e3" }}
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    )}
                    {stepIndex === 1 && (
                      <>
                        <Button
                          sx={{ ml: 3, mt: 1 }}
                          variant="outlined"
                          color="inherit"
                          style={{ color: "#8224e3" }}
                          onClick={handlePrev}
                        >
                          Previous
                        </Button>

                        <Button
                          sx={{ ml: 5, mt: 1 }}
                          variant="outlined"
                          color="inherit"
                          style={{ color: "#8224e3" }}
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      </>
                    )}
                   
                   
                    {stepIndex === 3 && (
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        color="inherit"
                        style={{ color: "#8224e3" }}
                        onClick={() => handleFinishClick()}
                      >
                        Finish
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </SimpleCard>
      </div>
    </AppButtonRoot>
  );
};

export default CreateSupervisor;
