import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { TextField, Grid } from "@mui/material";
//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Reports.css";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
//These import are for the reports download   -- Ends here

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { H2, H1, H3 } from "app/components/Typography";
import ExportReports from "../TenantReports/ExportReports";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const ActiveAccounts = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showNewUser, setShowNewUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const tenantUuid = localStorage.getItem("tenant_uuid");
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);

  const [firstNameVisible, setFirstNameVisible] = useState(true);
  const [lastNameVisible, setLastNameVisible] = useState(true);
  const [usernameVisible, setUsernameVisible] = useState(false);
  const [phoneVisible, setPhoneVisible] = useState(true);
  const [emailidVisible, setEmailidVisible] = useState(true);
  const [maxTenantLimitVisible, setmaxTenantLimitVisible] = useState(false);
  const [totalSimsVisible, setTotalSimsVisibleVisible] = useState(true);
  const [countryVisible, setCountryVisible] = useState(true);
  const [timeZoneVisible, setTimeZoneVisible] = useState(false);
  const [simsAllocatedVisible, setSimsAllocatedVisible] = useState(true);
  const [columnVisible, setColumnVisible] = useState(true);
  const [TenantsFilter, setTenantsFilter] = useState("");
  const [DurationFilter, setDurationFilter] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");
  const [activeTenants, setActiveTenants] = useState([]);
  const [iccids, setIccids] = useState([]);

  const [format, setFormat] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("FirstName");
  const [order, setOrder] = useState("asc");

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s", // Adjust the duration as needed
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredAndSortedTenants = activeTenants
    .filter((dataItem) => {
      const fullName = `${dataItem.FirstName} ${dataItem.LastName} ${dataItem.EmailId} ${dataItem.Country} ${dataItem.PhoneNumber} ${dataItem.MaxTenantLimit}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const isAsc = order === "asc";
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == true) {
      setFirstNameVisible(true);
      setLastNameVisible(true);
      setUsernameVisible(true);
      setPhoneVisible(true);
      setEmailidVisible(true);
      setmaxTenantLimitVisible(true);
      setTotalSimsVisibleVisible(true);
      setCountryVisible(true);
      setTimeZoneVisible(true);
      setSimsAllocatedVisible(true);
    } else if (columnVisible == false) {
      setLastNameVisible(false);
      setUsernameVisible(false);
      setPhoneVisible(false);
      setEmailidVisible(false);
      setmaxTenantLimitVisible(false);
      setTotalSimsVisibleVisible(false);
      setCountryVisible(false);
      setTimeZoneVisible(false);
      setSimsAllocatedVisible(false);
    }
  };

  const handleToggleFirstNameSwitch = () => {
    setFirstNameVisible(!firstNameVisible);
  };
  const handleToggleLastNameSwitch = () => {
    setLastNameVisible(!lastNameVisible);
  };
  const handleToggleUsernameSwitch = () => {
    setUsernameVisible(!usernameVisible);
  };
  const handlePhoneSwitch = () => {
    setPhoneVisible(!phoneVisible);
  };
  const handleEmailIdSwitch = () => {
    setEmailidVisible(!emailidVisible);
  };
  const handleMaxTenantLimitSwitch = () => {
    setmaxTenantLimitVisible(!maxTenantLimitVisible);
  };
  const handleToggleTotalSimsSwitch = () => {
    setTotalSimsVisibleVisible(!totalSimsVisible);
  };
  const handleToggleCountrySwitch = () => {
    setCountryVisible(!countryVisible);
  };
  const handleToggleTimeZoneSwitch = () => {
    setTimeZoneVisible(!timeZoneVisible);
  };
  const handleAllocatedSimsSwitch = () => {
    setSimsAllocatedVisible(!simsAllocatedVisible);
  };

  useEffect(() => {
    const fetchActiveReport = async () => {
      try {
        var requestOptions = {
          method: "POST",
          redirect: "follow",
        };

        const response = await fetch(
          `${REACT_APP_BASE_URL}/report/api/v1/active_accounts_reports`,
          requestOptions
        );
        const result = await response.json();
        if (result.Status === 1) {
          setActiveTenants(result.AccountReport.active_tenants);
          setIccids(result.AccountReport.sims[0].iccids[0]);
        } else {
        }
      } catch (error) {
      }
    };

    fetchActiveReport();
  }, []);

  //These Functions are for the reports download   -- Starts here
  const noDataReport = () => {
    toast("No data to Export", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  const generatePDF = () => {
    const table = document.getElementById("Table-Data");
    if (table && table.rows.length > 2) {
      const report = new jsPDF("portrait", "mm", "a4");
      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("Active_Report.pdf");
    } else {
      toast.error("No data available for export.");
    }
  };

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    if (table && table.rows.length > 2) {
      const ws = XLSX.utils.table_to_sheet(table);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Active_Report" + fileExtension);
    } else {
      toast.error("No data available for export.");
    }
  };



  const TenantDetails = (TenantUUID) => {
    localStorage.setItem("TenantUUID", TenantUUID);
    navigate("/tenant/tenantdetails");
  };

  return (
    <Container>
      <Box
        className="breadcrumb"
        style={{ display: "flex", margin: "20px 0px" }}
      >
        <H2>Reports | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          Active Account Reports
        </p>

   

        <Tooltip title="Apply column filter" arrow>
          <Button
            style={{ color: "#8224e3", marginLeft: "auto" }}
            variant="outlined"
            color="inherit"
            onClick={handleButtonClick}
          >
            <Icon color="#8224e3">filter_alt_outlined</Icon>
          </Button>
        </Tooltip>
      </Box>
      <Collapse in={open}>
        <div
          style={{
            backgroundColor: "white",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: "20px",
            marginBottom: "20px",
            boxShadow: "rgba(0, 0, 0, 0.1) -4px 9px 25px -6px",
          }}
        >
          <div
            style={{
              padding: "10px",
              margin: "10px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div className="inputInfoBox">
              <p id="inputInfoTopText">Tenant</p>
              <Select
                value={TenantsFilter}
                onChange={(e) => setTenantsFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Tenants--</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Start Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: "39px", margin: "0px", width: "100%" }}
              />
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">End Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: "39px", margin: "0px", width: "100%" }}
              />
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Duration</p>
              <Select
                value={DurationFilter}
                onChange={(e) => setDurationFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Duration--</MenuItem>
                <MenuItem value={10}>Daily</MenuItem>
                <MenuItem value={20}>Weekly</MenuItem>
                <MenuItem value={30}>Annual</MenuItem>
              </Select>
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Select Format</p>
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value={10}>excel</MenuItem>
                <MenuItem value={20}>csv</MenuItem>
              </Select>
            </div>
          </div>
          <div>
            <Button
              style={{
                marginLeft: "27px",
                marginBottom: "15px",
                color: "green",
              }}
              variant="outlined"
              color="inherit"
            // onClick={handleCancel}
            >
              Search
            </Button>
          </div>
        </div>
      </Collapse>

      <Container
        style={{
          ...containerStyle,
          display: animateContainer ? "block" : "none",
          position: "absolute",
          top: "25%",
          left: "25%",
          transform: "translate(-25%,-25%)",
          zIndex: "100",
        }}
      >
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              backgroundColor: " rgb(242 242 242 / 100%)",
              marginLeft: "10%",
              animation: "fadeIn 1.5s ease-in-out",
            }}
          >
            <div
              style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
            >
              <H2
                style={{ margin: "10px", padding: "0px", marginRight: "10px" }}
              >
                Columns
                <Switch
                  color="primary"
                  checked={columnVisible}
                  onChange={handleToggleColumnSwitch}
                />
              </H2>
              <hr />
            </div>

            <div
              style={{ padding: "5px", display: "flex", justifyContent: 'space-evenly', marginLeft: "20px" }}
            >
              <p
                style={{               
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                First Name
                <Switch
                  color="primary"
                  checked={firstNameVisible}
                  onChange={handleToggleFirstNameSwitch}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Last Name
                <Switch
                  color="primary"
                  checked={lastNameVisible}
                  onChange={handleToggleLastNameSwitch}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Tenant Username
                <Switch
                  color="primary"
                  checked={usernameVisible}
                  onChange={handleToggleUsernameSwitch}
                />
              </p>

            </div>

            <div
              style={{ padding: "5px", display: "flex", justifyContent: 'space-evenly', marginLeft: "20px" }}
            >
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Phone Number
                <Switch
                  color="primary"
                  checked={phoneVisible}
                  onChange={handlePhoneSwitch}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Email ID
                <Switch
                  color="primary"
                  checked={emailidVisible}
                  onChange={handleEmailIdSwitch}
                />
              </p>
      
              <p
                style={{
                  
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Country
                <Switch
                  color="primary"
                  checked={countryVisible}
                  onChange={handleToggleCountrySwitch}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Time Zone
                <Switch
                  color="primary"
                  checked={timeZoneVisible}
                  onChange={handleToggleTimeZoneSwitch}
                />
              </p>
            </div>

            <hr />
            <div style={{ padding: "4px", marginLeft: "600px" }}>
              <Button
                style={{ color: "#8224e3" }}
                variant="outlined"
                color="inherit"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      </Container>

      <SimpleCard title="Active Account Reports">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
            alignItems: 'flex-end',
            marginTop: '-2px',
            // border: '2px solid green'

          }}
        >
          <input
            style={{
              height: "40px",
              width: "30%",
              border: "1px solid  lightgrey",
              paddingLeft: "10px",
              borderRadius: "5px",
              outline: "1px black lightgrey",
            }}
            name="search"
            placeholder="Search..."
            type="search"
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div
            className="inputInfoBox"
            style={{ width: "30%", margin: "0px" }}
          >
         

            {filteredAndSortedTenants.length > 0 ? (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value="excel" onClick={() => exportToExcel()}>
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={generatePDF} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            ) : (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem onClick={noDataReport} value="excel">
                  Export View Export
                </MenuItem>
                <MenuItem onClick={noDataReport} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            )}
          </div>
        </div>

        <Box width="100%" overflow="auto" style={{ marginTop: "20px" }}>
          <StyledTable id="Table-Data">
            <TableHead>
              <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
                {firstNameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor:'pointer'
                    }}
                    align="center"
                    onClick={() => handleSort("FirstName")}
                  > First Name
                    <TableSortLabel
                      active={orderBy === "FirstName"}
                      direction={orderBy === "FirstName" ? order : "asc"}
                     
                    >

                    </TableSortLabel>
                  </TableCell>
                )}
                {lastNameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Last Name
                  </TableCell>
                )}
                {usernameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Tenant Username
                  </TableCell>
                )}
                {phoneVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Phone Number
                  </TableCell>
                )}
                {emailidVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Email ID
                  </TableCell>
                )}
            
                {countryVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Country
                  </TableCell>
                )}

                {timeZoneVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    TimeZone
                  </TableCell>
                )}

              </TableRow>
            </TableHead>

            <TableBody>
              {filteredAndSortedTenants.length > 0 ? (
                filteredAndSortedTenants
                  .map((tenant, index) => (
                    <TableRow hover key={index}>
                      {firstNameVisible && (
                        <TableCell
                          style={{ cursor: "pointer" }}
                          align="center"
                          onClick={() => TenantDetails(tenant.TenantUUID)}
                        >
                          {tenant.FirstName}
                        </TableCell>
                      )}
                      {lastNameVisible && (
                        <TableCell align="center" style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>{tenant.LastName}</TableCell>
                      )}
                      {usernameVisible && (
                        <TableCell align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>{tenant.Username}</TableCell>
                      )}
                      {phoneVisible && (
                        <TableCell align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>
                          {tenant.PhoneNumber}
                        </TableCell>
                      )}
                      {emailidVisible && (
                        <TableCell align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>{tenant.EmailId}</TableCell>
                      )}
                  
                      {countryVisible && (
                        <TableCell align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>{tenant.Country}</TableCell>
                      )}
                      {timeZoneVisible && (
                        <TableCell align="center"
                        style={{ cursor: "pointer" }}
                        onClick={() => TenantDetails(tenant.TenantUUID)}>{tenant.Timezone}</TableCell>
                      )}
                    </TableRow>
                  ))
              ) : (
                <TableRow colSpan={5} >
                  <TableCell colSpan={5} align="center">
                    No  data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[7, 14, 25]}
            component="div"
            count={activeTenants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </SimpleCard>
    </Container >
  );
};

export default ActiveAccounts;
