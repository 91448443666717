import React from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import CreateTenant from "./CreateTenant";
import axios from "axios"; // Import Axios
import TablePagination from "@mui/material/TablePagination"; // Add TablePagination
import { H2, H1, H3 } from "app/components/Typography";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const InactiveTenants = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showNewTenant, setShowNewTenant] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [tenantDataLength, setTenantDataLength] = useState("0");
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(7); // Rows per page

  const [searchTerm, setSearchTerm] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("FirstName");
  const [order, setOrder] = useState("asc");
  const [selectedTenants, setSelectedTenants] = useState([]);
  const TenantUUID = localStorage.getItem("TenantUUID");

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedTenants(filterAndSortedDisabledTenants.map((tenant) => tenant.TenantUUID));
    } else {
      setSelectedTenants([]);
    }
  };

  const handleSelectTenant = (tenantUUID) => {
    setSelectedTenants((prevSelected) => {
      if (prevSelected.includes(tenantUUID)) {
        return prevSelected.filter((id) => id !== tenantUUID);
      } else {
        return [...prevSelected, tenantUUID];
      }
    });
  };

  const isSelected = (tenantUUID) => selectedTenants.includes(tenantUUID);
  const turnOnCredentials = () => {
    const commaSeperatedTenants = selectedTenants.join(",");

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/enable_tenant?TenantUUID=${commaSeperatedTenants}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          fetchTenants();
          setSelectedTenants([]);
        }
      })
      .catch((error) => {
      });
  };

  const turnOffCredentials = () => {
    const commaSeperatedTenants = selectedTenants.join(",");

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/disable_tenant?TenantUUID=${commaSeperatedTenants}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          fetchTenants();
          setSelectedTenants([]);
        }
      })
      .catch((error) => {
    });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };


  const filterAndSortedDisabledTenants = tenantData
    .filter((tenant) => tenant.Enabled === "false")
    .filter((tenant) => {
      const fullName =
        `${tenant.FirstName} ${tenant.LastName} ${tenant.EmailId} ${tenant.Country} ${tenant.PhoneNumber} ${tenant.Enabled} ${tenant.MaxTenantLimit}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const isAsc = order === "asc";
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    });

  useEffect(() => {
    // Fetch tenant data from the API and update the sta
    fetchTenants();
  }, []);

  const accessToken = localStorage.getItem("accessToken");
  const fetchTenants = () => {
    var raw = "";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/get_tenants`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTenantData(result.Account_details);
        setTenantDataLength(result.Account_details.length);
      })
      .catch((error) => {
      });
  };

  const handleChange = async (isEnabled, TenantUUID) => {
    if (isEnabled === "false") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        TenantUUID: TenantUUID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/account/api/v1/enable_tenant`,
          requestOptions
        );
        const result = await response.json();
        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 2 seconds (adjust as needed)
        });
        fetchTenants();
      } catch (error) {
      }
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        TenantUUID: TenantUUID,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/account/api/v1/disable_tenant`,
          requestOptions
        );
        const result = await response.json();

        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 2 seconds (adjust as needed)
        });
        fetchTenants();
      } catch (error) {
      }
    }

  };

  const handleButtonClick = () => {
    navigate("/tenants/add-Tenant");
  };

  const handleDeleteTenant = (TenantUUID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/delete_tenant?domain=${TenantUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Close the toast after 3 seconds (adjust as needed)
        });
        window.location.reload();
      })
      .catch((error) => {
    });
  };

  const handleEditTenant = (tenantUuid, emailId) => {
    localStorage.setItem("edit_tenant_uuid", tenantUuid);
    localStorage.setItem("edit_emailId", emailId);
    navigate("/tenant/edit-tenant");
  };

  const TenantDetails = (TenantUUID) => {
    localStorage.setItem("TenantUUID", TenantUUID);
    navigate("/tenant/tenantdetails");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        {showNewTenant ? (
          <CreateTenant />
        ) : (
          <>
            <Box className="breadcrumb" style={{ display: "flex" }}>
              <H2>Tenants | </H2>
              <p
                style={{
                  margin: "5px",
                  color: "#8224e3",
                }}
              >
                Disabled Tenants
              </p>

              {showNewTenant && <CreateTenant />}
            </Box>

            <SimpleCard title="Disabled Tenants">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 8px 10px 8px",
                  height: "90px",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "28%",
                    border: "1px solid  lightgrey",
                    paddingLeft: "10px",
                    borderRadius: "5px",
                    outline: "1px black lightgrey",
                  }}
                  name="search"
                  placeholder="Search..."
                  type="search"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={handleSearch}
                />

                {filterAndSortedDisabledTenants.length > 0 && (
                  <div style={{ width: "100" }}>
                    <p id="inputInfoTopText">
                      Action
                      <Tooltip title="Select an action" placement="top-start">
                        <IconButton onClick={handleClick}>
                          <Icon style={{ transform: "scale(.7)" }}>
                            format_list_bulleted
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </p>

                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={turnOnCredentials}>
                        Turn On Credentials
                      </MenuItem>

         
                    </Menu>
                  </div>
                )}
              </div>

              <Box width="100%" overflow="auto">
                <StyledTable>
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}
                    >
                      <TableCell
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingRight: "8px",
                        }}
                        align="center"
                      >
                        <Checkbox
                          indeterminate={
                            selectedTenants.length > 0 &&
                            selectedTenants.length < filterAndSortedDisabledTenants.length
                          }
                          checked={
                            filterAndSortedDisabledTenants.length > 0 
                              ? selectedTenants.length === filterAndSortedDisabledTenants.length
                              : false
                          }
                          onChange={handleSelectAll}
                          inputProps={{ "aria-label": "select all tenants" }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        align="center"
                        onClick={() => handleSort("FirstName")}
                      >
                        {" "}
                        First Name
                        <TableSortLabel
                          active={orderBy === "FirstName"}
                          direction={orderBy === "FirstName" ? order : "asc"}
                        ></TableSortLabel>
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Last Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Email ID
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Contact No
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Time Zone
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Client Limit
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterAndSortedDisabledTenants.length > 0 ? (
                      filterAndSortedDisabledTenants.map((tenant) => (
                        <TableRow hover key={tenant.id}>
                          <TableCell
                            style={{
                              cursor: "pointer",
                            }}
                            align="center"
                          >
                            <Checkbox
                              checked={isSelected(tenant.TenantUUID)}
                              onChange={() =>
                                handleSelectTenant(tenant.TenantUUID)
                              }
                              inputProps={{
                                "aria-label": `select tenant ${tenant.TenantUUID}`,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.FirstName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.LastName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.EmailId}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.PhoneNumber}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.Timezone}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                          >
                            {tenant.MaxTenantLimit}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "13px", cursor: "pointer" }}
                            onClick={() => TenantDetails(tenant.TenantUUID)}
                            align="center"
                          >
                            <Tooltip
                              title={
                                tenant.Enabled === "true"
                                  ? "Enabled"
                                  : "Disabled"
                              }
                            >
                              {tenant.Enabled === "true" ? (
                                <CheckCircleOutlineIcon color="primary" />
                              ) : (
                                <CancelIcon color="error" />
                              )}
                            </Tooltip>
                          </TableCell>

                          <TableCell align="center">
                            <IconButton
                              onClick={() =>
                                handleDeleteTenant(tenant.TenantUUID)
                              }
                              sx={{
                                ".text-effect": {
                                  position: "absolute",
                                  top: "50%",
                                  right: "100%",
                                  transform: "translateX(50%,-50%)",
                                  display: "none", // Initially hide the text
                                  textAlign: "center",
                                  width: "100%",
                                  border: "1px solid black",
                                  backgroundColor: "white",
                                },
                                "&:hover .text-effect": {
                                  display: "block", // Show the text when hovering over the icon
                                },
                                position: "relative",
                              }}
                            >
                              <Icon color="error">delete</Icon>
                              <Typography
                                variant="caption"
                                className="text-effect"
                              >
                                Delete
                              </Typography>
                            </IconButton>

                            <IconButton
                              onClick={() =>
                                handleEditTenant(
                                  tenant.TenantUUID,
                                  tenant.EmailId
                                )
                              }
                              sx={{
                                ".text-effect": {
                                  position: "absolute",
                                  top: "-21%",
                                  left: "20%",
                                  transform: "translateX(50%,-50%)",
                                  display: "none", // Initially hide the text
                                  textAlign: "center",
                                  width: "100%",
                                  border: "1px solid black",
                                  backgroundColor: "white",
                                },
                                "&:hover .text-effect": {
                                  display: "block", // Show the text when hovering over the icon
                                },
                                position: "relative",
                              }}
                            >
                              <Icon>edit</Icon>
                              <Typography
                                variant="caption"
                                className="text-effect"
                              >
                                Edit
                              </Typography>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          No Disabled Tenants available.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  rowsPerPageOptions={[7, 14, 25]}
                  component="div"
                  count={filterAndSortedDisabledTenants.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </SimpleCard>
          </>
        )}
      </Container>
    </>
  );
};

export default InactiveTenants;
