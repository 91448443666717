import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Box,
  Container,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Input,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { H2, H1, H3 } from 'app/components/Typography';

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  }
}));

const BillingCreateGroup = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showNewTenant, setShowNewTenant] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [name, setName] = useState('');
  const [totalsubgroup, setTotalSubgroup] = useState('')
  const [totalsim, setTotalSim] = useState('')

  const TenantUUID = localStorage.getItem('TenantUUID');
  let id = localStorage.getItem('id')

  const handleCreateGroup = () => {

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      totalSubGroups: totalsubgroup,
      totalSim: totalsim,
      inventoryId: id,
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_group`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
      })
      .catch((error) => {

      });
  };

  return (
    <>
      <Container style={{ padding: '10px' }}>
        <>
          <SimpleCard>
            <Box className="breadcrumb" style={{ display: 'grid', marginBottom: '22px' }}>
              <H2 style={{ fontSize: '22px', marginLeft: '8%' }}>Create Group</H2>
            </Box>

            <Box
              style={{
                alignItems: 'center',
                backgroundColor: 'white'
              }}
            >
              <div
                className="box1"
                style={{
                  width: '75%',
                  height: '100%',
                  padding: '10px',
                  backgroundColor: ' rgb(233 226 226 / 35%)',
                  borderRadius: '25px',
                  marginLeft: '10%',

                }}
              >
                <div style={{ padding: '0px', marginLeft: '10px', marginTop: '0px' }}>
                  <H3 style={{ margin: '10px', padding: '0px', marginRight: '10px' }}>
                    Create Group
                  </H3>
                  <hr />
                </div>
                <br />

                <div style={{ padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                  <H3
                    style={{
                      padding: '0px',
                      fontSize: '16px',
                    }}
                  >
                    Name
                  </H3>
                  <TextField
                    style={{ width: '50%', marginLeft: '89px' }}
                    name="name"
                    size="small"
                    placeholder="Enter the Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></TextField>
                </div>

                <div style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                  <H3
                    style={{
                      padding: '0px',
                      fontSize: '16px',
                      marginRight: '10px',
                    }}
                  >
                    SubGroups
                  </H3>
                  <TextField
                    style={{ width: '50%', marginLeft: '37px' }}
                    name="search"
                    size="small"
                    placeholder="SubGroups"
                    type="text"
                    onChange={(e) => setTotalSubgroup(e.target.value)}
                  ></TextField>

                </div>
                <div style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                  <H3
                    style={{
                      padding: '0px',
                      fontSize: '16px',
                    }}
                  >
                    eSIM Card
                  </H3>
                  <TextField
                    style={{ width: '50%', marginLeft: '89px' }}
                    name="search"
                    size="small"
                    placeholder="eSIM Card"
                    type="text"
                    onChange={(e) => setTotalSim(e.target.value)}
                  ></TextField>
                </div>
                <div style={{ padding: '4px', marginLeft: '351px' }}>
                  <Button
                    style={{ color: '#8224e3' }}
                    variant="outlined"
                    color="inherit"
                    onClick={handleCreateGroup}
                  >
                    Create Group
                  </Button>
                </div>
              </div>
            </Box>
          </SimpleCard>
        </>
      </Container>
    </>
  );
};

export default BillingCreateGroup;
