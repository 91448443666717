import React from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import CreateTenant from "./CreateTenant";
import axios from "axios"; // Import Axios
import TablePagination from "@mui/material/TablePagination"; // Add TablePagination
import { H2, H1, H3 } from "app/components/Typography";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const ActiveTenants = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showNewTenant, setShowNewTenant] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("FirstName");
  const [order, setOrder] = useState("asc");
  const [selectedTenants, setSelectedTenants] = useState([]);
  const TenantUUID = localStorage.getItem("TenantUUID");

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedTenants(filterAndSortedActiveTenants.map((tenant) => tenant.TenantUUID));
    } else {
      setSelectedTenants([]);
    }
  };

  const handleSelectTenant = (tenantUUID) => {
    setSelectedTenants((prevSelected) => {
      if (prevSelected.includes(tenantUUID)) {
        return prevSelected.filter((id) => id !== tenantUUID);
      } else {
        return [...prevSelected, tenantUUID];
      }
    });
  };

  const isSelected = (tenantUUID) => selectedTenants.includes(tenantUUID);

  const turnOnCredentials = () => {
    const commaSeperatedTenants = selectedTenants.join(",");

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/enable_tenant?TenantUUID=${commaSeperatedTenants}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          fetchTenants();
          setSelectedTenants([]);
        }
      })
  };

  const turnOffCredentials = () => {
    const commaSeperatedTenants = selectedTenants.join(",");

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/disable_tenant?TenantUUID=${commaSeperatedTenants}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          fetchTenants();
          setSelectedTenants([]);
        }
      })
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterAndSortedActiveTenants = tenantData
    .filter((tenant) => tenant.Status === "Logged In")
    .filter((tenant) => {
      const fullName =
        `${tenant.FirstName} ${tenant.LastName} ${tenant.EmailId} ${tenant.Country} ${tenant.PhoneNumber} ${tenant.Enabled} ${tenant.MaxTenantLimit}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const isAsc = order === "asc";
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    });

  const accessToken = localStorage.getItem("accessToken");

  const fetchTenants = () => {
    var raw = "";
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/get_tenants`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTenantData(result.Account_details);
      })
  };

  useEffect(() => {
    // Fetch tenant data from the API and update the sta
    fetchTenants();
  }, []);

  const handleDeleteTenant = (TenantUUID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/delete_tenant?domain=${TenantUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Close the toast after 3 seconds (adjust as needed)
        });
        window.location.reload();
      })
  };

  const handleEditTenant = (tenantUuid, emailId) => {
    localStorage.setItem("edit_tenant_uuid", tenantUuid);
    localStorage.setItem("edit_emailId", emailId);
    navigate("/tenant/edit-tenant");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // These states and functions are for showing and hiding the actions buttons
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Container>
        {showNewTenant ? (
          <CreateTenant />
        ) : (
          <>
            <Box className="breadcrumb" style={{ display: "flex" }}>
              <H2>Tenants | </H2>
              <p
                style={{
                  margin: "5px",
                  color: "#8224e3",
                }}
              >
                Active Tenants
              </p>

              {showNewTenant && <CreateTenant />}
            </Box>

            <SimpleCard title="Active Tenants">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 8px 10px 8px",
                  height: "90px",
                }}
              >
                <input
                  style={{
                    height: "40px",
                    width: "28%",
                    border: "1px solid  lightgrey",
                    paddingLeft: "10px",
                    borderRadius: "5px",
                    outline: "1px black lightgrey",
                  }}
                  name="search"
                  placeholder="Search..."
                  type="search"
                  autoComplete="off"
                  value={searchTerm}
                  onChange={handleSearch}
                />

                {filterAndSortedActiveTenants.length > 0 && (
                  <div style={{ width: "100" }}>
                    <p id="inputInfoTopText">
                      Action
                      <Tooltip title="Select an action" placement="top-start">
                        <IconButton onClick={handleClick}>
                          <Icon style={{ transform: "scale(.7)" }}>
                            format_list_bulleted
                          </Icon>
                        </IconButton>
                      </Tooltip>
                    </p>

                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={turnOnCredentials}>
                        Turn On Credentials
                      </MenuItem>

                      <MenuItem onClick={turnOffCredentials}>
                        Turn Off Credentials
                      </MenuItem>

                    </Menu>
                  </div>
                )}
              </div>
              <Box width="100%" overflow="auto">
                <StyledTable>
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}
                    >
                      <TableCell
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingRight: "8px",
                        }}
                        align="center"
                      >
                        <Checkbox
                          indeterminate={
                            selectedTenants.length > 0 &&
                            selectedTenants.length <
                            filterAndSortedActiveTenants.length
                          }
                          checked={
                            filterAndSortedActiveTenants.length > 0
                              ? selectedTenants.length ===
                              filterAndSortedActiveTenants.length
                              : false
                          }
                          onChange={handleSelectAll}
                          inputProps={{ "aria-label": "select all tenants" }}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        align="center"
                        onClick={() => handleSort("FirstName")}
                      >
                        First Name
                        <TableSortLabel
                          active={orderBy === "FirstName"}
                          direction={orderBy === "FirstName" ? order : "asc"}
                        ></TableSortLabel>
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Last Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Email ID
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Contact No
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Time Zone
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Client Limit
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filterAndSortedActiveTenants.length > 0 ? (
                      filterAndSortedActiveTenants
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        .map((tenant) => (
                          <TableRow hover key={tenant.id}>
                            <TableCell
                              style={{
                                cursor: "pointer",
                              }}
                              align="center"
                            >
                              <Checkbox
                                checked={isSelected(tenant.TenantUUID)}
                                onChange={() =>
                                  handleSelectTenant(tenant.TenantUUID)
                                }
                                inputProps={{
                                  "aria-label": `select tenant ${tenant.TenantUUID}`,
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              {tenant.FirstName}
                            </TableCell>
                            <TableCell align="center">
                              {tenant.LastName}
                            </TableCell>
                            <TableCell align="center">
                              {tenant.EmailId}
                            </TableCell>
                            <TableCell align="center">
                              {tenant.PhoneNumber}
                            </TableCell>
                            <TableCell align="center">
                              {tenant.Timezone}
                            </TableCell>

                            <TableCell align="center">
                              {tenant.MaxTenantLimit}
                            </TableCell>

                            <TableCell
                              style={{ fontSize: "13px" }}
                              align="center"
                            >
                              <Tooltip
                                title={
                                  tenant.Enabled === "true"
                                    ? "Enabled"
                                    : "Disabled"
                                }
                              >
                                {tenant.Enabled === "true" ? (
                                  <CheckCircleOutlineIcon color="primary" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </Tooltip>
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                onClick={() =>
                                  handleDeleteTenant(tenant.TenantUUID)
                                }
                                sx={{
                                  ".text-effect": {
                                    position: "absolute",
                                    top: "50%",
                                    right: "100%",
                                    transform: "translateX(50%,-50%)",
                                    display: "none", // Initially hide the text
                                    textAlign: "center",
                                    width: "100%",
                                    border: "1px solid black",
                                    backgroundColor: "white",
                                  },
                                  "&:hover .text-effect": {
                                    display: "block", // Show the text when hovering over the icon
                                  },
                                  position: "relative",
                                }}
                              >
                                <Icon color="error">delete</Icon>
                                <Typography
                                  variant="caption"
                                  className="text-effect"
                                >
                                  Delete
                                </Typography>
                              </IconButton>

                              <IconButton
                                onClick={() =>
                                  handleEditTenant(
                                    tenant.TenantUUID,
                                    tenant.EmailId
                                  )
                                }
                                sx={{
                                  ".text-effect": {
                                    position: "absolute",
                                    top: "-21%",
                                    left: "20%",
                                    transform: "translateX(50%,-50%)",
                                    display: "none", // Initially hide the text
                                    textAlign: "center",
                                    width: "100%",
                                    border: "1px solid black",
                                    backgroundColor: "white",
                                  },
                                  "&:hover .text-effect": {
                                    display: "block", // Show the text when hovering over the icon
                                  },
                                  position: "relative",
                                }}
                              >
                                <Icon>edit</Icon>
                                <Typography
                                  variant="caption"
                                  className="text-effect"
                                >
                                  Edit
                                </Typography>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No Active Tenants available.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
              </Box>
              <TablePagination
                rowsPerPageOptions={[5, 14, 25]}
                component="div"
                count={filterAndSortedActiveTenants.length} // Total number of rows
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </SimpleCard>
          </>
        )}
      </Container>
    </>
  );
};

export default ActiveTenants;
