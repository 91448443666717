import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import "./CDR.css";
//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
//These import are for the reports download   -- Ends here
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { H2, H1, H3 } from "app/components/Typography";
import { TextField, Grid } from "@mui/material";


const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const Inactive_accounts = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  // These inputs are for the Filter ==
  const [ClientsFilter, setClientsFilter] = useState("");
  const [DurationFilter, setDurationFilter] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");


  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);

  // SWITCH Visible datas in table ...
  const [allVisible, setAllVisible] = useState(false);

  const [FirstNameVisible, setFirstNameVisible] = useState(true);
  const [LastNameVisible, setLastNameVisible] = useState(true);
  const [Phonevisible, setPhonevisible] = useState(true);
  const [EmailVisible, setEmailVisible] = useState(true);
  const [TotalSimVisible, setTotalSimVisible] = useState(true);
  const [TimeZoneVisible, setTimeZoneVisible] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("FirstName");
  const [order, setOrder] = useState("asc");

  const [ReportData, setReportData] = useState([]);
  const [ReportDataLength, setReportDataLength] = useState(0);

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s", // Adjust the duration as needed
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredAndSortedTenants = ReportData.filter((data) => {
    const fullName =
      `${data.FirstName} ${data.LastName} ${data.TotalSims} ${data.EmailId} ${data.Country} ${data.PhoneNumber} ${data.MaxTenantLimit}`.toLowerCase();
    return fullName.includes(searchTerm.toLowerCase());
  }).sort((a, b) => {
    const isAsc = order === "asc";
    return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);


  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleAllVisible = () => {
    setAllVisible(!allVisible);
    if (allVisible == true) {
      setFirstNameVisible(true);
      setLastNameVisible(true);
      setPhonevisible(true);
      setEmailVisible(true);
      setTotalSimVisible(true);
      setTimeZoneVisible(true);
    } else if (allVisible == false) {
      setFirstNameVisible(false);
      setLastNameVisible(false);
      setPhonevisible(false);
      setEmailVisible(false);
      setTotalSimVisible(false);
      setTimeZoneVisible(false);
    }
  };

  const handleFirstNameVisible = () => {
    setFirstNameVisible(!FirstNameVisible);
  };
  const handleLastNameVisible = () => {
    setLastNameVisible(!LastNameVisible);
  };
  const handlePhonevisible = () => {
    setPhonevisible(!Phonevisible);
  };
  const handleEmailVisible = () => {
    setEmailVisible(!EmailVisible);
  };
  const handleTotalSimVisible = () => {
    setTotalSimVisible(!TotalSimVisible);
  };
  const handleTimeZoneVisible = () => {
    setTimeZoneVisible(!TimeZoneVisible);
  };

  //These Functions are for the reports download   -- Starts here
  const noDataReport = () => {
    toast("No data to Export", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };
  // This is for the PDF format
  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("InActive_Reports.pdf");
    } else {
      toast.error("No data available for export.");
    }
  };

  // This is for the Excel format
  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    if (table && table.rows.length > 1) {
      const ws = XLSX.utils.table_to_sheet(table);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "Active_Report" + fileExtension);
    } else {
      toast.error("No data available for export.");
    }
  };


  const fetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "TenantUUID": TenantUUID
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${REACT_APP_BASE_URL}/report/api/v1/inactive_clients_report`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setReportData(result.AccountReport.inactive_clients);
        setReportDataLength(result.AccountReport.inactive_clients.length);
      })
  }

  useEffect(() => {
    fetchData();
  }, []);
  //These Functions are for the reports download   -- Ends here

  return (
    <Container>
      <Box
        className="breadcrumb"
        style={{ display: "flex", margin: "20px 0px" }}
      >
        <H2>Reports | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          Inactive Account Reports
        </p>

        <Tooltip title="Apply column filter" arrow>
          <Button
            style={{ color: "#8224e3", marginLeft: "auto" }}
            variant="outlined"
            color="inherit"
            onClick={handleButtonClick}
          >
            <Icon color="#8224e3">filter_alt_outlined</Icon>
          </Button>
        </Tooltip>
      </Box>

      <Collapse in={open}>
        <div
          style={{
            backgroundColor: "white",
            alignItems: "center",
            borderRadius: "10px",
            marginTop: "20px",
            marginBottom: "20px",
            boxShadow: "rgba(0, 0, 0, 0.1) -4px 9px 25px -6px",
          }}
        >
          <div
            style={{
              padding: "10px",
              margin: "10px",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div className="inputInfoBox">
              <p id="inputInfoTopText">Clients</p>
              <Select
                value={ClientsFilter}
                onChange={(e) => setClientsFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Clients--</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Start Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: "39px", margin: "0px", width: "100%" }}
              />
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">End Date & Time *</p>
              <TextField
                id="start-datetime"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ height: "39px", margin: "0px", width: "100%" }}
              />
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Duration</p>
              <Select
                value={DurationFilter}
                onChange={(e) => setDurationFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Duration--</MenuItem>
                <MenuItem value={10}>Daily</MenuItem>
                <MenuItem value={20}>Weekly</MenuItem>
                <MenuItem value={30}>Annual</MenuItem>
              </Select>
            </div>

            <div className="inputInfoBox">
              <p id="inputInfoTopText">Select Format</p>
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value={10}>excel</MenuItem>
                <MenuItem value={20}>csv</MenuItem>
              </Select>
            </div>
          </div>
          <div>
            <Button
              style={{
                marginLeft: "27px",
                marginBottom: "15px",
                color: "green",
              }}
              variant="outlined"
              color="inherit"
            >
              Search
            </Button>
          </div>
        </div>
      </Collapse>

      <Container
        style={{
          ...containerStyle,
          display: animateContainer ? "block" : "none",
          position: "absolute",
          top: "25%",
          left: "25%",
          transform: "translate(-25%,-25%)",
          zIndex: "100",
        }}
      >
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              backgroundColor: " rgb(233 226 226 / 100%)",
              borderRadius: "25px",
              marginLeft: "15%",
              animation: "fadeIn 1s ease-in-out",
            }}
          >
            <div
              style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
            >
              <H2
                style={{ margin: "10px", padding: "0px", marginRight: "10px" }}
              >
                Columns
                <Switch
                  color="primary"
                  checked={!allVisible}
                  onChange={handleAllVisible}
                />
              </H2>
              <hr />
            </div>

            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-evenly",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                First Name
                <Switch
                  color="primary"
                  checked={FirstNameVisible}
                  onChange={handleFirstNameVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Last Name
                <Switch
                  color="primary"
                  checked={LastNameVisible}
                  onChange={handleLastNameVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Phone Number
                <Switch
                  color="primary"
                  checked={Phonevisible}
                  onChange={handlePhonevisible}
                />
              </p>
            </div>

            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-evenly",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Email ID
                <Switch
                  color="primary"
                  checked={EmailVisible}
                  onChange={handleEmailVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Total SIM
                <Switch
                  color="primary"
                  checked={TotalSimVisible}
                  onChange={handleTotalSimVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Time Zone
                <Switch
                  color="primary"
                  checked={TimeZoneVisible}
                  onChange={handleTimeZoneVisible}
                />
              </p>
            </div>
            <hr />
            <div style={{ padding: "4px", marginLeft: "500px" }}>
              <Button
                style={{ color: "Red" }}
                variant="outlined"
                color="error"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      </Container>

      <SimpleCard title="Inactive Account Reports">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
            alignItems: "flex-end",
            marginTop: "-28px",
          }}
        >
          <input
            style={{
              height: "40px",
              width: "30%",
              border: "1px solid  lightgrey",
              paddingLeft: "10px",
              borderRadius: "5px",
              outline: "1px black lightgrey",
            }}
            name="search"
            placeholder="Search..."
            type="search"
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
          />

          <div className="inputInfoBox" style={{ width: "30%", margin: "0px" }}>
            <p id="inputInfoTopText">
              Select Format
              <Tooltip
                title="Select a format to download the report"
                placement="top-start"
              >
                <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
              </Tooltip>
            </p>
            {ReportDataLength > 0 ? (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value="excel" onClick={() => exportToExcel()}>
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={generatePDF} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            ) : (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem onClick={noDataReport} value="excel">
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={noDataReport} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            )}
          </div>
        </div>

        <Box width="100%" overflow="auto" style={{ marginTop: "20px" }}>
          <StyledTable id="Table-Data">
            <TableHead>
              <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
                {FirstNameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: 'pointer'
                    }}
                    align="center"
                    onClick={() => handleSort("FirstName")}
                  >
                    First Name
                    <TableSortLabel
                      active={orderBy === "FirstName"}
                      direction={orderBy === "FirstName" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {LastNameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Last Name
                  </TableCell>
                )}

                {Phonevisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Phone Number
                  </TableCell>
                )}

                {EmailVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Email ID
                  </TableCell>
                )}

                {TimeZoneVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Time Zone
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSortedTenants.length > 0 ? (
                filteredAndSortedTenants
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <TableRow key={index}>
                      {FirstNameVisible && (
                        <TableCell TableCell align="center">
                          {data.FirstName}
                        </TableCell>
                      )}
                      {LastNameVisible && (
                        <TableCell align="center">{data.LastName}</TableCell>
                      )}
                      {Phonevisible && (
                        <TableCell align="center">{data.PhoneNumber}</TableCell>
                      )}
                      {EmailVisible && (
                        <TableCell align="center">{data.EmailId}</TableCell>
                      )}

                      {TimeZoneVisible && (
                        <TableCell align="center">{data.Timezone}</TableCell>
                      )}
                    </TableRow>
                  ))
              ) : (
                <TableRow colSpan={5}>
                  <TableCell colSpan={5} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredAndSortedTenants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </SimpleCard>
    </Container>
  );
};

export default Inactive_accounts;
