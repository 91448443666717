import React, { useState, useEffect } from "react";
import { Fab, IconButton, Input } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Tooltip } from "@mui/material";
import { countries } from "countries-list";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Table,
  Avatar,
  Radio,
  Box,
  TextField,
  RadioGroup,
  FormControl,
  Typography,
  InputLabel,
  Collapse,
  MenuItem,
  Container,
  Select,
  styled,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { H2, H1 } from "app/components/Typography";
import { padding } from "@mui/system";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const UserSettings = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const EmailId = localStorage.getItem("EmailId");
  const accessToken = localStorage.getItem("accessToken");

  const [FirstName, setFirstName] = useState(
    localStorage.getItem("FirstName") || ""
  );
  const [LastName, setLastName] = useState(
    localStorage.getItem("LastName") || ""
  );
  const createdAt = localStorage.getItem("createdAt");
  const AccountType = localStorage.getItem("AccountType");
  const DomainUUID = localStorage.getItem("DomainUUID");
  const TenantUUID = localStorage.getItem("TenantUUID");
  const UserUUID = localStorage.getItem("UserUUID");
  const UserId = localStorage.getItem("UserId");

  const [countryList, setCountryList] = useState([]);

  // This is for the current address
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // This is for the Shipping Address
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingPostalCode, setShippingPostalCode] = useState("");

  const [open, setOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));

  countryOptions.sort((a, b) => a.label.localeCompare(b.label));


  //  Function for getting local time zone automatically
  const getUserTimeZone = () => {
    return fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const userTimeZone = data.timezone;
        return userTimeZone;
      })
      .catch((error) => {
        // Return a default time zone or handle the error as needed
        return "UTC";
      });
  };

  // Function for conversion of epoch time to local time
  const convertEpochToLocalTime = (epochTime) => {
    return getUserTimeZone()
      .then((selectedCountry) => {
        const date = new Date(epochTime * 1000);

        const formattedTime = date.toLocaleString("en-US", {
          timeZone: selectedCountry,
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          // timeZoneName: 'short'
        });

        return formattedTime;
      })
      .catch((error) => {
        return "Error";
      });
  };

  // getting epoch time from local storage and converting it to local time
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const createdAt = localStorage.getItem("createdAt");

      try {
        const formattedDate = await convertEpochToLocalTime(createdAt);
        setFormattedDate(formattedDate);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  // Function for fetching the country list from the API
  useEffect(() => {
    fetch(`${REACT_APP_BASE_URL}/user/api/v1/countrylist`)
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === 1) {
          setCountryList(data.CountryList);
        }
      })
  }, []);

  // Function for getting the details of TENANT for pre filling data (get tenant api)
  const fetchAddressTenant = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("Authorization", `Bearer ${accessToken}`);


    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/get_tenant?domain=${TenantUUID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPhoneNumber(result.Account_details[0].PhoneNumber);
        setEmail(result.Account_details[0].EmailId);
        setPassword(result.Account_details[0].Password);
        setAddressLine1(result.Account_details[0].AddressLine1);
        setAddressLine2(result.Account_details[0].AddressLine2);
        setAddressLine3(result.Account_details[0].AddressLine3);
        setSelectedCountry(result.Account_details[0].Country);
        setState(result.Account_details[0].State);
        setCity(result.Account_details[0].City);
        setPostalCode(result.Account_details[0].PostalCode);
        setShippingAddress1(result.Account_details[0].ShippingAddress1);
        setShippingAddress2(result.Account_details[0].ShippingAddress2);
        setShippingAddress3(result.Account_details[0].ShippingAddress3);
        setShippingCountry(result.Account_details[0].ShippingCountry);
        setShippingState(result.Account_details[0].ShippingState);
        setShippingCity(result.Account_details[0].ShippingCity);
        setShippingPostalCode(result.Account_details[0].ShippingPostalCode);
      })
  };

  // Function for getting the details of ADMIN for pre filling data (get tenant api)
  const fetchAddressAdmin = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/admin/api/v1/get_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPhoneNumber(result.Account_details[0].PhoneNumber);
        setEmail(result.Account_details[0].EmailId);
        setPassword(result.Account_details[0].Password);
        setAddressLine1(result.Account_details[0].AddressLine1);
        setAddressLine2(result.Account_details[0].AddressLine2);
        setAddressLine3(result.Account_details[0].AddressLine3);
        setSelectedCountry(result.Account_details[0].Country);
        setState(result.Account_details[0].State);
        setCity(result.Account_details[0].City);
        setPostalCode(result.Account_details[0].PostalCode);
        setShippingAddress1(result.Account_details[0].ShippingAddress1);
        setShippingAddress2(result.Account_details[0].ShippingAddress2);
        setShippingAddress3(result.Account_details[0].ShippingAddress3);
        setShippingCountry(result.Account_details[0].ShippingCountry);
        setShippingState(result.Account_details[0].ShippingState);
        setShippingCity(result.Account_details[0].ShippingCity);
        setShippingPostalCode(result.Account_details[0].ShippingPostalCode);
      })
  };

  // Function for getting the details of CLIENT for pre filling data (get tenant api)
  const fetchAddressClient = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserUUID: UserUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/user/api/v1/get_client`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPhoneNumber(result.Account_details.get_user[0].PhoneNumber);
        setEmail(result.Account_details.get_user[0].EmailId);
        setPassword(result.Account_details.get_user[0].Password);
        setAddressLine1(result.Account_details.get_user[0].AddressLine1);
        setAddressLine2(result.Account_details.get_user[0].AddressLine2);
        setAddressLine3(result.Account_details.get_user[0].AddressLine3);
        setSelectedCountry(result.Account_details.get_user[0].Country);
        setState(result.Account_details.get_user[0].State);
        setCity(result.Account_details.get_user[0].City);
        setPostalCode(result.Account_details.get_user[0].PostalCode);
        setShippingAddress1(
          result.Account_details.get_user[0].ShippingAddress1
        );
        setShippingAddress2(
          result.Account_details.get_user[0].ShippingAddress2
        );
        setShippingAddress3(
          result.Account_details.get_user[0].ShippingAddress3
        );
        setShippingCountry(result.Account_details.get_user[0].ShippingCountry);
        setShippingState(result.Account_details.get_user[0].ShippingState);
        setShippingCity(result.Account_details.get_user[0].ShippingCity);
        setShippingPostalCode(
          result.Account_details.get_user[0].ShippingPostalCode
        );
      })
  };

  // this is for calling update functions according to account type
  useEffect(() => {
    if (AccountType === "Admin") {
      fetchAddressAdmin();
    }
    if (AccountType === "Tenant") {
      fetchAddressTenant();
    }
    if (AccountType === "Client") {
      fetchAddressClient();
    }
  }, []);

  const updateAddressAdmin = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if (
      !addressLine1 ||
      !addressLine2 ||
      !selectedCountry ||
      !state ||
      !city ||
      !postalCode ||
      !shippingAddress1 ||
      !shippingAddress2 ||
      !shippingCountry ||
      !shippingState ||
      !shippingCity ||
      !shippingPostalCode
    ) {
      // Handle validation error, show a message, or prevent proceeding to the next step
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var raw = JSON.stringify({
      DomainUUID: DomainUUID,
      FirstName: FirstName,
      LastName: LastName,
      PhoneNumber: phoneNumber,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      Password: password,
      EmailId: email,
      MaxTenantLimit: "",
      TotalSims: "",
      ActiveSims: "",
      SuspendedSims: "",
      MTDUsage: "",
      PrepaidBalance: "",
      OutstandingBalace: "",
      PastDueBalance: "",
      Country: selectedCountry,
      City: city,
      State: state,
      PostalCode: postalCode,
      ShippingAddress1: shippingAddress1,
      ShippingAddress2: shippingAddress2,
      ShippingAddress3: shippingAddress3,
      ShippingCountry: shippingCountry,
      ShippingCity: shippingCity,
      ShippingState: shippingState,
      ShippingPostalCode: shippingPostalCode,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/admin/api/v1/update_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Details Updated Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  // update api for tenant
  const updateAddressTenant = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    if (
      !addressLine1 ||
      !addressLine2 ||
      !selectedCountry ||
      !state ||
      !city ||
      !postalCode ||
      !shippingAddress1 ||
      !shippingAddress2 ||
      !shippingCountry ||
      !shippingState ||
      !shippingCity ||
      !shippingPostalCode
    ) {
      // Handle validation error, show a message, or prevent proceeding to the next step
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      FirstName: FirstName,
      LastName: LastName,
      PhoneNumber: phoneNumber,
      DateOfBirth: "",
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      Password: password,
      EmailId: email,
      MaxTenantLimit: "",
      TotalSims: "",
      ActiveSims: "",
      SuspendedSims: "",
      MTDUsage: "",
      PrepaidBalance: "",
      OutstandingBalace: "",
      PastDueBalance: "",
      Country: selectedCountry,
      City: city,
      State: state,
      PostalCode: postalCode,
      ShippingAddress1: shippingAddress1,
      ShippingAddress2: shippingAddress2,
      ShippingAddress3: shippingAddress3,
      ShippingCountry: shippingCountry,
      ShippingCity: shippingCity,
      ShippingState: shippingState,
      ShippingPostalCode: shippingPostalCode,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/update_tenant?domain=${TenantUUID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Details Updated Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  // update api for Client
  const updateAddressClient = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    if (
      !addressLine1 ||
      !addressLine2 ||
      !selectedCountry ||
      !state ||
      !city ||
      !postalCode ||
      !shippingAddress1 ||
      !shippingAddress2 ||
      !shippingCountry ||
      !shippingState ||
      !shippingCity ||
      !shippingPostalCode
    ) {
      // Handle validation error, show a message, or prevent proceeding to the next step
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var raw = JSON.stringify({
      UserUUID: UserUUID,
      FirstName: FirstName,
      LastName: LastName,
      PhoneNumber: phoneNumber,
      DateOfBirth: "",
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      AddressLine3: addressLine3,
      Password: password,
      EmailId: email,
      MaxTenantLimit: "",
      TotalSims: "",
      ActiveSims: "",
      SuspendedSims: "",
      MTDUsage: "",
      PrepaidBalance: "",
      OutstandingBalace: "",
      PastDueBalance: "",
      Country: selectedCountry,
      City: city,
      State: state,
      PostalCode: postalCode,
      ShippingAddress1: shippingAddress1,
      ShippingAddress2: shippingAddress2,
      ShippingAddress3: shippingAddress3,
      ShippingCountry: shippingCountry,
      ShippingCity: shippingCity,
      ShippingState: shippingState,
      ShippingPostalCode: shippingPostalCode,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/user/api/v1/update_Client`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Details Updated Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  // api to update address for tenant
  const updateAddress = () => {
    if (AccountType === "Admin") {
      updateAddressAdmin();
    }
    if (AccountType === "Tenant") {
      updateAddressTenant();
    }
    if (AccountType === "Client") {
      updateAddressClient();
    }
  };

  //for changing tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState("1");

  // for getting first letter of first name
  const getFirstLetter = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  // for change password
  const [showPassword, setShowPassword] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [seePassword, setSeePassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleNewPasswordVisibility = () => {
    setViewPassword(!viewPassword);
  };
  const handleCurrentPasswordVisibility = () => {
    setSeePassword(!seePassword);
  };

  // for changing country value
  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };
  // for changing shipping country
  const handleChangeeship = (event) => {
    setShippingCountry(event.target.value);
  };

  // change password api function
  const handleApi = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: EmailId,
      password: currentPassword,
      newPassword: newPassword,
      retypePassword: confirmNewPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/change_password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setOpen(!open);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmNewPassword("");
        } else {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  // For changing first name and last name on pressing enter key,it also changes the avatar.
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const fullName = `${FirstName} ${LastName}`;
      localStorage.setItem("FirstName", FirstName);
      localStorage.setItem("LastName", LastName);
      // Perform any other necessary actions with the updated values
      window.location.reload();
    }
  };
  const extractCompanyName = (email) => {
    const atIndex = email.indexOf("@");
    const dotIndex = email.indexOf(".", atIndex);

    if (atIndex !== -1 && dotIndex !== -1) {
      const companyName = email.slice(atIndex + 1, dotIndex);
      return companyName.charAt(0).toUpperCase() + companyName.slice(1);
    }

    return "";
  };

  // For uploading the image ------- starts here
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      return;
    }

    const formdata = new FormData();
    formdata.append("profileImage", selectedFile, "img.jpeg");
    formdata.append("TenantUUID", TenantUUID);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/account/api/v1/add_profileimage`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchImage();
        window.location.reload();
      })
  };

  const [imageURL, setImageURL] = useState(null);

  const fetchImage = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/get_profileImage`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            TenantUUID: TenantUUID,
          }),
        }
      );

      const imageBlob = await response.blob();
      const url = URL.createObjectURL(imageBlob);
      if (response.status === 200) {
        setImageURL(url);
      } else {
        setImageURL(null);
      }
      setSelectedFile(null);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div
      style={{
        margin: "20px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="box1"
        style={{
          width: "100%",
          padding: "0px 25px",
        }}
      >
        <p
          style={{
            fontSize: "25px",
            fontWeight: "520",
          }}
        >
          Your Settings
        </p>
        <table
          style={{
            padding: "2px 0px",
          }}
        ></table>

        <H2
          style={{
            fontSize: "20px",
          }}
        >
          Your Profile
        </H2>
        <hr />

        <Table
          style={{
            width: "50%",
            padding: "1px 0px",
          }}
        >
          <tr>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Avatar
                sx={{
                  cursor: "pointer",
                  color: "#8224E3",
                  height: "100px",
                  width: "100px",
                  fontSize: "36px",
                  fontWeight: "600",
                }}
                style={{
                  margin: "10px",
                }}
              >
                {getFirstLetter(FirstName)}
                {getFirstLetter(LastName)}
              </Avatar>

              {AccountType === 'Tenant' && (
                <div>
                  {selectedFile && (
                    <Button
                      Button
                      style={{ color: "green", transform: "scale(.9)" }}
                      variant="outlined"
                      color="inherit"
                      onClick={handleUpload}
                    >
                      Upload
                    </Button>
                  )}

                  <Tooltip
                    title="Please select an Image file from your storage."
                    placement="right"
                  >
                    <Button
                      style={{ color: "red", transform: "scale(.9)" }}
                      variant="outlined"
                      color="inherit"
                      component="label"
                      onClick={handleUpload}
                    >
                      Change Branding Logo
                      <input type="file" hidden onChange={handleFileChange} />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </div>
          </tr>
        </Table>
        <div
          style={{
            padding: "30px 0px",
          }}
        >
          <TextField
            inputProps={{ readOnly: true }}
            style={{
              width: "40%",
              height: "10%",
            }}
            type="text"
            label="Name"
            value={`${FirstName} ${LastName}`}
            onChange={(event) => {
              const [first, last] = event.target.value.split(" ");
              setFirstName(first || "");
              setLastName(last || "");
            }}
            onKeyPress={handleKeyPress}
          />

          <table
            style={{
              padding: "12px 0px",
            }}
          >
            <tr>
              <td
                style={{
                  fontWeight: "600",
                }}
              >
                Organization
              </td>
              <td
                style={{
                  padding: "12px 25px ",
                }}
              >
                {extractCompanyName(EmailId)}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  // border: '2px purple solid',
                  fontWeight: "600",
                }}
              >
                Registration date
              </td>
              <td
                style={{
                  padding: "12px 25px ",
                }}
              >
                {formattedDate}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "600",
                }}
              >
                Language
              </td>
              <td
                style={{
                  padding: "12px 25px ",
                }}
              >
                English
              </td>
            </tr>
          </table>

          <>
            <H2
              style={{
                fontSize: "20px",
              }}
            >
              Authorization
            </H2>
            <hr />
            <p
              style={{
                fontSize: "18px",
              }}
            >
              Email ID -
              <span
                style={{
                  marginLeft: "5px",
                  color: "#8224E3",
                }}
              >
                {EmailId}
              </span>
            </p>
            <Box
              sx={{ width: "100%", typography: "body1" }}
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              {/*<---------------------------- tab view started ---------------------------->*/}
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Change Password" value="1" />
                    <Tab label="Update Address" value="2" />
                  </TabList>
                </Box>
                {/*<---------------------- password updation tab started --------------------->*/}

                <TabPanel value="1">
                  <div
                    style={{
                      backgroundColor: " rgb(233 226 226 / 35%)",
                      alignItems: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        margin: "10px",
                        display: "grid",
                        gridTemplateColumns: "auto auto auto auto ",
                      }}
                    >
                      <Typography>
                        <H2
                          style={{
                            color: "#8224E3",
                            fontWeight: "500",
                            fontSize: "22px",
                          }}
                        >
                          Change Password
                        </H2>

                        <br />

                        <TextField
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            padding: "0px",
                          }}
                          type={seePassword ? "text" : "password"}
                          label="Current Password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleCurrentPasswordVisibility}
                                  edge="end"
                                >
                                  {seePassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <br />
                        <TextField
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            padding: "0px",
                          }}
                          type={viewPassword ? "text" : "password"}
                          label="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleNewPasswordVisibility}
                                  edge="end"
                                >
                                  {viewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <br />
                        <TextField
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            padding: "0px",
                          }}
                          type={showPassword ? "text" : "password"}
                          label="Retype New Password"
                          value={confirmNewPassword}
                          onChange={(e) =>
                            setConfirmNewPassword(e.target.value)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <br />
                        <Button
                          style={{
                            backgroundColor: "#8224E3",
                            color: "white",
                            width: "90%",
                          }}
                          onClick={handleApi}
                        >
                          Change Password
                        </Button>
                      </Typography>
                    </div>
                  </div>
                </TabPanel>

                {/*<---------------------- address updation tab started --------------------->*/}
                <TabPanel value="2">
                  <div
                    className="addressBox"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      className="currentAddress"
                      style={{
                        width: "48%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                        }}
                      >
                        Current Address
                        <hr />
                      </p>

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3 "
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={addressLine3}
                        onChange={(e) => setAddressLine3(e.target.value)}
                      />

                      <InputLabel
                        id="timeZone-label"
                        style={{ marginTop: "15px" }}
                      >
                        Country
                      </InputLabel>
                      <Select
                        labelId="country-label"
                        value={selectedCountry}
                        onChange={handleCurrentCountry}
                        style={{ width: "100%", height: "51px" }}
                      >
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </div>
                    <div
                      className="shippingAddress"
                      style={{
                        width: "48%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "17px",
                        }}
                      >
                        Shipping Address
                        <hr />
                      </p>

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={shippingAddress1}
                        onChange={(e) => setShippingAddress1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress2}
                        onChange={(e) => setShippingAddress2(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress3}
                        onChange={(e) => setShippingAddress3(e.target.value)}
                      />

                      <InputLabel
                        id="timeZone-label"
                        style={{ marginTop: "15px" }}
                        placeholder="selected Country"
                      >
                        Country
                      </InputLabel>
                      <Select
                        labelId="timeZone-label"
                        id="timeZone"
                        value={shippingCountry}
                        onChange={handleChangeeship}
                        label="Time zone"
                      >
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingState}
                        onChange={(e) => setShippingState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingCity}
                        onChange={(e) => setShippingCity(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingPostalCode}
                        onChange={(e) => setShippingPostalCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <form
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={updateAddress}
                      // type="submit"
                      style={{
                        width: "40%",
                        fontFamily: "poppins",
                        fontSize: "15px",
                        color: "#9d4edd",
                        border: "1px solid #9d4edd",
                        borderRadius: "6px",
                        marginTop: "30px",
                      }}
                    >
                      UPDATE ADDRESS
                    </Button>
                  </form>
                </TabPanel>
              </TabContext>
            </Box>
          </>
        </div>
      </Box>
    </div>
  );
};

export default UserSettings;
