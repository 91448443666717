import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import { TextField, Grid } from "@mui/material";

import jsPDF from "jspdf";
import "jspdf-autotable";

import "./Reports.css";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SimpleCard } from "app/components";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import { H2, H1 } from "app/components/Typography";
import ExportReports from "../TenantReports/ExportReports";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const CDR = () => {
  const REACT_APP_BASE_URL_CDR = process.env.REACT_APP_BASE_URL_CDR;
  const navigate = useNavigate();
  const tenantUuid = localStorage.getItem("tenant_uuid");
  const [page, setPage] = useState(0);
  const [typevisible, setTypeVisible] = useState(true);
  const [mccVisible, setMCCVisible] = useState(true);
  const [iccidVisible, setIccidVisible] = useState(true);
  const [cdrIdVisible, setcdrIdVisible] = useState(true);
  const [countryVisible, setCountryVisible] = useState(true);
  const [carrierVisible, setCarrierVisible] = useState(true);
  const [mncVisible, setMNCVisible] = useState(true);
  const [imsiVisible, setImsiVisible] = useState(true);
  const [dataVisible, setDataVisible] = useState(true);
  const [connectTimeVisible, setConnectTimeVisible] = useState(true)
  const [closeTimeVisible, setCloseTimeVisible] = useState(true)
  const [eventVisible, setEventVisible] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [animateContainer, setAnimateContainer] = useState(false);
  const [open, setOpen] = useState(false);
  const [columnVisible, setColumnVisible] = useState(true);
  const [length, setLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [cdrData, setCdrData] = useState([]);
  const [cdrSmsData, setCdrSmsData] = useState([]);
  const [smsLength, setSmsLength] = useState([]);
  const [orderBy, setOrderBy] = useState("ICCID");
  // const [orderBy1, setOrderBy2] = useState("brand");
  const [order, setOrder] = useState("asc");
  const [selectedFilter, setSelectedFilter] = useState('');

  // These inputs are for the Filter ==
  const [ClientsFilter, setClientsFilter] = useState("");
  const [DurationFilter, setDurationFilter] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s",
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredAndSortedData = () => {
    let dataToUse = selectedFilter === 'SMS' ? cdrSmsData : cdrData;

    return dataToUse ? dataToUse.filter((cdr) => {
      const fullName =
        `${cdr.CDR_ID} ${cdr.ICCID} ${cdr.IMSI_ID} ${cdr.brand} ${cdr.CLOSE_TIME} ${cdr.DURATION} ${cdr.smsCount} ${cdr.TYPE} ${cdr.MCC} ${cdr.MNC} ${cdr.COUNTRY_NAME}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    }).sort((a, b) => {
      const isAsc = order === "asc";
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    }) : [];
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == false) {
      setTypeVisible(true);
      setCountryVisible(true);
      setCarrierVisible(true);
      setMCCVisible(true);
      setIccidVisible(true);
      setcdrIdVisible(true);
      setMNCVisible(true);
      setImsiVisible(true);
      setDataVisible(true);
      setConnectTimeVisible(true);
      setCloseTimeVisible(true);
      setEventVisible(true);
    } else if (columnVisible == true) {
      setTypeVisible(false);
      setCountryVisible(false);
      setCarrierVisible(false);
      setMCCVisible(false);
      setIccidVisible(false);
      setcdrIdVisible(false);
      setMNCVisible(false);
      setImsiVisible(false);
      setDataVisible(false);
      setConnectTimeVisible(false);
      setCloseTimeVisible(false)
      setEventVisible(false);
    }
  };

  const handleToggleSCountrySwitch = () => {
    setCountryVisible(!countryVisible);
  };

  const handleTogglesCarrierSwitch = () => {
    setCarrierVisible(!carrierVisible);
  }

  const handleToggletypeSwitch = () => {
    setTypeVisible(!typevisible);
  };

  const handleTogglemccSwitch = () => {
    setMCCVisible(!mccVisible);
  };
  const handleToggleIccidSwitch = () => {
    setIccidVisible(!iccidVisible);
  };
  const handleToggleCdrIdSwitch = () => {
    setcdrIdVisible(!cdrIdVisible);
  };
  const handleTogglemncSwitch = () => {
    setMNCVisible(!mncVisible);
  };
  const handleToggleImsiSwitch = () => {
    setImsiVisible(!imsiVisible);
  };

  const handleToggleDataSwitch = () => {
    setDataVisible(!dataVisible);
  };

  const handleToggleEventSwitch = () => {
    setEventVisible(!eventVisible);
  }

  const handleToggleConnectTimeSwitch = () => {
    setConnectTimeVisible(!connectTimeVisible);
  }

  const handleToggleCloseTimeSwitch = () => {
    setCloseTimeVisible(!closeTimeVisible);
  }
  // //These Functions are for the reports download   -- Starts here

  // This is for the PDF format
  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    // Check if there is any data before generating PDF
    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      // Use jsPDF autoTable plugin to add table to PDF
      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("CDR_Report.pdf");
    } else {
      toast.error("No data available for export.");
    }
  };

  const noDataReport = () => {
    toast("No data to Export", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  // This is for the Excel format
  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Check if there is any data before exporting to Excel
    if (table && table.rows.length > 1) {
      // Prepend a single quote to ICCID values to ensure text formatting in Excel
      Array.from(table.rows).forEach(row => {
        const iccidCell = row.cells[1]; // Adjust the index based on where ICCID is located
        if (iccidCell) {
          iccidCell.textContent = `'${iccidCell.textContent}`;
        }
      });

      const ws = XLSX.utils.table_to_sheet(table);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "CDR_Report" + fileExtension);
    } else {
      toast.error("No data available for export.");
    }
  };
  const fetchCdr = () => {
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL_CDR}/cdr/api/v1/admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCdrData(result.CDR);
        setLength(result.CDR.length);
      })
  };

  const fetchCdrSms = () => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    fetch(
      `${REACT_APP_BASE_URL_CDR}/cdr/api/v1/admin_sms_cdr`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        setCdrSmsData(result.CDR);
        setSmsLength(result.CDR.length);
      })

  }

  useEffect(() => {
    if (selectedFilter === 'SMS') {
      fetchCdrSms();
    } else {
      fetchCdr();
    }
  }, [selectedFilter]);

  const cdrDetails = (ICCID) => {
    localStorage.setItem("storedIccid", ICCID);
    navigate('/admin/cdr_details')
  }

  const storeSmsOption = () => {
    localStorage.setItem("storedSmsOption", selectedFilter)
  }


  const convertToNormalFormat = (timestamp) => {
    const formattedTime = new Date(timestamp).toISOString().replace(/T/, ' ').replace(/\.000Z/, '');
    return formattedTime;
  };

  return (
    <Container>
      <Box
        className="breadcrumb"
        style={{ display: "flex", marginBottom: "13px" }}
      >
        <H2>Reports | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          Call Detail Report
        </p>


        <Button
          style={{ color: "#8224e3", marginLeft: "auto" }}
          variant="outlined"
          color="inherit"
          onClick={handleButtonClick}
        >
          <Icon color="#8224e3">filter_alt_outlined</Icon>
        </Button>
      </Box>

      <Container
        style={{
          ...containerStyle,
          display: animateContainer ? "block" : "none",
          position: "absolute",
          top: "20%",
          left: "20%",
          transform: "translate(-25%,-25%)",
          zIndex: "100",
        }}
      >
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              backgroundColor: " rgb(242 242 242 / 100%)",
              borderRadius: "25px",
              marginLeft: "15%",
              animation: "fadeIn .3s ease-in-out",
            }}
          >
            <div
              style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
            >
              <H2
                style={{ margin: "10px", padding: "0px", marginRight: "10px" }}
              >
                Columns
                <Switch
                  color="primary"
                  checked={columnVisible}
                  onChange={handleToggleColumnSwitch}
                />
              </H2>
              <hr />
            </div>
            <br />

            <div
              style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
            >
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                CDR ID
                <Switch
                  color="primary"
                  checked={cdrIdVisible}
                  onChange={handleToggleCdrIdSwitch}
                />
              </p>

              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                MCC
                <Switch
                  color="primary"
                  checked={mccVisible}
                  onChange={handleTogglemccSwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                ICCID
                <Switch
                  color="primary"
                  checked={iccidVisible}
                  onChange={handleToggleIccidSwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Country
                <Switch
                  color="primary"
                  checked={countryVisible}
                  onChange={handleToggleSCountrySwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Carrier Name
                <Switch
                  color="primary"
                  checked={carrierVisible}
                  onChange={handleTogglesCarrierSwitch}
                />
              </p>

              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Event Date
                <Switch
                  color="primary"
                  checked={eventVisible}
                  onChange={handleToggleEventSwitch}
                />
              </p>
            </div>

            <div
              style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
            >
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                MNC
                <Switch
                  color="primary"
                  checked={mncVisible}
                  onChange={handleTogglemncSwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                IMSI
                <Switch
                  color="primary"
                  checked={imsiVisible}
                  onChange={handleToggleImsiSwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Data Usages
                <Switch
                  color="primary"
                  checked={dataVisible}
                  onChange={handleToggleDataSwitch}
                />
              </p>

              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  marginRight: "20px",
                  fontSize: "16px",
                  marginLeft: "10px",
                }}
              >
                Type
                <Switch
                  color="primary"
                  checked={typevisible}
                  onChange={handleToggletypeSwitch}
                />
              </p>

              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Connect Time
                <Switch
                  color="primary"
                  checked={connectTimeVisible}
                  onChange={handleToggleConnectTimeSwitch}
                />
              </p>
              <p
                style={{
                  margin: "10px",
                  padding: "0px",
                  fontSize: "16px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Close Time
                <Switch
                  color="primary"
                  checked={closeTimeVisible}
                  onChange={handleToggleCloseTimeSwitch}
                />
              </p>

            </div>

            <hr />
            <div style={{ padding: "4px", marginLeft: "85%" }}>
              <Button
                style={{ color: "#8224e3" }}
                variant="outlined"
                color="inherit"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      </Container>

      <SimpleCard title="Call Details Report">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
            alignItems: "flex-end",
            marginTop: "-2px",
          }}
        >
          <input
            style={{
              height: "40px",
              width: "30%",
              border: "1px solid  lightgrey",
              paddingLeft: "10px",
              borderRadius: "5px",
              outline: "1px black lightgrey",
            }}
            name="search"
            placeholder="Search..."
            type="search"
            autoComplete="off"
            value={searchTerm}
            onChange={handleSearch}
          />

          <Select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            style={{ height: "39px", width: "25%" }}
            displayEmpty
            MenuProps={{
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="DATA">DATA</MenuItem>
            <MenuItem value="DATA_PACKAGE">DATA PACKAGE</MenuItem>
            <MenuItem value="SMS" onClick={storeSmsOption()} >SMS</MenuItem>
          </Select>


          <div className="inputInfoBox" style={{ width: "30%", margin: "0px" }}>



            {length > 0 ? (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value="excel" onClick={() => exportToExcel()}>
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={generatePDF} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            ) : (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem onClick={noDataReport} value="excel">
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={noDataReport} value="pdf">
                  Export View PDF
                </MenuItem>
              </Select>
            )}
          </div>
        </div>

        <Box width="100%" overflow="auto" style={{ marginTop: "15px" }}>
          <StyledTable id="Table-Data" style={{ width: '175%' }} >
            <TableHead>
              <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
                {cdrIdVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: "13%"
                    }}
                    align="center"
                    onClick={() => handleSort("CDR_ID")}
                  >
                    CDR ID
                    <TableSortLabel
                      active={orderBy === "CDR_ID"}
                      direction={orderBy === "CDR_ID" ? order : "asc"}
                    >
                    </TableSortLabel>


                  </TableCell>
                )}
                {iccidVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      width: '10%',
                      cursor: 'pointer'
                    }}
                    align="center"
                    onClick={() => handleSort("ICCID")}
                  >
                    ICCID
                    <TableSortLabel
                      active={orderBy === "ICCID"}
                      direction={orderBy === "ICCID" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}
                {imsiVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    align="center"
                    onClick={() => handleSort("IMSI_ID")}
                  >
                    IMSI ID
                    <TableSortLabel
                      active={orderBy === "IMSI_ID"}
                      direction={orderBy === "IMSI_ID" ? order : "asc"}
                    >
                    </TableSortLabel>

                  </TableCell>
                )}
                {dataVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: '8%'
                    }}
                    align="center"
                    onClick={() => handleSort("DURATION")}
                  >
                    {selectedFilter === 'SMS' ? 'SMS Count' : 'Data Usage (MB)'}
                    <TableSortLabel
                      active={orderBy === "DURATION"}
                      direction={orderBy === "DURATION" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}

                {eventVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: '10%'
                    }}
                    align="center"
                    onClick={() => handleSort("ICCID")}
                  >
                    Event Date (UTC)
                    <TableSortLabel
                      active={orderBy === "ICCID"}
                      direction={orderBy === "ICCID" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}

                {typevisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer"
                    }}
                    align="center"
                    onClick={() => handleSort("TYPE")}
                  >
                    Type
                    <TableSortLabel
                      active={orderBy === "TYPE"}
                      direction={orderBy === "TYPE" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}

                {mccVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      width: '100px',
                      cursor: "pointer"
                    }}
                    align="center"
                    onClick={() => handleSort("MCC")}
                  >
                    MCC
                    <TableSortLabel
                      active={orderBy === "MCC"}
                      direction={orderBy === "MCC" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}
                {mncVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      width: '100px',
                      cursor: "pointer"
                    }}
                    align="center"
                    onClick={() => handleSort("MNC")}
                  >
                    MNC
                    <TableSortLabel
                      active={orderBy === "MNC"}
                      direction={orderBy === "MNC" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}

                {countryVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer"
                    }}
                    align="center"
                    onClick={() => handleSort("COUNTRY_NAME")}
                  >
                    Country
                    <TableSortLabel
                      active={orderBy === "COUNTRY_NAME"}
                      direction={orderBy === "COUNTRY_NAME" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}
                {carrierVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer"
                    }}
                    align="center"
                    onClick={() => handleSort("bands")}
                  >
                    Carrier Name
                    <TableSortLabel
                      active={orderBy === "bands"}
                      direction={orderBy === "bands" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}


                {connectTimeVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: '10%'
                    }}
                    align="center"
                    onClick={() => handleSort("CLOSE_TIME")}
                  >
                    Connect Time (UTC)
                    <TableSortLabel
                      active={orderBy === "CLOSE_TIME"}
                      direction={orderBy === "CLOSE_TIME" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}

                {closeTimeVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      width: '10%'
                    }}
                    align="center"
                    onClick={() => handleSort("CLOSE_TIME")}
                  >
                    Close Time (UTC)
                    <TableSortLabel
                      active={orderBy === "CLOSE_TIME"}
                      direction={orderBy === "CLOSE_TIME" ? order : "asc"}
                    >
                    </TableSortLabel>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFilter !== 'SMS' && cdrData.length > 0 ? (
                filteredAndSortedData()
                  .filter(cdr => selectedFilter === '' || cdr.TYPE === selectedFilter)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((cdr, index) => {
                    return (
                      <TableRow hover key={index} id="iccidRow" >
                        {cdrIdVisible && (
                          <TableCell align="center" style={{ paddingLeft: '10px' }} >{cdr.CDR_ID}</TableCell>
                        )}
                        {iccidVisible && (
                          <TableCell align="center" onClick={(ICCID) => cdrDetails(cdr.ICCID)} id="iccid" >{cdr.ICCID}</TableCell>
                        )}
                        {imsiVisible && (
                          <TableCell align="center">{cdr.IMSI_ID}</TableCell>
                        )}
                        {dataVisible && (
                          <TableCell align="center">{cdr.DURATION}</TableCell>
                        )}
                        {eventVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CLOSE_TIME)}

                          </TableCell>
                        )}
                        {typevisible && (
                          <TableCell align="center">{cdr.TYPE}</TableCell>
                        )}
                        {mccVisible && (
                          <TableCell align="center">{cdr.MCC}</TableCell>
                        )}
                        {mncVisible && (
                          <TableCell align="center">{cdr.MNC}</TableCell>
                        )}
                        {countryVisible && (
                          <TableCell align="center">{cdr.COUNTRY_NAME}</TableCell>
                        )}
                        {carrierVisible && (
                          <TableCell align="center">{cdr.brand}</TableCell>
                        )}
                        {connectTimeVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CONNECT_TIME)}

                          </TableCell>
                        )}
                        {closeTimeVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CLOSE_TIME)}

                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
              ) : selectedFilter === 'SMS' && cdrSmsData.length > 0 ? (
                filteredAndSortedData()
                  .filter(cdr => selectedFilter === '' || cdr.TYPE === selectedFilter)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((cdr, index) => {
                    return (
                      <TableRow hover key={index} id="iccidRow" >
                        {cdrIdVisible && (
                          <TableCell align="center" style={{ paddingLeft: '10px' }} >{cdr.CDR_ID}</TableCell>
                        )}
                        {iccidVisible && (
                          <TableCell align="center" onClick={(ICCID) => cdrDetails(cdr.ICCID)} id="iccid" >{cdr.ICCID}</TableCell>
                        )}
                        {imsiVisible && (
                          <TableCell align="center">{cdr.IMSI_ID}</TableCell>
                        )}
                        {dataVisible && (
                          <TableCell align="center">{cdr.smsCount}</TableCell>
                        )}
                        {eventVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CLOSE_TIME)}

                          </TableCell>
                        )}
                        {typevisible && (
                          <TableCell align="center">{cdr.TYPE}</TableCell>
                        )}
                        {mccVisible && (
                          <TableCell align="center">{cdr.MCC}</TableCell>
                        )}
                        {mncVisible && (
                          <TableCell align="center">{cdr.MNC}</TableCell>
                        )}

                        {countryVisible && (
                          <TableCell align="center">{cdr.COUNTRY_NAME}</TableCell>
                        )}
                        {carrierVisible && (
                          <TableCell align="center">{cdr.brand}</TableCell>
                        )}
                        {connectTimeVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CONNECT_TIME)}
                          </TableCell>
                        )}
                        {closeTimeVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(cdr.CLOSE_TIME)}

                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 15, 250]}
          component="div"
          count={selectedFilter === 'SMS' ? smsLength : length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default CDR;
