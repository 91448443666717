import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import { Collapse, MenuItem, FormControl, InputLabel, Select, Tooltip } from '@mui/material';

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel // Import TableSortLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { H2, H1 } from 'app/components/Typography';

const Container = styled('div')(({ theme }) => ({
  margin: '17px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } }
  }
}));

const AdminSIMs = () => {
  const REACT_APP_BASE_URL_CDR = process.env.REACT_APP_BASE_URL_CDR;

  const TenantUUID = localStorage.getItem('TenantUUID');
  const inventoryId = localStorage.getItem('inventoryId');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [simData, setSimData] = useState([]);
  const [status, setStatus] = useState('');

  const [sortedField, setSortedField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
  const [searchTerm, setSearchTerm] = useState('');


  const navigate = useNavigate();


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleSort = (field) => {
    setSortedField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const sortedData = simData
    .filter((sim) =>
      searchTerm
        ? sim.iccId.toLowerCase().includes(searchTerm.toLowerCase())
        : true
    )
    .sort((a, b) => {
      if (!sortedField) return 0;
      const aValue = a[sortedField];
      const bValue = b[sortedField];
      return sortOrder === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });



  const simInfo = (iccId) => {
    localStorage.setItem('iccId', iccId);
    navigate('/inventory/tenant-siminfo');
  };

  useEffect(() => {
    fetchSims();
  }, [])


  const fetchSims = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "TenantUUID": TenantUUID
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
// change base url to 12 and chng endpoint also
    fetch(`${REACT_APP_BASE_URL_CDR}/cdr/api/v1/tenant`, requestOptions)
      .then(response => response.json())
      .then(result => {

        setSimData(result.Sims[0].iccids)
      })
      .catch(error => {
      });

  }


  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: 'flex' }}>
          <H1>TenantSIM | </H1>
          <p
            style={{
              marginLeft: '5px',
              color: '#8224e3'
            }}
          >
            TenantSIM({inventoryId})
          </p>
        </Box>

        <SimpleCard title="TenantSIM" style={{}}>
          <div>
            <div>
              <input
                style={{
                  height: '36px',
                  width: '40%',
                  border: '1px solid  #dededf',
                  paddingLeft: '10px'
                }}
                name="search"
                placeholder="Search by ICCID.."
                type="search"
                autoComplete="off"
                value={searchTerm}
                onChange={handleSearch}
              />

            </div>
          </div>

          <br />
          <br />
          <Box
            style={{
              width: '100%',
              height: '80vh',
              overflowY: 'scroll',
              overflowX: 'scroll'
            }}
          >
            <StyledTable
              stickyHeader
              style={{
                width: '100%'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '15px',
                      fontWeight: '600',
                      paddingLeft: '10px'
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      onClick={() => handleSort('iccId')}
                    >
                      ICCID
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    SKU ID
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '15px',
                      fontWeight: '600'
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>


                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.length > 0
                  ? sortedData

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (
                      <TableRow key={sim.iccId}>
                        <TableCell
                          style={{ color: '#8224E3', cursor: 'pointer' }}
                          align="left"
                          onClick={() => simInfo(sim.iccId)}
                        >
                          {sim.iccId}
                        </TableCell>


                        <TableCell align="center">{sim.sku}</TableCell>
                        <TableCell align="center">{sim.status}</TableCell>
                      </TableRow>
                    ))
                  : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        No data available.
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>
    </>
  );
};

export default AdminSIMs;
