import { Container } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination"; 
import { SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import { loadStripe } from "@stripe/stripe-js";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const Support = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [tenantData, setTenantData] = useState([]);
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(7); 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };


  return (
    <>
      <Container
        style={{
          padding: "20px",
        }}
      >
        <>
          <Box
            className="breadcrumb"
            style={{ display: "flex", marginBottom: "13px" }}
          >
            <H2>Support | </H2>
            <p
              style={{
                margin: "5px",
                color: "#8224e3",
              }}
            >
              All Tickets
            </p>
          
          </Box>

          <SimpleCard title="Support">
            <div>
              <div>
                <input
                  style={{
                    height: "36px",
                    width: "25%",
                    border: "1px solid  #dededf",
                    paddingLeft: "10px",
                  }}
                  name="search"
                  placeholder="Search..."
                  type="search"
              
                />
              </div>
            </div>
            <br />
            <Box width="100%" overflow="auto">
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="left"
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Account_Type{" "}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Category
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Priority
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      Regarding cli manager in campaign
                    </TableCell>
                    <TableCell align="center">tenant</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">high</TableCell>
                    <TableCell align="center">22/02/2023 10:29:94</TableCell>

                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        sx={{
                          ".text-effect": {
                            position: "absolute",
                            top: "-21%",
                            left: "20%",
                            transform: "translateX(50%,-50%)",
                            display: "none", 
                            textAlign: "center",
                            width: "100%",
                            border: "1px solid black",
                            backgroundColor: "white",
                          },
                          "&:hover .text-effect": {
                            display: "block", 
                          },
                          position: "relative",
                        }}
                      >
                        <Icon>receipt</Icon>
                        <Typography variant="caption" className="text-effect">
                          Ticket Details
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="left">Testing question</TableCell>
                    <TableCell align="center">tenant</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">low</TableCell>
                    <TableCell align="center">21/02/2023 12:33:43</TableCell>

                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        sx={{
                          ".text-effect": {
                            position: "absolute",
                            top: "-21%",
                            left: "20%",
                            transform: "translateX(50%,-50%)",
                            display: "none", 
                            textAlign: "center",
                            width: "100%",
                            border: "1px solid black",
                            backgroundColor: "white",
                          },
                          "&:hover .text-effect": {
                            display: "block", 
                          },
                          position: "relative",
                        }}
                      >
                        <Icon>receipt</Icon>
                        <Typography variant="caption" className="text-effect">
                          Ticket Details
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">ticket testing</TableCell>
                    <TableCell align="center">tenant</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">low</TableCell>
                    <TableCell align="center">25/03/2022 20:08:11</TableCell>

                    <TableCell align="center">
                      <Switch {...label} />
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        sx={{
                          ".text-effect": {
                            position: "absolute",
                            top: "-21%",
                            left: "20%",
                            transform: "translateX(50%,-50%)",
                            display: "none", 
                            textAlign: "center",
                            width: "100%",
                            border: "1px solid black",
                            backgroundColor: "white",
                          },
                          "&:hover .text-effect": {
                            display: "block", 
                          },
                          position: "relative",
                        }}
                      >
                        <Icon>receipt</Icon>
                        <Typography variant="caption" className="text-effect">
                          Ticket Details
                        </Typography>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </StyledTable>

              <TablePagination
                rowsPerPageOptions={[7, 14, 25]}
                component="div"
                count={tenantData.length} 
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </SimpleCard>
        </>
      </Container>
    </>
  );
};

export default Support;
