import React from "react";
import { useState, useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import axios from "axios";
import { MenuItem } from "@mui/material";
import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  Paper,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  styled,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "app/components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { H2, H1, H3 } from "app/components/Typography";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { countries } from "countries-list";

const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginTop: "-8px",
  marginLeft: "44px",
  gap: "5px",
}));

const EditTenant = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const storedTenantUuid = localStorage.getItem("edit_tenant_uuid");
  const [showPassword, setShowPassword] = React.useState(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  // -------------------new states -----------------------------//
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [clientLimit, setClientLimit] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [AddressLine1, setAddressLine1] = useState("");
  const [AddressLine2, setAddressLine2] = useState("");
  const [AddressLine3, setAddressLine3] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [shippingPostalCode, setShippingPostalCode] = useState("");
  const [tenantUUID, setTenantUUID] = useState("");
  const [emailID, setEmailID] = useState("");

  useEffect(() => {
    const fetchTenantData = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Bearer ${accessToken}`);
      var raw = JSON.stringify({
        TenantUUID: storedTenantUuid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/account/api/v1/get_tenant?domain=${storedTenantUuid}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log(result.Account_details[0].ShippingCountry);
          setFirstname(result.Account_details[0].FirstName);
          setLastname(result.Account_details[0].LastName);
          setClientLimit(result.Account_details[0].MaxTenantLimit);
          setPhoneNumber(result.Account_details[0].PhoneNumber);
          setAddressLine1(result.Account_details[0].AddressLine1);
          setAddressLine2(result.Account_details[0].AddressLine2);
          setAddressLine3(result.Account_details[0].AddressLine3);
          setCity(result.Account_details[0].City);
          setCountry(result.Account_details[0].Country);
          setState(result.Account_details[0].State);
          setPostalCode(result.Account_details[0].PostalCode);
          setShippingAddress1(result.Account_details[0].ShippingAddress1);
          setShippingAddress2(result.Account_details[0].ShippingAddress2);
          setShippingAddress3(result.Account_details[0].ShippingAddress3);
          setShippingCity(result.Account_details[0].ShippingCity);
          setShippingState(result.Account_details[0].ShippingState);
          setShippingCountry(result.Account_details[0].ShippingCountry);
          setShippingPostalCode(result.Account_details[0].ShippingPostalCode);
          setTenantUUID(result.Account_details[0].TenantUUID);
          setEmailID(result.Account_details[0].EmailId);
        })
        .catch((error) => {});
    };
    fetchTenantData();
  }, []);

  const handleUpdateTenant = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    // if (
    //   !firstName ||
    //   !lastName ||
    //   !phone ||
    //   !addressLine1 ||
    //   !addressLine2 ||
    //   !state ||
    //   !city ||
    //   !postalCode ||
    //   !shippingAddress1 ||
    //   !shippingAddress2 ||
    //   !shippingCountry ||
    //   !shippingState ||
    //   !shippingCity ||
    //   !shippingPostalCode
    // ) {
    //   // Handle validation error, show a message, or prevent proceeding to the next step
    //   toast("Please fill in all required fields", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //   });
    //   return;
    // }

    var raw = JSON.stringify({
      TenantUUID: tenantUUID,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: PhoneNumber,
      EmailId: emailID,
      MaxTenantLimit: clientLimit,
      AddressLine1: AddressLine1,
      AddressLine2: AddressLine2,
      AddressLine3: AddressLine3,
      Country: country,
      City: city,
      State: state,
      PostalCode: postalCode,
      ShippingAddress1: shippingAddress1,
      ShippingAddress2: shippingAddress2,
      ShippingAddress3: shippingAddress3,
      ShippingCountry: shippingCountry,
      ShippingCity: shippingCity,
      ShippingState: shippingState,
      ShippingPostalCode: shippingPostalCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/account/api/v1/update_tenant?domain=${storedTenantUuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        navigate("/tenants/Tenants");
        if (result.Status === 1) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000, // Close the toast after 2 seconds (adjust as needed)
          });
        }
      })
      .catch((error) => {});
  };

  const handleCurrentCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleShippingCountry = (event) => {
    setShippingCountry(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));

  return (
    <>
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center" }}
        >
          <H3>Edit Tenants | </H3>

          <a
            href="/tenants/Tenants"
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              textDecoration: "none",
            }}
          >
            Tenants &gt;
          </a>
          <p
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              margin: "0",
            }}
          >
            Edit Tenants
          </p>
        </Box>
        <Paper
          style={{ marginTop: "2rem" }}
          elevation={3}
          sx={{ padding: "20px" }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Tenant Details
          </Typography>

          <Box
            sx={{ width: "100%", typography: "body1" }}
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Update General Details" value="1" />
                  <Tab label="Update Address" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div>
                  <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                    <Grid container spacing={3} style={{ marginTop: "5px" }}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          label="First Name"
                          variant="outlined"
                          inputProps={{ tabIndex: "1" }}
                          value={firstName}
                          onChange={(e) => setFirstname(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          fullWidth
                          label="Last Name"
                          variant="outlined"
                          inputProps={{ tabIndex: "2" }}
                          value={lastName}
                          onChange={(e) => setLastname(e.target.value)}
                        />
                      </Grid>

                      {/*---------------------- for phone number-------------------- */}

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          padding: "0px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          inputProps={{ tabIndex: "4" }}
                          value={PhoneNumber}
                          onChange={(phone) => setPhoneNumber(phone)}
                          style={{
                            width: "76%",
                            marginLeft: "20px",
                            marginTop: "15px",
                          }}
                          inputStyle={{
                            background: "transparent",
                            width: "100%",
                            padding: "14px 14px 14px 60px",
                          }}
                        />
                      </Grid>
                      {/*---------------------- for tenant limit-------------------- */}

                      <Grid item xs={12} sm={6}>
                        <TextField
                          // type="text"
                          name="clientLimit"
                          inputProps={{ tabIndex: "5" }}
                          onChange={(e) => setClientLimit(e.target.value)}
                          label="Client Limit"
                          value={clientLimit || ""}
                          validators={["required"]}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}></Grid>
                    </Grid>
                    <Button
                      variant="outlined"
                      color="inherit"
                      inputProps={{ tabIndex: "6" }}
                      style={{
                        width: "30%",
                        fontFamily: "poppins",
                        fontSize: "15px",
                        color: "#9d4edd",
                        border: "1px solid #9d4edd",
                        borderRadius: "6px",
                        marginTop: "30px",
                      }}
                      onClick={handleUpdateTenant}
                      sx={{ marginTop: "20px", ml: "300px" }}
                    >
                      Update
                    </Button>
                  </ValidatorForm>
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div
                  className="addressBox"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="currentAddress"
                    style={{
                      width: "48%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "17px",
                      }}
                    >
                      Billing Address
                      <hr />
                    </p>
                    <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={AddressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={AddressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={AddressLine3}
                        onChange={(e) => setAddressLine3(e.target.value)}
                      />

                      <Select
                        labelId="country-label"
                        value={country}
                        onChange={handleCurrentCountry}
                        style={{
                          width: "81%",
                          height: "51px",
                          marginLeft: "41px",
                          marginTop: "12px",
                        }}
                      >
                        <MenuItem disabled>--Select Country </MenuItem>
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </ValidatorForm>
                  </div>

                  <div
                    className="shippingAddress"
                    style={{
                      width: "48%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "17px",
                      }}
                    >
                      Shipping Address
                      <hr />
                    </p>
                    <ValidatorForm onSubmit={(e) => e.preventDefault()}>
                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 1"
                        value={shippingAddress1}
                        onChange={(e) => setShippingAddress1(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 2"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress2}
                        onChange={(e) => setShippingAddress2(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="address"
                        label="Address Line 3"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingAddress3}
                        onChange={(e) => setShippingAddress3(e.target.value)}
                      />

                      <Select
                        labelId="country-label"
                        value={shippingCountry}
                        onChange={handleShippingCountry}
                        style={{
                          width: "81%",
                          height: "51px",
                          marginLeft: "41px",
                          marginTop: "12px",
                        }}
                      >
                        <MenuItem disabled>--Select Country </MenuItem>
                        {countryOptions.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>

                      <TextField
                        type="text"
                        name="state"
                        label="State"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingState}
                        onChange={(e) => setShippingState(e.target.value)}
                      />

                      <TextField
                        type="text"
                        name="city"
                        label="City"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingCity}
                        onChange={(e) => setShippingCity(e.target.value)}
                      />
                      <TextField
                        type="text"
                        name="code"
                        label="Postal Code"
                        errorMessages={["this field is required"]}
                        style={{ marginTop: "15px" }}
                        value={shippingPostalCode}
                        onChange={(e) => setShippingPostalCode(e.target.value)}
                      />
                    </ValidatorForm>
                  </div>
                </div>

                <form
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="inherit"
                    style={{
                      width: "30%",
                      fontFamily: "poppins",
                      fontSize: "15px",
                      color: "#9d4edd",
                      border: "1px solid #9d4edd",
                      borderRadius: "6px",
                      marginTop: "30px",
                    }}
                    onClick={handleUpdateTenant}
                    sx={{ marginTop: "20px", ml: 5 }}
                  >
                    Update
                  </Button>
                </form>
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default EditTenant;