import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import {
    Box,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Collapse, TableSortLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { H2, H1 } from 'app/components/Typography';

const Container = styled('div')(({ theme }) => ({
    margin: '17px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    '& thead': {
        '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } },
    },
    '& tbody': {
        '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } },
    },
}));

const InventoryPackage = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [userData, setUserData] = useState([]);
    const [InventoryGroupsData, setInventoryGroupsData] = useState([]);
    const [input, setInput] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const TenantUUID = localStorage.getItem('TenantUUID');
    const [orderBy, setOrderBy] = useState('packageTypeId');
    const [order, setOrder] = useState('asc');

    useEffect(() => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                TenantUUID: TenantUUID,
            }),
        };

        fetch(`${REACT_APP_BASE_URL}/package_types?inventoryIds=49182&offset=0&count=10`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setData(result.PackageTypes[0].data.elements);
            })
    }, []);

    const handleSearch = (value) => {
        setSearchQuery(value.toLowerCase());
    };

    // Function to handle sorting
    const handleSort = (column) => {
        if (column === sortColumn) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');

        }
    };

    function epochToDate(epochTimestamp) {
        const date = new Date(epochTimestamp);
        const formattedDate = date.toISOString();
        return formattedDate;
    }

    // Function to filter data based on search query and sort it
    const filteredData = data
        .filter((item) => {
            const itemData = item.packageTypeId + item.status + item.name;
            return itemData.toLowerCase().includes(searchQuery);
        })
        .sort((a, b) => {
            const order = sortOrder === 'asc' ? 1 : -1;
            return order * (a[sortColumn] > b[sortColumn] ? 1 : -1);
        });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const NextPackageTemplate = () => {
        navigate('/inventory/template');
    };

    return (
        <Container>
            <div>
                <div>
                    <input
                        style={{
                            height: '36px',
                            width: '300px',
                            border: '0.2px solid gray',
                            borderRadius: '4px 4px 4px 4px',
                            padding: '10px',
                        }}
                        name="search"
                        placeholder="Search..."
                        type="text"
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Button
                        style={{ marginLeft: '350px', color: '#8224e3' }}
                        variant="outlined"
                        color="inherit"
                        onClick={NextPackageTemplate}
                    >
                        Create a Package Template
                    </Button>


                </div>
            </div>
            <br />
            <br />

            <Box width="100%" overflow="auto">
                <StyledTable style={{ color: 'gray', width: '100%' }}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: ' rgb(233 226 226 / 35%)', margin: '5px' }}>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', paddingLeft: '20px', cursor: 'pointer' }}
                                align="left"
                                onClick={() => handleSort('packageTypeId')}
                            >
                                Package ID

                                <TableSortLabel
                                    active={orderBy === 'packageTypeId'}
                                    direction={orderBy === 'packageTypeId' ? order : 'asc'}

                                >
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', cursor: 'pointer' }}
                                align="center"
                            >
                                Availability
                            </TableCell>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', cursor: 'pointer' }}
                                align="center"
                            >
                                Package Template Name
                            </TableCell>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', cursor: 'pointer' }}
                                align="center"
                            >
                                Date Created (UTC)
                            </TableCell>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', cursor: 'pointer' }}
                                align="center"
                            >
                                Package Duration
                            </TableCell>
                            <TableCell
                                style={{ fontSize: '13px', fontWeight: '600', cursor: 'pointer' }}
                                align="center"
                            >
                                Activation Type
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ fontSize: '10px,' }}>
                        {filteredData.length > 0 ? (
                            filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell style={{ fontSize: '13px', paddingLeft: '20px', color: 'blue' }} align="left">
                                        {item.packageTypeId}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="center">
                                        {item.status}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="center">
                                        {item.name}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="center">
                                        {epochToDate(item.dateLatestAvailable)}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="center">
                                        {item.activationTimeAllowanceInSeconds}
                                    </TableCell>
                                    <TableCell style={{ fontSize: '13px' }} align="center">
                                        {item.activationType}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No data available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </StyledTable>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 35]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Container>
    );
};

export default InventoryPackage;
