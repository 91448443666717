import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { H2 } from "app/components/Typography";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";

//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";
//These import are for the reports download   -- Ends here

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  // height: '200px',
  border: "0px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const Success = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const TenantUUID = localStorage.getItem("TenantUUID");

  const [openBox, setOpenBox] = useState(true);
  const navigate = useNavigate();
  const SessionId = localStorage.getItem("SessionId");
  const ICCIDS = localStorage.getItem("ICCIDS");
  const Packages = localStorage.getItem("Packages");
  const PaymentFlag = localStorage.getItem("PaymentFlag");

  const [status, setStatus] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [email, setEmailId] = useState("");
  const [name, setName] = useState("");

  const setPackageStatus = (packageIds) => {
    const commaSeparatedPackages = packageIds.join(",");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      packageStatus: "ACTIVE",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/set_package_status?packageId=${commaSeparatedPackages}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
      })
      .catch((error) => {
    });
  };

  const trialPopup = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      status: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/trial_popup?iccids=${ICCIDS}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
      })
      .catch((error) => {
      });
  };

  const enableSim = () => {
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      simDataServiceStatus: "ENABLED",
      simVoiceServiceStatus: "DISABLED",
      simSmsServiceStatus: "DISABLED",
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/enable_disable_multiplesim_tenant?iccid=${ICCIDS}&packageTypeId=${Packages}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result &&
          result.AddPackageData &&
          result.AddPackageData.length > 0
        ) {
          const packageIds = result.AddPackageData.map(
            (item) => item.data?.packageId
          ).filter(Boolean);

          setPackageStatus(packageIds);

        } else {
        }
        if (result.Status === 1) {
          trialPopup();
        }
      })
      .catch((error) => {
      });

    trialPopup();
  };

  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    // Check if there is any data before generating PDF
    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      // Use jsPDF autoTable plugin to add table to PDF
      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("Payment_Details.pdf");
    }
  };

  const getSuccessStatus = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      sessionId: SessionId,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/stripe/api/v1/get_paymentDetails`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setStatus(result.PaymentDetails.status);
        setPaymentType(result.PaymentDetails.payment_method_types[0]);
        setAmount(result.PaymentDetails.amount_received);
        setCurrency(result.PaymentDetails.currency);
        setTransactionId(result.PaymentDetails.id);
        setEmailId(result.SessionDetails.customer_details.email);
        setName(result.SessionDetails.customer_details.email);
      })
      .catch((error) => {
    });
  };

  useEffect(() => {
    getSuccessStatus();
  }, []);

  if (status === "succeeded") {
    if (PaymentFlag === "TrialPayment") {
      enableSim();
    }
    if (PaymentFlag === "DuePayment") {
    }
  }

  const handleClose = () => {
    if (PaymentFlag === "TrialPayment") {
      navigate("/dashboard/default");
    } else if (PaymentFlag === "DuePayment") {
      navigate("/client/client");
    }
  };

  return (
    <Box>
      <Modal
        open={openBox}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <div
            className="box1_1"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "15px",
              padding: "0px 10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "green",
                margin: "20px 0px",
              }}
            >
              <VerifiedIcon style={styles.blink}></VerifiedIcon>
              <H2
                style={{
                  fontSize: "25px",
                  letterSpacing: "1px",
                  color: "green",
                }}
              >
                Payment Successful
              </H2>
            </div>

            <table
              id="Table-Data"
              style={{
                padding: "5px 0px",
              }}
            >
              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Transaction Id :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {transactionId}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                    width: "40%",
                  }}
                >
                  Payment Type :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {paymentType} Payment
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Email ID :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {email}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  Name :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {name}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Amount Paid :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {amount}({currency})
                </td>
              </tr>
            </table>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "15px 0px ",
            }}
          >
            <Button
              style={{ color: "green", transform: "scale(.9)" }}
              variant="outlined"
              color="inherit"
              onClick={generatePDF}
            >
              Print Recipt
            </Button>

            <Button
              style={{ color: "red", transform: "scale(.9)" }}
              variant="outlined"
              color="inherit"
              onClick={handleClose}
            >
              Close Details
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

const keyframes = `
  @keyframes blink {
    0%, 100% {
      // opacity: 1;
      transform:scale(1)
    }
    50% {
      // opacity: 0;
      transform:scale(.7)
    }
  }
`;

const styles = {
  blink: {
    animation: "blink 1s infinite", // Increased duration to 1.5s
    fontSize: "60px",
    marginBottom: "10px",
  },
};

// Inject the keyframes into a style tag dynamically
const styleTag = document.createElement("style");
styleTag.innerHTML = keyframes;
document.head.appendChild(styleTag);

export default Success;
