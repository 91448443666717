import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
  Checkbox,
  Menu,
} from "@mui/material";
import "./ClientTotalSIM.css";
import { useNavigate } from "react-router-dom";
import { SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import Modal from "@mui/material/Modal";

const modalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  border: "0px",
  bgcolor: "background.paper",
  pt: 2,
  px: 3,
  pb: 3,
};

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const ClientTotalSIM = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");
  const navigate = useNavigate();
  const UserUUID = localStorage.getItem("UserUUID");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [simData, setSimData] = useState([]);

  const [orderBy, setOrderBy] = useState("iccid");
  const [order, setOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  // For SIM Details of selected SIM
  const selectedIccid = localStorage.getItem("selectedIccid");

  const [SelectedSimIccid, setSelectedSimIccid] = useState("");
  const [SelectedSimDateCreation, setSelectedSimDateCreation] = useState("");
  const [SelectedSimStatus, setSelectedSimStatus] = useState("");
  const [SelectedSimPackageStatus, setSelectedSimPackageStatus] = useState("");
  const [SimDataServiceStatus, setSimDataServiceStatus] = useState("");
  const [SimVoiceServiceStatus, setSimVoiceServiceStatus] = useState("");
  const [SimTenantName, setSimTenantName] = useState("");
  const [SimPackageDetails, setSimPackageDetails] = useState([]);

  // For showing the package details
  const selectedPackageId = localStorage.getItem("selectedPackageId");

  const [PackageName, setPackageName] = useState("");
  const [PackageTypeId, setPackageTypeId] = useState("");
  const [PackageAssignDate, setPackageAssignDate] = useState("");
  const [PackageExpiryDate, setPackageExpiryDate] = useState("");
  const [PackageActivationDate, setPackageActivationDate] = useState("");
  const [PackageType, setPackageType] = useState("");
  const [PackageTotalData, setPackageTotalData] = useState("");
  const [PackageRemainingData, setPackageRemainingData] = useState("");

  const fetchPackageDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      packageId: selectedPackageId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/packagedetails_BypackageId`, requestOptions) //For fetching the package details by package id
      .then((response) => response.json())
      .then((result) => {
        setPackageName(result.packageData[0].packageName)
        setPackageTypeId(result.packageData[0].packageTypeId)
        setPackageAssignDate(result.packageData[0].dateCreated)
        setPackageExpiryDate(result.packageData[0].windowActivationEnd)
        setPackageActivationDate(result.packageData[0].windowActivationStart)
        setPackageType(result.packageData[0].packageType)
        setPackageTotalData(result.packageData[0].TotalDataAssigninMb)
        setPackageRemainingData(result.packageData[0].RemainingDatainMb)
      })
  };

  useEffect(() => {
    fetchPackageDetails();
  });

  const fetchSimDetails = () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    // To show SIM Card details after clicking on the ICCId.
    fetch(
      `${REACT_APP_BASE_URL}/api/v1/fetch_Usersim_details_by_iccid?iccid=${selectedIccid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSelectedSimIccid(result.iccIdDetails[0].iccid);
        setSelectedSimDateCreation(result.iccIdDetails[0].assignedDate);
        setSelectedSimStatus(result.iccIdDetails[0].statusOfSim);
        setSelectedSimPackageStatus(result.iccIdDetails[0].status);
        setSimDataServiceStatus(result.iccIdDetails[0].simDataServiceStatus);
        setSimVoiceServiceStatus(result.iccIdDetails[0].simVoiceServiceStatus);
        setSimTenantName(result.iccIdDetails[0].TenantName);
        setSimPackageDetails(result.iccIdDetails[0].packagesDetails);
      })
  };

  useEffect(() => {
    fetchSimDetails();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (iccid) => {
    const updatedSelectedRows = selectedRows.includes(iccid)
      ? selectedRows.filter((id) => id !== iccid)
      : [...selectedRows, iccid];

    setSelectedRows(updatedSelectedRows);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          UserUUID: UserUUID,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        // To show All Sim cards in the table (this data is mapped in the table).
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_sims_of_user`,
          requestOptions
        );

        const result = await response.json();

        if (result.Status === 1) {
          setSimData(result.Sims);
        } else {
        }
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = simData.filter(
    (sim) =>
      sim.iccId.toLowerCase().includes(searchText.toLowerCase()) ||
      sim.status.toLowerCase().includes(searchText.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  function epochToDate(epochTimestamp) {
    const date = new Date(epochTimestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  }

  // FUNCTIONS FOR MODALS OPENING AND OPERATING

  const handleSimDetailBox = (iccid) => {
    localStorage.setItem("selectedIccid", iccid);
    setOpenSimDetailsBox(!OpenSimDetailsBox);
  };

  const handlePackageDetailBox = (packageId) => {
    localStorage.setItem("selectedPackageId", packageId);
    setOpenPackageDetailsBox(!OpenPackageDetailsBox);
  };

  const [OpenSimDetailsBox, setOpenSimDetailsBox] = useState(false);
  const [OpenPackageDetailsBox, setOpenPackageDetailsBox] = useState(false);

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>SIM Cards| </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Total SIM Cards
          </p>
        </Box>

        <SimpleCard title="Total SIM Cards" style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              height: "70px",
            }}
          >
            <input
              style={{
                height: "35px",
                width: "28%",
                border: "1px solid  lightgrey",
                paddingLeft: "10px",
                borderRadius: "5px",
                outline: "1px black lightgrey",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {selectedRows.length > 0 && (
                <div style={{ width: "100%", margin: "0px 55px 3px 0px" }}>
                  <p id="inputInfoTopText">
                    Action
                    <Tooltip title="Select an action" placement="top-start">
                      <IconButton onClick={handleClick}>
                        <Icon style={{ transform: "scale(.7)" }}>
                          format_list_bulleted
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </p>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={""}>Activate</MenuItem>

                    <MenuItem onClick={""}>Suspend</MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <Box
            style={{
              width: "100%",
              height: "auto",
              // overflowY: "scroll",
              // overflowX: "scroll",
            }}
          >
            <StyledTable
              stickyHeader
              style={{
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      paddingLeft: "10px",
                      cursor: "pointer",
                    }}
                    align="center"
                    onClick={() => handleSort("iccid")}
                  >
                    ICCID
                    <TableSortLabel
                      active={orderBy === "iccid"}
                      direction={orderBy === "iccid" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Assigned Date
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Next Payment Date
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.length > 0 ? (
                  sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (
                      <TableRow key={sim.iccId}>
                        <TableCell
                          style={{ color: "#8224E3", cursor: "pointer" }}
                          id="iccid"
                          align="center"
                          onClick={() => handleSimDetailBox(sim.iccId)}
                        >
                          {sim.iccId}
                        </TableCell>
                        <TableCell align="center">
                          {epochToDate(sim.assignedDate)}
                        </TableCell>
                        <TableCell style={{ fontSize: "13px" }} align="center">
                          <Tooltip
                            title={
                              sim.enabled === "true" ? "Enabled" : "Disabled"
                            }
                            placement="right"
                          >
                            {sim.enabled === "true" ? (
                              <CheckCircleOutlineIcon color="primary" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </Tooltip>
                        </TableCell>{" "}
                        <TableCell align="center">
                          {epochToDate(sim.nextpaymentDate)}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 500]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>

      {/* MODAL FOR THE SIM DETAILS */}
      <Modal
        open={OpenSimDetailsBox}
        onClose={() => setOpenSimDetailsBox(!OpenSimDetailsBox)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Grid
          sx={{ ...modalStyle2, width: 500 }}
          style={{
            width: "55%",
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0px 0px 5px lightGrey",
            paddingBottom: "10px",
          }}
        >
          <Box
            className="breadcrumb"
            style={{ display: "flex", margin: "10px 0px 0px 20px" }}
          >
            <h3 style={{ fontWeight: "500", fontSize: "18px " }}>
              SIM Card Details |
            </h3>

            <span
              style={{
                marginTop: "22px",
                marginLeft: "10px",
                color: "#8224e3",
                fontSize: "14px",
              }}
            >
              {SelectedSimIccid}
            </span>
          </Box>
          <hr
            style={{
              border: "1px lightGrey solid",
              width: "97%",
              marginBottom: "20px",
              marginTop: "-13px",
            }}
          />
          <div
            style={{
              margin: "20px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              height: "76%",
            }}
          >
            <div
              className="box1"
              style={{
                width: "100%",
                height: "100%",
                padding: "10px 10px 10px 15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "15px",
                fontSize: "15px",
              }}
            >
              <table>
                <tr>
                  <td>ICCID :</td>
                  <td style={{ padding: "2px 25px" }}>{SelectedSimIccid}</td>
                </tr>
                <tr>
                  <td>Date Created :</td>
                  <td style={{ padding: "2px 25px" }}>
                    {epochToDate(SelectedSimDateCreation)}
                  </td>
                </tr>
                <tr>
                  <td>SIM Status :</td>
                  <td style={{ padding: "2px 25px" }}>{SelectedSimStatus}</td>
                </tr>
                <tr>
                  <td>SIM Package Status :</td>
                  <td style={{ padding: "2px 25px" }}>
                    {SelectedSimPackageStatus}
                  </td>
                </tr>
                <tr>
                  <td>Data Services Status :</td>
                  <td style={{ padding: "2px 25px" }}>
                    {SimDataServiceStatus}
                  </td>
                </tr>
                <tr>
                  <td>Voice Services Status:</td>
                  <td style={{ padding: "2px 25px" }}>
                    {SimVoiceServiceStatus}
                  </td>
                </tr>
                <tr>
                  <td>Tenant Name:</td>
                  <td style={{ padding: "2px 25px" }}>{SimTenantName}</td>
                </tr>
                <tr style={{ border: "2px green solid" }}>
                  <td>Packages Details List</td>
                  <td
                    style={{
                      padding: "2px 0px 2px 25px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {SimPackageDetails.map((pkg, index) => {
                      return <li key={index} onClick={(packageId) => handlePackageDetailBox(pkg.packageId)} >{pkg.packageName} </li>
                    })}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </Grid>
      </Modal>

      {/* MODAL FOR SHOWING PACKAGE DETAILS */}

      <Modal
        open={OpenPackageDetailsBox}
        onClose={() => setOpenPackageDetailsBox(!OpenPackageDetailsBox)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...modalStyle2, width: 500 }}>
          <div
            className="box1_1"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "15px",
              padding: "0px 15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "green",
                margin: "20px 0px",
              }}
            >
              <H2
                style={{
                  fontSize: "25px",
                  letterSpacing: "1px",
                  color: "green",
                }}
              >
                Package details
              </H2>
            </div>

            <table
              id="Table-Data"
              style={{
                padding: "5px 0px",
                width: "95%",
                marginBottom: "15px",
              }}
            >
              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package Name :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {PackageName}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package ID :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {PackageTypeId}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package Assign Date :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {epochToDate(PackageAssignDate)}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package Expiry Date :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {epochToDate(PackageExpiryDate)}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package Activation Date :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {epochToDate(PackageActivationDate)}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Package Type :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {PackageType}
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Total Data Assigned :
                </td>
                <td
                  style={{
                    padding: "2px 25px ",
                  }}
                >
                  {PackageTotalData}
                </td>
              </tr>
            </table>

            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'
            }} >
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '500', fontSize: '13px', width: '100%', height: '20px', padding: '0px', marginBottom: '3px', padding: '0px 5px'
              }} >
                <p>Data Consumed</p>
                <p>Data Remaining</p>
              </div>
              <div style={{ display: 'flex', width: '100%' }} >
                <div className="prePost" style={{ backgroundColor: '#778da9', borderTopLeftRadius: '12px', borderBottomLeftRadius: '10px', height: '12px', marginBottom: "4px", width: `${((PackageTotalData - PackageRemainingData) / PackageTotalData) * 100}%` }} ></div>
                <div className="prePost" style={{ backgroundColor: '#e0e1dd', borderTopRightRadius: '12px', borderBottomRightRadius: '10px', height: '12px', marginBottom: "4px", width: `${((PackageRemainingData) / PackageTotalData) * 100}%`, }} ></div>
              </div>
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '100', fontSize: '12px', width: '100%', height: '20px', padding: '0px 5px'
              }} >
                <p>{PackageTotalData - PackageRemainingData} MB </p>
                <p>{PackageRemainingData} MB </p>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ClientTotalSIM;
