import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import './Reports.css';
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, SimpleCard } from 'app/components';
import { Collapse, MenuItem, FormControl, InputLabel, Select, Tooltip } from '@mui/material';
import { H2, H1 } from 'app/components/Typography';

const Container = styled('div')(({ theme }) => ({
  margin: '17px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, fontFamily: 'poppins' } }
  }
}));

const BillingReports = () => {
  const [showNewUser, setShowNewUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const tenantUuid = localStorage.getItem('tenant_uuid');
  const [user, setUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem('TenantUUID');
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);

  const [containerStyle, setContainerStyle] = useState({
    padding: '10px',
    top: '-238px',
    left: '500px',
    transitionProperty: 'all',
    transitionDuration: '0.5s', // Adjust the duration as needed
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };
  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  return (
    <Container>
      <Box className="breadcrumb" style={{ display: 'flex', marginBottom: '13px' }}>
        <H2>Reports | </H2>
        <p
          style={{
            margin: '5px',
            color: '#8224e3'
          }}
        >
          {' '}
          Billings Reports
        </p>

        <Button
          style={{ marginLeft: 'auto', height: '40px', width: '10px', color: '#8224e3' }}
          onClick={toggleCollapse}
          variant="outlined"
          color="inherit"

        // Push the button to the extreme right
        >
          <Tooltip title="Filter" arrow>

            <IconButton
              sx={{
                '.text-effect': {
                  position: 'absolute',
                  top: '50%',
                  right: '100%',
                  transform: 'translateX(50%,-50%)',
                  display: 'none',
                  textAlign: 'center',
                  width: '100%',
                  border: '1px solid black',
                  backgroundColor: 'white'
                },
                '&:hover .text-effect': {
                  display: 'block'
                },
                position: 'relative'
              }}
            >
              <Icon color="#8224e3">filter_alt_outlined</Icon>
            </IconButton>
          </Tooltip>
        </Button>
        <Button
          style={{ color: '#8224e3', marginLeft: '10px', }}
          variant="outlined"
          color="inherit"
          onClick={handleButtonClick}

        >
          <Icon color="#8224e3">settings</Icon>
        </Button>
      </Box>
      <Collapse in={open}>
        <div
          style={{
            backgroundColor: 'white',
            alignItems: 'center',
            borderRadius: '10px',
            boxShadow: '0px 0px 3px black'
          }}
        >
          <div
            style={{
              padding: '10px',
              margin: '10px',
              display: 'grid',
              gridTemplateColumns: 'auto auto auto auto'
            }}

          >
            <FormControl variant="outlined" style={{ margin: '8px' }}>
              <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tenant"

              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ margin: '8px' }}>
              <InputLabel id="demo-simple-select-label">Start Date & Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Start Date & Time"
              ></Select>
            </FormControl>

            <FormControl variant="outlined" style={{ margin: '8px' }}>
              <InputLabel id="demo-simple-select-label">End Date & Time </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="End Date & Time "
              ></Select>
            </FormControl>

            <FormControl variant="outlined" style={{ margin: '8px' }}>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Age">
                <MenuItem value={10}>Daily</MenuItem>
                <MenuItem value={20}>Weekly</MenuItem>
                <MenuItem value={30}>Annual</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ margin: '8px' }}>
              <InputLabel id="demo-simple-select-label">Downloadable Format </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Call Duration"
              >
                <MenuItem value={10}>excel</MenuItem>
                <MenuItem value={20}>csv</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              style={{
                marginLeft: '27px',
                marginBottom: '15px', color: 'green'
              }}
              variant="outlined"
              color="inherit"
            >
              Search
            </Button>
          </div>

        </div>
      </Collapse>

      <Container style={{
        ...containerStyle, display: animateContainer ? 'block' : 'none',
        position: 'absolute',
        top: '25%',
        left: '25%',
        transform: 'translate(-25%,-25%)',
        zIndex: '100'
      }}>
        <>

          <div
            style={{
              width: '100%',
              height: '100%',
              padding: '10px',
              backgroundColor: ' rgb(233 226 226 / 100%)',
              borderRadius: '25px',
              marginLeft: '15%',
              animation: 'fadeIn 1.5s ease-in-out',
            }}

          >
            <div style={{ padding: '0px', marginLeft: '10px', marginTop: '0px' }}>
              <H2 style={{ margin: '10px', padding: '0px', marginRight: '10px' }}>
                Columns
                <Switch
                  color="primary"
                />
              </H2>
              <hr />
            </div>
            <br />

            <div style={{ padding: '5px', display: 'flex', marginLeft: '20px' }}>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  marginRight: '20px',
                  fontSize: '16px',
                  marginLeft: '10px'
                }}
              >
                Date
                <Switch
                  color="primary"
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Time
                <Switch
                  color="primary"
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                ICCID
                <Switch
                  color="primary"
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Country
                <Switch
                  color="primary"
                />
              </p>



            </div>

            <div style={{ padding: '5px', display: 'flex', marginLeft: '20px' }}>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Network
                <Switch
                  color="primary"
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                IMSI
                <Switch
                  color="primary"
                />
              </p>
              <p
                style={{
                  margin: '10px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                Data Usages
                <Switch
                  color="primary"
                />
              </p>


              <p
                style={{
                  margin: '7px',
                  padding: '0px',
                  fontSize: '16px',
                  marginRight: '10px',
                  marginLeft: '10px'
                }}
              >
                ICCID
                <Switch
                  color="primary"
                />
              </p>

            </div>


            <hr />
            <div style={{ padding: '4px', marginLeft: '450px' }}>
              <Button
                style={{ color: '#8224e3' }}
                variant="outlined"
                color="inherit"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>

          </div>
        </>
      </Container>

      <SimpleCard title="Billings Reports">
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="left">Tenant Name</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Tenant ID</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Balance</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Dues</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Overages</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Invoice</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Revenue</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Payment</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Cost Analysis</TableCell>
                <TableCell style={{
                  fontSize: '16px',
                  fontWeight: '500'
                }} align="center">Discounts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">Tenant 1</TableCell>
                <TableCell align="center">665</TableCell>
                <TableCell align="center">2000</TableCell>

                <TableCell align="center">500</TableCell>
                <TableCell align="center">200</TableCell>
                <TableCell align="center">2</TableCell>
                <TableCell align="center">1000</TableCell>
                <TableCell align="center">20</TableCell>
                <TableCell align="center">500</TableCell>
                <TableCell align="center">200</TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left">Tenant 2</TableCell>
                <TableCell align="center">834</TableCell>
                <TableCell align="center">1000</TableCell>

                <TableCell align="center">200</TableCell>
                <TableCell align="center">100</TableCell>
                <TableCell align="center">4</TableCell>
                <TableCell align="center">500</TableCell>
                <TableCell align="center">20</TableCell>
                <TableCell align="center">400</TableCell>
                <TableCell align="center">300</TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={userData.length} // Total number of rows
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </SimpleCard>
    </Container>
  );
};

export default BillingReports;
