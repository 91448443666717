import React from "react";
import "./SignUpStyle.css";
import {
  Button,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { Icon } from "@mui/material";
import { H3 } from "app/components/Typography";
import { MenuItem } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { countries } from "countries-list";
import Select from "react-select";
const SignUp = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const maxPasswordLength = 18;
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [stateError, setStateError] = useState("");
  const [stateFlag, setStateFlag] = useState(true);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [cityFlag, setCityFlag] = useState(true);
  const [postalCode, setPostalCode] = useState("");
  const [postalError, setPostalError] = useState("");
  const [postalFlag, setPostalFlag] = useState(true);

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [signedIn, setSignedIn] = useState(0);
  const [SignSteps, setSignSteps] = useState(1);
  const [otp, setOTP] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [checkBox, setCheckBox] = useState(false); // for setting the billing and current address same
  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [count, setCount] = useState(0);
  const [autoSlide, setAutoSlide] = useState(null);
  let slides = document.querySelectorAll(".slides");
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const nextSlide = () => {
    if (count === slides.length - 1) {
      setCount(0);
    } else {
      setCount(count + 1);
    }
    slideimage(count);
  };

  useEffect(() => {
    slides.forEach((slide, index) => {
      slide.style.left = `${index * 100}%`;
    });
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => {
      clearInterval(autoSlide);
    };
  }, [count, isHovered]);
  const slideimage = (count) => {
    slides.forEach((slides) => {
      slides.style.transform = `translateX(-${count * 100}%)`;
    });
  };

  const previousSlide = () => {
    if (count === 0) {
      setCount(slides.length - 1);
    } else {
      setCount(count - 1);
    }
    slideimage(count);
  };

  const handleNext = () => {
    if (emailFlag === false) {
      toast("Please fill a proper bussiness Email ID", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (isValidFirstName === false) {
      toast("Please enter first name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (isValidLastName === false) {
      toast("Please enter last name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    if (isPasswordValid === false) {
      toast("Please enter password", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    if (!firstName || !lastName || !phone || !email || !password) {
      // Handle validation error, show a message, or prevent proceeding to the next step
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    } else {
      setSignSteps(2);
    }
  };

  const first = (e) => {
    e.preventDefault();
    if (signedIn === 0) {
      if (
        !firstName ||
        !lastName ||
        !phone ||
        !email ||
        !addressLine1 ||
        !addressLine2 ||
        // !addressLine3 ||
        !selectedCountry ||
        !state ||
        !city ||
        !password ||
        !postalCode
      ) {
        // Handle validation error, show a message, or prevent proceeding to the next step
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (cityFlag === false || stateFlag === false) {
        toast("Please enter only alphabetic characters", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (postalFlag === false) {
        toast("Please enter only number", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      if (checkBox == true) {
        var raw = JSON.stringify({
          DomainUUID: "6866ae91-6676-472c-9d12-de139d290450",
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: phone,
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          Password: password,
          EmailId: email,
          Country: selectedCountry.label,
          City: city,
          State: state,
          PostalCode: postalCode,
          ShippingAddress1: addressLine1,
          ShippingAddress2: addressLine2,
          ShippingAddress3: addressLine3,
          ShippingCountry: selectedCountry.label,
          ShippingCity: city,
          ShippingState: state,
          ShippingPostalCode: postalCode,
        });
      } else if (checkBox == false) {
        var raw = JSON.stringify({
          DomainUUID: "6866ae91-6676-472c-9d12-de139d290450",
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: phone,
          AddressLine1: addressLine1,
          AddressLine2: addressLine2,
          AddressLine3: addressLine3,
          Password: password,
          EmailId: email,
          Country: selectedCountry.label,
          City: city,
          State: state,
          PostalCode: postalCode,
          ShippingAddress1: "",
          ShippingAddress2: "",
          ShippingAddress3: "",
          ShippingCountry: "",
          ShippingCity: "",
          ShippingState: "",
          ShippingPostalCode: "",
        });
      }
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${REACT_APP_BASE_URL}/user/api/v1/signup`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            toast("Please Enter the OTP sent on the provided Email", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setSignedIn(1);
          } else if (result.Status == 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
    } else if (signedIn === 1) {
      if (!otp) {
        // Handle validation error, show a message, or prevent proceeding to the next step
        toast("Please enter the OTP", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        Otp: otp,
        EmailId: email,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${REACT_APP_BASE_URL}/account/api/v1/verify_email`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setSignedIn(2);
          } else if (result.Status === 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
    }
  };
  const finish = () => {
    navigate("/session/signin");
  };

  const [emailError, setEmailError] = useState("");
  const [emailFlag, setEmailFlag] = useState(true);
  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!email) {
      setEmailError("Please enter your bussiness email id");
    } else if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
    } else {
      const [, domain] = email.split("@").map((part) => part.toLowerCase());

      // Check if the email domain is allowed
      const allowedDomains = ["startelelogic.com"]; // Add your company domain(s) here

      // Disallow Gmail, Yahoo, and Hotmail
      const disallowedDomains = [
        "gmail.com",
        "yahoo.com",
        "hotmail.com",
        "rediffmail.com",
      ];

      if (disallowedDomains.includes(domain)) {
        // allowedDomains.includes(domain) && !
        setEmailError("Only bussiness email are allowed");
        setEmailFlag(false);
      } else {
        setEmailError("");
        setEmailFlag(true);
      }
    }
  };
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const handleFirstNameChange = (e) => {
    const inputFirstName = e.target.value;
    // Use a regular expression to check if the input contains only alphabetic characters
    // Check if the input is blank
    if (inputFirstName.trim() === "") {
      setFirstName("");
      setIsValidFirstName(!isValidFirstName);
      setIsValidFirstName(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputFirstName.replace(/[^a-zA-Z]/g, "");

      setFirstName(alphabeticValue);
      setIsValidFirstName("");
      setIsValidFirstName(true);
    }
  };
  const LastNameChanges = (e) => {
    const inputLastName = e.target.value;
    if (inputLastName.trim() === "") {
      setLastName("");
      setIsValidLastName(!isValidFirstName);
      setIsValidLastName(false);
    } else {
      // Remove non-alphabetic characters
      const alphabeticValue = inputLastName.replace(/[^a-zA-Z]/g, "");

      setLastName(alphabeticValue);
      setIsValidLastName("");
      setIsValidLastName(true);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordValid(
      newPassword.length >= 6 && newPassword.length <= maxPasswordLength
    );
  };

  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  countryOptions.sort((a, b) => a.label.localeCompare(b.label));

  const handleChange = (selectedCountry) => {
    setSelectedCountry(selectedCountry);
  };

  const handleStateChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input contains only characters using a regular expression
    if (inputValue.trim() === "") {
      setState("");
      setStateError("Please enter  state");
      setStateFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(inputValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setState(inputValue);
      setStateError(""); // Clear the error when the input is valid
      setStateFlag(true);
    } else {
    }
  };

  const handleCityChange = (e) => {
    const cityValue = e.target.value;

    if (cityValue.trim() === "") {
      setCity("");
      setCityError("Please enter  city");
      setCityFlag(false);
    } else if (/^[a-zA-Z0-9\s]+$/.test(cityValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setCity(cityValue);
      setCityError(""); // Clear the error when the input is valid
      setCityFlag(true);
    } else {
      // setStateError('Please enter only alphanumeric characters.');
    }
  };
  const handlePostalChange = (e) => {
    const postalValue = e.target.value;

    if (postalValue.trim() === "") {
      setPostalCode("");
      setPostalError("Please enter  postal code");
      setPostalFlag(false);
    } else if (/^[0-9\s]+$/.test(postalValue)) {
      // Check if the input contains only alphanumeric characters using a regular expression
      setPostalCode(postalValue);
      setPostalError(""); // Clear the error when the input is valid
      setPostalFlag(true);
    } else {
    }
  };

  return (
    <div className="Box">
      <div className="BoxTop">
        <img
          id="signUpLogo"
          src="/assets/images/illustrations/Deplofy-black-logo.webp"
          style={{
            transform: "scale(.8)",
          }}
        />
        <NavLink to="/session/signin">
          <Button
            fullWidth
            variant="outlined"
            style={{
              width: "120px",
              height: "40px",
              fontFamily: "poppins",
              fontSize: "16px",
              color: "#011015",
              border: "1px solid #011015",
            }}
          >
            Log In
          </Button>
        </NavLink>
      </div>
      <div className="BoxBottom">
        <div className="BoxBottom_1">
          <div
            style={{
              width: "85%",
              textAlign: "center",
              margin: "10px 0px",
            }}
          >
            <i>
              Deplofy IoT SIM ensures fast data speeds and consistent signal
              quality worldwide, thanks to its fully-owned core network and
              advanced SIM multi-carrier and auto-switching technology.
            </i>
          </div>
          <div
            className="slider"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="slides" style={{ zIndex: '100' }} >
              <p>IoT Connectivity</p>
              <img src="/assets/images/2.png" className="image" id="img2" />
            </div>
            <div className="slides">
              <p>Digital transformation</p>
              <img src="/assets/images/4.png" className="image" id="img4" />
            </div>
            <div className="slides">
              <p>IoT Sensors</p>
              <img src="/assets/images/3.png" className="image" id="img3" />
            </div>
            <div className="slides">
              <p>GPS tracking</p>
              <img src="/assets/images/1.png" className="image" id="img1" />
            </div>

            <div className="slides">
              <p>Travel SIM</p>
              <img src="/assets/images/5.png" className="image" id="img5" />
            </div>
            {/* <div className="buttons">
              <i id="prev" onClick={previousSlide}>
                <Icon>chevron_left</Icon>
              </i>
              <i id="next" onClick={nextSlide}>
                <Icon>chevron_right</Icon>
              </i>
            </div> */}
          </div>
        </div>
        <div className="BoxBottom_2">
          {(() => {
            if (signedIn === 0) {
              return (
                <form
                  action=""
                  className="signUpForm"
                  onSubmit={(e) => first(e)}
                >
                  <div>
                    <H3
                      style={{
                        fontSize: "30px",
                        color: "#0e1015",
                      }}
                    >
                      Sign Up and Start Your
                      <br />
                      Free Trial
                    </H3>
                    <p>No Credit Card Required*</p>
                  </div>
                  {(() => {
                    if (SignSteps == 1) {
                      return (
                        <>
                          <TextField
                            type="text"
                            name="firstName"
                            label="First Name"
                            error={!isValidFirstName}
                            helperText={
                              !isValidFirstName ? "Please enter first name" : ""
                            }
                            style={{ marginTop: "15px" }}
                            value={firstName}
                            onChange={handleFirstNameChange}
                          />
                          <TextField
                            type="text"
                            name="lastname"
                            label="Last Name"
                            autoComplete="new-value"
                            error={!isValidLastName}
                            helperText={
                              !isValidLastName ? "Please enter last name" : ""
                            }
                            style={{ marginTop: "15px" }}
                            value={lastName}
                            onChange={LastNameChanges}
                          />

                          <TextField
                            type="email"
                            name="email"
                            label="Bussiness Email ID"
                            style={{ marginTop: "15px" }}
                            errorMessages={["this field is required"]}
                            value={email}
                            onChange={handleEmailChange}
                          />
                          {emailError && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "11px",
                                marginLeft: "12px",
                              }}
                            >
                              {emailError}
                            </div>
                          )}
                          <TextField
                            name="password"
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            value={password}
                            autoComplete="new-text"
                            error={!isPasswordValid}
                            helperText={
                              !isPasswordValid
                                ? "Password must be between 6 and 18 characters in length."
                                : ""
                            }
                            style={{ marginTop: "15px" }}
                            onChange={handlePasswordChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <PhoneInput
                            country={"us"}
                            enableSearch={true}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                            style={{
                              marginTop: "17px",
                            }}
                            containerStyle={{}}
                            inputStyle={{
                              background: "transparent",
                              width: "100%",
                            }}
                          />
                        </>
                      );
                    } else if (SignSteps === 2) {
                      return (
                        <>
                          <TextField
                            type="text"
                            name="address"
                            label="Address Line 1"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                          />
                          <TextField
                            type="text"
                            name="address"
                            label="Address Line 2"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                          />

                          <TextField
                            type="text"
                            name="address"
                            label="Address Line 3"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={addressLine3}
                            onChange={(e) => setAddressLine3(e.target.value)}
                          />
                          <div style={{ marginTop: "16px" }}>
                            <Select style={{}}
                              value={selectedCountry}
                              onChange={handleChange}
                              options={countryOptions}
                              placeholder="Select Country"
                            />
                          </div>

                          <TextField
                            type="text"
                            name="state"
                            label="State"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={state}
                            onChange={handleStateChange}
                          />
                          {stateError && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "11px",
                                margin: "0px 0 0 10px",
                              }}
                            >
                              {stateError}
                            </div>
                          )}
                          <TextField
                            type="text"
                            name="city"
                            label="City"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={city}
                            onChange={handleCityChange}
                          />
                          {cityError && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "11px",
                                margin: "0px 0 0 10px",
                              }}
                            >
                              {cityError}
                            </div>
                          )}
                          <TextField
                            type="text"
                            name="code"
                            label="Postal Code"
                            errorMessages={["this field is required"]}
                            style={{ marginTop: "15px" }}
                            value={postalCode}
                            onChange={handlePostalChange}
                          />
                          {postalError && (
                            <div
                              style={{
                                color: "red",
                                fontSize: "11px",
                                margin: "0px 0 0 10px",
                              }}
                            >
                              {postalError}
                            </div>
                          )}
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkBox}
                                  onChange={handleCheckBox}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Keep this address as your Shipping Address as well."
                            />
                          </>
                        </>
                      );
                    }
                  })()}
                </form>
              );
            } else if (signedIn === 1) {
              return (
                <form className="signUpForm" onSubmit={(e) => first(e)}>
                  <div>
                    <H3
                      style={{
                        fontSize: "30px",
                        color: "#0e1015",
                      }}
                    >
                      Sign Up and Start Your
                      <br />
                      Free Trial
                    </H3>
                    <p>No Credit Card Required*</p>
                  </div>
                  <p
                    style={{
                      fontSize: "19px",
                    }}
                  >
                    The OTP has been sent to your registered Email address.
                    <br />
                    Please verify it
                  </p>
                  <TextField
                    type="text"
                    name="otp"
                    label="Enter the OTP"
                    errorMessages={["this field is required"]}
                    style={{ marginTop: "15px" }}
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                </form>
              );
            } else if (signedIn == 2) {
              return (
                <form className="signUpForm" onSubmit={(e) => finish(e)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <H3
                      style={{
                        fontSize: "40px",
                        color: "#0e1015",
                        textDecoration: "underline",
                        marginBottom: "10px",
                      }}
                    >
                      Welcome to Deplofy!
                    </H3>
                    <div id="gif">
                      <img
                        src="/assets/images/illustrations/Deplofy_GIF.gif"
                        id="welcomeGif"
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "21px",
                      }}
                    >
                      Please click on the button to login
                    </p>
                  </div>
                </form>
              );
            }
          })()}
          {(() => {
            if (signedIn === 0) {
              return (
                <>
                  {(() => {
                    if (SignSteps == 1) {
                      return (
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={handleNext}
                          style={{
                            width: "120px",
                            height: "40px",
                            fontFamily: "poppins",
                            fontSize: "16px",
                            color: "#011015",
                            border: "1px solid #011015",
                            margin: "20px 0px",
                          }}
                        >
                          Next Step
                        </Button>
                      );
                    } else if (SignSteps == 2) {
                      return (
                        <>
                          <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => setSignSteps(1)}
                            style={{
                              width: "120px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontSize: "16px",
                              color: "#011015",
                              border: "1px solid #011015",
                              margin: "20px 0px",
                            }}
                          >
                            Previous
                          </Button>
                          <Button
                            fullWidth
                            variant="outlined"
                            type="submit"
                            onClick={(e) => first(e)}
                            style={{
                              width: "120px",
                              height: "40px",
                              fontFamily: "poppins",
                              fontSize: "16px",
                              color: "#011015",
                              border: "1px solid #011015",
                              margin: "20px 10px",
                            }}
                          >
                            Get OTP
                          </Button>
                        </>
                      );
                    }
                  })()}
                </>
              );
            } else if (signedIn == 1) {
              return (
                <Button
                  fullWidth
                  variant="outlined"
                  type="submit"
                  onClick={(e) => first(e)}
                  style={{
                    width: "120px",
                    height: "40px",
                    fontFamily: "poppins",
                    fontSize: "16px",
                    color: "#011015",
                    border: "1px solid #011015",
                    margin: "20px 0px",
                  }}
                >
                  Verify OTP
                </Button>
              );
            } else if (signedIn == 2) {
              return (
                <Button
                  fullWidth
                  variant="outlined"
                  type="submit"
                  onClick={finish}
                  style={{
                    width: "120px",
                    height: "40px",
                    fontFamily: "poppins",
                    fontSize: "16px",
                    color: "#011015",
                    border: "1px solid #011015",
                    margin: "2px 0px 20px 30%",
                  }}
                >
                  Finish
                </Button>
              );
            }
          })()}
        </div>
      </div>
    </div>
  );
};
export default SignUp;
