import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Collapse,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const ClientSim = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const ICCID = localStorage.getItem("iccid");
  const [userData, setUserData] = useState([]);
  const [InventoryGroupsData, setInventoryGroupsData] = useState([]);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [orderBy, setOrderBy] = useState("packageTypeId");
  const [order, setOrder] = useState("asc");
  const [filteredData, setFilteredData] = useState([]);
  const [FormatFilter, setFormatFilter] = useState("");

  const UserSim = async () => {
    try {
      const raw = "";
      const requestOptions = {
        method: "POST",
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${REACT_APP_BASE_URL}/cdr/api/v1/cdr_by_iccid?iccid=${ICCID}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      setFilteredData(result.cdrData);
    } catch (error) {
    }
  };

  useEffect(() => {
    UserSim();
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value.toLowerCase());
  };

  // Function to handle sorting
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      setOrder(order === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  //These Functions are for the reports download   -- Starts here

  // This is for the PDF format
  const generatePDF = () => {
    const report = new jsPDF("portrait", "mm", "a4");
    const table = document.getElementById("Table-Data");
    // Use jsPDF autoTable plugin to add table to PDF
    report.autoTable({
      html: table,
      theme: "striped",
      styles: { fontSize: 8 },
      margin: { top: 15 },
    });
    report.save("CDR_Reports.pdf");
  };

  // This is for the Excel format
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "CDR_Report" + fileExtension);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Box
        className="breadcrumb"
        style={{ display: "flex", marginBottom: "13px" }}
      >
        <H2>Call Detail Report </H2>


        <br />

        <div style={{ marginLeft: "49%", marginTop: "4px", width: "30%" }}>
          <div className="inputInfoBox" style={{ width: "100%", margin: "0px" }}>
            <p id="inputInfoTopText">
              Select Format
              <Tooltip
                title="Select a format to download the report"
                placement="top-start"
              >
                <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
              </Tooltip>
            </p>
            <Select
              value={FormatFilter}
              onChange={(e) => setFormatFilter(e.target.value)}
              displayEmpty
              style={{ height: "39px", margin: "0px", width: "100%" }}
            >
              <MenuItem value="">--Select Downloadable Format--</MenuItem>
              <MenuItem value="excel" onClick={() => exportToExcel()}>
                excel
              </MenuItem>
              <MenuItem onClick={generatePDF} value="pdf">
                pdf
              </MenuItem>
            </Select>
          </div>
        </div>
      </Box>

      <br />
      <br />

      <Box width="100%" overflow="auto">
        <StyledTable id='Table-Data' style={{ color: "gray", width: "100%" }}>
          <TableHead>
            <TableRow
              style={{
                backgroundColor: " rgb(233 226 226 / 35%)",
                margin: "5px",
              }}
            >
              <TableCell
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  paddingLeft: "20px",
                  cursor: "pointer",
                }}
                align="left"
                onClick={() => handleSort("cdrData")}
              >
                ICCID
                <TableSortLabel
                  active={orderBy === "cdrData"}
                  direction={orderBy === "cdrData" ? order : "asc"}
                ></TableSortLabel>
              </TableCell>

              <TableCell
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                align="center"
              >
                CONNECT TIME
              </TableCell>
              <TableCell
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                align="center"
              >
                CLOSE TIME
              </TableCell>

              <TableCell
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                align="center"
              >
                COUNTRY NAME
              </TableCell>
              <TableCell
                style={{
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                align="center"
              >
                DATA USAGE(Byte)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: "10px," }}>
            {filteredData.length > 0 ? (
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <TableRow key={item.id}>
                    <TableCell
                      style={{
                        fontSize: "13px",
                        paddingLeft: "20px",
                        color: "blue",
                      }}
                      align="left"
                    >
                      {item.ICCID}
                    </TableCell>

                    <TableCell style={{ fontSize: "13px" }} align="center">
                      {epochToDate(item.CONNECT_TIME)}
                    </TableCell>
                    <TableCell style={{ fontSize: "13px" }} align="center">
                      {epochToDate(item.CLOSE_TIME)}
                    </TableCell>

                    <TableCell style={{ fontSize: "13px" }} align="center">
                      {item.COUNTRY_NAME}
                    </TableCell>

                    <TableCell style={{ fontSize: "13px" }} align="center">
                      {item.DURATION}
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 35]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Container>
  );
};

export default ClientSim;
