import {
  Card,
  Chip,
  Container,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import {
  Collapse,
  MenuItem,
  Select,
} from "@mui/material";
import { TextField, Grid } from "@mui/material";
import {
  Box,
  Icon,
  styled,
  Table,
  Button,
  Input,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination"; 
import { H2, H3, H4, H5 } from "../Typography";
import { SimpleCard } from "..";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import { useTheme } from "@mui/material/styles";

import Modal from "@mui/material/Modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
      maxHeight: 200,
    },
  },
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  // height: '200px',
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SMSGateways_T = () => {
  const REACT_APP_BASE_URL_SMPP = process.env.REACT_APP_BASE_URL_SMPP;
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [CredentialName, setCredentialName] = useState("");
  const [Sid, setSId] = useState("");
  const [AuthToken, setAuthToken] = useState("");
  const [MobileNum, setMobileNum] = useState("");
  const [ShortCode, setShortCode] = useState("");

  const [Credentials, setCredentials] = useState([]);
  const [CredentialId, setCredentialId] = useState("");
  const [CredentialDetails, setCredentialDetails] = useState("");
  const [CredentialsLength, setCredentialsLength] = useState("");
  const [CampaignListLength, setCampaignListLength] = useState("");

  const [TemplatesList, setTemplatesList] = useState([]);
  const [TemplateId, setTemplateId] = useState("0");
  const [Message, setMessage] = useState("");
  const [NewTemplateName, setNewTemplateName] = useState("");

  const [CampaignList, setCampaignList] = useState([]);
  const [CampaignId, setCampaignId] = useState("0");
  const [NewCampaignName, setNewCampaignName] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [CampaignDetailBox, setCampaignDetailBox] = useState(false); 

  const [AddContactForm, setAddContactForm] = useState(false);
  const [ContactNum, setContactNum] = useState("");
  const [ContactList, setContactList] = useState([]);

  const [contactFname, setcontactFname] = useState("");
  const [contactLname, setcontactLname] = useState("");
  const [NewContactObject, setNewContactObject] = useState({
    FirstName: "",
    LastName: "",
    UserUUId: "",
    Number: "",
  });
  const [NewContactArray, setNewContactArray] = useState([]);

  const [open, setOpen] = useState(false);
  const [TemplateNameBox, setTemplateNameBox] = useState(false);
  const [campaignNameBox, setcampaignNameBox] = useState(false);

  const [openCredentialBox, setOpenCredentialBox] = useState(false);
  const [openCredentialDetails, setOpenCredentialDetails] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleSelectTemplateId = (e) => {
    setTemplateId(e.target.value);
    setTemplateNameBox(false);
  };

  const handleNewTemplate = () => {
    setTemplateNameBox(true);
    setNewTemplateName("");
    setMessage("");
    setTemplateId("0");
  };

  const getUserNameById = (userId) => {
    const user = ContactList.find((contact) => contact.UserUUID === userId);
    return user ? user.UserName : "";
  };

  const handleAddCredential = () => {
    if (!CredentialName || !Sid || !AuthToken || !MobileNum || !ShortCode) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
      credentialName: CredentialName,
      twilio_sid: Sid,
      twilio_auth_token: AuthToken,
      twilio_phone_number: MobileNum,
      twilio_short_code: ShortCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/credentials`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else if (result.Status == 1) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setOpen(false);
        }
      })
  };

  const showCredentials = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/get_credentials`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
       
        setCredentials(result.CredentialDetails);
        if (result.CredentialDetails.length > 0) {
          setCredentialsLength(result.CredentialDetails.length);
        } else {
          setCredentialsLength("0");
        }
      })
  };

  useEffect(() => {
    showCredentials();
  }, []);

  const saveSelectedCredentialId = (id) => {
    setCredentialId(id);
    setOpenCredentialBox(false);
  };

  const showCredentialDetails = (ID) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      credentialId: ID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/get_credentialsDetails`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
      
        setCredentialDetails(result.CredentialDetails);
      })
  };

  const handleShowTemplates = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/get-templates`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
      
        setTemplatesList(result.templateDetails);
      })
  };

  useEffect(() => {
    handleShowTemplates();
  }, []);

  const handleShowTemplateDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      templateId: TemplateId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/getTemplate_byTemplateId`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
       
        setMessage(result.template.message_body);
      })
  };

  useEffect(() => {
    handleShowTemplateDetails();
  }, [TemplateId]);

  const createNewCampaign = () => {
    if (!NewCampaignName) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
      campaign_name: NewCampaignName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/create_campaign`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 1) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setcampaignNameBox(false);
          setNewCampaignName("");
          handleShowCampaigns();
        } else if (result.Status == 0) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };

  const createNewTemplate = () => {
    if (!NewTemplateName || !Message) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
      template_name: NewTemplateName,
      message_body: Message,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/create_template`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        } else if (result.Status == 1) {
          toast(result.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleShowTemplates();
          setTemplateNameBox(false);
          setNewTemplateName("");
          setMessage("");
          setTemplateId("0");
        }
      })
  };

  const handleShowCampaigns = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/getcampaign_by_tenantuuid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCampaignList(result.campaigns);
        if (result.campaigns.length > 0) {
          setCampaignListLength(result.campaigns.length);
        } else {
          setCampaignListLength("0");
        }
      })
  };

  useEffect(() => {
    handleShowCampaigns();
  }, []);

  useEffect(() => {
    handleShowCampaignDetails();
  }, [CampaignId]);

  const handleShowCampaignDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      campaign_id: CampaignId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/getcampaign_by_campaignid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCampaignName(result.campaign_name);
      })
  };

  const toggleCollapse = () => {
    setOpen(!open);
    setOpenCredentialBox(!openCredentialBox);
  };

  const showContactList = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/fetch_clientMobileNo`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setContactList(result.TenantDetails);
      })
  };

  useEffect(() => {
    showContactList();
  }, []);

  const showSelectedContact = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserUUID: ContactName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/user/api/v1/get_client`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setcontactFname(result.Account_details.get_user[0].FirstName);
        setcontactLname(result.Account_details.get_user[0].LastName);
      })
  };

  useEffect(() => {
    showSelectedContact();
  }, [ContactName]);

  const handleCreateContact = () => {
    const newContactEntry = {
      contact_id: ContactName,
      Name: ContactName,
      contact_number: ContactNum,
    };

    setNewContactArray((prevArray) => [...prevArray, newContactEntry]);

    setNewContactObject({
      FirstName: "",
      LastName: "",
      UserUUId: "",
      Number: "",
    });

    setContactName("0");
    setContactNum("");
  };

  const handleAddContact = () => {
  };
  const [details, setDetails] = useState([]);
  const convertUUID = () => {
    const commaSeparatedIds = ContactName.join(",");

    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/clientMobileNo_by_Useruuid?UserUUID=${commaSeparatedIds}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDetails(result.TenantDetails);
        const formattedContactList = result.TenantDetails.map((contact) => ({
          ...contact,
          contact_number: `+${contact.contact_number}`,
        }));

        sendMessage(formattedContactList);
      })
  
  };

  const sendMessage = (formattedContactList) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      tenant_uuid: TenantUUID,
      credentialId: CredentialId,
      templateId: TemplateId,
      contacts: formattedContactList,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_SMPP}/smpp/api/v1/single-sms_byICCID`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Message Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
  };
  const [ContactName, setContactName] = useState([]);

  const handleContactChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    const selectedValues = event.target.value;
    setContactName(selectedValues);
  };

  useEffect(() => {
  }, [handleCreateContact]);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <Container
      style={{
        padding: "20px",
        width: "100%",
      }}
    >
      <Box
        className="breadcrumb"
        style={{ display: "flex", marginBottom: "10px" }}
      >
        <H2>SMS Gateway | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          {" "}
          Send SMS
        </p>
      </Box>

      <Collapse in={open}>
        <Card
          style={{
            boxShadow: "rgba(0, 0, 0, 0.1) - 4px 9px 15px - 6px",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              padding: "10px",
              margin: "10px",
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Enter Your Credential Name"
                  onChange={(e) => setCredentialName(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Enter Your SID"
                  onChange={(e) => setSId(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Enter Your Auth Token "
                  onChange={(e) => setAuthToken(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Enter Your Mobile Number "
                  onChange={(e) => setMobileNum(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Enter Your Short Code"
                  onChange={(e) => setShortCode(e.target.value)}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              padding: "0px 0px 5px 10px",
            }}
          >
            {" "}
            <Button
              style={{ margin: "10px", color: "red" }}
              variant="outlined"
              color="inherit"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              style={{ margin: "10px", color: "green" }}
              variant="outlined"
              color="inherit"
              onClick={handleAddCredential}
            >
              Add
            </Button>
          </div>
        </Card>
      </Collapse>

      <Card
        style={{
          padding: "10px",
          paddingTop: "15px",
          marginBottom: "10px",
        }}
      >
        <Box
          className="breadcrumb"
          style={{ display: "flex", marginBottom: "10px" }}
        >
          <p
            style={{
              margin: "5px",
              fontSize: "17px",
            }}
          >
            {" "}
            MANAGE SMS
          </p>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setOpenCredentialBox(true)}
            style={{
              marginLeft: "auto",
              marginRight: "10px",
              color: "#485460",
            }}
          >
            Select Credentials
          </Button>
          <Dialog
            open={openCredentialBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpenCredentialBox(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle style={{ width: "400px" }}>
              Select Credentials
            </DialogTitle>
            <List sx={{ pt: 0 }}>
              {CredentialsLength > 0 ? (
                Credentials.map((details) => (
                  <ListItem disableGutters key={details.credentialId}>
                    <ListItemButton
                      onClick={(id) =>
                        saveSelectedCredentialId(details.credentialId)
                      }
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                          <RecentActorsOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={details.credentialName} />
                    </ListItemButton>
                    <Button
                      variant="outlined"
                      color="inherit"
                      onClick={() => {
                        showCredentialDetails(details.credentialId);
                        setOpenCredentialDetails(true);
                      }}
                      style={{
                        marginLeft: "auto",
                        color: "#8224e3",
                        marginRight: "10px",
                      }}
                    >
                      Details
                    </Button>
                  </ListItem>
                ))
              ) : (
                <MenuItem>--No data now--</MenuItem>
              )}
              <ListItem disableGutters>
                <ListItemButton autoFocus onClick={toggleCollapse}>
                  <ListItemAvatar>
                    <Avatar>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Add New Credential" />
                </ListItemButton>
              </ListItem>
            </List>
          </Dialog>

          {/* Credentials Details */}
          <Modal
            open={openCredentialDetails}
            onClose={() => setOpenCredentialDetails(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <div
                className="box1_1"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "15px",
                  padding: "0px 10px",
                }}
              >
                <p
                  style={{
                    fontSize: "23px",
                    letterSpacing: "1px",
                  }}
                >
                  Credential Details
                  <hr
                    style={{
                      border: "1px lightGrey solid",
                      width: "100%",
                    }}
                  />
                </p>

                <table
                  style={{
                    padding: "2px 0px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                        width: "40%",
                      }}
                    >
                      Credential ID :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.credentialId}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      {" "}
                      Credential Name :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.credentialName}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      SID :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.twilio_sid}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      Auth Token :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.twilio_auth_token}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                        width: "30%",
                      }}
                    >
                      Phone Number :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.twilio_phone_number}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      Short Code :
                    </td>
                    <td
                      style={{
                        padding: "2px 25px ",
                      }}
                    >
                      {CredentialDetails.twilio_short_code}
                    </td>
                  </tr>
                </table>
              </div>
              <Button
                onClick={() => {
                  setOpenCredentialDetails(false);
                  setCredentialDetails("");
                }}
              >
                Close Details
              </Button>
            </Box>
          </Modal>
        </Box>

        <hr
          style={{
            border: "1px lightGrey solid",
            width: "100%",
          }}
        />

        <Grid
          container
          spacing={2}
          columns={16}
          style={{ margin: "0px", marginTop: "15px" }}
        >
          <Grid
            item
            xs={7.5}
            style={{
              border: "1px #d2dae2 solid",
              boxShadow: "0px 0px 2px #d2dae2",
              minHeight: "80vh",
              marginLeft: "5px",
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <H3>Select Contact</H3>

            
              <Select
                labelId="contact-name-label"
                id="contact-name"
                displayEmpty
                multiple
                value={ContactName}
                onChange={handleContactChange}
                inputProps={{ "aria-label": "Without label" }}
                style={{ minHeight: "30px", marginTop: "10px" }}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      width: 420,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip key={value} label={getUserNameById(value)} />
                    ))}
                  </Box>
                )}

                MenuProps={MenuProps}
              >
                <MenuItem disabled value="">
                  --Select Name--
                </MenuItem>
                {ContactList.map((contacts, index) => (
                  <MenuItem
                    key={index}
                    value={contacts.UserUUID}
                  >
                    <Checkbox
                      checked={ContactName.includes(contacts.UserUUID)}
                    />
                    {contacts.UserName}
                  </MenuItem>
                ))}
              </Select>
            </div>
           
          </Grid>

          <Modal
            open={AddContactForm}
            onClose={() => setAddContactForm(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style, width: 700 }}>
              <H3>Select Contacts</H3>
              <br />
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <Select
                  labelId="contact-name-label"
                  id="contact-name"
                  multiple
                  value={ContactList}
                  onChange={(e) => setContactName(e.target.value)}
                  input={<Input />}
                  style={{ height: "35px", width: "60%", margin: "0px 5px" }}
                >
                  <MenuItem disabled value="0">
                    --Select Name--
                  </MenuItem>
                  {ContactList.length > 0 ? (
                    ContactList.map((contacts, index) => (
                      <MenuItem key={index} value={contacts.PhoneNumber}>
                        <Checkbox
                          checked={
                            ContactName.indexOf(contacts.PhoneNumber) > -1
                          }
                        />
                        <ListItemText primary={contacts.UserName} />
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled value="0">
                      --No Names Data--
                    </MenuItem>
                  )}
                </Select>

                <Button
                  style={{ color: "green" }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleCreateContact}
                >
                  <AddIcon />
                </Button>
              </Grid>

              {NewContactArray.length > 0
                ? NewContactArray.map((contacts, index) => (
                  <Grid
                    item
                    style={{
                      display: "flex",
                      border: "1px #d2dae2 solid",
                      boxShadow: "0px 0px 2px #d2dae2",
                      paddingLeft: "10px",
                    }}
                  >
                    <p style={{ width: "100%" }}>
                      {" "}
                      Name : {contacts.first_name} {contacts.last_name}{" "}
                    </p>
                    <p style={{ width: "100%" }}>
                      {" "}
                      Number : {contacts.contact_number}{" "}
                    </p>
                  </Grid>
                ))
                : null}

              <Button
                style={{
                  color: "green",
                  transform: "scale(.9)",
                  marginTop: "10px",
                }}
                variant="outlined"
                color="inherit"
                onClick={() => handleAddContact(true)}
              >
                Add
              </Button>
              <Button
                style={{
                  color: "green",
                  transform: "scale(.9)",
                  marginTop: "10px",
                }}
                variant="outlined"
                color="inherit"
                onClick={() => setAddContactForm(false)}
              >
                Cancel
              </Button>
            </Box>
          </Modal>

          <Grid
            item
            xs={8}
            style={{
              border: "1px #d2dae2 solid",
              boxShadow: "0px 0px 2px #d2dae2",
              minHeight: "80vh",
              marginLeft: "5px",
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <H3>Templates</H3>
              <Button
                style={{ color: "#485460", transform: "scale(.9)" }}
                variant="outlined"
                color="inherit"
                onClick={handleNewTemplate}
              >
                Add New Template
              </Button>
            </div>

            <div
              className="inputInfoBox"
              style={{ width: "97%", margin: "0px", marginTop: "10px" }}
            >
              <p
                id="inputInfoTopText"
                style={{ display: "flex", fontSize: "14px" }}
              >
                Select Template
                <Tooltip
                  title="Select a template from the list"
                  placement="right"
                >
                  <Icon style={{ transform: "scale(.7)", color: "#4b7bec" }}>
                    info_outline
                  </Icon>
                </Tooltip>
              </p>
              <Select
                value={TemplateId}
                onChange={handleSelectTemplateId}
                displayEmpty
                style={{ height: "35px", margin: "0px", width: "100%" }}
              >
                <MenuItem disabled value="0">
                  --Select Template--
                </MenuItem>
                {TemplatesList.length > 0 ? (
                  TemplatesList.map((templates, index) => (
                    <MenuItem key={index} value={templates.templateId}>
                      {templates.template_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>--No Templates Data--</MenuItem>
                )}
              </Select>
            </div>

            <Collapse in={TemplateNameBox}>
              <div
                style={{
                  display: "flex",
                  width: "97%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  margin: "12px 0px",
                }}
              >
                <p style={{ width: "40%" }}> Enter name for template : </p>
                <TextField
                  style={{ width: "60%" }}
                  label="Template Name"
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  value={NewTemplateName}
                  fullWidth
                  size="small"
                />
              </div>
            </Collapse>

            <textarea
              style={{
                height: "35%",
                width: "97%",
                marginTop: "8px",
                padding: "9px",
                borderRadius: "6px",
              }}
              value={Message}
              placeholder="Type message for template here…"
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <Collapse in={TemplateNameBox}>
              <Button
                style={{ color: "green", transform: "scale(.9)" }}
                variant="outlined"
                color="inherit"
                onClick={createNewTemplate}
              >
                Create Template
              </Button>
              <Button
                style={{ color: "red", transform: "scale(.9)" }}
                variant="outlined"
                color="inherit"
                onClick={() => setTemplateNameBox(false)}
              >
                Cancel
              </Button>
            </Collapse>

            <Button
              style={{
                color: "red",
                transform: "scale(.9)",
                marginTop: "10px",
              }}
              variant="outlined"
              color="inherit"
              onClick={convertUUID}
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};
export default SMSGateways_T;
