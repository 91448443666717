import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react'
import { H1 } from 'app/components/Typography'
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {

    const { children, value, index, ...other } = props;

  

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [value, setValue] = React.useState(0);
    const [name, setName] = useState('');
    const [datecreated, setDateCreated] = useState('');
    const [datemodified, setDateModified] = useState('');
    const [status, setStatus] = useState('')
    const [totalSim, setTotalSims] = useState('');
    const [parentGroupId, setParentGroupID] = useState('');
    const [parentGroupName, setParentGroupName] = useState('');
    const [company, setCompany] = useState('')
    const TenantUUID = localStorage.getItem('TenantUUID');
    const navigate = useNavigate();

    let id = localStorage.getItem('id')
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const fetchbalance = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            TenantUUID: TenantUUID
        })

        const requestOptions = {
            headers: myHeaders,
            body: raw,
            method: 'POST',
            redirect: 'follow'
        }
        const response = await fetch(`${REACT_APP_BASE_URL}/inventory_balance?inventoryId=49182`, requestOptions);
        const result = await response.json();
        setDateModified(result.InventoryBalance.dateModified)
    }
    const fetchoverdraft = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            TenantUUID: TenantUUID
        })
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        }
        const response = await fetch(`${REACT_APP_BASE_URL}/inventory_overdraft?inventoryId=49182`, requestOptions);
        const result = await response.json();
    }

    const fetchInventoryDetails = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
            "inventoryId": id
        })
        const requestOptions = {
            headers: myHeaders,
            body: raw,
            method: 'POST',
            redirect: 'follow'
        }
        const response = await fetch(`${REACT_APP_BASE_URL}/api/v1/inventory_details_by_id`, requestOptions);
        const result = await response.json();

        setName(result.InventoryData.name)
        setDateCreated(result.InventoryData.createdOn)
        setCompany(result.InventoryData.distributorId)
        setStatus(result.InventoryData.status);
        setTotalSims(result.InventoryData.totalSim);
        setParentGroupID(result.InventoryData.parentGroupId);
        setParentGroupName(result.InventoryData.parentGroupName)


    }

    useEffect(() => {
        fetchInventoryDetails();
        fetchbalance();
        fetchoverdraft();
    }, []);

    function epochToDate(epochTimestamp) {
        // Create a new Date object from the epochTimestamp
        const date = new Date(epochTimestamp);

        // Extract date components
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Create a formatted date string
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }

    // Example usage:
    const epochTimestamp = datecreated; // Replace with your epoch timestamp
    const formattedDate = epochToDate(epochTimestamp);

    function EpochToDate(timestamp) {
        const date = new Date(timestamp);

        // Extract date components
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        const FormatteDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return FormatteDate;
    }
    const timestamp = datemodified; // Replace with your epoch timestamp
    const FormatteDate = EpochToDate(timestamp);

    function centsToUSD(cents) {
        return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    function isoDate(inputDateString) {
        const date = new Date(inputDateString)

        const formattedDate = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }).format(date);
        return formattedDate;
    }

    const SIMDetails = () => {
        localStorage.setItem("id", id);
        navigate("/inventory/esim");
      }

    return (
        <>
            <Container>

                <Box sx={{ height: '300px', typography: 'body1' }} style={{
                    backgroundColor: 'white'
                }}>


                    <div style={{ margin: '8px', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <div className="box1" style={{
                            width: '48%',
                            height: 'auto',
                            padding: '0px 25px',
                            backgroundColor: 'rgb(233 226 226 / 35%)',
                            borderRadius: '25px',

                        }} >
                            <p style={{
                                fontSize: '24px'
                            }} >General Details <hr /></p>
                            <table>
                                <tr>
                                    <td>Inventory Name :</td>
                                    <td style={{ padding: '2px 25px' }}>{name}</td>
                                </tr>
                                <tr>
                                    <td>Inventory ID :</td>
                                    <td style={{ padding: '2px 25px' }}>{id}</td>
                                </tr>
                                <tr>
                                    <td>Date Created (UTC) :</td>
                                    <td style={{ padding: '2px 25px' }}>{EpochToDate(datecreated)}</td>
                                </tr>

                                <tr>
                                    <td>Parent Group ID :</td>
                                    <td style={{ padding: '2px 25px' }}>{parentGroupId}</td>
                                </tr>
                                <tr>
                                    <td>Parent Group Name :</td>
                                    <td style={{ padding: '2px 25px' }}>{parentGroupName}</td>
                                </tr>
                                <tr>
                                    <td>Total SIM Cards :</td>
                                    <td onClick={(id) => SIMDetails(id)} style={{ cursor: 'pointer' , color: "#8224E3", padding: "2px 25px" }}>{totalSim}</td>
                                </tr>
                                <tr>
                                    <td>Status :</td>
                                    <td style={{ padding: '2px 25px' }}>{status}</td>
                                </tr>
                            </table>
                        </div>



                    </div>
                </Box>
            </Container>
        </>

    );
}
