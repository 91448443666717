import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import "./AdminSIM.css";

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
  Checkbox,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const Suspended_Admin = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [simData, setSimData] = useState([]);
  const [status, setStatus] = useState("");

  const [orderBy, setOrderBy] = useState("iccid");
  const [order, setOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");

  const [iccid, setIccid] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (iccid) => {
    const updatedSelectedRows = selectedRows.includes(iccid)
      ? selectedRows.filter((id) => id !== iccid)
      : [...selectedRows, iccid];

    setSelectedRows(updatedSelectedRows);
  };

  const navigate = useNavigate();



  const fetchSims = () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/suspended_sim_list`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSimData(result.SuspendedSims);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    fetchSims();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = simData.filter(
    (sim) =>
      sim.statusOfSim.toLowerCase() === "pre-service" &&
      (sim.iccid.toLowerCase().includes(searchText.toLowerCase()) ||
        sim.statusOfSim.toLowerCase().includes(searchText.toLowerCase()) ||
        sim.parentGroupId.toLowerCase().includes(searchText.toLowerCase()) ||
        "Deplofy Inventory".toLowerCase().includes(searchText.toLowerCase()) ||
        sim.Provider.toLowerCase().includes(searchText.toLowerCase()) ||
        sim.SkuId.toLowerCase().includes(searchText.toLowerCase()) ||
        sim.inventoryId.toLowerCase().includes(searchText.toLowerCase())
        
        ) 
  );

  const sortedData = filteredData.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  const simInfo = (iccid) => {
    localStorage.setItem("iccid", iccid);
    navigate("/inventory/admin-siminfo");
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>SIM Cards| </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Suspended SIM Cards
          </p>
        </Box>

        <SimpleCard title="Suspended SIM Cards" style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              height: "70px",
            }}
          >
            <input
              style={{
                height: "40px",
                width: "28%",
                border: "1px solid  lightgrey",
                paddingLeft: "10px",
                borderRadius: "5px",
                outline: "1px black lightgrey",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {selectedRows.length > 0 && (
                <div style={{ width: "100%", margin: "0px 55px 3px 0px" }}>
                  <p id="inputInfoTopText">
                    Action
                    <Tooltip title="Select an action" placement="top-start">
                      <IconButton onClick={handleClick}>
                        <Icon style={{ transform: "scale(.7)" }}>
                          format_list_bulleted
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </p>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>Reconnect</MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <Box
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <StyledTable
              stickyHeader
              style={{
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingRight: "8px" }} align="center">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < sortedData.length
                      }
                      checked={
                        sortedData.length > 0
                          ? selectedRows.length === sortedData.length
                          : false
                      }
                      onChange={() =>
                        selectedRows.length === sortedData.length
                          ? setSelectedRows([])
                          : setSelectedRows(sortedData.map((sim) => sim.iccid))
                      }
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      paddingLeft: "10px",
                      width: "18%",
                      cursor: "pointer",
                    }}
                    align="center"
                    onClick={() => handleSort("iccid")}
                  >
                    ICCID
                    <TableSortLabel
                      active={orderBy === "iccid"}
                      direction={orderBy === "iccid" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Parent Group ID
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Inventory ID
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    SKU ID
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Provider
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.length > 0 ? (
                  sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (
                      <TableRow key={sim.iccid}>
                        <TableCell align="center">
                          <Checkbox
                            checked={selectedRows.includes(sim.iccid)}
                            onChange={() => handleCheckboxChange(sim.iccid)}
                          />
                        </TableCell>
                        <TableCell
                          id="iccid"
                          align="center"
                          onClick={() => simInfo(sim.iccid)}
                        >
                          {sim.iccid}
                        </TableCell>

                        <TableCell align="center">{sim.parentGroupId}</TableCell>

                        <TableCell align="center">{sim.inventoryId}</TableCell>
                        <TableCell align="center">{sim.SkuId}</TableCell>

                        <TableCell align="center">{sim.Provider}</TableCell>
                        <TableCell align="center">{sim.statusOfSim}</TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 500]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>
    </>
  );
};

export default Suspended_Admin;
