import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import CreateSupervisor from "./CreateSupervisor";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const InactiveUsers = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showNewUser, setShowNewUser] = useState(false);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const tenantUuid = localStorage.getItem("tenant_uuid");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const TenantUUID = localStorage.getItem("TenantUUID");

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("FirstName");
  const [order, setOrder] = useState("asc");

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterAndSortedInactiveUsers = userData
    .filter((user) => user.Status === "Logged Out")
    .filter((user) => {
      const fullName =
        `${user.FirstName} ${user.LastName} ${user.Timezone} ${user.Country} ${user.PhoneNumber} ${user.MaxTenantLimit}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const isAsc = order === "asc";
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    });

  useEffect(() => {
    fetchUser();
  }, []);
  
  const fetchUser = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/user/api/v1/get_all_Client/${TenantUUID}`
      );
      const result = await response.json();
      if (result.Status === 1) {
        setUserData(result.Account_details);
      } else {
      }
    } catch (error) {
    }
  };

  const handleDeleteUser = (UserUUID) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      UserUUID: UserUUID,
    });

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch`${REACT_APP_BASE_URL}/user/api/v1/delete_client, requestOptions`
      .then((response) => response.text())
      .then((result) => {
        toast("User Deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000, // Close the toast after 2 seconds (adjust as needed)
        });
        // Instead of window.location.reload, you can simply remove the deleted user from the list.
        setUserData(userData.filter((user) => user.UserUUID !== UserUUID));
      })
  };

  const handleButtonClick = () => {
    navigate("/client/add-client");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditUser = (userUuid, emailId) => {
    // Programmatically navigate to the '/material/checkbox' route
    localStorage.setItem("edit_user_uuid", userUuid);
    localStorage.setItem("edit_emailId", emailId);
    navigate("/client/edit-client");
  };
  const handleChange = async (Enabled, UserUUID) => {
    if (Enabled === "false") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        UserUUID: UserUUID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/account/api/v1/enable_user,
          requestOptions`
        );
        const result = await response.json();
        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 2 seconds (adjust as needed)
        });
        fetchUser();
      } catch (error) {
      }
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        UserUUID: UserUUID,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/account/api/v1/disable_user`,
          requestOptions
        );
        const result = await response.json();

        toast(result.Message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000, // Close the toast after 2 seconds (adjust as needed)
        });
        fetchUser();
      } catch (error) {
      }
    }
  };


  return (
    <Container>
      <Box className="breadcrumb" style={{ display: "flex" }}>
        <H2>Clients | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          Inactive Clients
        </p>
        <Button
          variant="outlined"
          color="inherit"
          style={{
            marginLeft: "auto",
            width: "170px",
            height: "40px",
            color: "#8224e3",
          }}
          onClick={handleButtonClick}
        >
          Add New Client
        </Button>
        {showNewUser && <CreateSupervisor />}
      </Box>

      <SimpleCard title="Clients">
        <div>
          <div>
            <input
              style={{
                height: "36px",
                width: "25%",
                border: "1px solid  #dededf",
                paddingLeft: "10px",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <br />
        <Box width="100%" overflow="auto">
          <StyledTable>
            <TableHead style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: 'pointer'
                  }}
                  align="center"
                  onClick={() => handleSort("FirstName")}
                > First Name
                  <TableSortLabel
                    active={orderBy === "FirstName"}
                    direction={orderBy === "FirstName" ? order : "asc"}

                  >

                  </TableSortLabel>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  align="center"
                >
                  Last Name
                </TableCell>

                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  align="center"
                >
                  Mobile No
                </TableCell>

                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  align="center"
                >
                  Time Zone
                </TableCell>

                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterAndSortedInactiveUsers.length > 0 ? (
                filterAndSortedInactiveUsers
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{user.FirstName}</TableCell>
                      <TableCell align="center">{user.LastName}</TableCell>
                      <TableCell align="center">{user.PhoneNumber}</TableCell>
                      <TableCell align="center">{user.Timezone}</TableCell>
                      <TableCell align="center">
                        <Switch
                          defaultChecked={user.Enabled === "true"}
                          onChange={() =>
                            handleChange(user.Enabled, user.UserUUID)
                          }
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleDeleteUser(user.UserUUID)}
                          sx={{
                            ".text-effect": {
                              position: "absolute",
                              top: "50%",
                              right: "100%",
                              transform: "translateX(50%,-50%)",
                              display: "none",
                              textAlign: "center",
                              width: "100%",
                              border: "1px solid black",
                              backgroundColor: "white",
                            },
                            "&:hover .text-effect": {
                              display: "block",
                            },
                            position: "relative",
                          }}
                        >
                          <Icon color="error">delete</Icon>
                          <Typography variant="caption" className="text-effect">
                            Delete
                          </Typography>
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            handleEditUser(user.UserUUID, user.EmailId)
                          }
                          sx={{
                            ".text-effect": {
                              position: "absolute",
                              top: "-21%",
                              left: "20%",
                              transform: "translateX(50%,-50%)",
                              display: "none", // Initially hide the text
                              textAlign: "center",
                              width: "100%",
                              border: "1px solid black",
                              backgroundColor: "white",
                            },
                            "&:hover .text-effect": {
                              display: "block", // Show the text when hovering over the icon
                            },
                            position: "relative",
                          }}
                        >
                          <Icon>edit</Icon>
                          <Typography variant="caption" className="text-effect">
                            Edit
                          </Typography>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Inactive Client available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[7, 14, 25]}
            component="div"
            count={userData.length} // Total number of rows
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </SimpleCard>
    </Container>
  );
};

export default InactiveUsers;
