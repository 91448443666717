import { TabContext, TabList, TabPanel, TabPanel2 } from "@mui/lab";
import {
  Box,
  Container,
  Modal,
  Tab,
  Checkbox,
  Typography,
  Menu,
} from "@mui/material";
import { H1, H2 } from "app/components/Typography";
import { use } from "echarts";
import React from "react";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { loadStripe } from "@stripe/stripe-js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Collapse,
  TableSortLabel,
} from "@mui/material";
import { Navigate } from "react-router-dom";
//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  border: "0px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const UserDetails = (props) => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const UserUUID = localStorage.getItem("UserUUID");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [userlimit, setUserLimit] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [totalSims, setTotalSims] = useState("");
  const [activeSims, setActiveSims] = useState("");
  const [suspendedSims, setSuspendedSims] = useState("");
  const [simLimit, setSimLimit] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [timezone, setTimezone] = useState("");
  const [address1, setAddress1] = useState("");
  const [state, setState] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingPostal, setShippingPostal] = useState("");
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingAddress3, setShippingAddress3] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [orderBy, setOrderBy] = useState("iccid");
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("asc");
  const [filteredData, setFilteredData] = useState(props.data || []);
  const [filterdatalength, setFilterdatalength] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");
  const [length, setLength] = useState("");

  const [packageName, setPackageName] = useState("");
  const [packagePrice, setPackagePrice] = useState("");
  const navigate = useNavigate();

  const [value1, setValue1] = React.useState("1");
  const [value2, setValue2] = React.useState("1");

  const [PaymentConfirmationBox, setPaymentConfirmationBox] = useState(false);
  const [ICCID, setICCID] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = (props.data || []).filter((item) =>
      `${item.iccId} ${item.sku} ${item.statusOfSim}`
        .toLowerCase()
        .includes(term)
    );
    return filtered.includes(searchTerm.toLowerCase());
  };

  const handleSort = (column) => {
    const isAsc = orderBy === column && order === "asc";
    setOrderBy(column);
    setOrder(isAsc ? "desc" : "asc");
    const sortedData = (filteredData || []).slice().sort((a, b) => {
      if (isAsc) {
        return a[column] > b[column] ? 1 : -1;
      } else {
        return a[column] < b[column] ? 1 : -1;
      }
    });
    setFilteredData(sortedData);
  };


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    handleClose();
  };

  const handleCheckboxChange = (iccId) => {
    const updatedSelectedRows = selectedRows.includes(iccId)
      ? selectedRows.filter((selected) => selected !== iccId)
      : [...selectedRows, iccId];

    setSelectedRows(updatedSelectedRows);
  };

  const handleValue1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleValue2 = (event, newValue) => {
    setValue2(newValue);
  };
  const randomNumber = Math.floor(Math.random() * 900) + 100;

  // Construct the string with the generated number
  const generatedSKU = `POND-${randomNumber}`;

  useEffect(() => {
    TenantDetails();
    TenantSim();
    GetPrice();
  }, []);

  const TenantDetails = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      UserUUID: UserUUID,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${REACT_APP_BASE_URL}/user/api/v1/get_client`,
      requestOptions
    );
    const result = await response.json();
    setFirstName(result.Account_details.get_user[0].FirstName);
    setLastName(result.Account_details.get_user[0].LastName);
    setAddress(result.Account_details.get_user[0].Address);
    setMobile(result.Account_details.get_user[0].PhoneNumber);
    setUserLimit(result.Account_details.get_user[0].MaxTenantLimit);
    setEmail(result.Account_details.get_user[0].EmailId);
    setCountry(result.Account_details.get_user[0].Country);
    setTotalSims(result.Account_details.get_user[0].TotalSims);
    setActiveSims(result.Account_details.get_user[0].ActiveSims);
    setSuspendedSims(result.Account_details.get_user[0].SuspendedSims);
    setSimLimit(result.Account_details.get_user[0].SimLimit);
    setCity(result.Account_details.get_user[0].City);
    setPostalCode(result.Account_details.get_user[0].PostalCode);
    setTimezone(result.Account_details.get_user[0].Timezone);
    setAddress1(result.Account_details.get_user[0].AddressLine1);
    setState(result.Account_details.get_user[0].State);
    setShippingCountry(result.Account_details.get_user[0].ShippingCountry);
    setAddress2(result.Account_details.get_user[0].AddressLine2);
    setAddress3(result.Account_details.get_user[0].AddressLine3);
    setShippingState(result.Account_details.get_user[0].ShippingState);
    setShippingCity(result.Account_details.get_user[0].ShippingCity);
    setShippingPostal(result.Account_details.get_user[0].ShippingPostalCode);
    setShippingAddress1(result.Account_details.get_user[0].ShippingAddress1);
    setShippingAddress2(result.Account_details.get_user[0].ShippingAddress2);
    setShippingAddress3(result.Account_details.get_user[0].ShippingAddress3);
  };

  const TenantSim = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserUUID: UserUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_user_sims`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.Status === "0" &&
          result.message === "No user sims found for the specified user."
        ) {
          setLength("0");
        } else {
          setLength(result.Sims[0].iccids.length);
          setFilteredData(result.Sims[0].iccids);
          setFilterdatalength(result.Sims[0].iccids.length);
        }
      })
      .catch((error) => {
      });
  };

  const iccidInfo = (iccid) => {
    localStorage.setItem("iccid", iccid);
    navigate("/client/clientsim");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51OFJZ6SEcQuFHGfiI7eQ1fgYMhF8MfCY24Mw8IrLjEOSqUatXeFZqswfkBu4nqHDtbLUe0Lma6ieWHAZQuYR3h4T00ymT3ZfXQ"
    );

    const Product = {
      name: "Test Package",
      price: 100000,
      quantity: 1,
    };

    const body = {
      products: [Product],
      AccountType: "Tenant",
      packageName: "Test Package",
      packageId: "yieiioope1",
      packageDescription: "jdkjlfllfkkffkfk",
      UUID: TenantUUID,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      `${REACT_APP_BASE_URL}/stripe/api/v1/create-checkout-session`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();
    localStorage.setItem("SessionId", session.id);
    localStorage.setItem("PaymentFlag", "EnableSIM");
    localStorage.setItem("SelectedICCID", selectedRows);

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });
  };

  const enableSims = () => {
    const commaSeparatedString = selectedRows.join(",");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      simDataServiceStatus: "ENABLED",
      simVoiceServiceStatus: "DISABLED",
      simSmsServiceStatus: "DISABLED",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/enable_disable_multiplesim?iccid=${commaSeparatedString}`,
      requestOptions
    )
      .then((response) => response.json())

      .then((result) => {
        if (result.Status == 1) {
          toast("SIM Enabled Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          TenantSim();
        }
      })
      .catch((error) => {
      });
  };

  const disableSims = () => {
    const commaSeparatedString = selectedRows.join(",");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      simDataServiceStatus: "DISABLED",
      simVoiceServiceStatus: "DISABLED",
      simSmsServiceStatus: "DISABLED",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/enable_disable_multiplesim?iccid=${commaSeparatedString}`,
      requestOptions
    )
      .then((response) => response.json())

      .then((result) => {
        if (result.Status == 1) {
          toast("SIM Disabled Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          TenantSim();
        }
      })
      .catch((error) => {
      });
  };
  const activatepackage = () => {
    const commaSeparatedString = selectedRows.join(",");

    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/add_package_to_sim?iccid=${commaSeparatedString}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        toast("Package Activated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      
      })
      .catch((error) => {
      });
  };

  const GetPrice = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      packageId: "732607",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_price`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPackageName(result.Name);
        setPackagePrice(result.DynamicPrice);
      })
      .catch((error) => {
      });
  };

  const ClosePaymentConfirmationBox = () => {
    setPaymentConfirmationBox(false);
  };

  return (
    <>
      <Container>
        <Box
          sx={{ width: "100%", typography: "body1" }}
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {/*<---------------------------- tab view started ---------------------------->*/}
          <TabContext value={value1}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleValue1}
                aria-label="lab API tabs example"
              >
                <Tab label="Client Details" value="1" />
                <Tab label="SIM Cards" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div
                style={{
                  padding: "10px",
                  margin: "10px",
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto ",
                }}
              >
                <Box
                  className="breadcrumb"
                  style={{ margin: "15px 0px 5px 0px" }}
                ></Box>

                <div
                  className="box1"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="box1_1"
                    style={{
                      width: "55%",
                      height: "auto",
                      backgroundColor: "rgb(233 226 226 /35%)",
                      borderRadius: "15px",
                      padding: "0px 20px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "23px",
                        letterSpacing: "1px",
                      }}
                    >
                      General Details
                      <hr
                        style={{
                          border: "1px lightGrey solid",
                          width: "100%",
                        }}
                      />
                    </p>

                    <table
                      style={{
                        padding: "2px 0px",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          {" "}
                          First Name :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {firstName}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Last Name :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {lastName}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Email ID :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {email}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Mobile Number :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {mobile}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Total SIM :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {length}
                        </td>
                      </tr>

             
                      <tr>
                        <td
                          style={{
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          SIM Limit :
                        </td>
                        <td
                          style={{
                            padding: "2px 25px ",
                          }}
                        >
                          {simLimit}
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div
                    className="box1_2"
                    style={{
                      width: "55%",
                      backgroundColor: "rgb(233 226 226 /35%)",
                      borderRadius: "25px",
                      padding: "0px 20px",
                      marginLeft: "17px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "23px",
                        letterSpacing: "1px",
                      }}
                    >
                      Address Details
                      <hr
                        style={{
                          border: "1px lightGrey solid",
                          width: "100%",
                        }}
                      />
                    </p>
                    <TabContext value={value2}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleValue2}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Current Address" value="1" />
                          <Tab label="Shipping Address" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        <table
                          style={{
                            padding: "2px 0px",
                          }}
                        >
                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Address Line 1 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {address1}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {" "}
                              Address Line 2 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {address2}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {" "}
                              Address Line 3 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {address3}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Country :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {country}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              State :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {state}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              City :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {city}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Postal Code :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {postalCode}
                            </td>
                          </tr>
                        </table>
                      </TabPanel>
                      <TabPanel value="2">
                        <table
                          style={{
                            padding: "2px 0px",
                          }}
                        >
                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Address Line 1 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingAddress1}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {" "}
                              Address Line 2 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingAddress2}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              {" "}
                              Address Line 3 :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingAddress3}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Country :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingCountry}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              State :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingState}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              City :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingCity}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                fontSize: "14px",
                                color: "black",
                              }}
                            >
                              Postal Code :
                            </td>
                            <td
                              style={{
                                padding: "2px 25px ",
                              }}
                            >
                              {shippingPostal}
                            </td>
                          </tr>
                        </table>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel value="2">
              <div>
                <input
                  style={{
                    height: "36px",
                    width: "25%",
                    border: "1px solid  #dededf",
                    paddingLeft: "10px",
                  }}
                  name="search"
                  placeholder="Search..."
                  type="search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>

              <Box
                width="100%"
              >
                <div style={{ height: "50px" }}>
                  {selectedRows.length > 0 && (
                    <div
                      style={{
                        marginLeft: "90%",
                        marginTop: "0px",
                        width: "20%",
                        marginBottom: "2%",
                      }}
                    >
                      <div style={{ width: "100%", margin: "0px" }}>
                        <p id="inputInfoTopText">
                          Action
                          <Tooltip
                            title="Select an action"
                            placement="top-start"
                          >
                            <IconButton onClick={handleClick}>
                              <Icon style={{ transform: "scale(.7)" }}>
                                format_list_bulleted
                              </Icon>
                            </IconButton>
                          </Tooltip>
                        </p>

                        <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem onClick={enableSims} >
                        Enable SIM
                      </MenuItem>

                      <MenuItem onClick={disableSims} >
                        Disable SIM
                      </MenuItem>
                      <MenuItem onClick={activatepackage} >
                        Activate SIM
                      </MenuItem>
                    </Menu>
                      </div>
                    </div>
                  )}
                </div>

                <StyledTable
                  id="Table-Data"
                  style={{ color: "gray", width: "100%" }}
                >
                  <TableHead>
                    <TableRow
                      style={{
                        backgroundColor: " rgb(233 226 226 / 35%)",
                        margin: "5px",
                      }}
                    >
                      <TableCell style={{ paddingRight: "8px" }} align="center">
                        <Checkbox
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < filterdatalength
                          }
                          checked={
                            filterdatalength > 0
                              ? selectedRows.length === filterdatalength
                              : false
                          }
                          onChange={() =>
                            selectedRows.length === filterdatalength
                              ? setSelectedRows([])
                              : setSelectedRows(
                                  filteredData.map((item) => item.iccId)
                                )
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          paddingLeft: "20px",
                          cursor: "pointer",
                        }}
                        align="center"
                        onClick={() => handleSort("iccid")}
                      >
                        ICCID
                        <TableSortLabel
                          active={orderBy === "iccid"}
                          direction={orderBy === "iccid" ? order : "asc"}
                          onClick={() => handleSort("iccid")}
                        ></TableSortLabel>
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        align="center"
                      >
                        SKU ID
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "13px",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        align="center"
                      >
                        Enabled
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ fontSize: "10px" }}>
                    {length >= 1 ? (
                      filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell align="center">
                              <Checkbox
                                checked={selectedRows.includes(item.iccId)}
                                onChange={() =>
                                  handleCheckboxChange(item.iccId)
                                }
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                fontSize: "13px",
                                paddingLeft: "20px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              align="center"
                            >
                              {item.iccId}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "13px" }}
                              align="center"
                            >
                              {generatedSKU}
                            </TableCell>
                            <TableCell
                              style={{ fontSize: "13px" }}
                              align="center"
                            >
                              {item.statusOfSim}
                            </TableCell>

                            <TableCell
                              style={{ fontSize: "13px" }}
                              align="center"
                            >
                              <Tooltip
                                title={
                                  item.enabled === "true"
                                    ? "Enabled"
                                    : "Disabled"
                                }
                              >
                                {item.enabled === "true" ? (
                                  <CheckCircleOutlineIcon color="primary" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No SIM Cards Found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </StyledTable>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 35]}
                  component="div"
                  count={filterdatalength}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {/* This modal is for the payment confirmation   */}
                <Modal
                  open={PaymentConfirmationBox}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box sx={{ ...style }}>
                    <div
                      className="box1_1"
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "15px",
                        padding: "0px 10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "green",
                          margin: "15px 0px",
                        }}
                      >
                        <H2
                          style={{
                            fontSize: "25px",
                            letterSpacing: "1px",
                          }}
                        >
                          Payment Details
                        </H2>
                      </div>

                      <table
                        id="Table-Data"
                        style={{
                          padding: "5px 0px",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "black",
                              width: "40%",
                            }}
                          >
                            Package Name
                          </td>
                          <td
                            style={{
                              padding: "2px 25px ",
                            }}
                          >
                            {packageName}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Package Description :
                          </td>
                          <td
                            style={{
                              padding: "2px 25px ",
                            }}
                          >
                            Data Usage of 15 GB
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Email ID :
                          </td>
                          <td
                            style={{
                              padding: "2px 25px ",
                            }}
                          >
                            pankhuri.gandhi@startelelogic.com
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Amount To be Paid :
                          </td>
                          <td
                            style={{
                              padding: "2px 25px ",
                            }}
                          >
                            10000 (INR)
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "15px 0px ",
                      }}
                    >
                      <Button
                        style={{ color: "Blue", transform: "scale(.9)" }}
                        variant="outlined"
                        color="inherit"
                        onClick={makePayment}
                      >
                        Make Payment
                      </Button>

                      <Button
                        style={{ color: "red", transform: "scale(.9)" }}
                        variant="outlined"
                        color="inherit"
                        onClick={ClosePaymentConfirmationBox}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Modal>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
};

export default UserDetails;
