import { Container } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AdminInventoryInfo = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [name, setName] = useState("");
  const [datecreated, setDateCreated] = useState("");
  const [status, setStatus] = useState("");
  const [totalSim, setTotalSims] = useState("");
  const [company, setCompany] = useState("");
  const [parentGroupid, setParentGroupID] = useState("");
  const [parentGroupName, setParentGroupName] = useState("");

  let id = localStorage.getItem("id");

  const navigate = useNavigate();

  const fetchInventoryDetails = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      inventoryId: id,
    });
    const requestOptions = {
      headers: myHeaders,
      body: raw,
      method: "POST",
      redirect: "follow",
    };
    const response = await fetch(
      `${REACT_APP_BASE_URL}/api/v1/inventory_details_by_id`,
      requestOptions
    );
    const result = await response.json();
    setName(result.InventoryData.name);
    setDateCreated(result.InventoryData.createdOn);
    setCompany(result.InventoryData.distributorId);
    setStatus(result.InventoryData.status);
    setTotalSims(result.InventoryData.totalSim);
    setParentGroupID(result.InventoryData.parentGroupId);
    setParentGroupName(result.InventoryData.parentGroupName);
  };

  useEffect(() => {
    fetchInventoryDetails();
  }, []);

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  // Example usage:
  const epochTimestamp = datecreated; // Replace with your epoch timestamp
  const formattedDate = epochToDate(epochTimestamp);

  const SIMDetails = () => {
    localStorage.setItem("id", id);
    navigate("/inventory/esim");
  };

  return (
    <>
      <Container>
        <Box
          sx={{ height: "auto", typography: "body1" }}
          style={{
            backgroundColor: "white",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              margin: "8px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="box1"
              style={{
                width: "60%",
                height: "auto",
                padding: "10px 10px 10px 15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "25px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                General Details <hr />
              </p>
              <table>
                <tr>
                  <td>Inventory Name :</td>
                  <td style={{ padding: "2px 25px" }}>{name}</td>
                </tr>
                <tr>
                  <td>Inventory ID :</td>
                  <td style={{ padding: "2px 25px" }}>{id}</td>
                </tr>
                <tr>
                  <td>Date Created (UTC) :</td>
                  <td style={{ padding: "2px 25px" }}>{formattedDate}</td>
                </tr>

                <tr>
                  <td>Distributed ID:</td>
                  <td style={{ padding: "2px 25px" }}>{company}</td>
                </tr>
                <tr>
                  <td>Parent Group ID:</td>
                  <td style={{ padding: "2px 25px" }}>{parentGroupid}</td>
                </tr>
                <tr>
                  <td>Parent Group Name:</td>
                  <td style={{ padding: "2px 25px" }}>{parentGroupName}</td>
                </tr>
                <tr>
                  <td>Total SIM :</td>
                  <td
                    onClick={(id) => SIMDetails(id)}
                    style={{
                      cursor: "pointer",
                      color: "#8224E3",
                      padding: "2px 25px",
                    }}
                  >
                    {totalSim}
                  </td>
                </tr>
                <tr>
                  <td>Status :</td>
                  <td style={{ padding: "2px 25px" }}>{status}</td>
                </tr>
              </table>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default AdminInventoryInfo;
