import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { H1 } from "app/components/Typography";
import { Container } from "@mui/material";

const ParentGroupIDDetails = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const parentGroupId = localStorage.getItem("parentGroupId");

  const [name, setName] = useState('');
  const [inventoryId, setInventoryId] = useState('');
  const [totalSim, setTotalSim] = useState('');
  const [pendingsims, setPendingSims] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState('');
  const [inventoryName, setInventoryName] = useState('')
  const [tenantName, setTenantName] = useState('');


  const packageDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      parentGroupId: parentGroupId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/parentgroupdetails_by_parentgroupid`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        setName(result.parentGroup.name);
        setInventoryId(result.parentGroup.inventoryId);
        setType(result.parentGroup.type);
        setTotalSim(result.parentGroup.totalSim);
        setPendingSims(result.parentGroup.pendingSim);
        setDate(result.parentGroup.createdOn);
        setInventoryName(result.parentGroup.inventoryName);
        setTenantName(result.parentGroup.TenantName)

      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    packageDetails();
  }, []);


  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  return (
    <>
      <Container>
        <Box
          sx={{ height: "500px", typography: "body1" }}
          style={{
            backgroundColor: "white",
          }}
        >
          <Box
            className="breadcrumb"
            style={{ display: "flex", margin: "10px 0px 0px 20px", }}
          >
            <h3 style={{ fontWeight: "500", fontSize: "21.14px" }}>
              Parent Group ID |
            </h3>

            <span
              style={{
                marginTop: "26px",
                marginLeft: "10px",
                color: "#8224e3",
                fontSize: "14px",
              }}
            >
              {" "}
              {parentGroupId}
            </span>
          </Box>
          <hr
            style={{
              border: "1px lightGrey solid",
              width: "97%",
              marginBottom: "20px",
              marginTop: '-13px'
            }}
          />


          <div
            style={{
              margin: "40px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="box1"
              style={{
                width: "60%",
                height: "320px",
                padding: "10px 10px 0px  15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "25px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                General Details <hr />
              </p>
              <table>
                <tr>
                  <td>Parent Group ID :</td>
                  <td style={{ padding: "2px 25px" }}>{parentGroupId}</td>
                </tr>

                <tr>
                  <td>Parent Group Name:</td>
                  <td style={{ padding: "2px 25px" }}>{name}</td>
                </tr>

                <tr>
                  <td>Inventory ID :</td>
                  <td style={{ padding: "2px 25px" }}>{inventoryId}</td>
                </tr>
                <tr>
                  <td>Inventory Name :</td>
                  <td style={{ padding: "2px 25px" }}>{inventoryName}</td>
                </tr>

                <tr>
                  <td>Tenant Name :</td>
                  <td style={{ padding: "2px 25px" }}>{tenantName}</td>
                </tr>

                <tr>
                  <td>Date Created :</td>
                  <td style={{ padding: "2px 25px" }}>{epochToDate(date)}</td>
                </tr>
              </table>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};
export default ParentGroupIDDetails;
