import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const InventorySims = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [simData, setSimData] = useState([]);

  const [orderBy, setOrderBy] = useState("iccId");
  const [order, setOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");

  const id = localStorage.getItem("id");
  const navigate = useNavigate();

  const fetchSims = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      inventoryId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/fetch_inventory_sims`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {

        setSimData(result.sims.iccids);
      })
  };

  useEffect(() => {
    fetchSims();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = simData.filter((sim) =>
    sim.iccId.toLowerCase().includes(searchText.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  const simInfo = (iccId) => {
    localStorage.setItem("iccid", iccId);
    navigate("/inventory/admin-siminfo");
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>SIM Cards| </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            SIM Cards
          </p>
        </Box>

        <SimpleCard title="SIM Cards" style={{}}>
          <div>
            <div>
              <input
                style={{
                  height: "36px",
                  width: "40%",
                  border: "1px solid  #dededf",
                  paddingLeft: "10px",
                }}
                name="search"
                placeholder="Search by ICCID "
                type="search"
                autoComplete="off"
                value={searchText}
                onChange={handleSearch}
              />
             
            </div>
          </div>

          <br />
          <br />
          <Box
            style={{
              width: "100%",
              height: "80vh",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <StyledTable
              stickyHeader
              style={{
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      paddingLeft: "10px",
                    }}
                    align="left"
                  >
                    <TableSortLabel
                      active={orderBy === "iccId"}
                      direction={orderBy === "iccId" ? order : "asc"}
                      onClick={() => handleSort("iccId")}
                    >
                      ICCID
                    </TableSortLabel>
                  </TableCell>

                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Enabled
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.length > 0 ? (
                  sortedData

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (
                      <TableRow key={sim.iccId}>
                        <TableCell
                          style={{ color: "#8224E3", cursor: "pointer" }}
                          align="left"
                          onClick={() => simInfo(sim.iccId)}
                        >
                          {sim.iccId}
                        </TableCell>

                        <TableCell
                          align="center"
                        >
                          {sim.status}
                        </TableCell>
                        <TableCell
                         
                          align="center"
                        >
                          {sim.enabled}
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>
    </>
  );
};

export default InventorySims;
