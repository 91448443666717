import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { H1 } from "app/components/Typography";
import { Container } from "@mui/material";

const PackageInfo = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  let packageId = localStorage.getItem("packageId");
  const TenantUUID = localStorage.getItem("TenantUUID");

  const [name, setName] = useState("");
  const [packageType, setPackageType] = useState("");
  const [status, setStatus] = useState("");
  const [dataUsage, setDataUsage] = useState("");
  const [voiceUsage, setVoiceUsage] = useState("");
  const [smsUsage, setSmsUsage] = useState("");
  const [dataUsageTime, setDataUsageTime] = useState("");
  const [voiceUsageTime, setVoiceUsageTime] = useState("");
  const [smsUsageTime, setSmsUsageTime] = useState("");
  const [activationTime, setActivationTime] = useState("");
  const [description, setDescription] = useState('');

  const fetchSimDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      packageId: packageId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_package_details`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setName(result.PackageDetails.name);
        setPackageType(result.PackageDetails.type);
        setStatus(result.PackageDetails.status);
        setDataUsage(result.PackageDetails.dataUsageAllowanceType);
        setVoiceUsage(result.PackageDetails.voiceUsageAllowanceType);
        setSmsUsage(result.PackageDetails.smsUsageAllowanceType);
        setDataUsageTime(result.PackageDetails.dataUsageAllowanceInBytes);
        setVoiceUsageTime(result.PackageDetails.voiceUsageAllowanceInSeconds);
        setSmsUsageTime(result.PackageDetails.smsUsageAllowanceInNums);
        setActivationTime(result.PackageDetails.activationTimeAllowanceInSeconds);
        setDescription(result.PackageDetails.packageDescription);


      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    fetchSimDetails();
  }, []);


  return (
    <>
      <Container>
        <Box
          sx={{ height: "600px", typography: "body1" }}
          style={{
            backgroundColor: "white",
          }}
        >
          <Box
            className="breadcrumb"
            style={{ display: "flex", margin: "10px 0px 0px 20px", }}
          >
            <h3 style={{ fontWeight: "500", fontSize: "21.14px" }}>
              Package Details |
            </h3>

            <span
              style={{
                marginTop: "26px",
                marginLeft: "10px",
                color: "#8224e3",
                fontSize: "14px",
              }}
            >
              {" "}
              {packageId}
            </span>
          </Box>
          <hr
            style={{
              border: "1px lightGrey solid",
              width: "97%",
              marginBottom: "20px",
              marginTop: '-13px'
            }}
          />


          <div
            style={{
              margin: "40px",
              display: "flex-start",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="box1"
              style={{
                width: "60%",
                height: "430px",
                padding: "10px 10px 0px  15px",
                backgroundColor: "rgb(233 226 226 / 35%)",
                borderRadius: "25px",

                marginBottom: "15px",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                }}
              >
                General Details <hr />
              </p>
              <table>
                <tr>
                  <td>PackageId :</td>
                  <td style={{ padding: "2px 25px" }}>{packageId}</td>
                </tr>
                <tr>
                  <td>Name :</td>
                  <td style={{ padding: "2px 25px" }}>{name}</td>
                </tr>
                <tr>
                  <td>Package Type :</td>
                  <td style={{ padding: "2px 25px" }}>{packageType}</td>
                </tr>
                <tr>
                  <td>Status :</td>
                  <td style={{ padding: "2px 25px" }}>{status}</td>
                </tr>

                <tr>
                  <td>Data Usage Type:</td>
                  <td style={{ padding: "2px 25px" }}>{dataUsage}</td>
                </tr>
                <tr>
                  <td>Voice Usage Type :</td>
                  <td style={{ padding: "2px 25px" }}>{voiceUsage}</td>
                </tr>
                <tr>
                  <td>Sms Usage Type :</td>
                  <td style={{ padding: "2px 25px" }}>{smsUsage}</td>
                </tr>
                <tr>
                  <td>Data Usage Limit :</td>
                  <td style={{ padding: "2px 25px" }}>{dataUsageTime}</td>
                </tr>
                <tr>
                  <td>Voice Usage Limit :</td>
                  <td style={{ padding: "2px 25px" }}>{voiceUsageTime}</td>
                </tr>
                <tr>
                  <td>Sms Usage Limit :</td>
                  <td style={{ padding: "2px 25px" }}>{smsUsageTime}</td>
                </tr>
                <tr>
                  <td>Activation Time :</td>
                  <td style={{ padding: "2px 25px" }}>{activationTime}</td>
                </tr>
                <tr>
                  <td>Description :</td>
                  <td style={{ padding: "2px 25px" }}>{description}</td>
                </tr>
              </table>
            </div>
          </div>
        </Box>
      </Container>
    </>
  );
};

export default PackageInfo;
