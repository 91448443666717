import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react'
import { color } from 'echarts';
import { NearMeOutlined } from '@mui/icons-material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    const [BillingDetailsData, setBillingDetailsData] = useState([]);



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function BasicTabs() {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const [value, setValue] = React.useState(0);
    const [BillingDetailsData, setBillingDetailsData] = useState('');
    const [name, setname] = useState('');
    const [billinggroupid, setbillingGroupId] = useState('');
    const [createepochmilli, setcreateEpochMilli] = useState('');
    const [totalSubGroup, setTotalSubGroup] = useState('');
    const [totalSims, setTotalSims] = useState('')
    const [distributorname, setdistributorName] = useState('');
    const [inventoryid, setinventoryId] = useState('');
    const [balanceCurrency, setbalanceCurrency] = useState('');
    const [overdraftCurrency, setoverdraftCurrency] = useState('');
    const [ocsgroupid, setocsGroupId] = useState('');
    const [balance, setbalanceCent] = useState('');
    const [balanceupdate, setbalanceModifyEpochMilli] = useState('');
    const [overdraftcent, setoverdraftCent] = useState('');
    const [overdraftupdate, setoverdraftModifyEpochMilli] = useState('');
    const TenantUUID = localStorage.getItem('TenantUUID');

    const id = localStorage.getItem('id');
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const fetchBillingDetails = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "TenantUUID": TenantUUID,
            "groupId": id
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${REACT_APP_BASE_URL}/api/v1/groupById?groupId=1107174`, requestOptions)
        const result = await response.json();
        setname(result.GroupData.get_group.name);
        setbillingGroupId(result.GroupData.get_group.id);
        setcreateEpochMilli(result.GroupData.get_group.createdOn);
        setTotalSubGroup(result.GroupData.get_group.totalSubGroup);
        setTotalSims(result.GroupData.get_group.totalSim)
        setdistributorName(result.GroupData.distributorName);
        setinventoryId(result.GroupData.get_group.inventoryId);
        setocsGroupId(result.GroupData.ocsGroupId);
        setbalanceCent(result.GroupData.balanceCent);
        setbalanceCurrency(result.GroupData.balanceCurrency);
        setoverdraftCurrency(result.GroupData.overdraftCurrency);
        setoverdraftCent(result.GroupData.overdraftCent);
        setbalanceModifyEpochMilli(result.GroupData.balanceModifyEpochMilli);
        setoverdraftModifyEpochMilli(result.GroupData.overdraftModifyEpochMilli);
    }

    useEffect(() => {
        fetchBillingDetails();
    }, []);

    function epochToDate(epochTimestamp) {
        const date = new Date(epochTimestamp);

        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed, so add 1
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        const seconds = date.getUTCSeconds().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;

        return formattedDate;
    }

    // Define the conversion rate from the original currency to INR
    const conversionRate = 75.0; // Change this to the actual conversion rate

    // Function to convert the balance and overdraft values to INR
    function convertToINR(value) {
        return (value * conversionRate).toFixed(2); // Rounded to 2 decimal places
    }

    function centsToUSD(cents) {
        return (cents / 100).toLocaleString('en-US',);
    }

    return (
        <Box sx={{ width: '100%' }}>

            <h2 style={{ display: 'flex', marginTop: '10px', fontSize: '22.2857px', fontWeight: '600', marginLeft: '30px', fontFamily: 'sans-serif', }}> {name}  <p
                style={{
                    margin: '9px',
                    // color: '#8224E3',
                    color: 'gray',
                    fontSize: '14px',
                    fontWeight: '500'
                }}
            >
                {billinggroupid}
            </p>
            </h2>

            <Box style={{ margin: '30px' }} sx={{ borderColor: '#8224E3' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: '600' }} label="Overview" {...a11yProps(0)} />
                </Tabs>
            </Box>
            <div style={{ margin: '40px', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <div className="box1" style={{
                    width: '50%',
                    height: '300px',
                    padding: '0px 25px',
                    backgroundColor: ' rgb(233 226 226 / 35%)',
                    borderRadius: '25px'
                }} >
                    <p style={{

                        fontSize: '25px',
                        fontWeight: '500',

                    }} >General Details <hr /> </p>
                    <table style={{
                        padding: '2px 0px',


                    }}>
                        <tr>
                            <td
                                style={{

                                    fontWeight: '500',

                                }}>Name :</td>
                            <td style={{
                                padding: '2px 25px ',
                            }} >{name}</td>
                        </tr>
                        <tr>
                            <td style={{
                                fontWeight: '500',
                            }}>ID :</td>
                            <td style={{
                                padding: '2px 25px '

                            }} >{billinggroupid}</td>
                        </tr>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>Date Created  :</td>
                            <td style={{
                                padding: '2px 25px '

                            }}  >{epochToDate(createepochmilli)}</td>
                        </tr>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>TotalSubGroups :</td>
                            <td style={{
                                padding: '2px 25px '

                            }}  >{totalSubGroup}</td>
                        </tr>
                        <tr>
                            <td style={{
                                fontWeight: '500',

                            }}>TotalSim :</td>
                            <td style={{
                                padding: '2px 25px '

                            }}  >{totalSims}</td>
                        </tr>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>Inventory ID :</td>
                            <td style={{
                                padding: '2px 25px ',
                                color: '#8224E3'

                            }}  >{inventoryid}</td>
                        </tr>
                    </table>
                </div>

                <div className="box2" style={{
                    width: '48%',
                    height: '300px',
                    padding: '0px 25px',
                    backgroundColor: ' rgb(233 226 226 / 35%)',
                    borderRadius: '25px'
                }} >
                    <p style={{
                        fontSize: '25px',
                        fontWeight: '500',
                        alignItem: 'center'
                    }} >Balance and Overdraft  <hr /> </p>

                    <table style={{
                        padding: '2px 0px ',
                    }}>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>OCS ID :</td>
                            <td style={{
                                padding: '2px 25px '

                            }} >{ocsgroupid}</td>
                        </tr>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>Balance :</td>
                            <td style={{
                                padding: '2px 25px '

                            }} > {centsToUSD(balance)} {balanceCurrency}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{
                                color: 'Gray',
                                fontSize: '12px',
                                padding: '2px 25px '
                            }}>{epochToDate(balanceupdate)}</td>
                        </tr>
                        <tr>
                            <td style={{

                                fontWeight: '500',

                            }}>Overdraft :</td>
                            <td style={{
                                padding: '2px 25px '

                            }} > {centsToUSD(overdraftcent)} {overdraftCurrency}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{
                                color: 'Gray',
                                fontSize: '12px',
                                padding: '2px 25px '
                            }}>{epochToDate(overdraftupdate)}</td>
                        </tr>

                    </table>
                </div>

            </div>
        </Box>
    );
}
