import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";

import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import { func } from "prop-types";
import { functions } from "lodash";
import "./AdminInventory.css";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { use } from "echarts";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const AdminInventories = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [inventoryData, setInventoryData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [columnVisible, setColumnVisible] = useState(true);
  const [inventoryId, setInventoryId] = useState(true);
  const [inventoryName, setInventoryName] = useState(true);
  const [parentGroupId, setParentGroupId] = useState(false);
  const [parentGroupName, setParentGroupName] = useState(true);
  const [status, setStatus] = useState(true);
  const [tenantName, setTenantName] = useState(true);
  const [totalSim, setTotalSim] = useState(true);
  const [provider, setProvider] = useState(true);
  const [FormatFilter, setFormatFilter] = useState("");

  const [open, setOpen] = useState(false);
  const accessToken = localStorage.getItem("accessToken");

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchInventory = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);


    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/inventory_list_admin`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setInventoryData(result.InventoryList);
      })
      .catch((error) => {
      });
  };
  useEffect(() => {
    fetchInventory();
  }, []);

  function isoDate(inputDateString) {
    const date = new Date(inputDateString);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedDate;
  }

  const NextInventory = (id) => {
    localStorage.setItem("id", id);
    navigate("/inventory/inventory-details");
  };

  const parentGroupIdDetails = (parentGroupId) => {
    navigate("/parentGroupID-Details");
    localStorage.setItem("parentGroupId", parentGroupId);
  };

  const TenantDetails = (TenantUUID) => {
    localStorage.setItem("TenantUUID", TenantUUID);
    navigate("/tenant/tenantdetails");
  };

  // Function to handle search
  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  // Function to handle sorting
  const handleSort = (column) => {
    const isAsc = orderBy === column && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortColumn(column);
    setOrderBy(column);
  };
 
  const sortedAndFilteredData = inventoryData
  ? inventoryData
      .filter((item) => {
        return (
          (item.id && item.id.toString().includes(searchQuery)) ||
          (item.totalSim &&
            item.totalSim.toString().includes(searchQuery)) ||
          (item.status &&
            item.status.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.name &&
            item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.tenantName &&
            item.tenantName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.parentGroupId &&
            item.parentGroupId
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.parentGroupName &&
            item.parentGroupName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.Provider &&
            item.Provider.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
      .sort((a, b) => {
        const order = sortOrder === "asc" ? 1 : -1;
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (aValue && bValue) {
          return order * (aValue > bValue ? 1 : -1);
        } else {
          // Handle the case where either aValue or bValue is undefined
          // You might want to customize this based on your requirements
          return 0;
        }
      })
  : [];

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "Inventory_Details" + fileExtension);
  };

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s",
  });

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == false) {
      setInventoryId(true);
      setInventoryName(true);
      setStatus(true);
      setTenantName(true);
      setTotalSim(true);
      setParentGroupId(true);
      setParentGroupName(true);
      setProvider(true);
    } else if (columnVisible == true) {
      setInventoryId(false);
      setInventoryName(false);
      setStatus(false);
      setTenantName(false);
      setTotalSim(false);
      setParentGroupId(false);
      setParentGroupName(false);
      setProvider(false);
    }
  };

  const handleToggleSwitch = () => {
    setInventoryId(!inventoryId);
  };
  const handleToggleInventoryName = () => {
    setInventoryName(!inventoryName);
  };

  const handleToggleStatus = () => {
    setStatus(!status);
  };
  const handleToggleTenantName = () => {
    setTenantName(!tenantName);
  };
  const handleToggleProviderName = () => {
    setProvider(!provider);
  };
  const handleToggleparentGroupId = () => {
    setTotalSim(!totalSim);
  };
  const handleParentGroupId = () => {
    setParentGroupId(!parentGroupId);
  };
  const handleParentGroupName = () => {
    setParentGroupName(!parentGroupName);
  };

  const simDetails = (inventoryId, TenantUUID) => {
    localStorage.setItem("inventoryId", inventoryId);
    localStorage.setItem("TenantUUID", TenantUUID);
    navigate("/inventory/esim");
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>Inventories | </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
              fontSize: "14px",
            }}
          >
            Inventories
          </p>
        </Box>
        <Container
          style={{
            ...containerStyle,
            display: animateContainer ? "block" : "none",
            position: "absolute",
            top: "25%",
            left: "25%",
            transform: "translate(-25%,-25%)",
            zIndex: "100",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "10px",
                backgroundColor: " rgb(242 242 242 / 100%)",
                borderRadius: "25px",
                marginLeft: "15%",
                animation: "fadeIn 1.5s ease-in-out",
              }}
            >
              <div
                style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
              >
                <H2
                  style={{
                    margin: "10px",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                >
                  Columns
                  <Switch
                    color="primary"
                    checked={columnVisible}
                    onChange={handleToggleColumnSwitch}
                  />
                </H2>
                <hr />
              </div>
              <br />

              <div style={{ padding: "5px", marginLeft: "20px" }}>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      marginRight: "20px",
                      fontSize: "16px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Inventory ID
                    <Switch
                      color="primary"
                      checked={inventoryId}
                      onChange={handleToggleSwitch}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Inventory Name
                    <Switch
                      color="primary"
                      checked={inventoryName}
                      onChange={handleToggleInventoryName}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Parent Group ID
                    <Switch
                      color="primary"
                      checked={parentGroupId}
                      onChange={handleParentGroupId}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Parent Group Name
                    <Switch
                      color="primary"
                      checked={parentGroupName}
                      onChange={handleParentGroupName}
                    />
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Status
                    <Switch
                      color="primary"
                      checked={status}
                      onChange={handleToggleStatus}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    TenantName
                    <Switch
                      color="primary"
                      checked={tenantName}
                      onChange={handleToggleTenantName}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Provider Name
                    <Switch
                      color="primary"
                      checked={provider}
                      onChange={handleToggleProviderName}
                    />
                  </p>
                  <p
                    style={{
                      margin: "10px",
                      padding: "0px",
                      fontSize: "16px",
                      marginRight: "10px",
                      marginLeft: "10px",
                      display: "flex",
                    }}
                  >
                    Total SIM Cards
                    <Switch
                      color="primary"
                      checked={totalSim}
                      onChange={handleToggleparentGroupId}
                    />
                  </p>
                </div>
              </div>

              <hr />
              <div style={{ padding: "4px", textAlign: "center" }}>
                <Button
                  style={{ color: "#8224e3" }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleButtonClick}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        </Container>

        <SimpleCard title="Inventories">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              height: "70px",
            }}
          >
            <input
              style={{
                height: "40px",
                width: "28%",
                border: "1px solid  lightgrey",
                paddingLeft: "10px",
                borderRadius: "5px",
                outline: "1px black lightgrey",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              onChange={(e) => handleSearch(e.target.value)}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "38px", marginLeft: "10px", width: "290px" }}
              >
                <MenuItem value="">
                  {" "}
                  <i>Select Format -- </i>
                </MenuItem>
                <MenuItem value="excel" onClick={() => exportToExcel()}>
                  Export View Excel
                </MenuItem>
                <MenuItem value={10} onClick={handleButtonClick}>
                  Custom Column Views
                </MenuItem>
              </Select>
            </div>
          </div>

          <Box width="100%" overflow="auto">
            <StyledTable id="Table-Data">
              <TableHead>
                <TableRow
                  style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}
                >
                  {inventoryId && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        paddingLeft: "10px",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort('id')}
                    >
                      {" "}
                      Inventory ID
                      <TableSortLabel
                        active={orderBy === "id"}
                        direction={orderBy === "id" ? sortOrder : "asc"}
                       
                      ></TableSortLabel>
                    </TableCell>
                  )}
                  {totalSim && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("totalSim")}
                    >
                      Total SIM Cards
                    </TableCell>
                  )}
                  {inventoryName && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("name")}
                    >
                      Inventory Name
                    </TableCell>
                  )}
                  {parentGroupId && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("parentGroupId")}
                    >
                      Parent Group ID
                    </TableCell>
                  )}
                  {parentGroupName && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("parentGroupName")}
                    >
                      Parent Group Name
                    </TableCell>
                  )}

                  {status && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </TableCell>
                  )}
                  {tenantName && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("tenantName")}
                    >
                      Tenant Name
                    </TableCell>
                  )}
                  {provider && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        cursor:'pointer'
                      }}
                      align="center"
                      onClick={() => handleSort("provider")}
                    >
                      Provider Name
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAndFilteredData && sortedAndFilteredData.length > 0 ? (
                  sortedAndFilteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((inventory) => (
                      <TableRow key={inventory.id}>
                        {inventoryId && (
                          <TableCell
                            onClick={(id) => NextInventory(inventory.id)}
                            id="iccid"
                            align="center"
                          >
                            {inventory.id}
                          </TableCell>
                        )}
                        {totalSim && (
                          <TableCell
                            align="center"
                            id="sims"
                            onClick={() =>
                              simDetails(inventory.id, inventory.TenantUUID)
                            }
                          >
                            {inventory.totalSim}
                          </TableCell>
                        )}
                        {inventoryName && (
                          <TableCell
                            align="center"
                            id="name"
                            onClick={(id) => NextInventory(inventory.id)}
                          >
                            {inventory.name}
                          </TableCell>
                        )}
                        {parentGroupId && (
                          <TableCell
                            align="center"
                            id="id"
                            onClick={() =>
                              parentGroupIdDetails(inventory.parentGroupId)
                            }
                          >
                            {inventory.parentGroupId}
                          </TableCell>
                        )}
                        {parentGroupName && (
                          <TableCell
                            align="center"
                            id="pname"
                            onClick={() =>
                              parentGroupIdDetails(inventory.parentGroupId)
                            }
                          >
                            {inventory.parentGroupName}
                          </TableCell>
                        )}
                        {status && (
                          <TableCell align="center">
                            {inventory.status}
                          </TableCell>
                        )}
                        {tenantName && (
                          <TableCell
                            id="tname"
                            onClick={() => TenantDetails(inventory.TenantUUID)}
                            align="center"
                          >
                            {inventory.tenantName}
                          </TableCell>
                        )}
                        {provider && (
                          <TableCell
                            align="center"
                          >
                            {inventory.Provider}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
            <TablePagination
              rowsPerPageOptions={[7, 14, 25]}
              component="div"
              count={sortedAndFilteredData.length} // Total number of rows
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </SimpleCard>
      </Container>
    </>
  );
};

export default AdminInventories;
