import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Container, Tab } from '@mui/material';
import { H1 } from 'app/components/Typography';
import { use } from 'echarts';
import React from 'react'
import { useEffect, useState } from 'react'

const TenantDetails = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const accessToken = localStorage.getItem("accessToken");
    const storedTenantUuid = localStorage.getItem('TenantUUID');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('');
    const [mobile, setMobile] = useState('');
    const [userlimit, setUserLimit] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [totalSims, setTotalSims] = useState('');
    const [city, setCity] = useState('');
    const [timezone, setTimezone] = useState('');
    const [address1, setAddress1] = useState('');
    const [state, setState] = useState('');
    const [shippingCountry, setShippingCountry] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [shippingState, setShippingState] = useState('');
    const [shippingCity, setShippingCity] = useState('');
    const [shippingPostal, setShippingPostal] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [shippingAddress1, setShippingAddress1] = useState('');
    const [shippingAddress2, setShippingAddress2] = useState('');
    const [shippingAddress3, setShippingAddress3] = useState('');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value, setValue] = React.useState('1');

    const TenantDetails = async () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Authorization", `Bearer ${accessToken}`);


        const raw = JSON.stringify({
            TenantUUID: storedTenantUuid
        })

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }

        const response = await fetch(`${REACT_APP_BASE_URL}/account/api/v1/get_tenant?domain${storedTenantUuid}`, requestOptions);
        const result = await response.json();
        setFirstName(result.Account_details[0].FirstName)
        setLastName(result.Account_details[0].LastName)
        setMobile(result.Account_details[0].PhoneNumber);
        setUserLimit(result.Account_details[0].MaxTenantLimit);
        setEmail(result.Account_details[0].EmailId);
        setCountry(result.Account_details[0].Country);
        setTotalSims(result.Account_details[0].TotalSims);
        setCity(result.Account_details[0].City);
        setShippingCity(result.Account_details[0].ShippingCity);
        setTimezone(result.Account_details[0].Timezone);
        setAddress1(result.Account_details[0].AddressLine1);
        setState(result.Account_details[0].State);
        setShippingCountry(result.Account_details[0].ShippingCountry);
        setAddress2(result.Account_details[0].AddressLine2);
        setAddress3(result.Account_details[0].AddressLine3);
        setShippingState(result.Account_details[0].ShippingState);
        setPostalCode(result.Account_details[0].PostalCode);
        setShippingPostal(result.Account_details[0].ShippingPostalCode);
        setShippingAddress1(result.Account_details[0].ShippingAddress1)
        setShippingAddress2(result.Account_details[0].ShippingAddress2)
        setShippingAddress3(result.Account_details[0].ShippingAddress3)
    }

    useEffect(() => {
        TenantDetails()
    }, [])


    return (
        <>
            <Container>
                <div style={{ display: 'flex', padding: '20px 0px 15px 10px' }} >
                    <H1>Tenant Details  </H1>
                </div>

                <div
                    className="box1"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        className="box1_1"
                        style={{
                            width: '47%',
                            height: 'auto',
                            backgroundColor: 'rgb(233 226 226 /35%)',
                            borderRadius: '25px',
                            padding: '0px 20px'
                        }}
                    >
                        <p
                            style={{
                                fontSize: '23px',
                                letterSpacing: '1px'
                            }}
                        >
                            General Details
                            <hr
                                style={{
                                    border: '1px lightGrey solid',
                                    width: '100%'
                                }}
                            />
                        </p>

                        <table
                            style={{
                                padding: '2px 0px'
                            }}
                        >

                            <tr>
                                <td
                                    style={{
                                        fontSize: '14px',
                                        color: 'black'
                                    }}
                                >
                                    {' '}
                                    First Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {firstName}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{
                                        fontSize: '14px',
                                        color: 'black'
                                    }}
                                >
                                    Last Name :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {lastName}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{
                                        fontSize: '14px',
                                        color: 'black'
                                    }}
                                >
                                    Email ID :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {email}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{
                                        fontSize: '14px',
                                        color: 'black',
                                        width: '30%'
                                    }}
                                >
                                    Mobile Number :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {mobile}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    style={{
                                        fontSize: '14px',
                                        color: 'black'
                                    }}
                                >
                                    Client Limit :
                                </td>
                                <td
                                    style={{
                                        padding: '2px 25px '
                                    }}
                                >
                                    {userlimit}
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div
                        className="box1_2"
                        style={{
                            width: '55%',
                            backgroundColor: 'rgb(233 226 226 /35%)',
                            borderRadius: '25px',
                            padding: '0px 20px',
                            marginLeft: '17px'
                        }}
                    >
                        <p
                            style={{
                                fontSize: '23px',
                                letterSpacing: '1px'
                            }}
                        >
                            Address Details
                            <hr
                                style={{
                                    border: '1px lightGrey solid',
                                    width: '100%'
                                }}
                            />
                        </p>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Current Address" value="1" />
                                    <Tab label="Shipping Address" value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <table
                                    style={{
                                        padding: '2px 0px'
                                    }}
                                >
                                    <tr >
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Address Line 1 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {address1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            {' '}
                                            Address Line 2 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {address2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            {' '}
                                            Address Line 3 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {address3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Country :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {country}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            State :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {state}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            City :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {city}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Postal Code :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {postalCode}
                                        </td>
                                    </tr>
                                </table>
                            </TabPanel>
                            <TabPanel value="2">
                                <table
                                    style={{
                                        padding: '2px 0px'
                                    }}
                                >
                                    <tr >
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Address Line 1 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingAddress1}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            {' '}
                                            Address Line 2 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingAddress2}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            {' '}
                                            Address Line 3 :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingAddress3}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Country :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingCountry}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            State :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingState}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            City :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingCity}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td
                                            style={{
                                                fontSize: '14px',
                                                color: 'black'
                                            }}
                                        >
                                            Postal Code :
                                        </td>
                                        <td
                                            style={{
                                                padding: '2px 25px '
                                            }}
                                        >
                                            {shippingPostal}
                                        </td>
                                    </tr>
                                </table>
                            </TabPanel>
                        </TabContext>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default TenantDetails; 