import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import { TextField, Grid } from "@mui/material";
import "./CDR.css";

//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
//These import are for the reports download   -- Ends here

import {
  Box,
  Icon,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { Collapse, MenuItem, Select, Tooltip } from "@mui/material";
import { H2, H1 } from "app/components/Typography";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const CDR_ = () => {
  const REACT_APP_BASE_URL_CDR = process.env.REACT_APP_BASE_URL_CDR;

  const [ClientsFilter, setClientsFilter] = useState("");
  const [DurationFilter, setDurationFilter] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");

  const navigate = useNavigate();
  const tenantUuid = localStorage.getItem("tenant_uuid");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const TenantUUID = localStorage.getItem("TenantUUID");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [length, setLength] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("");

  const [CdrData, setCdrData] = useState([]);

  // SWITCH Visible datas in table ...
  const [allVisible, setAllVisible] = useState(false);

  const [CdrIdVisible, setCdrIdVisible] = useState(true);
  const [iccidVisible, setIccidVisible] = useState(true);
  const [imsiVisible, setImsiVisible] = useState(true);
  const [dataVisible, setDataVisible] = useState(true);
  const [typeVisible, setTypeVisible] = useState(true);
  const [eventDateVisible, setEventDateVisible] = useState(true);
  const [mccVisible, setMccVisible] = useState(true);
  const [mncVisible, setMncVisible] = useState(true);
  const [ImsiNoVisible, setImsiNoVisible] = useState(true);
  const [CountryVisible, setCountryVisible] = useState(true);
  const [carrierNameVisible, setCarrierNameVisible] = useState(true);
  const [connectTimeVisible, setConnectTimeVisible] = useState(true);
  const [closeTimeVisible, setCloseTimeVisible] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("CDR_ID");
  const [order, setOrder] = useState("asc");

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s", // Adjust the duration as needed
  });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const filteredAndSortedData = CdrData
    ? CdrData.filter((sim) => {
        const fullName =
          `${sim.CDR_ID} ${sim.ICCID} ${sim.IMSI_ID} ${sim.DURATION} ${sim.COUNTRY_NAME} ${sim.brand} ${sim.CLOSE_TIME} ${sim.TYPE} ${sim.MCC} ${sim.MNC} ${sim.IMSI_NO}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
      }).sort((a, b) => {
        const isAsc = order === "asc";
        return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
      })
    : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleAllVisible = () => {
    setAllVisible(!allVisible);
    if (allVisible == true) {
      setCdrIdVisible(true);
      setIccidVisible(true);
      setImsiVisible(true);
      setDataVisible(true);
      setTypeVisible(true);
      setEventDateVisible(true);
      setMccVisible(true);
      setMncVisible(true);
      setImsiNoVisible(true);
      setCountryVisible(true);
      setCarrierNameVisible(true);
      setConnectTimeVisible(true);
      setCloseTimeVisible(true);
    } else if (allVisible == false) {
      setCdrIdVisible(false);
      setIccidVisible(false);
      setImsiVisible(false);
      setDataVisible(false);
      setTypeVisible(false);
      setEventDateVisible(false);
      setMccVisible(false);
      setMncVisible(false);
      setImsiNoVisible(false);
      setCountryVisible(false);
      setCarrierNameVisible(false);
      setConnectTimeVisible(false);
      setCloseTimeVisible(false);
    }
  };

  const handleCdrIdVisible = () => {
    setCdrIdVisible(!CdrIdVisible);
  };
  const handleIccidVisible = () => {
    setIccidVisible(!iccidVisible);
  };
  const handleImsiVisible = () => {
    setImsiVisible(!imsiVisible);
  };
  const handleDataVisible = () => {
    setDataVisible(!dataVisible);
  };
  const handleTypeVisible = () => {
    setTypeVisible(!typeVisible);
  };
  const handleEventDateVisible = () => {
    setEventDateVisible(!eventDateVisible);
  };
  const handleMccVisible = () => {
    setMccVisible(!mccVisible);
  };
  const handleMncVisible = () => {
    setMncVisible(!mncVisible);
  };
  const handleImsiNoVisible = () => {
    setImsiNoVisible(!ImsiNoVisible);
  };
  const handleCountryVisible = () => {
    setCountryVisible(!CountryVisible);
  };

  const handleCarrierNameVisible = () => {
    setCarrierNameVisible(!carrierNameVisible);
  };
  const handleConnectTimeVisible = () => {
    setConnectTimeVisible(!connectTimeVisible);
  }
  const handleCloseTimeVisible = () => {
    setCloseTimeVisible(!closeTimeVisible);
  }

  //These Functions are for the reports download   -- Starts here

  // This is for the PDF format
  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("CDR_Reports.pdf");
    } else {
      toast.error("No data available for export.");
    }
  };

  // This is for the Excel format

  const noDataReport = () => {
    toast("No data to Export", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 2000,
    });
  };

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Check if there is any data before exporting to Excel
    if (table && table.rows.length > 1) {
      // Prepend a single quote to ICCID values to ensure text formatting in Excel
      Array.from(table.rows).forEach((row) => {
        const iccidCell = row.cells[1];
        if (iccidCell) {
          iccidCell.textContent = `'${iccidCell.textContent}`;
        }
      });

      const ws = XLSX.utils.table_to_sheet(table);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "CDR_Report" + fileExtension);
    } else {
      toast.error("No data available for export.");
    }
  };
  //These Functions are for the reports download   -- Ends here
  useEffect(() => {
    fetchSimData();
  }, []);

  const fetchSimData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL_CDR}/cdr/api/v1/tenant`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCdrData(result.cdrData);
        setLength(result.cdrData.length);
      })
      .catch((error) => {});
  };

  const fetchIccid = (Iccid, MCC, MNC) => {
    localStorage.setItem("storedIccid", Iccid);
    localStorage.setItem("MCC", MCC);
    localStorage.setItem("MNC", MNC);

    navigate("/CDR_Details");
  };

  const convertToNormalFormat = (timestamp) => {
    const formattedTime = new Date(timestamp)
      .toISOString()
      .replace(/T/, " ")
      .replace(/\.000Z/, "");
    return formattedTime;
  };

  return (
    <Container>
      <Box
        className="breadcrumb"
        style={{ display: "flex", margin: "20px 0px" }}
      >
        <H2>Reports | </H2>
        <p
          style={{
            margin: "5px",
            color: "#8224e3",
          }}
        >
          Call Detail Report
        </p>
        <Tooltip title="Apply column filter" arrow>
          <Button
            style={{ color: "#8224e3", marginLeft: "auto" }}
            variant="outlined"
            color="inherit"
            onClick={handleButtonClick}
          >
            <Icon color="#8224e3">filter_alt_outlined</Icon>
          </Button>
        </Tooltip>
      </Box>

      <Container
        style={{
          ...containerStyle,
          display: animateContainer ? "block" : "none",
          position: "absolute",
          top: "20%",
          left: "20%",
          transform: "translate(-25%,-25%)",
          zIndex: "100",
        }}
      >
        <>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "10px",
              backgroundColor: " rgb(233 226 226 / 100%)",
              borderRadius: "25px",
              marginLeft: "15%",
              animation: "fadeIn .3s ease-in-out",
            }}
          >
            <div
              style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
            >
              <H2
                style={{ margin: "10px", padding: "0px", marginRight: "10px" }}
              >
                Columns
                <Switch
                  color="primary"
                  checked={!allVisible}
                  onChange={handleAllVisible}
                />
              </H2>
              <hr />
            </div>

            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-evenly",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                CDR ID
                <Switch
                  color="primary"
                  checked={CdrIdVisible}
                  onChange={handleCdrIdVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                ICCID
                <Switch
                  color="primary"
                  checked={iccidVisible}
                  onChange={handleIccidVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                IMSI Id
                <Switch
                  color="primary"
                  checked={imsiVisible}
                  onChange={handleImsiVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                IMSI_NO.
                <Switch
                  color="primary"
                  checked={ImsiNoVisible}
                  onChange={handleImsiNoVisible}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                TYPE
                <Switch
                  color="primary"
                  checked={typeVisible}
                  onChange={handleTypeVisible}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Carrier Name
                <Switch
                  color="primary"
                  checked={carrierNameVisible}
                  onChange={handleCarrierNameVisible}
                />
              </p>
            </div>

            <div
              style={{
                padding: "5px",
                display: "flex",
                justifyContent: "space-evenly",
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Event Date
                <Switch
                  color="primary"
                  checked={eventDateVisible}
                  onChange={handleEventDateVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                MCC
                <Switch
                  color="primary"
                  checked={mccVisible}
                  onChange={handleMccVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                MNC
                <Switch
                  color="primary"
                  checked={mncVisible}
                  onChange={handleMncVisible}
                />
              </p>

              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                DATA USAGE
                <Switch
                  color="primary"
                  checked={dataVisible}
                  onChange={handleDataVisible}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                COUNTRY
                <Switch
                  color="primary"
                  checked={CountryVisible}
                  onChange={handleCountryVisible}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Connect Time
                <Switch
                  color="primary"
                  checked={connectTimeVisible}
                  onChange={handleConnectTimeVisible}
                />
              </p>
              <p
                style={{
                  padding: "0px",
                  fontSize: "16px",
                }}
              >
                Close Time
                <Switch
                  color="primary"
                  checked={closeTimeVisible}
                  onChange={handleCloseTimeVisible}
                />
              </p>
            </div>

            <hr />
            <div style={{ padding: "4px", marginLeft: "85%" }}>
              <Button
                style={{ color: "#8224e3" }}
                variant="outlined"
                color="inherit"
                onClick={handleButtonClick}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      </Container>

      <SimpleCard title="Call Details Report">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "10px",
            alignItems: "flex-end",
            marginTop: "-28px",
          }}
        >
          <input
            style={{
              height: "40px",
              width: "30%",
              border: "1px solid  lightgrey",
              paddingLeft: "10px",
              borderRadius: "5px",
              outline: "1px black lightgrey",
            }}
            name="search"
            placeholder="Search..."
            type="search"
            value={searchTerm}
            onChange={handleSearch}
          />

          <Select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            style={{ height: "39px", width: "25%" }}
            displayEmpty
            MenuProps={{
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="DATA">DATA</MenuItem>
            <MenuItem value="DATA_PACKAGE	">DATA PACKAGE</MenuItem>
            <MenuItem value="SMS">SMS</MenuItem>
          </Select>

          <div className="inputInfoBox" style={{ width: "30%", margin: "0px" }}>
            <p id="inputInfoTopText">
              Select Format
              <Tooltip
                title="Select a format to download the report"
                placement="top-start"
              >
                <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
              </Tooltip>
            </p>
            {length > 0 ? (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem value="excel" onClick={() => exportToExcel()}>
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={generatePDF} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            ) : (
              <Select
                value={FormatFilter}
                onChange={(e) => setFormatFilter(e.target.value)}
                displayEmpty
                style={{ height: "39px", margin: "0px", width: "100%" }}
              >
                <MenuItem value="">--Select Downloadable Format--</MenuItem>
                <MenuItem onClick={noDataReport} value="excel">
                  Export View Excel
                </MenuItem>
                <MenuItem onClick={noDataReport} value="pdf">
                  Export View Pdf
                </MenuItem>
              </Select>
            )}
          </div>
        </div>

        <Box width="100%" overflow="auto" style={{ marginTop: "15px" }}>
          <StyledTable id="Table-Data" style={{ width: "180%" }}>
            <TableHead>
              <TableRow style={{ backgroundColor: " rgb(233 226 226 / 35%)" }}>
                {CdrIdVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                    //  width: '100px'
                    }}
                    align="center"
                    onClick={() => handleSort("CDR_ID")}
                  >
                    CDR ID
                    <TableSortLabel
                      active={orderBy === "CDR_ID"}
                      direction={orderBy === "CDR_ID" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {iccidVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                   
                    }}
                    align="center"
                    onClick={() => handleSort("ICCID")}
                  >
                    ICCID
                    <TableSortLabel
                      active={orderBy === "ICCID"}
                      direction={orderBy === "ICCID" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {imsiVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                   
                    }}
                    align="center"
                    onClick={() => handleSort("IMSI_ID")}
                  >
                    IMSI ID
                    <TableSortLabel
                      active={orderBy === "IMSI_ID"}
                      direction={orderBy === "IMSI_ID" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {dataVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width: '200px'
                    }}
                    align="center"
                    onClick={() => handleSort("DURATION")}
                  >
                    Data Usage (MB)
                    <TableSortLabel
                      active={orderBy === "DURATION"}
                      direction={orderBy === "DURATION" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {typeVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    align="center"
                    onClick={() => handleSort("TYPE")}
                  >
                    Type
                    <TableSortLabel
                      active={orderBy === "TYPE"}
                      direction={orderBy === "TYPE" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}
                {eventDateVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width: '200px'
                    }}
                    align="center"
                    onClick={() => handleSort("CLOSE_TIME")}
                  >
                    Event Date(UTC)
                    <TableSortLabel
                      active={orderBy === "CLOSE_TIME"}
                      direction={orderBy === "CLOSE_TIME" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}
                {mccVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                    
                    }}
                    align="center"
                    onClick={() => handleSort("MCC")}
                  >
                    MCC
                    <TableSortLabel
                      active={orderBy === "MCC"}
                      direction={orderBy === "MCC" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {mncVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                     
                    }}
                    align="center"
                    onClick={() => handleSort("MNC")}
                  >
                    MNC
                    <TableSortLabel
                      active={orderBy === "MNC"}
                      direction={orderBy === "MNC" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {ImsiNoVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width:'150px'
                    }}
                    align="center"
                    onClick={() => handleSort("IMSI_NO")}
                  >
                    IMSI Number
                    <TableSortLabel
                      active={orderBy === "IMSI_NO"}
                      direction={orderBy === "IMSI_NO" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}

                {CountryVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    align="center"
                    onClick={() => handleSort("COUNTRY_NAME")}
                  >
                    Country
                    <TableSortLabel
                      active={orderBy === "COUNTRY_NAME"}
                      direction={orderBy === "COUNTRY_NAME" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}
                {carrierNameVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width:'150px'
                    }}
                    align="center"
                    onClick={() => handleSort("brand")}
                  >
                    Carrier Name
                    <TableSortLabel
                      active={orderBy === "brand"}
                      direction={orderBy === "brand" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                )}
                {connectTimeVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width:'180px'
                    }}
                    align="center"
                    onClick={() => handleSort("brand")}
                  >
                    Connect Time (UTC)
                    <TableSortLabel
                      active={orderBy === "brand"}
                      direction={orderBy === "brand" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                  )}
                  {closeTimeVisible && (
                  <TableCell
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      cursor: "pointer",
                      // width:'180px'
                    }}
                    align="center"
                    onClick={() => handleSort("brand")}
                  >
                    Close Time (UTC)
                    <TableSortLabel
                      active={orderBy === "brand"}
                      direction={orderBy === "brand" ? order : "asc"}
                    ></TableSortLabel>
                  </TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {length > 0 ? (
                filteredAndSortedData
                  .filter(
                    (sim) =>
                      selectedFilter === "" || sim.TYPE === selectedFilter
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((sim, index) => {
                    return (
                      <TableRow hover key={index} id="iccidRow">
                        {CdrIdVisible && (
                          <TableCell
                            align="center"
                            style={{ paddingLeft: "10px" }}
                          >
                            {sim.CDR_ID}
                          </TableCell>
                        )}
                        {iccidVisible && (
                          <TableCell
                            align="center"
                            onClick={(Iccid) =>
                              fetchIccid(sim.ICCID, sim.MCC, sim.MNC)
                            }
                            id="iccid"
                          >
                            {sim.ICCID}
                          </TableCell>
                        )}
                        {imsiVisible && (
                          <TableCell align="center">{sim.IMSI_ID}</TableCell>
                        )}
                        {dataVisible && (
                          <TableCell align="center">{sim.DURATION}</TableCell>
                        )}
                        {typeVisible && (
                          <TableCell align="center">{sim.TYPE}</TableCell>
                        )}
                        {eventDateVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(sim.CLOSE_TIME)}
                          </TableCell>
                        )}
                        {mccVisible && (
                          <TableCell align="center">{sim.MCC}</TableCell>
                        )}
                        {mncVisible && (
                          <TableCell align="center">{sim.MNC}</TableCell>
                        )}
                        {ImsiNoVisible && (
                          <TableCell align="center">{sim.IMSI_NO}</TableCell>
                        )}
                        {CountryVisible && (
                          <TableCell align="center">
                            {sim.COUNTRY_NAME}
                          </TableCell>
                        )}
                        {carrierNameVisible && (
                          <TableCell align="center">
                            {sim.brand}
                          </TableCell>
                        )}
                        {connectTimeVisible && (
                          <TableCell align="center">
                            {convertToNormalFormat(sim.CONNECT_TIME)}
                          </TableCell>
                          )}
                          {closeTimeVisible && (
                          <TableCell align="center">
                          {convertToNormalFormat(sim.CLOSE_TIME)}
                          </TableCell>
                          )}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 15, 50]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </SimpleCard>
    </Container>
  );
};

export default CDR_;

