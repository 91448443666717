import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Switch from "@mui/material/Switch";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  TableSortLabel, // Import TableSortLabel
  Checkbox,
  Menu,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1 } from "app/components/Typography";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import "./AdminSIM.css";

const Container = styled("div")(({ theme }) => ({
  margin: "17px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0, fontFamily: "poppins" } },
  },
}));

const AdminSIMs = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [simData, setSimData] = useState([]);
  const [status, setStatus] = useState("");

  const [orderBy, setOrderBy] = useState("iccid");
  const [order, setOrder] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [FormatFilter, setFormatFilter] = useState("");

  const [columnVisible, setColumnVisible] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [animateContainer, setAnimateContainer] = useState(false);
  const [iccid, setIccid] = useState(true);
  const [inventoryId, setInventoryId] = useState(true);
  const [parentGroupID, setParentGroupId] = useState(true);
  const [simStatus, setSimStatus] = useState(true);
  const [providerName, setProviderName] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const accessToken = localStorage.getItem("accessToken");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (iccid) => {
    const updatedSelectedRows = selectedRows.includes(iccid)
      ? selectedRows.filter((id) => id !== iccid)
      : [...selectedRows, iccid];

    setSelectedRows(updatedSelectedRows);
  };

  const navigate = useNavigate();

  const fetchSims = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      headers: myHeaders,
    };
    const response = await fetch(
      `${REACT_APP_BASE_URL}/api/v1/get_admin_sims`,
      requestOptions
    );
    const result = await response.json();
    setSimData(result.AdminSims);
  };

  useEffect(() => {
    fetchSims();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function isoDate(inputDateString) {
    const date = new Date(inputDateString);

    const formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return formattedDate;
  }
  const handleSort = (property) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredData = simData.filter(
    (sim) =>
      sim.iccid.toLowerCase().includes(searchText.toLowerCase()) ||
      sim.Provider.toLowerCase().includes(searchText.toLowerCase()) ||
      "Deplofy Inventory".toLowerCase().includes(searchText.toLowerCase()) ||
      sim.status1.toLowerCase().includes(searchText.toLowerCase()) ||
      sim.SkuId.toLowerCase().includes(searchText.toLowerCase()) ||
      sim.statusOfSim.toLowerCase().includes(searchText.toLowerCase()) 
  );

  const sortedData = filteredData.sort((a, b) => {
    const isAsc = order === "asc";
    if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
    if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
    return 0;
  });

  const simInfo = (iccid) => {
    localStorage.setItem("iccid", iccid);
    navigate("/inventory/admin-siminfo");
  };

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const table = document.getElementById("Table-Data");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "SIM_Details" + fileExtension);
  };

  const [containerStyle, setContainerStyle] = useState({
    padding: "10px",
    top: "-238px",
    left: "500px",
    transitionProperty: "all",
    transitionDuration: "0.5s",
  });

  const handleButtonClick = () => {
    setAnimateContainer(!animateContainer);
    setButtonClicked(true);
  };

  const handleToggleColumnSwitch = () => {
    setColumnVisible(!columnVisible);
    if (columnVisible == false) {
      setInventoryId(true);
      setProviderName(true);
      setIccid(true);
      setParentGroupId(true);
      setSimStatus(true);
    } else if (columnVisible == true) {
      setInventoryId(false);
      setProviderName(false);
      setIccid(false);
      setParentGroupId(false);
      setSimStatus(false);
    }
  };

  const handleToggleSwitch = () => {
    setIccid(!iccid);
  };
  const handleToggleProviderName = () => {
    setProviderName(!providerName);
  };

  const handleToggleInventoryid = () => {
    setInventoryId(!inventoryId);
  };


  const handleToggleparentGroupId = () => {
    setParentGroupId(!parentGroupID);
  };

  const handleToggleSimStatus = () => {
    setSimStatus(!simStatus);
  };

  return (
    <>
      <Container>
        <Box className="breadcrumb" style={{ display: "flex" }}>
          <H2>SIM Cards| </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Pending SIM Cards
          </p>
        </Box>
        <Container
          style={{
            ...containerStyle,
            display: animateContainer ? "block" : "none",
            position: "absolute",
            top: "13%",
            left: "20%",
            transform: "translate(-25%,-25%)",
            zIndex: "100",
          }}
        >
          <>
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: "10px",
                backgroundColor: " rgb(242 242 242 / 100%)",
                borderRadius: "25px",
                marginLeft: "15%",
                animation: "fadeIn 1.5s ease-in-out",
              }}
            >
              <div
                style={{ padding: "0px", marginLeft: "10px", marginTop: "0px" }}
              >
                <H2
                  style={{
                    margin: "10px",
                    padding: "0px",
                    marginRight: "10px",
                  }}
                >
                  Columns
                  <Switch
                    color="primary"
                    checked={columnVisible}
                    onChange={handleToggleColumnSwitch}
                  />
                </H2>
                <hr />
              </div>
              <br />

              <div
                style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
              >
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    marginRight: "20px",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  ICCID
                  <Switch
                    color="primary"
                    checked={iccid}
                    onChange={handleToggleSwitch}
                  />
                </p>
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Inventory ID
                  <Switch
                    color="primary"
                    checked={inventoryId}
                    onChange={handleToggleInventoryid}
                  />
                </p>
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Status
                  <Switch
                    color="primary"
                    checked={simStatus}
                    onChange={handleToggleSimStatus}
                  />
                </p>
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Parent GroupID
                  <Switch
                    color="primary"
                    checked={parentGroupID}
                    onChange={handleToggleparentGroupId}
                  />
                </p>

              </div>

              <div
                style={{ padding: "5px", display: "flex", marginLeft: "20px" }}
              >
                <p
                  style={{
                    margin: "10px",
                    padding: "0px",
                    fontSize: "16px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  Provider Name
                  <Switch
                    color="primary"
                    checked={providerName}
                    onChange={handleToggleProviderName}
                  />
                </p>
              </div>

              <hr />
              <div style={{ padding: "4px", textAlign: "center" }}>
                <Button
                  style={{ color: "#8224e3" }}
                  variant="outlined"
                  color="inherit"
                  onClick={handleButtonClick}
                >
                  Close
                </Button>
              </div>
            </div>
          </>
        </Container>
        <SimpleCard title="Pending SIM Cards" style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "30px",
              height: "70px",
            }}
          >
            <input
              style={{
                height: "40px",
                width: "28%",
                border: "1px solid  lightgrey",
                paddingLeft: "10px",
                borderRadius: "5px",
                outline: "1px black lightgrey",
              }}
              name="search"
              placeholder="Search..."
              type="search"
              autoComplete="off"
              value={searchText}
              onChange={handleSearch}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {selectedRows.length > 0 && (
                <div style={{ width: "100%", margin: "0px 50px 3px 0px" }}>
                  <p id="inputInfoTopText">
                    Action
                    <Tooltip title="Select an action" placement="top-start">
                      <IconButton onClick={handleClick}>
                        <Icon style={{ transform: "scale(.7)" }}>
                          format_list_bulleted
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </p>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>Activate SIM</MenuItem>

                    <MenuItem>Suspend SIM</MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <Box
            style={{
              width: "100%",
              height: "auto",
            }}
          >
            <StyledTable
              id="Table-Data"
              stickyHeader
              style={{
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingRight: "8px" }} align="center">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < sortedData.length
                      }
                      checked={
                        sortedData.length > 0
                          ? selectedRows.length === sortedData.length
                          : false
                      }
                      onChange={() =>
                        selectedRows.length === sortedData.length
                          ? setSelectedRows([])
                          : setSelectedRows(sortedData.map((sim) => sim.iccid))
                      }
                    />
                  </TableCell>
                  {iccid && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        paddingLeft: "10px",
                        width: "18%",
                        cursor: "pointer",
                      }}
                      align="center"
                      onClick={() => handleSort("iccid")}
                    >
                      {" "}
                      ICCID
                      <TableSortLabel
                        active={orderBy === "iccid"}
                        direction={orderBy === "iccid" ? order : "asc"}
                      ></TableSortLabel>
                    </TableCell>
                  )}
                  {inventoryId && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Inventory Name
                    </TableCell>
                  )}

                  {providerName && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      Provider Name
                    </TableCell>
                  )}
                  {providerName && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      align="center"
                    >
                      SKU ID
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    align="center"
                  >
                    Assigned
                  </TableCell>
                  {simStatus && (
                    <TableCell
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        width: "15%",
                      }}
                      align="center"
                    >
                      Status
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData.length > 0 ? (
                  sortedData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((sim) => (
                      <TableRow key={sim.iccid}>
                        <TableCell align="center">
                          <Checkbox
                            checked={selectedRows.includes(sim.iccid)}
                            onChange={() => handleCheckboxChange(sim.iccid)}
                          />
                        </TableCell>
                        {iccid && (
                          <TableCell
                            id="iccid"
                            align="center"
                            onClick={() => simInfo(sim.iccid)}
                          >
                            {sim.iccid}
                          </TableCell>
                        )}
                        {inventoryId && (
                          <TableCell
                            align="center"
                          >
                            Deplofy Inventory
                          </TableCell>
                        )}
                        {providerName && (
                          <TableCell align="center"> {sim.Provider} </TableCell>
                        )}

                        {providerName && (
                          <TableCell align="center"> {sim.SkuId} </TableCell>
                        )}
                        <TableCell style={{ fontSize: "13px" }} align="center">
                          <Tooltip
                            title={
                              sim.status1 === "Unassigned"
                                ? "Unassigned"
                                : "Assigned"
                            }
                          >
                            {sim.status1 === "Unassigned" ? (
                              <CancelIcon color="error" />
                            ) : (
                              <CheckCircleOutlineIcon color="primary" />
                            )}
                          </Tooltip>
                        </TableCell>

                        {simStatus && (
                          <TableCell align="center">{sim.statusOfSim}</TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow colSpan={3}>
                    <TableCell colSpan={7} align="center">
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 500]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </SimpleCard>
      </Container>
    </>
  );
};

export default AdminSIMs;
