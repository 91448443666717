import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { H2, H3, H4, H5 } from "app/components/Typography";
import { styled } from "@mui/material/styles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import StoreTwoToneIcon from "@mui/icons-material/StoreTwoTone";
import "./AdminBilling.css";
import { useState } from "react";
import BankLogo from "../../components/Asset/images/BankLogo.png";
import { useEffect } from "react";
import noData from '../../components/no-data-found.png';
//These import are for the reports download   -- Starts here
import jsPDF from "jspdf";
import "jspdf-autotable";
//These import are for the reports download   -- Ends here

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  border: "0px",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AdminBilling = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [showBalance, setShowBalance] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [SessionData, setSessionData] = useState([]);
  const [SessionDataLength, setSessionDataLength] = useState("");
  const [openTransactionBox, setOpenTransactionBox] = useState(false);

  // Transaction details states which will be visible after selecting a transaction
  const [NoData, SetNoData] = useState(false);

  const [TransactionId, setTransactionId] = useState("");
  const [TransactionName, setTransactionName] = useState("");
  const [TransactionEmailId, setTransactionEmailId] = useState("");
  const [PackageName, setPackageName] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [TransactionAmount, setTransactionAmount] = useState("");
  const [TransactionAmountCurrency, setTransactionAmountCurrency] =
    useState("");
  const [TransactionDate, setTransactionDate] = useState("");

  const handleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  // Function to handle search
  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const showTransaction = () => {
    var raw = "";

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/stripe/api/v1/admin_paymentDetail`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSessionData(result.PaymentDetails);
        setSessionDataLength(result.PaymentDetails.length);
      })
    .catch((error) => {
    });
  };

  useEffect(() => {
    showTransaction();
  }, []);

  const handleOpenTransactionBox = (sessionId) => {
    setOpenTransactionBox(true);
    localStorage.setItem("sessionId", sessionId);
    showTransactionDetails(sessionId);
  };

  const showTransactionDetails = (sessionId) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      sessionId: sessionId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/stripe/api/v1/get_paymentDetails`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 0) {
          SetNoData(true);
        } else {
          setTransactionId(result.PaymentDetails.id);
          setTransactionName(result.TenantName);
          setTransactionEmailId(result.EmailId);
          setPackageName(result.packageName);
          setPaymentType(result.PaymentDetails.payment_method_types[0]);
          setTransactionAmount(result.PaymentDetails.amount);
          setTransactionAmountCurrency(result.PaymentDetails.currency);
          setTransactionDate(result.PaymentDetails.created);
          SetNoData(false);
        }
      })
  };

  useEffect(() => {
    showTransactionDetails();
  }, []);

  const generatePDF = () => {
    const table = document.getElementById("Table-Data");

    // Check if there is any data before generating PDF
    if (table && table.rows.length > 1) {
      const report = new jsPDF("portrait", "mm", "a4");

      // Use jsPDF autoTable plugin to add table to PDF
      report.autoTable({
        html: table,
        theme: "striped",
        styles: { fontSize: 8 },
        margin: { top: 15 },
      });
      report.save("Transaction_Details.pdf");
    }
  };

  const handleCloseTransactionDetails = () => {
    setOpenTransactionBox(false);
    setTransactionId("");
    setTransactionName("");
    setTransactionEmailId("");
    setPackageName("");
    setPaymentType("");
    setTransactionAmount("");
    setTransactionAmountCurrency("");
    setTransactionDate("");
  };

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  return (
    <Container style={{ paddingBottom: "30px" }}>
      <Box>
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center", margin: "20px 0px" }}
        >
          <H2>Billing | </H2>
          <p
            style={{
              margin: "5px",
              color: "#8224e3",
            }}
          >
            Billing and Payment Details
          </p>
        </Box>

        <Box>
          <H3>Payment Details</H3>
          <hr
            style={{
              border: "1px #dfe6e9 solid",
              width: "100%",
              marginBottom: "10px",
            }}
          />

          <div className="box">
            <div style={{ width: "28%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant="outlined">
                  <AccountBalanceIcon
                    style={{ fontSize: "140px" }}
                  ></AccountBalanceIcon>
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    padding: "10px 15px",
                    border: "1px #dfe6e9 solid",
                  }}
                >
                  <Button variant="outlined">
                    <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
                  </Button>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontWeight: "600",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    >
                      Total Amount :
                    </Typography>
                    <div
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={handleShowBalance}
                    >
                      {" "}
                      Click here{" "}
                    </div>
                  </div>
                </div>

                {showBalance && (
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      width: "70%",
                      margin: "10px 0px",
                    }}
                  >
                    Amount : $ 71.00
                  </Typography>
                )}
              </div>
            </div>

            <div
              className="boxItems"
              style={{
                padding: "10px",
                width: "35%",
                height: "400px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  borderBottom: "1px grey solid",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    margin: "0px 0px 6px 5px ",
                  }}
                >
                  Pending Payments
                </Typography>
              </div>

              <input
                style={{
                  padding: "10px",
                  height: "30px",
                  width: "230px",
                  margin: "8px 0px",
                  fontSize: "12px",
                  border: "1px grey solid",
                  outline: "none",
                  borderRadius: "6px",
                }}
                name="search"
                placeholder="Search by Tenant Name..."
                type="text"
              />

              <div
                style={{
                  height: "300px",
                  overflow: "auto",
                }}
                className="scroll"
              >
                <img src={noData} style={{ height: '100%' }} alt="" />
              </div>
            </div>

            <div
              className="boxItems"
              style={{
                padding: "10px",
                width: "33%",
                height: "400px",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  borderBottom: "1px grey solid",
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    margin: "0px 0px 6px 5px ",
                  }}
                >
                  Transaction History
                </Typography>
              </div>

              <input
                style={{
                  padding: "10px",
                  height: "30px",
                  width: "230px",
                  margin: "8px 0px",
                  fontSize: "12px",
                  border: "1px grey solid",
                  outline: "none",
                  borderRadius: "6px",
                }}
                name="search"
                placeholder="Search by Tenant Name..."
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
              />

              <div
                style={{
                  height: "300px",
                  overflow: "auto",
                }}
                className="scroll"
              >
                {SessionDataLength > 0 ? (
                  SessionData.filter(
                    (data) =>
                      data.TenantName &&
                      data.TenantName.toLowerCase().includes(
                        searchQuery.toLowerCase()
                      )
                  ).map((data) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "6px 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenTransactionBox(data.sessionId)}
                      >
                        <StoreTwoToneIcon
                          style={{
                            border: "1px #dfe6e9 solid",
                            padding: "7px",
                            fontSize: "45px",
                            borderRadius: "50%",
                          }}
                        ></StoreTwoToneIcon>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "56%",
                            padding: "0px 7px 9px 3px",
                            borderBottom: "1px grey solid",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "100",
                              fontSize: "14px",
                              marginRight: "5px",
                            }}
                          >
                            {data.TenantName}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ fontSize: "10px", color: "grey" }}>
                              {" "}
                              {epochToDate(data.sessionData.created)}{" "}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px 0px",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{
                              fontWeight: "600",
                              color: "#8224e3",
                              fontSize: "16px",
                              marginRight: "5px",
                            }}
                          >
                            {data.sessionData.amount_subtotal}{" "}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              fontSize: "13px",
                              alignItems: "center",
                              justifyContent: "space-around",
                            }}
                          >
                            ( {data.sessionData.currency})
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "6px 0px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: "0px 7px 9px 3px",
                        borderBottom: "1px grey solid",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{
                          fontWeight: "100",
                          fontSize: "14px",
                          marginRight: "5px",
                        }}
                      >
                      </Typography>
                      <img src={noData} style={{ height: '100%', width: '100%' }} alt="" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>

        {/* MODAL for showing the transaction details on selecting a transaction */}
        <Modal
          open={openTransactionBox}
          onClose={() => setOpenTransactionBox(false)}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 600 }}>
            <div
              className="box1_1"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "15px",
                padding: "0px 10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "green",
                  margin: "20px 0px",
                }}
              >
                <H2
                  style={{
                    fontSize: "25px",
                    letterSpacing: "1px",
                    color: "green",
                  }}
                >
                  Transaction Details
                </H2>
              </div>

              <table
                id="Table-Data"
                style={{
                  padding: "5px 0px",
                }}
              >
                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Transaction Id :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData ? "No data available" : TransactionId}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                      width: "40%",
                    }}
                  >
                    Name :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData ? "No data available" : TransactionName}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Email Id :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData ? "No data available" : TransactionEmailId}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Package Name :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData ? "No data available" : PackageName}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Payment Method :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData ? "No data available" : PaymentType}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Amount :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData
                      ? "No data available"
                      : TransactionAmount + "  " + TransactionAmountCurrency}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    Date & Time :
                  </td>
                  <td
                    style={{
                      padding: "2px 25px ",
                    }}
                  >
                    {NoData
                      ? "No data available"
                      : epochToDate(TransactionDate)}
                  </td>
                </tr>
              </table>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "15px 0px ",
              }}
            >
              <Button
                style={{ color: "green", transform: "scale(.9)" }}
                variant="outlined"
                color="inherit"
                onClick={generatePDF}
              >
                Print Recipt
              </Button>

              <Button
                style={{ color: "red", transform: "scale(.9)" }}
                variant="outlined"
                color="inherit"
                onClick={handleCloseTransactionDetails}
              >
                Close Details
              </Button>
            </div>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
};

export default AdminBilling;
