import { Box, Container } from '@mui/material'
import { H1 } from 'app/components/Typography'
import React from 'react'

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Inventorygroup from './Inventorygroup';
import InventoryPackage from './InventoryPackage';

const InventoryInfo = () => {

    let id = localStorage.getItem('id')


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [value, setValue] = React.useState('1');

    return (
        <>
            <Container>
                <Box className="breadcrumb" style={{ display: 'flex', marginTop: '10px' }}>
                    <H1>Deplofy Inventory  </H1>
                    <p
                        style={{
                            marginLeft: '5px',
                            color: '#8224E3'
                        }}
                    >
                        ({id})
                    </p>
                </Box>
                <Box sx={{ width: '100%', typography: 'body1' }} style={{
                    backgroundColor: 'white'
                }} >
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Overview" value="1" />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Inventorygroup /> </TabPanel>

                    </TabContext>
                </Box>
            </Container >
        </>
    )
}

export default InventoryInfo;
