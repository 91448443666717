import { Box, Fab, IconButton, Container } from "@mui/material";
import { Breadcrumb, SimpleCard } from "app/components";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import IconButton from '@mui/material/IconButton';
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { Tooltip } from "@mui/material";

import { countries } from "countries-list";
// import Select from "react-select";



import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  FormControl,
  InputLabel,
  MenuItem,
  styled,
  Chip,
} from "@mui/material";
import { H2, H1, H3, H4 } from "app/components/Typography";
import iso3166 from "iso-3166-1";

import { Span } from "app/components/Typography";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import {
  Select,
  // Tooltip,
} from "@mui/material";

const TextField = styled(TextValidator)(() => ({
  width: "80%",
  marginBottom: "16px",
  marginLeft: "44px",
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AppButtonRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
  "& .button": { margin: theme.spacing(1) },
  "& .input": { display: "none" },
}));


const TenantPackages = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const TenantUUID = localStorage.getItem("TenantUUID");
  const accessToken = localStorage.getItem("accessToken");


  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [voiceUsage, setVoiceUsage] = useState("");
  const [dataUsage, setDataUsage] = useState("");
  const [SMSUsage, setSMSUsage] = useState("");
  const [allowTime, setAllowTime] = useState("");
  const [activationTime, setActivationTime] = useState("");
  const [packageDescription, setPackageDescription] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [SelectedDataSpeed, setSelectedDataSpeed] = useState("");
  const [ThrottleSpeedList, setThrottleSpeedList] = useState('')
  const [SelectedThrottleSpeed, setSelectedThrottleSpeed] = useState("");
  const [showSMSUsageInput, setShowSMSUsageInput] = useState(false);
  const [showDataUsageInput, setShowDataUsageInput] = useState(false);
  const [isVoiceUsageClicked, setIsVoiceUsageClicked] = useState(false);
  const [showThrottleSpeed, setShowThrottleSpeed] = useState(true);
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState([]);


  const handleCheckBox1 = () => {
    setCheckBox1((prevCheckBox) => !prevCheckBox);

    if (checkBox1) {
      setDataUsage(dataUsage);
    } else {
      setDataUsage("");
    }
  };
  const handleDataUsageChange = (e) => {
    const enteredValue = e.target.value;
    setDataUsage(enteredValue);
  };
  const handleCheckBox2 = () => {
    setCheckBox2((prevCheckBox) => !prevCheckBox);

    if (checkBox2) {

      setSMSUsage(SMSUsage);
    } else {
      setSMSUsage("");
    }
  };
  const handleSMSUsageChange = (e) => {
    const enteredValue = e.target.value;

    if (/^[0-9]*$/.test(enteredValue)) {
      setSMSUsage(enteredValue);
    }
  };

  const handleCheckBox3 = () => {
    setCheckBox3((prevCheckBox) => !prevCheckBox);

    if (checkBox3) {
      setVoiceUsage(voiceUsage);
    } else {
      setVoiceUsage("");
    }
  };

  const handleVoiceUsageChange = (e) => {
    const enteredValue = e.target.value;

    if (!isNaN(enteredValue)) {
      setVoiceUsage(enteredValue);
    }
  };
  const handleCurrentCountry = (event) => {
    setSelectedCountry(event.target.value);
  };
  const countryOptions = Object.keys(countries).map((code) => ({
    value: code,
    label: countries[code].name,
  }));
  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const showThrottleSpeedList = () => {
    var raw = "";

    var requestOptions = {
      method: 'POST',
      body: raw,
      redirect: 'follow'
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/throttle_list`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        setThrottleSpeedList(result.throttleList)
      })
      .catch(error => {
      });
  }

  useEffect(() => {
    showThrottleSpeedList()
  }, [])


  const handleDataSpeed = (event) => {
    setSelectedDataSpeed(event.target.value);
  };
  const handleThrottleSpeed = (event) => {
    setSelectedThrottleSpeed(event.target.value);
  };

  const handleCreatePackage = () => {
    if (!(selectedPlan && name && selectedCountry && dataUsage)) {
      toast.error("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  

    const iso3Codes = selectedCountry.map((countryName) => {
      const country = iso3166.whereCountry(countryName.toUpperCase());
      return country ? country.alpha3 : null;
    });


    const dataUsageInBytes = dataUsage * 1024 * 1024;

    if (SelectedDataSpeed == 'SameSpeed') {
      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        inventoryId: 49182,
        PackageType: selectedPlan,
        activationType: "API",
        name: name,
        supportedCountries: iso3Codes,
        voiceUsageAllowanceInSeconds: 0,
        dataUsageAllowanceInBytes: dataUsageInBytes,
        smsUsageAllowanceInNums: 0,
        timeAllowanceInSeconds: 3660,
        activationTimeAllowanceInSeconds: 3700,
        dateEarliestActivation: 86400000,
        dateEarliestAvailable: 86400056,
        dateLatestAvailable: 86400567,
        packageDescription: packageDescription,
        ThrottleSpeed: "NO_LIMIT"
      });
    } else if (SelectedDataSpeed == 'ThrottleSpeed') {
      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        inventoryId: 49182,
        PackageType: selectedPlan,
        activationType: "API",
        name: name,
        supportedCountries: iso3Codes,
        voiceUsageAllowanceInSeconds: 0,
        dataUsageAllowanceInBytes: dataUsageInBytes,
        smsUsageAllowanceInNums: 0,
        timeAllowanceInSeconds: 3660,
        activationTimeAllowanceInSeconds: 3700,
        dateEarliestActivation: 86400000,
        dateEarliestAvailable: 86400056,
        dateLatestAvailable: 86400567,
        packageDescription: packageDescription,
        ThrottleSpeed: SelectedThrottleSpeed
      });
    } else {
      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        inventoryId: 49182,
        PackageType: selectedPlan,
        activationType: "API",
        name: name,
        supportedCountries: iso3Codes,
        voiceUsageAllowanceInSeconds: 0,
        dataUsageAllowanceInBytes: dataUsageInBytes,
        smsUsageAllowanceInNums: 0,
        timeAllowanceInSeconds: 3660,
        activationTimeAllowanceInSeconds: 3700,
        dateEarliestActivation: 86400000,
        dateEarliestAvailable: 86400056,
        dateLatestAvailable: 86400567,
        packageDescription: packageDescription,

      });
    }


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_package?domain=${TenantUUID}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === 1) {
          toast("Package Created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });

          setSelectedPlan("");
          setName("");
          setSelectedCountry([]);
          setDataUsage("");
          setPackageDescription("");
        }

      })
      .catch((error) => {
      });
  };


  return (
    <>
      <AppButtonRoot>
        <Box
          className="breadcrumb"
          style={{ display: "flex", alignItems: "center" }}
        >
          <H3>Create Package | </H3>

          <p
            style={{
              marginLeft: "10px",
              color: "#8224e3",
              margin: "0",
              marginTop: "4px",
            }}
          >
            Create Package
          </p>
        </Box>
        <Box>
          <SimpleCard title="Create Package">
            <hr />
            <div
              className="inputInfoBox"
              style={{ width: "47%", marginLeft: "1.5%" }}
            >
              <div style={{ display: "flex" }}>
                <p id="inputInfoTopText">Select Package Type</p>
                <Tooltip title="Select Package Type" placement="right">
                  <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
                </Tooltip>
              </div>
              <Select
                inputProps={{ tabIndex: "1" }}
                value={selectedPlan}
                onChange={(e) => handlePlanChange(e)}
                input={<OutlinedInput labelWidth={0} />}
                style={{ height: "42px", width: "100%" }}
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Package Type
                </MenuItem>
                <MenuItem value="CAPPED">Capped</MenuItem>
                <MenuItem value="AUTORENEW">Autorenew</MenuItem>
                <MenuItem value="OVERAGES">Overages</MenuItem>
              </Select>
            </div>
            {(selectedPlan == "OVERAGES") && (
              <div className="inputInfoBox"
                style={{ width: "47%", margin: "20px 0px 10px 1.5%", }}
              >
                <div style={{ display: "flex" }}>
                  <p id="inputInfoTopText">Select Overages Internet Speed</p>
                  <Tooltip title="Select an option for overages internet speed" placement="right">
                    <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
                  </Tooltip>
                </div>
                <Select
                  inputProps={{ tabIndex: "1" }}
                  value={SelectedDataSpeed}
                  onChange={(e) => handleDataSpeed(e)}
                  input={<OutlinedInput labelWidth={0} />}
                  style={{ height: "42px", width: "100%" }}
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    -- Select --
                  </MenuItem>
                  <MenuItem value="SameSpeed">Usage with same speed</MenuItem>
                  <MenuItem value="ThrottleSpeed">Usage with throttle speed</MenuItem>
                </Select>
              </div>
            )}

            {(SelectedDataSpeed == "ThrottleSpeed" && selectedPlan == "OVERAGES") && (
              <div className="inputInfoBox"
                style={{ width: "47%", margin: "20px 0px 10px 1.5%", }}
              >
                <div style={{ display: "flex" }}>
                  <p id="inputInfoTopText">Select Throttle Speed</p>
                  <Tooltip title="Select a throttle speed for the package" placement="right">
                    <Icon style={{ transform: "scale(.7)" }}>info_outline</Icon>
                  </Tooltip>
                </div>
                <Select
                  inputProps={{ tabIndex: "1" }}
                  value={SelectedThrottleSpeed}
                  onChange={(e) => handleThrottleSpeed(e)}
                  input={<OutlinedInput labelWidth={0} />}
                  style={{ height: "42px", width: "100%" }}
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    -- Select --
                  </MenuItem>
                  {ThrottleSpeedList.map((speed, index) => {
                    return <MenuItem key={index} value={speed}>{speed}</MenuItem>
                  })}
                </Select>
              </div>
            )}

            {/* <hr /> */}

            <ValidatorForm>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{ mt: 2 }}
                  px={2}
                  style={{}}
                >
                  <div
                    className="inputInfoBox "
                    style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Name</p>
                      <Tooltip title="Name" placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "2" }}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      style={{ width: "100%" }}
                      placeholder="Name"
                    />
                  </div>

                  <div style={{ display: "grid" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBox1}
                          onChange={handleCheckBox1}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Data Usage"
                      sx={{}}
                    />
                    {checkBox1 && (
                      <div
                        className="inputInfoBox"
                        style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Data Usage Limit</p>
                          <Tooltip
                            title="Data Usage Limit in MB"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <OutlinedInput
                          type="text"
                          inputProps={{ tabIndex: "3" }}
                          onChange={handleDataUsageChange}
                          value={dataUsage}
                          style={{ width: "100%" }}
                          placeholder="Data Usage Limit in MB"
                        />
                      </div>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBox2}
                          onChange={handleCheckBox2}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="SMS Usage"
                      sx={{}}
                    />
                    {checkBox2 && (
                      <div
                        className="inputInfoBox"
                        style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">SMS Usage Limit</p>
                          <Tooltip
                            title="SMS Usage in Number"
                            placement="right"
                          >
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <OutlinedInput
                          type="text"
                          inputProps={{ tabIndex: "4" }}
                          onChange={handleSMSUsageChange}
                          value={SMSUsage}
                          style={{ width: "100%" }}
                          placeholder="SMS Usage Limit in Number"
                        />
                      </div>
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBox3}
                          onChange={handleCheckBox3}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Voice Usage"
                      sx={{}}
                    />
                    {checkBox3 && (
                      <div
                        className="inputInfoBox"
                        style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <p id="inputInfoTopText">Voice Usage Limit</p>
                          <Tooltip title="Voice Usage Limit" placement="right">
                            <Icon style={{ transform: "scale(.7)" }}>
                              info_outline
                            </Icon>
                          </Tooltip>
                        </div>
                        <OutlinedInput
                          type="text"
                          inputProps={{ tabIndex: "5" }}
                          onChange={handleVoiceUsageChange}
                          value={voiceUsage}
                          style={{ width: "100%" }}
                          placeholder="Voice Usage Limit"
                        />
                      </div>
                    )}
                  </div>

                  <div
                    className="inputInfoBox "
                    style={{
                      width: "100%",
                      margin: "10px 0px 12px 0px",
                      //   border: "2px green solid",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Supported Country</p>
                      <Tooltip
                        title="Enter the Countries seperated by comma"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    {/* <Select
                      isMulti={true}
                      value={selectedCountry}
                      onChange={handleChange}
                      options={countryOptions}
                      placeholder="Select Country"
                      style={{
                        width: "100%",
                        // border: "2px red solid !important",
                      }}
                    /> */}
                    <Select
                      multiple
                      labelId="country-label"
                      inputProps={{ tabIndex: "4" }}
                      value={selectedCountry}
                      onChange={handleCurrentCountry}
                      style={{ width: "100%", height: "42px" }}
                    >
                      <MenuItem disabled>--Select Country </MenuItem>
                      {countryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {selectedPlan === "Overages" && (
                    <div
                      className="inputInfoBox"
                      style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Select Throttle Speeds</p>
                        <Tooltip
                          title="Select Throttle Speeds"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        inputProps={{ tabIndex: "7" }}
                        value={selectedPlan}
                        onChange={(e) => handlePlanChange(e)}
                        input={<OutlinedInput labelWidth={0} />}
                        style={{ height: "42px", width: "100%" }}
                        displayEmpty
                        MenuProps={{
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select Throttle Speeds
                        </MenuItem>
                      </Select>
                    </div>
                  )}

                  {/* <div
                    className="inputInfoBox "
                    style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Allow Time</p>
                      <Tooltip title="Allow Time in hrs" placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "7" }}
                      onChange={(e) => setAllowTime(e.target.value)}
                      value={allowTime || ""}
                      style={{ width: "100%" }}
                      placeholder="Allow Time in hrs"
                    />
                  </div> */}
                  <div style={{ padding: "5px", marginLeft: "-6px" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        marginLeft: "4px",
                      }}
                    >
                      Remark (Optional)
                    </p>
                    <TextField
                      // fullWidth
                      multiline
                      inputProps={{ tabIndex: "9" }}
                      onChange={(e) => setPackageDescription(e.target.value)}
                      value={packageDescription}
                      rows={6}
                      label="Description"
                      variant="outlined"
                      style={{
                        margin: "2px",
                        width: "100%",
                        marginLeft: "1px",
                        marginTop: "2px",
                      }}
                    />
                  </div>
                </Grid>

                <div style={{ width: "50%" }}>
                  <Grid
                    item
                    // lg={11}
                    // md={6}
                    // sm={12}
                    // xs={12}
                    // sx={{ mt: 2 }}
                    // px={2}
                    style={{ width: "95%" }}
                  >
                    {/* <div
                    className="inputInfoBox "
                    style={{ width: "100%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Activation Time</p>
                      <Tooltip title="Activation Time in sec" placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "6" }}
                      onChange={(e) => setActivationTime(e.target.value)}
                      value={activationTime || ""}
                      style={{ width: "100%" }}
                      placeholder="Activation Time in hrs"
                    />
                  </div> */}
                  </Grid>
                </div>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleCreatePackage}
                  inputProps={{ tabIndex: "10" }}
                  // type="submit"
                  style={{
                    width: "18%",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    color: "#9d4edd",
                    border: "1px solid #9d4edd",
                    borderRadius: "6px",
                    marginTop: "30px",
                    marginLeft: "40%",
                  }}
                >
                  Create Package
                </Button>
              </Grid>
            </ValidatorForm>
          </SimpleCard>
        </Box>
      </AppButtonRoot>
    </>
  )
}

export default TenantPackages;