import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Collapse,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Modal,
} from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import "./AssignSimStyle.css";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

import {
  Box,
  Container,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Input,
  Button,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Breadcrumb, SimpleCard } from "app/components";
import { H2, H1, H3, H4 } from "app/components/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { loadStripe } from "@stripe/stripe-js";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "0px",
  p: 4,
};

const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } },
  },
}));

const AssignSim = () => {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const TrialPeriod = localStorage.getItem('TrialPeriod');

  const accessToken = localStorage.getItem("accessToken");
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    if (paymentStatus === "0") {
      setOpenModel(true);
    } else {
      setOpen(true);
    }
  };

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [iccid, setIccid] = useState("");
  const [supervisorList, setSupervisorList] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState("");

  const TenantUUID = localStorage.getItem("TenantUUID");
  const [inventoryList, setInventoryList] = useState([]);
  const [packageList, setPackageList] = useState([]);

  const [selectedInventory, setSelectedInventory] = useState("");

  const [selectedGroup, setSelectedGroup] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [billCycle, setBillCycle] = useState("");
  const [billDate, setBillDate] = useState("");
  const [checkBoxTrial, setCheckBoxTrial] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState("");
  const [endBillDate, setEndBillDate] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [ratePrice, setRatePrice] = useState("");
  const [trial, setTrial] = useState(false);
  const [startBillDate, setStartBillDate] = useState("");

  const [iccids, setIccids] = useState([]);
  const [selectedStartIccid, setSelectedStartIccid] = useState("");
  const [selectedEndIccid, setSelectedEndIccid] = useState("");
  const [selectedICCIDs, setSelectedICCIDs] = useState([]);
  const [selectedIccids, setSelectedIccids] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [totalSims, setTotalSims] = useState("");
  const [parentGroupId, setParentGroupId] = useState("");
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [dynamicPrice, setDynamicPrice] = useState("");
  const [packageName, setPackageName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [OpenModel, setOpenModel] = useState(false);
  const paymentStatus = localStorage.getItem("paymentStatus");
  const PayableAmount = localStorage.getItem("PayableAmount");
  let formattedValue = parseFloat(PayableAmount).toFixed(2);


  const [packages, setPackages] = useState([]);

  const handleChangePackage = (event) => {
    const { value } = event.target;
    setSelectedPackage(value);
  };

  const handleTogglePackages = (packageId) => {
    const isSelected = selectedPackage.indexOf(packageId) > -1;

    // Create a new array with the selectedPackage IDs as strings
    const newSelectedPackages = isSelected
      ? selectedPackage.filter((id) => id !== packageId)
      : [...selectedPackage, packageId];

    setSelectedPackage(newSelectedPackages);
  };

  const getPackageNameById = (packageId) => {
    const selectedPackage = packages.find((pkg) => pkg.packageId === packageId);
    return selectedPackage ? selectedPackage.name : "";
  };

  const handleRemovePackage = (value) => {
    const updatedSelectedPackage = selectedPackage.filter((id) => id !== value);
    setSelectedPackage(updatedSelectedPackage);
  };

  useEffect(() => {
    fetchpackages();
  }, []);

  const fetchpackages = async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_packages`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const packagevalue = result.Packages.map((packages) => packages.name);
        setPackages(result.Packages);
      })
      .catch(error);
  };

  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51OFJZ6SEcQuFHGfiI7eQ1fgYMhF8MfCY24Mw8IrLjEOSqUatXeFZqswfkBu4nqHDtbLUe0Lma6ieWHAZQuYR3h4T00ymT3ZfXQ"
    );

    const Product = {
      name: "Total Payable Amount",
      price: formattedValue,
      quantity: 1,
    };

    const body = {
      products: [Product],
      AccountType: "Tenant",
      packageName: "Total Payable Amount",
      packageId: "0",
      packageDescription: "",
      UUID: TenantUUID,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      `${REACT_APP_BASE_URL}/stripe/api/v1/create-checkout-session`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();
    localStorage.setItem("SessionId", session.id);
    localStorage.setItem("PaymentFlag", "DuePayment");

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });
  };

  useEffect(() => {
    const fetchPackageList = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${REACT_APP_BASE_URL}/api/v1/get_tenant_package`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            setPackageList(result.TenantPackages);
          }
        })
        .catch(error);
    };

    fetchPackageList();
  }, []);

  const handleCheckBoxTrial = () => {
    setCheckBoxTrial((prevCheckBox) => !prevCheckBox);
    setTrial(!trial);
  };

  useEffect(() => {
    const fetchInventoryList = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        TenantUUID: TenantUUID,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${REACT_APP_BASE_URL}/inventory_list`,
          requestOptions
        );

        if (response.ok) {
          const result = await response.json();
          if (result.Status === 1) {
            setInventoryList(result.InventoryList);
          } else {
          }
        } else {
        }
      } catch (error) {
      }
    };

    fetchInventoryList();
  }, []);

  const getUserNameById = (userId) => {
    const user = supervisorList
      ? supervisorList.find((contact) => contact.UserUUID === userId)
      : "";
    return user ? user.FirstName + " " + user.LastName : "";
  };

  const getInventoryNameById = (inventoryId) => {
    const inventoryName = inventoryList
      ? inventoryList.find((inventory) => inventory.id === inventoryId)
      : "";
    return inventoryName ? inventoryName.name : "";
  };

  const getGroupNameById = (groupId) => {
    const groupName = groupList
      ? groupList.find((group) => group.id === groupId)
      : "";
    return groupName ? groupName.name : "";
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedIccids(value);
  };

  const handleRemoveICCID = (iccid) => {
    const updatedSelectedICCIDs = selectedICCIDs.filter((id) => id !== iccid);
    setSelectedICCIDs(updatedSelectedICCIDs);

    const updatedSelectedIccids = selectedIccids.filter((id) => id !== iccid);
    setSelectedIccids(updatedSelectedIccids);
  };

  const handleStartIccidChange = (newValue) => {
    setSelectedStartIccid(newValue);
  };

  const handleEndIccidChange = (newValue) => {
    setSelectedEndIccid(newValue);

    setSelectedICCIDs([]);

    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === newValue);

    if (startIndex !== -1 && endIndex !== -1) {
      const idsInRange = iccids.slice(startIndex, endIndex + 1);
      setSelectedICCIDs(idsInRange);
    }
  };

  const handleSave = () => {
    const ValidIccid = selectedIccids;
    const validData = ValidIccid
      ? selectedIccids
      : !selectedStartIccid || selectedEndIccid;
    if (
      !selectedGroup ||
      !selectedInventory ||
      !selectedSupervisor ||
      !selectedPackage ||
      !validData
    ) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setOpened(true);
  };
  const handleClosed = () => {
    setOpened(false);
  };
  const handleAdd = () => {
    AssignSim();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    fetchRate();
  }, [selectedPackage]);

  const fetchRate = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      packageId: selectedPackage,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_price`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDynamicPrice(result.DynamicPrice);
        setPackageName(result.Name);
      })
      .catch(error);
  };
  // warning modal functions

  const closedModel = () => {
    setOpenModel(false);
  };

  const cancelpagess = () => {
    setOpenModel(false);
  };

  const openModel = () => {
    if (paymentStatus === "0") {
      setOpenModel(true);
      return;
    } else {
      const startIndex = iccids.findIndex(
        (iccid) => iccid === selectedStartIccid
      );
      const endIndex = iccids.findIndex((iccid) => iccid === selectedEndIccid);
      const commaSeparatedIds = selectedICCIDs.join(",");
      const commaSeparatedIdsss = selectedIccids.join(",");

      const mergedCommaSeparatedIds = commaSeparatedIds.concat(
        ",",
        commaSeparatedIdsss
      );

      var formattedMergedIds = "";

      if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
        formattedMergedIds = `${mergedCommaSeparatedIds}`;
      }

      if (commaSeparatedIds == "") {
        formattedMergedIds = `${commaSeparatedIdsss}`;
      }
      if (commaSeparatedIdsss == "") {
        formattedMergedIds = `${commaSeparatedIds}`;
      }

      if (
        !selectedSupervisor ||
        !selectedInventory ||
        !selectedGroup ||
        !billCycle ||
        !billDate ||
        !formattedMergedIds
      ) {
        toast("Please fill in all required fields", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      setOpened(true);
    }
  };

  const AssignSim = () => {
    const startIndex = iccids.findIndex(
      (iccid) => iccid === selectedStartIccid
    );
    const endIndex = iccids.findIndex((iccid) => iccid === selectedEndIccid);
    const commaSeparatedIds = selectedICCIDs.join(",");
    const commaSeparatedIdsss = selectedIccids.join(",");

    const mergedCommaSeparatedIds = commaSeparatedIds.concat(
      ",",
      commaSeparatedIdsss
    );

    var formattedMergedIds = "";

    if (commaSeparatedIds != "" && commaSeparatedIdsss != "") {
      formattedMergedIds = `${mergedCommaSeparatedIds}`;
    }

    if (commaSeparatedIds == "") {
      formattedMergedIds = `${commaSeparatedIdsss}`;
    }
    if (commaSeparatedIdsss == "") {
      formattedMergedIds = `${commaSeparatedIds}`;
    }

    if (!selectedSupervisor || !selectedInventory || !formattedMergedIds) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    const startbilldate = new Date(startBillDate);
    const formattedStartBillDate = startbilldate
      .toISOString()
      .replace("Z", "+00:00");

    const endbilldate = new Date(endBillDate);
    const formattedEndBillDate = endbilldate
      .toISOString()
      .replace("Z", "+00:00");

    if (trial) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      if (!dateFlag) {
        toast.error("Ending billing date must be greater than the starting billing date", {
          toast: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
        return;
      }

      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        UserUUID: selectedSupervisor,
        inventoryId: selectedInventory,
        packageId: "1234",
        billDate: billDate,
        billStartDate: formattedStartBillDate,
        billDate: formattedEndBillDate,
        trial: true,
        TrialPeriod: trialPeriod,
        basePrice: basePrice,
        ratePrice: ratePrice,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/api/v1/assign_sim_to_user?iccidRange=${formattedMergedIds}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            toast("Sim Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (result.Status === 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
        .catch(error);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      if (!dateFlag) {
        toast.error("Ending billing date must be greater than the starting billing date", {
          toast: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
        return;
      }

      var raw = JSON.stringify({
        TenantUUID: TenantUUID,
        UserUUID: selectedSupervisor,
        inventoryId: selectedInventory,
        packageId: "1234",
        billDate: billDate,
        billStartDate: formattedStartBillDate,
        billDate: formattedEndBillDate,
        trial: false,
        TrialPeriod: "",
        basePrice: basePrice,
        ratePrice: ratePrice,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_BASE_URL}/api/v1/assign_sim_to_user?iccidRange=${formattedMergedIds}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.Status === 1) {
            toast("Sim Assigned Successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (result.Status === 0) {
            toast(result.Message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
        })
        .catch(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_URL}/get_all_client/${TenantUUID}`
      );
      const result = await response.json();
      if (result.Status === 1) {
        setSupervisorList(result.Account_details);
      } else {
      }
    } catch (error) {
    }
  };

  const fetchGroupList = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      inventoryId: selectedInventory,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/get_group`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGroupList(result.Groups);
      })
      .catch(error);
  };

  useEffect(() => {
    fetchGroupList();
    getGroupNameById();
  }, [selectedInventory]);

  useEffect(() => {
    const fetchIccids = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          TenantUUID: TenantUUID,
          inventoryId: selectedInventory,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `${REACT_APP_BASE_URL}/api/v1/get_sims_of_tenant`,
          requestOptions
        );
        const result = await response.json();

        if (result.Status === 1) {
          const iccidValues = result.Sims[0].iccids.map((sim) => sim.iccId);
          setIccids(iccidValues);
        } else {
        }
      } catch (error) {
      }
    };

    fetchIccids();
  }, [selectedInventory]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_URL}/api/v1/get_parent_group?domain=${TenantUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setParentGroupId(result.ParentGroup[0].parentGroupId);
      })
      .catch(error);
  });

  const cancelpage = () => {
    setSelectedInventory("");
    setGroupName("");
    setOpen(false);
  };
  const cancelpages = () => {
    setOpened(false);
  };

  const handleCreateGroup = () => {
    if (!groupName || !selectedInventory) {
      toast("Please fill in all required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      TenantUUID: TenantUUID,
      name: groupName,
      inventoryId: selectedInventory,
      parentGroupId: parentGroupId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_URL}/api/v1/create_group`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 1) {
          toast(result.Message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setOpen(false);
          setSelectedInventory("");
          setGroupName("");
        }
      })
      .catch(error);
  };

  const [error, setError] = useState(false);
  const [dateFlag, setDateFlag] = useState(true)
  const checkDate = (endDate) => {
    if (startBillDate && endDate) {
      const startTimestamp = new Date(startBillDate).getTime();
      const endTimestamp = new Date(endDate).getTime();

      if (endTimestamp <= startTimestamp) {
        setError(true);
        setDateFlag(false);
        return;
      } else {
        setError(false);
        setDateFlag(true);
      }
    }
  };

  const handleToggleIccid = (iccid) => {
    const currentIndex = selectedIccids.indexOf(iccid);
    const newSelectedIccids = [...selectedIccids];

    if (currentIndex === -1) {
      newSelectedIccids.push(iccid);
    } else {
      newSelectedIccids.splice(currentIndex, 1);
    }

    setSelectedIccids(newSelectedIccids);
  };

  function epochToDate(epochTimestamp) {
    // Create a new Date object from the epochTimestamp
    const date = new Date(epochTimestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Create a formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  }

  return (
    <>
      <Container style={{ padding: "10px" }}>
        <>
          <ValidatorForm onSubmit={(e) => e.preventDefault()}>
            <Box
              style={{
                alignItems: "center",
                backgroundColor: "white",
                padding: "30px",
              }}
            >
              <div
                className="box1"
                style={{
                  width: "100%",
                  height: "auto",
                  padding: "15px 10px",
                  backgroundColor: " rgb(233 226 226 / 35%)",
                  borderRadius: "25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                }}
              >
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={ModalStyle}>
                    <div
                      className="box2_1"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    >
                      <div className="ModalInputSelect">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "50%",
                          }}
                        >
                          Select a Inventory :
                        </H3>
                        <div
                          className="inputInfoBox "
                          style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                        >
                          <div style={{ display: "flex" }}>
                            <p id="inputInfoTopText">Inventory List</p>
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Select a Inventory from the List"
                              placement="right"
                            >
                              <Icon style={{ transform: "scale(.7)" }}>
                                info_outline
                              </Icon>
                            </Tooltip>
                          </div>
                          <Select
                            value={selectedInventory}
                            onChange={(e) =>
                              setSelectedInventory(e.target.value)
                            }
                            displayEmpty
                            style={{ height: "50px", width: "100%" }}
                            MenuProps={{
                              getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  maxHeight: 200,
                                },
                              },
                            }}
                          >
                            <MenuItem value="" disabled>
                              -- Select the Inventory--
                            </MenuItem>
                            {inventoryList.length > 0 ? (
                              inventoryList.map((inventory, index) => (
                                <MenuItem key={index} value={inventory.id}>
                                  {inventory.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>--No Inventory Data--</MenuItem>
                            )}
                          </Select>
                        </div>
                      </div>

                      <div className="ModalInputSelect">
                        <H3
                          style={{
                            fontSize: "16px",
                            width: "50%",
                          }}
                        >
                          Enter Name of The Group :
                        </H3>
                        <div
                          className="inputInfoBox "
                          style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                        >
                          <div style={{ display: "flex" }}>
                            <p id="inputInfoTopText">Billing Group Name</p>
                            <span style={{ color: "red" }}>*</span>
                            <Tooltip
                              title="Enter Name of the Billing Group"
                              placement="right"
                            >
                              <Icon style={{ transform: "scale(.7)" }}>
                                info_outline
                              </Icon>
                            </Tooltip>
                          </div>
                          <OutlinedInput
                            type="text"
                            name="shippingState"
                            inputProps={{ tabIndex: "" }}
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            style={{ width: "100%", height: "50px" }}
                            placeholder="Billing Group Name "
                          />
                        </div>
                      </div>

                      <Button
                        onClick={handleCreateGroup}
                        style={{
                          color: "#8224e3",
                          margin: "20px 0px 10px 38%",
                        }}
                        variant="outlined"
                        color="inherit"
                      >
                        Create Group
                      </Button>
                      <Button
                        onClick={cancelpage}
                        style={{ color: "red", margin: "20px 0px 10px 5%" }}
                        variant="outlined"
                        color="inherit"
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Modal>
                <div
                  style={{
                    padding: "5px 20px 7px 10px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <H3 style={{ padding: "0px" }}>Assign SIM Cards to Client</H3>
                </div>
                <hr
                  style={{
                    border: "1px lightGrey solid",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                />
                <div className="AssignSIMSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Clients List</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Client from the List"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedSupervisor}
                      onChange={(e) => setSelectedSupervisor(e.target.value)}
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select Client--
                      </MenuItem>
                      {supervisorList.length > 0 ? (
                        supervisorList.map((supervisor, index) => (
                          <MenuItem key={index} value={supervisor.UserUUID}>
                            {getUserNameById(supervisor.UserUUID)}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>--No data now--</MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
                <div className="AssignSIMSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Inventory List</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Inventory from the List"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedInventory}
                      onChange={(e) => setSelectedInventory(e.target.value)}
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Inventory--
                      </MenuItem>
                      {inventoryList.length > 0 ? (
                        inventoryList.map((inventory, index) => (
                          <MenuItem key={index} value={inventory.id}>
                            {getInventoryNameById(inventory.id)}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>--No Inventory Data--</MenuItem>
                      )}
                    </Select>
                  </div>
                </div>
                <div className="createTenantAssignInput">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "-10px 10px 4px 0px" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBoxTrial}
                          onChange={handleCheckBoxTrial}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Keep This as Trial Package"
                      sx={{}}
                    />
                  </div>
                </div>
                {checkBoxTrial && (
                  <div className="createTenantAssignSelect">
                    <div
                      className="inputInfoBox "
                      style={{ width: "50%", margin: "0px 0px 0px 0px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <p id="inputInfoTopText">Trial Days</p>
                        <span style={{ color: "red" }}>*</span>
                        <Tooltip
                          title="Select the Days for Trial Period"
                          placement="right"
                        >
                          <Icon style={{ transform: "scale(.7)" }}>
                            info_outline
                          </Icon>
                        </Tooltip>
                      </div>
                      <Select
                        value={trialPeriod}
                        onChange={(e) => setTrialPeriod(e.target.value)}
                        displayEmpty
                        style={{ height: "35px", width: "100%" }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          -- Select the Days of Trial Period --
                        </MenuItem>
                        <MenuItem value="7"> 7 Days </MenuItem>
                        <MenuItem value="14"> 14 Days </MenuItem>
                        <MenuItem value="21"> 21 Days </MenuItem>
                        <MenuItem value="28"> 28 Days </MenuItem>
                      </Select>
                    </div>
                  </div>
                )}{" "}
                <div className="AssignSIMSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Package Name</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="Select the Package" placement="right">
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={selectedPackage}
                      onChange={handleChangePackage}
                      // multiple
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Package --
                      </MenuItem>
                      {(packages ?? []).length > 0 ? (
                        packages.map((pkg) => (
                          <MenuItem key={pkg.packageId} value={pkg.packageId}>
                            {pkg.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>--No Packages Data--</MenuItem>
                      )}

                    </Select>
                  </div>
                </div>
                <div className="AssignSIMSelect">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Billing Cycle</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Billing Cycle for the Selected Package"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <Select
                      value={billCycle}
                      onChange={(e) => setBillCycle(e.target.value)}
                      displayEmpty
                      style={{ height: "35px", width: "100%" }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        -- Select the Billing Cycle --
                      </MenuItem>
                      <MenuItem value="7"> 7 Days </MenuItem>
                      <MenuItem value="14"> 14 Days </MenuItem>
                      <MenuItem value="21"> 21 Days </MenuItem>
                      <MenuItem value="28"> 28 Days </MenuItem>
                    </Select>
                  </div>
                </div>

                <div className="createTenantAssignInput">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Starting Billing Date</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Billing Date for the Selected Package"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      id="startbillDate"
                      type="datetime-local"
                      inputProps={{ tabIndex: "" }}
                      value={startBillDate}
                      onChange={(e) => setStartBillDate(e.target.value)}
                      style={{ height: "35px", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="createTenantAssignInput">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Ending Billing Date</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Select the Billing Date for the Selected Package"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      id="billDate"
                      type="datetime-local"
                      inputProps={{ tabIndex: "" }}
                      value={endBillDate}
                      onChange={(e) => {
                        setEndBillDate(e.target.value);
                        checkDate(e.target.value);
                      }}
                      style={{ height: "35px", width: "100%" }}
                    />
                    {error && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "0px 0 0 10px",
                        }}
                      >
                        Ending billing date must be greater than the starting
                        billing date
                      </div>
                    )}
                  </div>
                </div>
                <div className="createTenantAssignInput">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Base Price</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Enter the Base Price of the Package"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "2" }}
                      onChange={(e) => setBasePrice(e.target.value)}
                      value={basePrice}
                      style={{ width: "100%", height: "35px" }}
                      placeholder="Base Price"
                    />
                  </div>
                </div>
                <div className="createTenantAssignInput">
                  <div
                    className="inputInfoBox "
                    style={{ width: "50%", margin: "0px 0px 12px 0px" }}
                  >
                    <div style={{ display: "flex" }}>
                      <p id="inputInfoTopText">Rate Price</p>
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip
                        title="Enter the Rate Price of the Package(per MB Cost)"
                        placement="right"
                      >
                        <Icon style={{ transform: "scale(.7)" }}>
                          info_outline
                        </Icon>
                      </Tooltip>
                    </div>
                    <OutlinedInput
                      type="text"
                      inputProps={{ tabIndex: "2" }}
                      onChange={(e) => setRatePrice(e.target.value)}
                      value={ratePrice}
                      style={{ width: "100%", height: "35px" }}
                      placeholder="Rate Price"
                    />
                  </div>
                </div>
                {/* Here this is for the slection -------------------------------------------------------- */}
                <div className="SelectionBox">
                  <div className="Selection ">
                    <div className="range">
                      <H3
                        style={{
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Range Selection of SIM Cards{" "}
                        <span style={{ color: "red" }}>*</span>
                      </H3>

                      <Autocomplete
                        value={selectedStartIccid}
                        onChange={(event, newValue) =>
                          handleStartIccidChange(newValue)
                        }
                        options={iccids.filter(
                          (option) =>
                            !selectedIccids.includes(option) &&
                            option !== selectedEndIccid
                        )}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="-- Starting ICCID --"
                            variant="outlined"
                            style={{
                              height: "40px",
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                        )}
                      />

                      <Autocomplete
                        value={selectedEndIccid}
                        onChange={(event, newValue) =>
                          handleEndIccidChange(newValue)
                        }
                        options={iccids.filter(
                          (option) =>
                            !selectedIccids.includes(option) &&
                            option !== selectedStartIccid
                        )}
                        style={{ width: "100%", marginTop: "10px" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="-- Ending ICCID --"
                            variant="outlined"
                            style={{
                              height: "47px",
                              width: "100%",
                              marginTop: "10px",
                            }}
                          />
                        )}
                      />
                    </div>

                    <H2
                      style={{
                        margin: "12px 46% 12px 0px",
                      }}
                    >
                      OR
                    </H2>

                    <div className="individual">
                      <H3
                        style={{
                          fontSize: "16px",
                          width: "100%",
                        }}
                      >
                        Individual Selection of SIM Cards{" "}
                        <span style={{ color: "red" }}>*</span>
                      </H3>
                      <div style={{ width: "100%", marginTop: "10px" }}>
                        <FormControl style={{ width: "100%" }}>
                          <Autocomplete
                            multiple
                            displayEmpty={true}
                            id="demo-multiple-checkbox"
                            options={iccids.filter(
                              (option) =>
                                option !== selectedStartIccid &&
                                option !== selectedEndIccid &&
                                option !== selectedICCIDs &&
                                !selectedICCIDs.includes(option)
                            )}
                            value={selectedIccids}
                            renderTags={() => null}
                            onChange={(event, newValue) =>
                              setSelectedIccids(newValue)
                            }
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="-- Select ICCIDs --"
                                variant="outlined"
                                onChange={(e) => setSearchValue(e.target.value)}
                              />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  checked={selected}
                                  onClick={() => handleToggleIccid(option)}
                                />
                                {option}
                              </li>
                            )}
                            isOptionEqualToValues={(option, value) =>
                              option === value
                            }
                            filterOptions={(options, { inputValue }) => {
                              if (!inputValue) {
                                return options;
                              }

                              const regex = new RegExp(inputValue, "i");
                              return options.filter((option) =>
                                regex.test(option)
                              );
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div
                    className="SelectionIccid"
                    style={{
                      width: "50%",
                      height: "200px",
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      border: "1px #2d3436 solid",
                      boxShadow: "rgba(0, 0, 0, 0.1) - 4px 9px 25px - 6px",
                      marginLeft: "20px",
                      // overflow: "auto",
                    }}
                  >
                    <H4 style={{ padding: "0px", color: "#636e72" }}>
                      <i>These are the Selected SIM Card :</i>
                    </H4>
                    {(selectedEndIccid || selectedIccids.length > 0) && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "10px 0px 0px 5px",
                          overflow: "auto",
                          height: "150px",
                        }}
                      >
                        {[...selectedICCIDs, ...selectedIccids].map(
                          (iccid, index) => (
                            <Chip
                              key={index}
                              label={iccid}
                              onDelete={() => handleRemoveICCID(iccid)}
                              color="primary"
                              variant="outlined"
                              style={{
                                marginRight: "8px",
                                marginBottom: "8px",
                              }}
                            />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <Modal
                  open={opened}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={ModalStyle}
                    style={{
                      width: "45%",
                      height: "auto",
                      borderRadius: "15px",
                      backgroundColor:
                        "rgb(110 118 115 / 90%) 0px 4px 8px 0px, rgba(0, 0, 0, 0.99) 0px 6px 20px 0px",
                      padding: "20px 30px",
                    }}
                  >
                    <div
                      className="box2_2"
                    >
                      <div className="ModalInputSelect">
                        <H2
                          style={{
                            fontSize: "26px",
                            width: "100%",
                            fontWeight: "500",
                          }}
                        >
                          Review Order Summary
                        </H2>
                      </div>
                      <hr />

                      <table style={{}}>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Client Name:
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#4a4646f0",
                            }}
                          >
                            {getUserNameById(selectedSupervisor)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Inventory Name :
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#4a4646f0",
                            }}
                          >
                            {getInventoryNameById(selectedInventory)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Group Name :
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#4a4646f0",
                            }}
                          >
                            {getGroupNameById(selectedGroup)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Package Name :
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#4a4646f0",
                            }}
                          >
                            {selectedPackage}
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Billing Cycle :
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#4a4646f0",
                            }}
                          >
                            {billCycle} Days
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                            }}
                          >
                            Billing Date :
                          </td>
                          <td
                            style={{
                              padding: "2px 10px",
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#4a4646f0",
                            }}
                          >
                            {epochToDate(billDate)}
                          </td>
                        </tr>

                        <tr
                          style={{
                            border: "1px brown solid",
                          }}
                        >
                          <td
                            style={{
                              fontSize: "14px",
                              color: "#1e3799",
                              width: "30%",
                              marginTop: "0px",
                            }}
                          >
                            Selected SIM Card :
                          </td>
                          <td
                            style={{
                              width: "90%",
                              padding: "3px 10px",
                              fontWeight: "500",
                              fontSize: "13px",
                              color: "#4a4646f0",
                              display: "flex",
                              flexWrap: "wrap",
                              border: "1px lightgrey solid",
                              borderRadius: "10px",
                            }}
                          >
                            <div
                              id="confirmSims"
                              style={{
                                maxHeight: "80px",
                                overflow: "auto",
                              }}
                            >
                              {selectedICCIDs.join(" ")}
                              {selectedIccids.join("  ")}
                            </div>
                          </td>
                        </tr>
                      </table>

                      <div
                        style={{
                          // border: '1px purple solid',
                          marginTop: "7px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={cancelpages}
                          style={{ color: "red", transform: "scale(.9)" }}
                          variant="outlined"
                          color="inherit"
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={AssignSim}
                          style={{ color: "green", transform: "scale(.9)" }}
                          variant="outlined"
                          color="inherit"
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <Modal
                  open={OpenModel}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={ModalStyle}
                    style={{
                      width: "30%",
                      height: "auto",
                      borderRadius: "15px",
                      backgroundColor:
                        "rgb(110 118 115 / 90%) 0px 4px 8px 0px, rgba(0, 0, 0, 0.99) 0px 6px 20px 0px",
                    }}
                  >
                    <div
                      className="box2_2"
                      style={{
                        width: "100%",
                        height: "auto",
                        textAlign: "center",
                      }}
                    >
                      <div className="ModalInputSelect">
                        <H2
                          style={{
                            fontSize: "20px",
                            width: "100%",
                            fontWeight: "501",
                            textAlign: "center",
                          }}
                        >
                          <WarningAmberOutlinedIcon
                            style={{
                              color: "red",
                              fontSize: "25px",
                              marginBottom: "-5px",
                            }}
                          ></WarningAmberOutlinedIcon>
                          Warning!
                        </H2>
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "15px",
                          fontWeight: "500",
                        }}
                      >
                        You have exceeded the due date of your payment!
                      </p>
                      <p style={{ textAlign: "center", fontSize: "13px" }}>
                        To continue using the platform, please complete your
                        payment.
                      </p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          onClick={cancelpagess}
                          style={{ color: "red", margin: "0px 10px" }}
                          variant="outlined"
                          color="inherit"
                        >
                          Cancel
                        </Button>

                        <Button
                          onClick={makePayment}
                          style={{ color: "green", margin: "0px 10px" }}
                          variant="outlined"
                          color="inherit"
                        >
                          Make Payment
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Modal>
                <div
                  style={{
                    padding: "5px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={AssignSim}
                    disabled={TrialPeriod == "Expired"}
                    style={{ color: "#8224e3" }}
                    variant="outlined"
                    color="inherit"
                  >
                    Assign SIM Cards
                  </Button>
                </div>
              </div>
            </Box>
          </ValidatorForm>
        </>
      </Container>
    </>
  );
};

export default AssignSim;
