const countries = [
  {
    code: "AFG",
    name: "Afghanistan",
    eu: false,
  },
  {
    code: "ALA",
    name: "Åland Islands",
    eu: false,
  },
  {
    code: "ALB",
    name: "Albania",
    eu: false,
  },
  {
    code: "DZA",
    name: "Algeria",
    eu: false,
  },
  {
    code: "ASM",
    name: "American Samoa",
    eu: false,
  },
  {
    code: "AND",
    name: "Andorra",
    eu: false,
  },
  {
    code: "AGO",
    name: "Angola",
    eu: false,
  },
  {
    code: "AIA",
    name: "Anguilla",
    eu: false,
  },
  {
    code: "ATA",
    name: "Antarctica",
    eu: false,
  },
  {
    code: "ATG",
    name: "Antigua and Barbuda",
    eu: false,
  },
  {
    code: "ARG",
    name: "Argentina",
    eu: false,
  },
  {
    code: "ARM",
    name: "Armenia",
    eu: false,
  },
  {
    code: "ABW",
    name: "Aruba",
    eu: false,
  },
  {
    code: "AUS",
    name: "Australia",
    eu: false,
  },
  {
    code: "AUT",
    name: "Austria",
    eu: true,
  },
  {
    code: "AZE",
    name: "Azerbaijan",
    eu: false,
  },
  {
    code: "BHS",
    name: "Bahamas",
    eu: false,
  },
  {
    code: "BHR",
    name: "Bahrain",
    eu: false,
  },
  {
    code: "BGD",
    name: "Bangladesh",
    eu: false,
  },
  {
    code: "BRB",
    name: "Barbados",
    eu: false,
  },
  {
    code: "BLR",
    name: "Belarus",
    eu: false,
  },
  {
    code: "BEL",
    name: "Belgium",
    eu: true,
  },
  {
    code: "BLZ",
    name: "Belize",
    eu: false,
  },
  {
    code: "BEN",
    name: "Benin",
    eu: false,
  },
  {
    code: "BMU",
    name: "Bermuda",
    eu: false,
  },
  {
    code: "BTN",
    name: "Bhutan",
    eu: false,
  },
  {
    code: "BOL",
    name: "Bolivia, Plurinational State of",
    eu: false,
  },
  {
    code: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    eu: false,
  },
  {
    code: "BIH",
    name: "Bosnia and Herzegovina",
    eu: false,
  },
  {
    code: "BWA",
    name: "Botswana",
    eu: false,
  },
  {
    code: "BVT",
    name: "Bouvet Island",
    eu: false,
  },
  {
    code: "BRA",
    name: "Brazil",
    eu: false,
  },
  {
    code: "IOT",
    name: "British Indian Ocean Territory",
    eu: false,
  },
  {
    code: "BRN",
    name: "Brunei Darussalam",
    eu: false,
  },
  {
    code: "BGR",
    name: "Bulgaria",
    eu: true,
  },
  {
    code: "BFA",
    name: "Burkina Faso",
    eu: false,
  },
  {
    code: "BDI",
    name: "Burundi",
    eu: false,
  },
  {
    code: "KHM",
    name: "Cambodia",
    eu: false,
  },
  {
    code: "CMR",
    name: "Cameroon",
    eu: false,
  },
  {
    code: "CAN",
    name: "Canada",
    eu: false,
  },
  {
    code: "CPV",
    name: "Cape Verde",
    eu: false,
  },
  {
    code: "CYM",
    name: "Cayman Islands",
    eu: false,
  },
  {
    code: "CAF",
    name: "Central African Republic",
    eu: false,
  },
  {
    code: "TCD",
    name: "Chad",
    eu: false,
  },
  {
    code: "CHL",
    name: "Chile",
    eu: false,
  },
  {
    code: "CHN",
    name: "China",
    eu: false,
  },
  {
    code: "CXR",
    name: "Christmas Island",
    eu: false,
  },
  {
    code: "CCK",
    name: "Cocos (Keeling) Islands",
    eu: false,
  },
  {
    code: "COL",
    name: "Colombia",
    eu: false,
  },
  {
    code: "COM",
    name: "Comoros",
    eu: false,
  },
  {
    code: "COG",
    name: "Congo",
    eu: false,
  },
  {
    code: "COD",
    name: "Congo, the Democratic Republic of the",
    eu: false,
  },
  {
    code: "COK",
    name: "Cook Islands",
    eu: false,
  },
  {
    code: "CRI",
    name: "Costa Rica",
    eu: false,
  },
  {
    code: "CIV",
    name: "Côte d'Ivoire",
    eu: false,
  },
  {
    code: "HRV",
    name: "Croatia",
    eu: true,
  },
  {
    code: "CUB",
    name: "Cuba",
    eu: false,
  },
  {
    code: "CUW",
    name: "Curaçao",
    eu: false,
  },
  {
    code: "CYP",
    name: "Cyprus",
    eu: true,
  },
  {
    code: "CZE",
    name: "Czech Republic",
    eu: true,
  },
  {
    code: "DNK",
    name: "Denmark",
    eu: true,
  },
  {
    code: "DJI",
    name: "Djibouti",
    eu: false,
  },
  {
    code: "DMA",
    name: "Dominica",
    eu: false,
  },
  {
    code: "DOM",
    name: "Dominican Republic",
    eu: false,
  },
  {
    code: "ECU",
    name: "Ecuador",
    eu: false,
  },
  {
    code: "EGY",
    name: "Egypt",
    eu: false,
  },
  {
    code: "SLV",
    name: "El Salvador",
    eu: false,
  },
  {
    code: "GNQ",
    name: "Equatorial Guinea",
    eu: false,
  },
  {
    code: "ERI",
    name: "Eritrea",
    eu: false,
  },
  {
    code: "EST",
    name: "Estonia",
    eu: true,
  },
  {
    code: "ETH",
    name: "Ethiopia",
    eu: false,
  },
  {
    code: "FLK",
    name: "Falkland Islands (Malvinas)",
    eu: false,
  },
  {
    code: "FRO",
    name: "Faroe Islands",
    eu: false,
  },
  {
    code: "FJI",
    name: "Fiji",
    eu: false,
  },
  {
    code: "FIN",
    name: "Finland",
    eu: true,
  },
  {
    code: "FRA",
    name: "France",
    eu: true,
  },
  {
    code: "GUF",
    name: "French Guiana",
    eu: false,
  },
  {
    code: "PYF",
    name: "French Polynesia",
    eu: false,
  },
  {
    code: "ATF",
    name: "French Southern Territories",
    eu: false,
  },
  {
    code: "GAB",
    name: "Gabon",
    eu: false,
  },
  {
    code: "GMB",
    name: "Gambia",
    eu: false,
  },
  {
    code: "GEO",
    name: "Georgia",
    eu: false,
  },
  {
    code: "DEU",
    name: "Germany",
    eu: true,
  },
  {
    code: "GHA",
    name: "Ghana",
    eu: false,
  },
  {
    code: "GIB",
    name: "Gibraltar",
    eu: false,
  },
  {
    code: "GRC",
    name: "Greece",
    eu: true,
  },
  {
    code: "GRL",
    name: "Greenland",
    eu: false,
  },
  {
    code: "GRD",
    name: "Grenada",
    eu: false,
  },
  {
    code: "GLP",
    name: "Guadeloupe",
    eu: false,
  },
  {
    code: "GUM",
    name: "Guam",
    eu: false,
  },
  {
    code: "GTM",
    name: "Guatemala",
    eu: false,
  },
  {
    code: "GGY",
    name: "Guernsey",
    eu: false,
  },
  {
    code: "GIN",
    name: "Guinea",
    eu: false,
  },
  {
    code: "GNB",
    name: "Guinea-Bissau",
    eu: false,
  },
  {
    code: "GUY",
    name: "Guyana",
    eu: false,
  },
  {
    code: "HTI",
    name: "Haiti",
    eu: false,
  },
  {
    code: "HMD",
    name: "Heard Island and McDonald Islands",
    eu: false,
  },
  {
    code: "VAT",
    name: "Holy See (Vatican City State)",
    eu: false,
  },
  {
    code: "HND",
    name: "Honduras",
    eu: false,
  },
  {
    code: "HKG",
    name: "Hong Kong",
    eu: false,
  },
  {
    code: "HUN",
    name: "Hungary",
    eu: true,
  },
  {
    code: "ISL",
    name: "Iceland",
    eu: false,
  },
  {
    code: "IND",
    name: "India",
    eu: false,
  },
  {
    code: "IDN",
    name: "Indonesia",
    eu: false,
  },
  {
    code: "IRN",
    name: "Iran, Islamic Republic of",
    eu: false,
  },
  {
    code: "IRQ",
    name: "Iraq",
    eu: false,
  },
  {
    code: "IRL",
    name: "Ireland",
    eu: true,
  },
  {
    code: "IMN",
    name: "Isle of Man",
    eu: false,
  },
  {
    code: "ISR",
    name: "Israel",
    eu: false,
  },
  {
    code: "ITA",
    name: "Italy",
    eu: true,
  },
  {
    code: "JAM",
    name: "Jamaica",
    eu: false,
  },
  {
    code: "JPN",
    name: "Japan",
    eu: false,
  },
  {
    code: "JEY",
    name: "Jersey",
    eu: false,
  },
  {
    code: "JOR",
    name: "Jordan",
    eu: false,
  },
  {
    code: "KAZ",
    name: "Kazakhstan",
    eu: false,
  },
  {
    code: "KEN",
    name: "Kenya",
    eu: false,
  },
  {
    code: "KIR",
    name: "Kiribati",
    eu: false,
  },
  {
    code: "PRK",
    name: "Korea, Democratic People's Republic of",
    eu: false,
  },
  {
    code: "KOR",
    name: "Korea, Republic of",
    eu: false,
  },
  {
    code: "KWT",
    name: "Kuwait",
    eu: false,
  },
  {
    code: "KGZ",
    name: "Kyrgyzstan",
    eu: false,
  },
  {
    code: "LAO",
    name: "Lao People's Democratic Republic",
    eu: false,
  },
  {
    code: "LVA",
    name: "Latvia",
    eu: true,
  },
  {
    code: "LBN",
    name: "Lebanon",
    eu: false,
  },
  {
    code: "LSO",
    name: "Lesotho",
    eu: false,
  },
  {
    code: "LBR",
    name: "Liberia",
    eu: false,
  },
  {
    code: "LBY",
    name: "Libya",
    eu: false,
  },
  {
    code: "LIE",
    name: "Liechtenstein",
    eu: false,
  },
  {
    code: "LTU",
    name: "Lithuania",
    eu: true,
  },
  {
    code: "LUX",
    name: "Luxembourg",
    eu: true,
  },
  {
    code: "MAC",
    name: "Macao",
    eu: false,
  },
  {
    code: "MKD",
    name: "Macedonia, the former Yugoslav Republic of",
    eu: false,
  },
  {
    code: "MDG",
    name: "Madagascar",
    eu: false,
  },
  {
    code: "MWI",
    name: "Malawi",
    eu: false,
  },
  {
    code: "MYS",
    name: "Malaysia",
    eu: false,
  },
  {
    code: "MDV",
    name: "Maldives",
    eu: false,
  },
  {
    code: "MLI",
    name: "Mali",
    eu: false,
  },
  {
    code: "MLT",
    name: "Malta",
    eu: true,
  },
  {
    code: "MHL",
    name: "Marshall Islands",
    eu: false,
  },
  {
    code: "MTQ",
    name: "Martinique",
    eu: false,
  },
  {
    code: "MRT",
    name: "Mauritania",
    eu: false,
  },
  {
    code: "MUS",
    name: "Mauritius",
    eu: false,
  },
  {
    code: "MYT",
    name: "Mayotte",
    eu: false,
  },
  {
    code: "MEX",
    name: "Mexico",
    eu: false,
  },
  {
    code: "FSM",
    name: "Micronesia, Federated States of",
    eu: false,
  },
  {
    code: "MDA",
    name: "Moldova, Republic of",
    eu: false,
  },
  {
    code: "MCO",
    name: "Monaco",
    eu: false,
  },
  {
    code: "MNG",
    name: "Mongolia",
    eu: false,
  },
  {
    code: "MNE",
    name: "Montenegro",
    eu: false,
  },
  {
    code: "MSR",
    name: "Montserrat",
    eu: false,
  },
  {
    code: "MAR",
    name: "Morocco",
    eu: false,
  },
  {
    code: "MOZ",
    name: "Mozambique",
    eu: false,
  },
  {
    code: "MMR",
    name: "Myanmar",
    eu: false,
  },
  {
    code: "NAM",
    name: "Namibia",
    eu: false,
  },
  {
    code: "NRU",
    name: "Nauru",
    eu: false,
  },
  {
    code: "NPL",
    name: "Nepal",
    eu: false,
  },
  {
    code: "NLD",
    name: "Netherlands",
    eu: true,
  },
  {
    code: "NCL",
    name: "New Caledonia",
    eu: false,
  },
  {
    code: "NZL",
    name: "New Zealand",
    eu: false,
  },
  {
    code: "NIC",
    name: "Nicaragua",
    eu: false,
  },
  {
    code: "NER",
    name: "Niger",
    eu: false,
  },
  {
    code: "NGA",
    name: "Nigeria",
    eu: false,
  },
  {
    code: "NIU",
    name: "Niue",
    eu: false,
  },
  {
    code: "NFK",
    name: "Norfolk Island",
    eu: false,
  },
  {
    code: "MNP",
    name: "Northern Mariana Islands",
    eu: false,
  },
  {
    code: "NOR",
    name: "Norway",
    eu: false,
  },
  {
    code: "OMN",
    name: "Oman",
    eu: false,
  },
  {
    code: "PAK",
    name: "Pakistan",
    eu: false,
  },
  {
    code: "PLW",
    name: "Palau",
    eu: false,
  },
  {
    code: "PSE",
    name: "Palestinian Territory, Occupied",
    eu: false,
  },
  {
    code: "PAN",
    name: "Panama",
    eu: false,
  },
  {
    code: "PNG",
    name: "Papua New Guinea",
    eu: false,
  },
  {
    code: "PRY",
    name: "Paraguay",
    eu: false,
  },
  {
    code: "PER",
    name: "Peru",
    eu: false,
  },
  {
    code: "PHL",
    name: "Philippines",
    eu: false,
  },
  {
    code: "PCN",
    name: "Pitcairn",
    eu: false,
  },
  {
    code: "POL",
    name: "Poland",
    eu: true,
  },
  {
    code: "PRT",
    name: "Portugal",
    eu: true,
  },
  {
    code: "PRI",
    name: "Puerto Rico",
    eu: false,
  },
  {
    code: "QAT",
    name: "Qatar",
    eu: false,
  },
  {
    code: "REU",
    name: "Réunion",
    eu: false,
  },
  {
    code: "ROU",
    name: "Romania",
    eu: true,
  },
  {
    code: "RUS",
    name: "Russian Federation",
    eu: false,
  },
  {
    code: "RWA",
    name: "Rwanda",
    eu: false,
  },
  {
    code: "BLM",
    name: "Saint Barthélemy",
    eu: false,
  },
  {
    code: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    eu: false,
  },
  {
    code: "KNA",
    name: "Saint Kitts and Nevis",
    eu: false,
  },
  {
    code: "LCA",
    name: "Saint Lucia",
    eu: false,
  },
  {
    code: "MAF",
    name: "Saint Martin (French part)",
    eu: false,
  },
  {
    code: "SPM",
    name: "Saint Pierre and Miquelon",
    eu: false,
  },
  {
    code: "VCT",
    name: "Saint Vincent and the Grenadines",
    eu: false,
  },
  {
    code: "WSM",
    name: "Samoa",
    eu: false,
  },
  {
    code: "SMR",
    name: "San Marino",
    eu: false,
  },
  {
    code: "STP",
    name: "Sao Tome and Principe",
    eu: false,
  },
  {
    code: "SAU",
    name: "Saudi Arabia",
    eu: false,
  },
  {
    code: "SEN",
    name: "Senegal",
    eu: false,
  },
  {
    code: "SRB",
    name: "Serbia",
    eu: false,
  },
  {
    code: "SYC",
    name: "Seychelles",
    eu: false,
  },
  {
    code: "SLE",
    name: "Sierra Leone",
    eu: false,
  },
  {
    code: "SGP",
    name: "Singapore",
    eu: false,
  },
  {
    code: "SXM",
    name: "Sint Maarten (Dutch part)",
    eu: false,
  },
  {
    code: "SVK",
    name: "Slovakia",
    eu: true,
  },
  {
    code: "SVN",
    name: "Slovenia",
    eu: true,
  },
  {
    code: "SLB",
    name: "Solomon Islands",
    eu: false,
  },
  {
    code: "SOM",
    name: "Somalia",
    eu: false,
  },
  {
    code: "ZAF",
    name: "South Africa",
    eu: false,
  },
  {
    code: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    eu: false,
  },
  {
    code: "SSD",
    name: "South Sudan",
    eu: false,
  },
  {
    code: "ESP",
    name: "Spain",
    eu: true,
  },
  {
    code: "LKA",
    name: "Sri Lanka",
    eu: false,
  },
  {
    code: "SDN",
    name: "Sudan",
    eu: false,
  },
  {
    code: "SUR",
    name: "Suriname",
    eu: false,
  },
  {
    code: "SJM",
    name: "Svalbard and Jan Mayen",
    eu: false,
  },
  {
    code: "SWZ",
    name: "Swaziland",
    eu: false,
  },
  {
    code: "SWE",
    name: "Sweden",
    eu: true,
  },
  {
    code: "CHE",
    name: "Switzerland",
    eu: false,
  },
  {
    code: "SYR",
    name: "Syrian Arab Republic",
    eu: false,
  },
  {
    code: "TWN",
    name: "Taiwan, Province of China",
    eu: false,
  },
  {
    code: "TJK",
    name: "Tajikistan",
    eu: false,
  },
  {
    code: "TZA",
    name: "Tanzania, United Republic of",
    eu: false,
  },
  {
    code: "THA",
    name: "Thailand",
    eu: false,
  },
  {
    code: "TLS",
    name: "Timor-Leste",
    eu: false,
  },
  {
    code: "TGO",
    name: "Togo",
    eu: false,
  },
  {
    code: "TKL",
    name: "Tokelau",
    eu: false,
  },
  {
    code: "TON",
    name: "Tonga",
    eu: false,
  },
  {
    code: "TTO",
    name: "Trinidad and Tobago",
    eu: false,
  },
  {
    code: "TUN",
    name: "Tunisia",
    eu: false,
  },
  {
    code: "TUR",
    name: "Turkey",
    eu: false,
  },
  {
    code: "TKM",
    name: "Turkmenistan",
    eu: false,
  },
  {
    code: "TCA",
    name: "Turks and Caicos Islands",
    eu: false,
  },
  {
    code: "TUV",
    name: "Tuvalu",
    eu: false,
  },
  {
    code: "UGA",
    name: "Uganda",
    eu: false,
  },
  {
    code: "UKR",
    name: "Ukraine",
    eu: false,
  },
  {
    code: "ARE",
    name: "United Arab Emirates",
    eu: false,
  },
  {
    code: "GBR",
    name: "United Kingdom",
    eu: true,
  },
  {
    code: "USA",
    name: "United States",
    eu: false,
  },
  {
    code: "UMI",
    name: "United States Minor Outlying Islands",
    eu: false,
  },
  {
    code: "URY",
    name: "Uruguay",
    eu: false,
  },
  {
    code: "UZB",
    name: "Uzbekistan",
    eu: false,
  },
  {
    code: "VUT",
    name: "Vanuatu",
    eu: false,
  },
  {
    code: "VEN",
    name: "Venezuela, Bolivarian Republic of",
    eu: false,
  },
  {
    code: "VNM",
    name: "Viet Nam",
    eu: false,
  },
  {
    code: "VGB",
    name: "Virgin Islands, British",
    eu: false,
  },
  {
    code: "VIR",
    name: "Virgin Islands, U.S.",
    eu: false,
  },
  {
    code: "WLF",
    name: "Wallis and Futuna",
    eu: false,
  },
  {
    code: "ESH",
    name: "Western Sahara",
    eu: false,
  },
  {
    code: "YEM",
    name: "Yemen",
    eu: false,
  },
  {
    code: "ZMB",
    name: "Zambia",
    eu: false,
  },
  {
    code: "ZWE",
    name: "Zimbabwe",
    eu: false,
  },
];

export default countries; 
